import { DateRangeType } from '../../@types/dashboard'
import api from '../../helpers/api'

export const getSummaryWithPath = <T>(path: string, dateRange: DateRangeType) =>
	api.get<void, T>(`/summary/${path}`, {
		params: {
			start: dateRange.start,
			end: dateRange.end,
		},
	})
