import React, { FC, useState } from 'react'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { BankConfig, SCBAccountConfig } from '../../@types/prefix'
import { Colors } from '../../helpers/color'
import { MobileSize } from '../../helpers/responsive'
import PrimaryButton from '../Common/Button/PrimaryButton'
import BankListItem from './BankListItem'
import CreateAccount from './CreateAccount'
import EditAccount from './EditAccount'
import ConfirmDeleteAccount from './ConfirmDeleteAccount'
import { toast } from 'react-toastify'
import PrefixService from '../../services/prefix'
import { requestAuthInit } from '../../store/auth/auth.actions'
import ContentContainer from '../Common/ContentContainer'

interface BankListProps {
	tab: string
	banks: BankConfig[]
	scbAccounts: SCBAccountConfig[]
}
const BankList: FC<BankListProps> = ({ tab, banks, scbAccounts }) => {
	const history = useHistory()
	const dispatch = useDispatch()

	const [createAccount, setCreateAccount] = useState(false);
    const toggleCreateAccount = () => setCreateAccount(!createAccount);

	const [editAccount, setEditAccount] = useState(false);
    const toggleEditAccount = () => setEditAccount(!editAccount);

	const [deleteAccount, setDeleteAccount] = useState(false);
    const toggleDeleteAccount = () => setDeleteAccount(!deleteAccount);

	const [accountNumber, setAccountNumber] = useState<string>('')
	const currentAccountNumber = (number: string) => setAccountNumber(number);

	const submitDeleteAccount = async () => {
		try {
			const res = await PrefixService.deleteBankAccount(accountNumber)

			dispatch(requestAuthInit(false))
			toast.success(res.message)
			history.push('/bank')
			toggleDeleteAccount()
		} catch ({ message }) {
			toast.error(message as string)
		}
	}

	const addTruewalletClick = () => {
		history.push('/bank/add-truewallet')
	}

	return (
		<LeftBankWrapper show={tab === 'scb'}>
			<ContentContainer>
				<BankHeader>
					<BankHeaderInner>
						<BankHeaderActionItem>
							<PrimaryButton
								type="button"
								width="120px"
								onClick={() => toggleCreateAccount()}
							>
								เพิ่มธนาคาร
							</PrimaryButton>
						</BankHeaderActionItem>
						{/* <BankHeaderActionItem>
							<PrimaryButton
								type="button"
								width="150px"
								onClick={addTruewalletClick}
							>
								เพิ่มทรูมันนี่วอลเล็ท
							</PrimaryButton>
						</BankHeaderActionItem> */}
					</BankHeaderInner>
				</BankHeader>
				<BankListItem
					banks={banks}
					scbAccounts={scbAccounts}
					currentAccountNumber={currentAccountNumber}
					toggleEditAccount={toggleEditAccount}
					toggleDeleteAccountModal={toggleDeleteAccount}
				/>

				<CreateAccount
					isOpenModal={createAccount}
					toggleCreateAccount={toggleCreateAccount}
				/>

				<EditAccount
					accountNumber={accountNumber}
					isOpenModal={editAccount}
					toggleEditAccount={ toggleEditAccount}
				/>

				<ConfirmDeleteAccount
					onSubmit={submitDeleteAccount}
					isOpenModal={deleteAccount}
					toggleDeleteAccount={toggleDeleteAccount}
				/>
			</ContentContainer>
		</LeftBankWrapper>
	)
}

export default BankList

interface BankWrapperSyledProps {
	show: boolean
}
const LeftBankWrapper = styled.div<BankWrapperSyledProps>`
	background: ${Colors.linkWater};
	width: 100%;
	border-radius: 25px 25px 25px 25px;
`

const BankHeader = styled.div`
	padding: 24px 0;
	border-bottom: 1px solid ${Colors.linkWater2};
`

const BankHeaderInner = styled.div`
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	margin: 0 24px 0 auto;
	width: calc(100% - 86px);
	@media only screen and (max-width: ${MobileSize}px) {
		width: calc(100% - 32px);
		margin: 0 auto;
	}
`
const BankHeaderActionItem = styled.div`
	margin-right: 12px;
	flex: 0 0 auto;
	@media only screen and (max-width: ${MobileSize}px) {
		width: 100%;
		margin-bottom: 12px;
	}
`
