import { RedeemSummaryItem } from '../api/summary/types'

export const RedeemSummarySort = (a: RedeemSummaryItem, b: RedeemSummaryItem) => {
	if (a.amount > b.amount) {
		return -1
	} else if (a.amount < b.amount) {
		return 1
	}
	return 0
}
