import { ButtonHTMLAttributes, FC } from 'react'
import styled from 'styled-components'
import { Colors } from '../../../helpers/color'
import { Fonts } from '../../../helpers/font'
import { MobileSize } from '../../../helpers/responsive'

interface PrimaryButtonProps {
	height?: string
	width?: string
	mobileWidth?: string

	loading?: boolean
}
const PrimaryButton: FC<
	PrimaryButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children, height, width, mobileWidth, loading, ...rest }) => (
	<Button
		{...rest}
		height={height}
		width={width}
		mobileWidth={mobileWidth}
		disabled={loading}>
		{loading ? <i className="fad fa-spinner fa-spin" /> : children}
	</Button>
)

export default PrimaryButton

interface ButtonStyledProps {
	height?: string
	width?: string
	mobileWidth?: string
}
const Button = styled.button<ButtonStyledProps>`
	cursor: pointer;
	width: ${({ width }) => width && width};
	height: ${({ height }) => (height ? height : '39px')};
	background: ${Colors.dodgerBlue};
	border-radius: 8px;
	color: ${Colors.white};
	align-items: center;
	justify-content: center;
	font-family: ${Fonts.primary};
	font-weight: bold;
	border: 0;
	outline: none;
	@media only screen and (max-width: ${MobileSize}px) {
		width: ${({ mobileWidth }) => (mobileWidth ? mobileWidth : '100%')};
	}

	&:disabled {
		background-color: ${Colors.casper};
	}
`
