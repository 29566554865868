import { BadgeType } from '../@types/badge'
import { Colors } from './color'

export const getBadgeColor = (type: BadgeType) => {
	switch (type) {
		case BadgeType.sucess: {
			return Colors.positive3
		}
		case BadgeType.failed: {
			return Colors.negative3
		}
		case BadgeType.warning: {
			return Colors.true3
		}
		case BadgeType.info: {
			return Colors.bbl
		}
		default: {
			return Colors.casper
		}
	}
}

export const getBadgeBG = (type: BadgeType) => {
	switch (type) {
		case BadgeType.sucess: {
			return Colors.positive2
		}
		case BadgeType.failed: {
			return Colors.negative2
		}
		case BadgeType.warning: {
			return Colors.true2
		}
		case BadgeType.info: {
			return Colors.zumthor
		}
		default: {
			return Colors.casper2
		}
	}
}
