import React, { FC, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ApplicationState } from '../../store'
import styled from 'styled-components'
import { useHistory } from 'react-router'
import { Modal } from 'reactstrap'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import PrefixService from '../../services/prefix'
import { usersConfig } from '../../@types/prefix'
import { requestAuthInit } from '../../store/auth/auth.actions'
import PrimaryButton from '../Common/Button/PrimaryButton'
import Input from '../Common/Input'
import moment from 'moment'
import CheckboxItem from '../Common/Checkbox/CheckboxItem'
import { Colors } from '../../helpers/color'

interface AddUserModalProps {
    userInfo: usersConfig | any
	permissions: object
	isOpenModal: boolean
	toggle: () => void
	update: () => void
}

const AddUserModal: FC<AddUserModalProps> = ({ userInfo, permissions, isOpenModal, toggle, update}) => {
    const permissionsObjRename = Object.fromEntries(
        Object.entries(permissions).map(([key, value]) => 
          // Modify key here
            [key.replace('manage.', ''), false]
        )
    )
	const dispatch = useDispatch()
	const history = useHistory()
	const {
		values,
		isSubmitting,
        setValues,
		setFieldValue,
		resetForm,
		handleChange,
		handleSubmit,
	} = useFormik({
		initialValues: {
			username: '',
            password: '',
            permissions: permissionsObjRename
		},
		enableReinitialize: true,
		onSubmit: async (values, { resetForm }) => {
			try {
                values.permissions = Object.fromEntries(
                    Object.entries(values.permissions).map(([key, value]) => 
                        key !== 'rewithdraw' ? [`manage.`+key, value] : [key, value]
                    )
                )
                
				const res = await PrefixService.editUsers(values.username, values)
				toggle()
				resetForm()
				dispatch(requestAuthInit(false))
				toast.success(res.message)
				update()
			} catch ({ message }) {
				toast.error(message as string)
			}
		},
	})

	useEffect(() => {
		if (isOpenModal) {
            resetForm()
            const permissionsUserInfo = Object.fromEntries(
                Object.entries(userInfo?.permissions).map(([key, value]) => 
                  // Modify key here
                    [key.replace('manage.', ''), value]
                )
            )
            setValues({
                username: userInfo?.username,
                password: '',
                permissions: userInfo?.permissions && permissionsUserInfo,
            })
		}
	}, [isOpenModal, resetForm, setFieldValue, userInfo, setValues]);

    const listPermissions =  Object.keys(permissions).map((key:string, index:any) => {
        const labelCheckbox = permissions[key as keyof typeof permissions];
        const nameKey = key.replace('manage.', '')
		const permissionsValue:boolean = values.permissions[nameKey as keyof typeof permissions];
        return (
            <CheckboxItem
                key={index}
                label={labelCheckbox}
                name={`permissions.${key}`}
                checked={permissionsValue}
                padding="0 0 8px 0"
                onChange={(value) => {
                    setFieldValue((`permissions.${nameKey}`), value)
                    
                }}
            />
        )
    })

	return (
        <Modal isOpen={isOpenModal} className="flex-row modal-dialog-centered" fade={true} toggle={() => toggle()}>
            <div className="modal-body p-lg-4">
                <div className="d-flex flex-row justify-content-between align-items-center mb-4">
                    <h3 className="mb-0"><strong>เพิ่มโค๊ดโปรโมชั่น</strong></h3>
                    <div className="modal-close">
                        <i className="far fa-times" onClick={() => toggle()}></i>
                    </div>
                </div>
                <Form onSubmit={handleSubmit}>
                    <InputSection>
                        <Input
                            type="text"
                            name="username"
                            label="ชื่อผู้ใช้งาน"
                            defaultValue={values.username}
                            required
                            onChange={handleChange}
                            disabled
                        />
                    </InputSection>
                    <InputSection>
                        <Input
                            type="password"
                            name="password"
                            label="รหัสผ่าน"
                            defaultValue={values.password}
                            onChange={handleChange}
                        />
                    </InputSection>
                    <InputSection>
                        <RuleTitle></RuleTitle>
                        <RuleCheckBoxWrapper>
                            {listPermissions}
                        </RuleCheckBoxWrapper>
                    </InputSection>
                    { isOpenModal &&
                    <InputSection>
                        <PrimaryButton
                            type="submit"
                            width="150px"
                            loading={isSubmitting}
                        >
                            บันทึกข้อมูล
                        </PrimaryButton>
                    </InputSection>
                    }
                </Form>
            </div>
        </Modal>
	)
}
export default AddUserModal


const Form = styled.form`
	width: 100%;
`
const InputSection = styled.div`
	margin-bottom: 20px;
	width: 100%;
`
const DescriptionTextAreaWrapper = styled.div`
	height: 126px;
`

const RuleTitle = styled.div`
	font-size: 18px;
	color: ${Colors.slateGray};
	font-weight: bold;
`
const RuleCheckBoxWrapper = styled.div`
	margin-top: 12px;
`