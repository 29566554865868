import styled from 'styled-components'
import { Colors } from '../../../helpers/color'
import { Fonts } from '../../../helpers/font'
import { MobileSize } from '../../../helpers/responsive'

export const TableWrapper = styled.div`
	@media only screen and (max-width: ${MobileSize}px) {
		overflow: scroll;
	}
`
export const Table = styled.table`
	@media only screen and (max-width: ${MobileSize}px) {
		width: max-content;
		display: table-cell;
	}
`

export const Thead = styled.thead`
	background: ${Colors.linkWater};
	border-top: 1px solid ${Colors.linkWater2};
	border-bottom: 1px solid ${Colors.linkWater2};
	font-family: ${Fonts.primary};
	font-weight: bold;
	font-size: 16px;
	color: ${Colors.black};
`
export const HeadTR = styled.tr``
export const BodyTR = styled.tr``

export const Th = styled.th`
	padding: 16px 1% 16px 1%;
	text-align: center;
`

export const Tbody = styled.tbody``

export const Td = styled.td`
	text-align: left;
	padding: 16px 20px 16px 20px;
	color: ${Colors.slateGray};
	border-bottom: 1px solid ${Colors.zircon};
`

export const TitleTable = styled.h2`
	color: ${Colors.dodgerBlue};
	font-size: 24px;
	font-weight: 700;
	width: fit-content;
	padding: 0;
	font-family: ${Fonts.primary};
	@media only screen and (max-width: ${MobileSize}px) {
		font-size: 20px;
	}
`
export const SubTitleTable = styled.h4`
	width: fit-content;
	color: ${Colors.casper};
	font-size: 14px;
	font-weight: normal;
	font-family: ${Fonts.primary};
	margin-left: 8px;
	@media only screen and (max-width: ${MobileSize}px) {
		margin-left: 0px;
	}
`

export const TableHeader = styled.div`
	flex-direction: row;
	justify-content: space-between;
	padding: 0 24px 16px 24px;
	@media only screen and (max-width: ${MobileSize}px) {
		flex-direction: column;
	}
`

export const TimeWrapper = styled.div`
	text-align: left;
`

export const TimeDate = styled.b`
	color: ${Colors.slateGray};
	font-size: 14px;
	margin-bottom: 4px;
`

export const Time = styled.span`
	margin-top: calc(0.25rem - 4px);
	color: ${Colors.casper};
	font-size: 14px;
`
