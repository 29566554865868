import React, { FC } from 'react'
import useSWR from 'swr'
import PrefixService from '../../services/prefix'
import styled from 'styled-components'
import moment from 'moment'
import Card, { CardTitle } from '../Common/Card'
import { Colors } from '../../helpers/color'
import { format } from '../../helpers/number'
import { DepositTransaction } from '../../@types/transactions'
import { PromotionConfig } from '../../@types/prefix'

interface DepositTransactionLastestProps {
	promotions: Record<string, PromotionConfig>
}

const DepositTransactionLastest: FC<DepositTransactionLastestProps> = ({ promotions }) => {
	const { data } = useSWR(
		`/deposit`,
		() =>
			PrefixService.getTransactionList<DepositTransaction>({
				dateRange: {
					start: moment().add(-1, 'days').format('YYYY-MM-DD'),
					end: moment().format('YYYY-MM-DD')
				},
				type: 'deposit',
				status: 'pending',
				limit: 20
			}),
		{
			refreshInterval: 10000,
		}
	)

	const getPromotion = (name : any) => {
		const promotion = Object.fromEntries(
			Object.entries(promotions).filter(([key]) => (key === name))
		)
		return Object.values(promotion)[0]?.name
	}

	return (
		<Card mobilePadding="16px">
			<CardTitle>รายการฝาก</CardTitle>
			<InfoContainer className="mt-3">
				{ data !== undefined
					?
						data?.count !== 0
						?
							Object.values(data?.transactions as any).map((item:any, index: number) => (
								<Card padding="12px 16px" mobilePadding="16px" marginBottom="12px" border="1px" key={index}>
									<Row>
										<div className="d-flex flex-row justify-content-between">
											<div>
												<strong>{item.member.firstName} {item.member.lastName}</strong>
												<small>{item.member.game.username}</small>
											</div>
											<div className="text-end">
												<strong><span>฿</span> {format(item.amount, 2)}</strong>
												<small style={{ color: '#f09b38' }} >รอดำเนินการ</small>
											</div>
										</div>
										<div className="other-info pt-2 mt-2">
											<small>ธนาคารที่โอนเข้ามา : {item.transferFrom}</small>
											<small>ทำรายการโอน : {moment(item?.createdAt).format('DD/MM/YYYY HH:mm:ss')}</small>
											<small className="mb-2">ดึงเข้าระบบ : {moment(item?.transferTime).format('DD/MM/YYYY HH:mm:ss')}</small>
										</div>
										<div style={{ alignItems: 'flex-start' }}>
											{ item.member.autoBonus === "off"
												? (<div style={{ color: '#f5365c' }}><DangerBadge>ไม่รับโบนัส</DangerBadge></div>)
												: (<InfoBadge>{getPromotion(item.member.autoBonus)}</InfoBadge>)
											}
										</div>
									</Row>
								</Card>
							))
						:
							<div style={{ color: '#95a0b8' }}>ไม่มีข้อมูล</div> 
					:
						<Loading>
							<i className="fad fa-spinner-third fa-spin fa-2x" />
						</Loading>
				}
			</InfoContainer>
		</Card>
	)
}

export default DepositTransactionLastest

const Loading = styled.div`
	width: 100%;
	text-align: center;
	color: ${Colors.anakiwa};
	margin-top: 2.5rem;
`

const Row = styled.div`
	font-family: Sarabun, sans-serif;
	font-style: normal;

	strong {
		color: #68748d;
	}

	small, span {
		color: #95a0b8;
	}
`

const DangerBadge = styled.div`
	min-width: 20px;
	height: 20px;
	padding: 0 12px;
	color: #ff4d4f;
	font-weight: bold;
	font-size: 12px;
	line-height: 20px;
	white-space: nowrap;
	text-align: center;
	background: #ff4d4f1c;
	border-radius: 10px;
	box-shadow: 0 0 0 1px #fff;
	overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
	display:inline-block;
`

const InfoBadge = styled.div`
	min-width: 20px;
	height: 20px;
	padding: 0 12px;
	color: #4f80ff;
	font-weight: bold;
	font-size: 12px;
	line-height: 20px;
	white-space: nowrap;
	text-align: center;
	background: #4f80ff1f;
	border-radius: 10px;
	box-shadow: 0 0 0 1px #fff;
	overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
	display:inline-block;
`

const InfoContainer = styled.div`
	.other-info {
		border-top: 1px solid #eee;
		align-items: flex-start;
	}

	@media only screen and (min-width: 1024px) and (max-width: 1400px) {
		.other-info {
			justify-content: space-between;
    	flex-direction: row;
		}
	}
	@media only screen and (min-width: 1400px) {
		.other-info {
			justify-content: flex-start;
    		flex-direction: column;
		}
	}
`