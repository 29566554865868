import React from 'react'
import styled from 'styled-components'
import PageLayout from '../components/PageLayout'
import { MobileSize } from '../helpers/responsive'
import DepositTransactionLastest from '../components/Main/DepositTransactionLastest'
import WithDrawTransactionLastest from '../components/Main/WithDrawTransactionLastest'
import BankTransactionLastest from '../components/Main/BankTransactionLastest'
import ContentContainer from '../components/Common/ContentContainer'
import { ApplicationState } from '../store'
import { useSelector } from 'react-redux'

const Main = () => {
	const { prefix } = useSelector((state: ApplicationState) => state.prefix)

	return (
		<PageLayout title="รายการล่าสุด" subTitle="รายการที่รอดำเนินการ / รานการที่ค้าง">
			<Body>
				<ContentContainer>
					<div className="row flex-row">
						<div className="col-12 col-xxl-4 d-block">
							<DepositTransactionLastest promotions={prefix.promotions} />
						</div>
						<div className="col-12 col-xxl-4 d-block">
							<WithDrawTransactionLastest />
						</div>
						<div className="col-12 col-xxl-4 d-block">
							<BankTransactionLastest />
						</div>
					</div>
				</ContentContainer>
			</Body>
		</PageLayout>
	)
}

export default Main

const Body = styled.div`
	width: 100%;
	flex: 1;
	padding: 32px 0;
	@media only screen and (max-width: ${MobileSize}px) {
		padding: 20px 0;
		margin-top: 20px;
	}
`