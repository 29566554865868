import React, { ChangeEvent, FC, FormEvent, useState, useEffect } from 'react'
import styled from 'styled-components'
import { Member } from '../../@types/member'
import { bankNames } from '../../helpers/bank'
import { Colors } from '../../helpers/color'
import { Fonts } from '../../helpers/font'
import { MobileSize } from '../../helpers/responsive'
import PrimaryButton from '../Common/Button/PrimaryButton'
import SecondButton from '../Common/Button/SecondButton'
import TwoColumn from '../Common/Columns/TwoColumn'
import Input from '../Common/Input'
import Select from '../Common/Select'
import Separator from '../Common/Separator'
import { ModalBody } from 'reactstrap'
import { toast } from 'react-toastify'
import PrefixService from '../../services/prefix'
interface EditMemberFormProps {
  member: Member
  loading: boolean
  isModal?: boolean

  setFieldValue: (field: string, v: any) => void
  onChange: (e: ChangeEvent<any>) => void
  onSubmit: (e: FormEvent<any>) => void
}
const EditMemberForm: FC<EditMemberFormProps> = ({
  member,
  loading,
  isModal,

  setFieldValue,
  onChange,
  onSubmit,
}) => {

  const [memberBankAccount, setMemberBankAccount] = useState<any>()
  const [loadingBankAccount, setLoadingBankAccount] = useState<boolean>(false)
  const getDataBank = async (id: number) => {
    try {
      setLoadingBankAccount(true)
      const responseMemberBankAccount = await PrefixService.getMemberBankAccount(id).then()

      setFieldValue('firstName', responseMemberBankAccount?.firstName)
      setFieldValue('lastName', responseMemberBankAccount?.lastName)
      setMemberBankAccount(responseMemberBankAccount);
      setLoadingBankAccount(false)
    } catch ({ message }) {
      toast.error(message as string)
      setLoadingBankAccount(false)
    }
  }

  useEffect(() => {
    if (memberBankAccount === undefined) {
      setMemberBankAccount({
        'firstName': member?.firstName,
        'lastName': member?.lastName,
      })
    }
  }, [member, isModal, memberBankAccount])
	return (
		<ModalBody style={{ maxWidth: '1200px' }}>

			<Form onSubmit={onSubmit} id="updateMemberForm">
				<div className="row flex-row">
					<div className="col-12 col-xxl-6 d-block">
						<MainTitleWrapper>
							<FormTitle>ข้อมูลสมาชิก</FormTitle>
						</MainTitleWrapper>
						<TwoColumn margin="12px">
							<InputSection>
								<Input
									type="text"
									name="phone"
									label="เบอร์โทรศัพท์"
									defaultValue={member?.phone}
									onChange={onChange}
								/>
							</InputSection>
							<InputSection>
								<Input
									type="text"
									name="twID"
									label="Truewallet ID"
									defaultValue={member?.twID}
									onChange={onChange}
								/>
							</InputSection>
						</TwoColumn>
						<TwoColumn margin="12px">
							<InputSection>
								<Input
									type="text"
									name="firstName"
									label="ชื่อ"
									defaultValue={memberBankAccount?.firstName}
									required
									onChange={onChange}
								/>
							</InputSection>
							<InputSection>
								<Input
									type="text"
									name="lastName"
									label="นามสกุล"
									defaultValue={memberBankAccount?.lastName}
									required
									onChange={onChange}
								/>
							</InputSection>
						</TwoColumn>

						<TwoColumn margin="12px">
							<InputSection>
								<Input
									type="text"
									name="firstNameEN"
									label="ชื่อ (ภาษาอังกฤษ)"
									defaultValue={member?.firstNameEN}
									onChange={onChange}
								/>
							</InputSection>
							<InputSection>
								<Input
									type="text"
									name="lastNameEN"
									label="นามสกุล (ภาษาอังกฤษ)"
									defaultValue={member?.lastNameEN}
									onChange={onChange}
								/>
							</InputSection>
						</TwoColumn>
						<SeparatorWrapper>
							<Separator color={Colors.linkWater} />
						</SeparatorWrapper>
						<MainTitleWrapper>
							<FormTitle>ตั้งค่ารหัสผ่าน</FormTitle>
						</MainTitleWrapper>
						<TwoColumn margin="12px">
							<InputSection>
								<Input
									type="text"
									name="password"
									label="รหัสผ่าน"
									onChange={onChange}
								/>
							</InputSection>
							<InputSection>
								<Input
									type="text"
									name="gamePassword"
									label="รหัสผ่านสำหรับเปิดยูซเซอร์ใหม่ "
									onChange={onChange}
								/>
							</InputSection>
						</TwoColumn>
						<SeparatorWrapper>
							<Separator color={Colors.linkWater} />
						</SeparatorWrapper>
					</div>
					<div className="col-12 col-xxl-6 d-block" style={{ borderLeft: `1px solid ${Colors.linkWater}` }}>
						<MainTitleWrapper>
							<FormTitle>ข้อมูลธนาคาร</FormTitle>
						</MainTitleWrapper>
						<TwoColumn margin="12px">
							<InputSection>
								<Select
									name="bankCode"
									items={Object.keys(bankNames).map((key) => ({
										label: bankNames[key],
										value: key,
									}))}
									label="ธนาคาร"
									placeholder="-- เลือกธนาคาร --"
									defaultValue={member?.bankCode}
									required
									onChange={onChange}
								/>
							</InputSection>
							<InputSection>
								<Input
									type="text"
									name="bankNumber"
									label="เลขที่บัญชี"
									defaultValue={member?.bankNumber}
									required
									onChange={onChange}
								/>
							</InputSection>
						</TwoColumn>
						<SeparatorWrapper>
							<Separator color={Colors.linkWater} />
						</SeparatorWrapper>

						<MainTitleWrapper>
							<FormTitle>ตั้งค่าเทิร์น</FormTitle>
						</MainTitleWrapper>
						<TwoColumn margin="12px">
							<InputSection>
								<Input
									type="number"
									name="affiliate.balance"
									label="ยอดเชิญเพื่อน"
									defaultValue={member?.affiliate.balance}
									required
									onChange={onChange}
								/>
							</InputSection>
							<InputSection>
								<Input
									type="number"
									name="totalTurn"
									label="เทิร์นที่ต้องทำ"
									defaultValue={member?.totalTurn}
									required
									onChange={onChange}
								/>
							</InputSection>
						</TwoColumn>
						<TwoColumn margin="12px">
							<InputSection>
								<Input
									type="number"
									name="minWithdraw"
									label="ยอดถอนขั้นต่ำ"
									defaultValue={member?.minWithdraw}
									required
									onChange={onChange}
								/>
							</InputSection>
							<InputSection>
								<Input
									type="number"
									name="maxWithdraw"
									label="ยอดถอนสูงสุด"
									defaultValue={member?.maxWithdraw}
									required
									onChange={onChange}
								/>
							</InputSection>
						</TwoColumn>
						<SeparatorWrapper>
							<Separator color={Colors.linkWater} />
						</SeparatorWrapper>

						<SecondButton type="button" width="220px" loading={loadingBankAccount} onClick={() => getDataBank(member?.id)}>
							ดึงข้อมูลจากบัญชีธนาคาร
						</SecondButton>
					</div>
				</div>


				{isModal ? '' :
					<ButtonGroup>
						<PrimaryButton type="submit" width="120px" loading={loading}>
							บันทึกข้อมูล
						</PrimaryButton>
					</ButtonGroup>
				}
			</Form>
		</ModalBody>
	)
}

export default EditMemberForm

const Form = styled.form`
	width: 100%;
`
const FormTitle = styled.div`
	flex: 1;
	font-size: 20px;
	color: ${Colors.black2};
	font-weight: bold;
	font-family: ${Fonts.primary};
	padding: 0;
	@media only screen and (max-width: ${MobileSize}px) {
		width: 100%;
	}
`

const InputSection = styled.div`
	margin-bottom: 20px;
	width: 100%;
`

const MainTitleWrapper = styled.div`
	width: 100%;
	justify-content: flex-start;
	align-items: flex-start;
	margin-top: 20px;
	margin-bottom: 24px;
`

const SeparatorWrapper = styled.div`
	margin: 20px 0;
	width: 100%;
`

const ButtonGroup = styled.div`
	width: 100%;
	margin-top: 2rem;
	flex-direction: row;
	justify-content: center;
`
