import moment from 'moment'
import React, { FC } from 'react'
import styled from 'styled-components'
import { BonusTx } from '../../../api/transaction/types'
import { Colors } from '../../../helpers/color'
import { Fonts } from '../../../helpers/font'
import { format } from '../../../helpers/number'
import MoneyWithCurrency from '../../Common/Money/MoneyWithCurrency'
import PhoneNumberWithIcon from '../../Common/PhoneNumberWithIcon/PhoneNumberWithIcon'
import { BodyTR, Table, TableWrapper, Tbody, Td, Th, Thead, Time, TimeDate, TimeWrapper } from '../../Common/Table'

interface BonusTxListTableProps {
	data: BonusTx[]
}
const BonusTxListTable: FC<BonusTxListTableProps> = ({ data }) => {
	const renderContent = () =>
		data.map((row) => (
			<BodyTR key={row.createdAt}>
				<Td>
					<TimeWrapper>
						<TimeDate>{moment(row.createdAt).format('DD/MM/YYYY')}</TimeDate>
						<Time>{moment(row.createdAt).format('HH:hh:ss')}</Time>
					</TimeWrapper>
				</Td>
				<Td>
					<PhoneNumberWrapper>
						<span>
							{row.member.firstName} {row.member.lastName}
						</span>
						<PhoneNumberWithIcon phoneNumber={row.member.phone} />
					</PhoneNumberWrapper>
				</Td>
				<Td>
					<BonusName>{row.promotion.name}</BonusName>
				</Td>
				<Td>
					<MoneyWithCurrency value={format(row.amount, 2)} fontSize="16px" />
				</Td>
				<Td>
					<MoneyWithCurrency value={format(row.totalTurn, 2)} fontSize="16px" />
				</Td>
				<Td>
					<b>{format(row.turn, 0)}</b>
					&nbsp;เท่า
				</Td>
			</BodyTR>
		))

	return (
		<TableWrapper>
			<Table>
				<Thead>
					<tr>
						<Th style={{ textAlign: 'left' }}>เวลา</Th>
						<Th style={{ textAlign: 'left' }}>สมาชิก</Th>
						<Th style={{ textAlign: 'left' }}>โบนัส</Th>
						<Th style={{ textAlign: 'left' }}>จำนวนเงิน</Th>
						<Th style={{ textAlign: 'left' }}>ยอดเทิร์น</Th>
						<Th style={{ textAlign: 'left' }}>เทิร์น</Th>
					</tr>
				</Thead>
				<Tbody>{renderContent()}</Tbody>
			</Table>
		</TableWrapper>
	)
}

export default BonusTxListTable

const BonusName = styled.div`
	font-size: 16px;
	color: ${Colors.slateGray};
	font-family: ${Fonts.primary};
`

const PhoneNumberWrapper = styled.div`
	font-size: 16px;
	color: ${Colors.slateGray};
	font-weight: bold;
`
