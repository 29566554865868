import React, { ChangeEvent, FC, useEffect } from 'react'
import styled from 'styled-components'
import { PromotionConfig } from '../../@types/prefix'
import trashIcon from '../../assets/images/trash-icon.svg'
import { Colors } from '../../helpers/color'
import { Fonts } from '../../helpers/font'
import CheckboxWithContent from '../Common/Checkbox/CheckboxWithContent'
import Input from '../Common/Input'

interface PeriodSettingProps {
	data: PromotionConfig

	onChange: (e: ChangeEvent<any>) => void
	setFieldValue: (field: string, value: any) => void
}
const PeriodSetting: FC<PeriodSettingProps> = ({
	data,
	onChange,
	setFieldValue,
}) => {
	const periods = data.periods || [{ from: "00:00", to: "23:59", deleted: false }]

	useEffect(() => {
		if(!data.periods) {
			setFieldValue('periods', periods)
		}
	}, [])

	return (
		<CheckboxWithContent
			label="ใช้งานโบนัสตามช่วงเวลา"
			checked={data.period}
			setChecked={(v: boolean) => setFieldValue('period', v)}
		>
			<CheckboxWithContentBody>
				{periods.map((row, pIndex) =>
					row.deleted ? null : (
						<TopCheckboxWithContentSection key={pIndex}>
							<LeftCheckboxWithContentBody>
								<CheckboxWithContentInputItemWrapper>
									<Input
										type="text"
										name={`periods[${pIndex}].from`}
										label="จาก"
										defaultValue={row.from}
										required
										onChange={onChange}
									/>
								</CheckboxWithContentInputItemWrapper>
								<CheckboxWithContentInputItemWrapper>
									<Input
										type="text"
										name={`periods[${pIndex}].to`}
										label="ถึง"
										defaultValue={row.to}
										required
										onChange={onChange}
									/>
								</CheckboxWithContentInputItemWrapper>
							</LeftCheckboxWithContentBody>
							<RigtCheckboxWithContentBody>
								{
									pIndex > 0 ? (
										<RemoveItemButton
											src={trashIcon}
											alt="remove"
											onClick={() => {
												data.periods[pIndex].deleted = true

												setFieldValue('periods', periods)
											}}
										/>
									) : (
										<div style={{ width: '24px' }}></div>
									)
								}
							</RigtCheckboxWithContentBody>
						</TopCheckboxWithContentSection>
					)
				)}
				<BottomCheckboxWithContentSection
					onClick={() => {
						periods.push({
							from: '00:00',
							to: '23:59',
							deleted: false,
						})
						setFieldValue('periods', periods)
					}}
				>
					<AddItemButton>เพิ่มเงื่อนไข</AddItemButton>
				</BottomCheckboxWithContentSection>
			</CheckboxWithContentBody>
		</CheckboxWithContent>
	)
}
export default PeriodSetting

const CheckboxWithContentBody = styled.div``

const TopCheckboxWithContentSection = styled.div`
	padding: 0 16px;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;

	&:not(:first-child) {
		margin-top: 15px;
	}
`
const BottomCheckboxWithContentSection = styled.div`
	margin-top: 20px;
`

const LeftCheckboxWithContentBody = styled.div`
	flex-direction: row;
	margin: 0 -6px;
	flex: 1;
	align-items: center;
`
const CheckboxWithContentInputItemWrapper = styled.div`
	margin: 0 6px;
	flex: 1;
	width: fit-content;
	margin: 0 12px 0 0;
`
const RigtCheckboxWithContentBody = styled.div`
	margin-left: 16px;
	align-items: center;
`
const RemoveItemButton = styled.img`
	margin-top: 24px;
	cursor: pointer;
`
const AddItemButton = styled.div`
	flex: 0 0 auto;
	width: 100%;
	height: 35px;
	align-items: center;
	justify-content: center;
	background: ${Colors.linkWater2};
	border-radius: 0px 0px 10px 10px;
	font-family: ${Fonts.primary};
	font-weight: bold;
	font-size: 14px;
	color: ${Colors.casper2};
	cursor: pointer;
`
