import debounce from 'lodash/debounce'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import ReactPaginate from 'react-paginate'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { usersConfig } from '../../@types/prefix'
import { format } from '../../helpers/number'
import { usePagination } from '../../hooks/usePagination'
import Card, {
	CardActionHeaderWrapper,
	CardActionItem,
	CardSubTitle,
	CardTitle,
	CardTitletRowWrapperWithOutBorder,
	CardTitleWrapperWithOutBorder,
} from '../Common/Card'
import SearchBar from '../Common/Input/SearchBar'
import { CountItemList, PaginateWrapper, TableWrapper } from '../Common/Table'
import UesrListTable from './UesrListTable'
import ContentContainer from '../Common/ContentContainer'
import PrimaryButton from '../Common/Button/PrimaryButton'
import IconAdd from '../../assets/images/add-icon.svg'
import PrefixService from '../../services/prefix'
import { requestAuthInit } from '../../store/auth/auth.actions'
import AddUserModal from './AddUserModal'
// import ConfirmDelete from './ConfirmDelete'
import EditUserModal from './EditUserModal'
import Open2faModal from './Open2faModal'
import Close2faModal from './Close2faModal'

interface UserListContainerProps {
	data: usersConfig[]
	permissions: object

	update: () => void
	setSearch: (v: string) => void
}

const UserListContainer: FC<UserListContainerProps> = ({ data, permissions, update, setSearch }) => {
	const dispatch = useDispatch()
	const _setSearch = debounce((v: string) => {
		setSearch(v)
	}, 200)
	const [addUserModal, setAddUserModal] = useState(false)
	const toggleAddUser = () => setAddUserModal(!addUserModal)
	// const [deleteRedeemModal, setDeleteRedeemModal] = useState(false)
	// const toggleDeleteRedeem = () => setDeleteRedeemModal(!deleteRedeemModal)
	const [IdUser, setIdUser] = useState(0)
	const [editUser, setEditUser] = useState(false)
	const toggleEditUser = () => setEditUser(!editUser)

	const [open2faUser, setOpen2faUser] = useState(false)
	const toggleOpen2faUser = () => setOpen2faUser(!open2faUser)
	const [close2faUser, setClose2faUser] = useState(false)
	const toggleClose2faUser = () => setClose2faUser(!close2faUser)
	const { data: _data, page, pageCount, setPage } = usePagination(data)

	useEffect(() => {
        setPage(0)
	}, [data, setPage])

	// // // Edit Member
	const [userInfo, setUserInfo] = useState<usersConfig>()


	const fetchUsersInfo = async (id: number, action: string) => {
		// if (action === 'delete') {
		// 	toggleDeleteRedeem()
		// 	setIdUser(id)
		// }else 
        if(action === 'edit') {
			toggleEditUser()
			setIdUser(id)
			const dataEdit = _data.find((item:usersConfig) => item.id === id)
			setUserInfo(dataEdit);
			
		}else if(action === '2faOpen'){
            toggleOpen2faUser()
			setIdUser(id)
			const dataEdit = _data.find((item:usersConfig) => item.id === id)
			setUserInfo(dataEdit);
        }else if(action === '2faColse'){
            toggleClose2faUser()
			setIdUser(id)
        }
        
	}

	// const submitDeleteRedeem = async () => {
	// 	try {
	// 		const res = await PrefixService.deleteRedeem(IdRedeem)
	// 		dispatch(requestAuthInit(false))
	// 		toast.success(res.message)
	// 		toggleDeleteRedeem()
	// 		update();
	// 	} catch ({ message }) {
	// 		toast.error(message as string)
	// 	}
	// }
	const submitClose2FA = async () => {
		try {
			const res = await PrefixService.disable2FA(IdUser)
			dispatch(requestAuthInit(false))
			toast.success(res.message)
			toggleClose2faUser()
			update();
		} catch ({ message }) {
			toast.error(message as string)
		}
	}


	return (
		<>
        <ContentContainer>
			<Card padding="24px 0 0 0">
				<CardTitleWrapperWithOutBorder>
					<CardTitletRowWrapperWithOutBorder>
						<CardTitle>รายการผู้ใช้งาน</CardTitle>
						<CardSubTitle>{format(data?.length || 0, 0)} รายการ</CardSubTitle>
					</CardTitletRowWrapperWithOutBorder>
					<CardActionHeaderWrapper style={{marginRight:'20px'}}>
						<CardActionItem>
							<SearchBar
								type="text"
								placeholder="ค้นหา ..."
								onChange={(e) => _setSearch(e.currentTarget.value)}
							/>
						</CardActionItem>
					</CardActionHeaderWrapper>
                    <PrimaryButton onClick={() => toggleAddUser()}>
						<AddButtonWrapper>
							<AddIcon src={IconAdd} alt="add" />
							<span>เพิ่มผู้ใช้งาน</span>
						</AddButtonWrapper>
					</PrimaryButton>
				</CardTitleWrapperWithOutBorder>
				<TableWrapper>
					<UesrListTable
						data={_data}
						fetchInfo={fetchUsersInfo}
					/>
					<PaginateWrapper>
						<CountItemList></CountItemList>
						<ReactPaginate
							pageCount={pageCount}
							forcePage={page}
							pageRangeDisplayed={5}
							marginPagesDisplayed={2}
							containerClassName={'pagination'}
							pageClassName={'pages pagination-pages'}
							activeClassName={'active-pagination'}
							onPageChange={(e) => {
								setPage(e.selected)
							}}
						/>
					</PaginateWrapper>
				</TableWrapper>
			</Card>
		</ContentContainer>

			<AddUserModal
				update={update}
				permissions={permissions}
                isOpenModal={addUserModal}
                toggle={toggleAddUser}
            />
            {/* 
			<ConfirmDelete
            onSubmit={() => submitDeleteRedeem()}
            isOpenModal={deleteRedeemModal}
            toggleDelete={() => toggleDeleteRedeem()}
			/>
        */}

			<EditUserModal
				userInfo={userInfo}
				permissions={permissions}
                isOpenModal={editUser}
				update={update}
                toggle={toggleEditUser}
            /> 

			<Open2faModal
				userInfo={userInfo}
                isOpenModal={open2faUser}
				update={update}
                toggle={toggleOpen2faUser}
            /> 
            <Close2faModal
                onSubmit={() => submitClose2FA()}
                isOpenModal={close2faUser}
                toggleModal={() => toggleClose2faUser()}
            />

			
		</>
	)
}

export default UserListContainer


const AddButtonWrapper = styled.div`
	flex-direction: row;
	align-items: center;
	padding: 0 8px;
`
const AddIcon = styled.img`
	margin-right: 8px;
`