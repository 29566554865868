import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import App from './App'
import './assets/app.scss'
import store from './store'
import Login from './views/Login'

ReactDOM.render(
	<>
		<Provider store={store}>
			<Router>
				<Switch>
					<Route exact path="/login" component={Login} />

					<App />
				</Switch>
			</Router>

			<ToastContainer />
		</Provider>
	</>,
	document.getElementById('root')
)
