import React from 'react'
import { CashbackSummaryItem } from '../../../api/summary/types'
import useDateRange from '../../../hooks/useDateRange'
import useFetchSummary from '../../../hooks/useFetchSummary'
import ContentContainer from '../../Common/ContentContainer'
import DateRange from '../../Common/DateRange'
import TabDataLoading from '../TabDataLoading'
import CashbackSummaryContainer from './CashbackSummaryContainer'

const CashbackTab = () => {
	const { dateRange, setDateRange } = useDateRange()
	const { data } = useFetchSummary<CashbackSummaryItem[]>('cashback', dateRange)

	return (
		<ContentContainer>
			<DateRange onChange={setDateRange} />

			{!data ? <TabDataLoading /> : <CashbackSummaryContainer data={data} />}
		</ContentContainer>
	)
}

export default CashbackTab
