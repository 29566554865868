import styled from 'styled-components'
import nextIcon from '../../../assets/images/next-paginate-icon.svg'
import prevIcon from '../../../assets/images/prev-paginate-icon.svg'
import { Colors } from '../../../helpers/color'
import { MobileSize } from '../../../helpers/responsive'

export const paginationIcon = { prevIcon: prevIcon, nextIcon: nextIcon }

export const PaginateWrapper = styled.div`
	background: ${Colors.selago};
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	flex-direction: row;
	justify-content: space-between;
	padding: 0 24px;
	@media only screen and (max-width: ${MobileSize}px) {
		padding: 0 24px;
		width: 100%;
		text-align: center;
		align-items: center;
		flex-direction: column;
	}
`

export const CountItemList = styled.div`
	flex-direction: row;
	font-size: 14px;
	font-weight: bold;
	align-items: center;
	span {
		color: ${Colors.slateGray};
		margin-right: 2px;
	}
	p {
		color: ${Colors.casper};
	}
`
