import axios, { AxiosError } from 'axios'
import AuthService from '../services/auth'
import PrefixService from '../services/prefix'

const baseRequest = axios.create({
	baseURL: process.env.REACT_APP_API_ENDPOINT,
})

baseRequest.interceptors.request.use((config) => {
	const token = AuthService.getToken()
	const prefix = PrefixService.getPrefix()

	return {
		...config,
		headers: {
			...config.headers,
			...(prefix && { 'X-Prefix': prefix }),
			...(token && { Authorization: token }),
		},
	}
})

baseRequest.interceptors.response.use(
	(res) => res.data,
	(err: AxiosError) => {
		if (err.response) {
			return Promise.reject(err.response.data)
		}
		return Promise.reject({
			message: 'ไม่สามารถเชื่อมต่อเซิร์ฟเวอร์ได้ในขณะนี้',
		})
	}
)

export default baseRequest
