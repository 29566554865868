import { useState } from 'react'
import useSWR from 'swr'
import { fetchMember } from '../api/member/member'
import { DateRangeType } from '../api/types'

export const useFetchMember = (dateRange: DateRangeType) => {
	const [search, setSearch] = useState('')
	const { data, mutate } = useSWR([`/members`, dateRange.start, dateRange.end, search], () =>
		fetchMember({
			dateRange: dateRange,
			search: search,
		})
	)

	return {
		data,
		
		mutate,
		setSearch,
	}
}

export default useFetchMember
