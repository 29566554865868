import { useEffect, useState } from 'react'

interface UsePagination<T> {
	data: T[]

	page: number
	pageCount: number
	setPage: (v: number) => void
}

export const usePagination = <T>(data: T[]): UsePagination<T> => {
	const [page, setPage] = useState(0)
	useEffect(() => {
		setPage(0)
	}, [data, setPage])

	const sizePerPage = 10
	const pageCount = Math.ceil(data.length / sizePerPage)
	const _data = data.slice(sizePerPage * page, sizePerPage * (page + 1))

	return {
		data: _data,
		pageCount: pageCount <= 0 ? 1 : pageCount,
		page: page,
		setPage: setPage,
	}
}
