import { BankConfig, SCBAccountConfig } from '../@types/prefix'
import baacIcon from '../assets/images/bank-logo/baac.svg'
import bayIcon from '../assets/images/bank-logo/bay.svg'
import bblIcon from '../assets/images/bank-logo/bbl.svg'
import bocIcon from '../assets/images/bank-logo/boc.svg'
import bofaIcon from '../assets/images/bank-logo/bofa.svg'
import cimbIcon from '../assets/images/bank-logo/cimb.svg'
import citiIcon from '../assets/images/bank-logo/citi.svg'
import ghIcon from '../assets/images/bank-logo/gh.svg'
import gsbIcon from '../assets/images/bank-logo/gsb.svg'
import hsbcIcon from '../assets/images/bank-logo/hsbc.svg'
import ibankIcon from '../assets/images/bank-logo/ibank.svg'
import icbcIcon from '../assets/images/bank-logo/icbc.svg'
import kbankIcon from '../assets/images/bank-logo/kbank.svg'
import kkIcon from '../assets/images/bank-logo/kk.svg'
import ktbIcon from '../assets/images/bank-logo/ktb.svg'
import lhIcon from '../assets/images/bank-logo/lh.svg'
import scIcon from '../assets/images/bank-logo/sc.svg'
import scbIcon from '../assets/images/bank-logo/scb.svg'
import tbankIcon from '../assets/images/bank-logo/tbank.svg'
import tiscoIcon from '../assets/images/bank-logo/tisco.svg'
import tmbIcon from '../assets/images/bank-logo/tmb.svg'
import ttbIcon from '../assets/images/bank-logo/ttb.svg'
import truewalletIcon from '../assets/images/bank-logo/truewallet.svg'
import uobtIcon from '../assets/images/bank-logo/uobt.svg'
import { OptionType } from '../components/Common/Select/Select'

export const bankNames: Record<string, string> = {
	KBANK: 'ธนาคารกสิกรไทย',
	BBL: 'ธนาคารกรุงเทพ',
	KTB: 'ธนาคารกรุงไทย',
	BAY: 'ธนาคารกรุงศรีอยุธยา',
	SCB: 'ธนาคารไทยพาณิชย์',
	KK: 'ธนาคารเกียรตินาคิน',
	CITI: 'ธนาคารซิตี้แบ้งค์',
	CIMB: 'ธนาคารซีไอเอ็มบี (ไทย)',
	TMB: 'ธนาคารทหารไทย',
	TTB: 'ธนาคารทหารไทยธนชาต',
	TISCO: 'ธนาคารทิสโก้',
	TBANK: 'ธนาคารธนชาต',
	BAAC: 'ธ.ก.ส.',
	UOBT: 'ธนาคารยูโอบี',
	LH: 'ธนาคารแลนด์ แอนด์ เฮ้าส',
	SC: 'ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย)',
	BOC: 'ธนาคารแห่งประเทศจีน (ไทย)',
	BOFA: 'ธนาคารแห่งอเมริกาฯ',
	GSB: 'ธนาคารออมสิน',
	GH: 'ธนาคารอาคารสงเคราะห์',
	IBANK: 'ธนาคารอิสลามแห่งประเทศไทย',
	ICBC: 'ธนาคารไอซีบีซี (ไทย)',
	HSBC: 'ธนาคารฮ่องกงและเซี่ยงไฮ้',
	TRUEWALLET: 'ทรูมันนี่วอลเล็ท',
}

export const getBankName = (code: string = 'UNKNOWN') => {
	code = code.toUpperCase()
	if (code === 'TRUEWALLET') {
		return 'ทรูมันนี่วอลเล็ท'
	}
	if (bankNames.hasOwnProperty(code)) {
		return bankNames[code]
	}
	return code
}
export const getBankIcon = (code: string) => {
	code = code.toUpperCase()
	switch (code) {
		case 'KBANK': {
			return kbankIcon
		}
		case 'BBL': {
			return bblIcon
		}
		case 'KTB': {
			return ktbIcon
		}
		case 'BAY': {
			return bayIcon
		}
		case 'SCB': {
			return scbIcon
		}
		case 'KK': {
			return kkIcon
		}
		case 'CITI': {
			return citiIcon
		}
		case 'CIMB': {
			return cimbIcon
		}
		case 'TMB': {
			return tmbIcon
		}
		case 'TTB': {
			return ttbIcon
		}
		case 'TISCO': {
			return tiscoIcon
		}
		case 'TBANK': {
			return tbankIcon
		}
		case 'BAAC': {
			return baacIcon
		}
		case 'UOBT': {
			return uobtIcon
		}
		case 'LH': {
			return lhIcon
		}
		case 'SC': {
			return scIcon
		}
		case 'BOC': {
			return bocIcon
		}
		case 'BOFA': {
			return bofaIcon
		}
		case 'GSB': {
			return gsbIcon
		}
		case 'GH': {
			return ghIcon
		}
		case 'IBANK': {
			return ibankIcon
		}
		case 'ICBC': {
			return icbcIcon
		}
		case 'HSBC': {
			return hsbcIcon
		}
		case 'TRUEWALLET': {
			return truewalletIcon
		}
		default: {
			return ''
		}
	}
}

export const createBankSelector = (banks: BankConfig[]): OptionType[] =>
	banks
		.filter(
			({ bank, meta }) => bank === 'scb' && meta.username && meta.password
		)
		.map(({ accountNumber, accountName }) => ({
			label: `${accountNumber} - ${accountName}`,
			value: accountNumber,
		}))

export const createSCBAccountSelector = (
	accounts: SCBAccountConfig[]
): OptionType[] =>
	accounts.map(({ accountNumber, accountName }) => ({
		label: `${accountNumber} - ${accountName}`,
		value: accountNumber,
	}))
