import { useFormik } from 'formik'
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { SCBAccountConfig } from '../../../@types/prefix'
import { Colors } from '../../../helpers/color'
import { Fonts } from '../../../helpers/font'
import { MobileSize } from '../../../helpers/responsive'
import PrefixService from '../../../services/prefix'
import { requestAuthInit } from '../../../store/auth/auth.actions'
import PrimaryButton from '../../Common/Button/PrimaryButton'
import SecondButton from '../../Common/Button/SecondButton'
import Input from '../../Common/Input'
import Toggle from '../../Common/Toggle'

interface EditSCBAccountFormProps {
	data: SCBAccountConfig

	onClickDelete: (v: string) => void
}
const EditSCBAccountForm: FC<EditSCBAccountFormProps> = ({
	data,
	onClickDelete,
}) => {
	const dispatch = useDispatch()
	const history = useHistory()
	const { values, isSubmitting, setFieldValue, handleSubmit } = useFormik({
		initialValues: data,
		onSubmit: async (values) => {
			try {
				const res = await PrefixService.saveSCBAccount(values)

				dispatch(requestAuthInit(false))
				toast.success(res.message)
				history.push('/bank')
			} catch ({ message }) {
				toast.error(message as string)
			}
		},
	})

	return (
		<EditForm onSubmit={handleSubmit}>
			<MainTitleWrapper>
				<FormTitle>ข้อมูลบัญชี</FormTitle>
				<EditBankToggleWrapper>
					<Toggle
						active={values.enable}
						activeLabel="เปิดใช้งาน"
						disableLabel="ปิดใช้งาน"
						setActive={(v) => setFieldValue('enable', v)}
					/>
				</EditBankToggleWrapper>
			</MainTitleWrapper>

			<InputSection>
				<Input
					type="text"
					name="accountNumber"
					label="เลขที่บัญชี"
					defaultValue={values.accountNumber}
					readOnly={true}
					required
				/>
			</InputSection>
			<InputSection>
				<Input
					type="text"
					name="accountName"
					label="ชื่อบัญชี"
					defaultValue={values.accountName}
					readOnly={true}
					required
				/>
			</InputSection>

			<ButtonGroup>
				<SecondButton
					type="button"
					width="120px"
					color={Colors.negative}
					textColor={Colors.white}
					onClick={() => onClickDelete(data.accountNumber)}
				>
					ลบบัญชีนี้
				</SecondButton>
				<PrimaryButton
					type="submit"
					width="120px"
					loading={isSubmitting}
				>
					บันทึกข้อมูล
				</PrimaryButton>
			</ButtonGroup>
		</EditForm>
	)
}

export default EditSCBAccountForm

const EditForm = styled.form`
	width: 100%;
`
const FormTitle = styled.div`
	flex: 1;
	font-size: 24px;
	color: ${Colors.black2};
	font-weight: bold;
	font-family: ${Fonts.primary};
	margin: 0 0 20px 0;
	padding: 0;
`
const InputSection = styled.div`
	margin-bottom: 20px;
	width: 100%;
`
const MainTitleWrapper = styled.div`
	flex-direction: row;
	width: 100%;
	justify-content: flex-end;
	align-items: center;
	margin-top: 20px;
	margin-bottom: 16px;
`
const EditBankToggleWrapper = styled.div`
	width: 162px;
	height: 36px;
`
const ButtonGroup = styled.div`
	width: 100%;
	flex-direction: row;
	justify-content: space-between;
	@media only screen and (max-width: ${MobileSize}px) {
		flex-direction: column-reverse;
	}
`
