import React, { FC, Fragment, useEffect } from 'react'
import ReactPaginate from 'react-paginate'
import styled from 'styled-components'
import { RedeemSummary } from '../../../api/summary/types'
import { format } from '../../../helpers/number'
import { MobileSize } from '../../../helpers/responsive'
import { usePagination } from '../../../hooks/usePagination'
import Card from '../../Common/Card'
import { CountItemList, PaginateWrapper, SubTitleTable, TableHeader, TitleTable } from '../../Common/Table'
import RedeemSummaryTable from './RedeemSummaryTable'
import RedeemTxListTable from './RedeemTxListTable'

interface RedeemTabContainerProps {
	data: RedeemSummary
}
const RedeemTabContainer: FC<RedeemTabContainerProps> = ({ data }) => {
	const { data: txData, page: txPage, pageCount: txPageCount, setPage: txSetPage } = usePagination(data.transactions)
	useEffect(() => {
		txSetPage(0)
	}, [data, txSetPage])

	return (
		<Fragment>
			<CardWrapper>
				<Card padding="20px 0" mobilePadding="12px 0">
					<TableHeader>
						<TableWithPaymentLeftHeader>
							<TitleTable>รายการรับโค๊ดโปรโมชั่นแต่ละประเภท</TitleTable>
						</TableWithPaymentLeftHeader>
					</TableHeader>
					<RedeemSummaryTable data={data} />
				</Card>
			</CardWrapper>
			<SecondSection>
				<Card padding="20px 0 0 0" mobilePadding="12px 0 0 0">
					<TableHeader>
						<TableWithPaymentLeftHeader>
							<TitleTable>รายการรับโบนัส</TitleTable>
							<SubTitleTable>
								{format(data.transactions.length, 0)}
								&nbsp;รายการ
							</SubTitleTable>
						</TableWithPaymentLeftHeader>
					</TableHeader>
					<RedeemTxListTable data={txData} />
					<PaginateWrapper>
						<CountItemList></CountItemList>
						<ReactPaginate
							forcePage={txPage}
							pageCount={txPageCount}
							pageRangeDisplayed={5}
							marginPagesDisplayed={2}
							containerClassName={'pagination'}
							pageClassName={'pages pagination-pages'}
							activeClassName={'active-pagination'}
							onPageChange={(e) => {
								txSetPage(e.selected)
							}}
						/>
					</PaginateWrapper>
				</Card>
			</SecondSection>
		</Fragment>
	)
}

export default RedeemTabContainer

const TableWithPaymentLeftHeader = styled.div`
	flex-direction: row;
	align-items: flex-end;
	@media only screen and (max-width: ${MobileSize}px) {
		flex-direction: column;
		align-items: flex-start;
	}
`

const CardWrapper = styled.div`
	margin-top: 20px;
	@media only screen and (max-width: ${MobileSize}px) {
		margin-top: 0;
	}
`

const SecondSection = styled.div`
	margin-top: 20px;
	@media only screen and (max-width: ${MobileSize}px) {
		margin-top: 12px;
	}
`
