import moment from 'moment'
import React, { FC, useCallback, useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import styled from 'styled-components'
import { Member, MemberTransaction } from '../../../@types/member'
import { format } from '../../../helpers/number'
import { MobileSize } from '../../../helpers/responsive'
import { useAPIRequest } from '../../../hooks/useAPIRequest'
import { usePagination } from '../../../hooks/usePagination'
import PrefixService from '../../../services/prefix'
import Card, {
	CardSubTitle,
	CardTitle,
	CardTitletRowWrapperWithOutBorder,
	CardTitleWrapper,
} from '../../Common/Card'
import ContentContainer from '../../Common/ContentContainer'
import DateRange from '../../Common/DateRange'
import { CountItemList, PaginateWrapper, TableWrapper } from '../../Common/Table'
import TabDataLoading from '../../Dashboard/TabDataLoading'
import OtherListTable from './OtherListTable'

interface OtherTabProps {
	member: Member
}
const OtherTab: FC<OtherTabProps> = ({ member }) => {
	const [dateRange, setDateRange] = useState({
		start: moment().format('YYYY-MM-DD'),
		end: moment().format('YYYY-MM-DD'),
	})
	const { data, loading } = useAPIRequest(
		useCallback(
			async () =>
				PrefixService.getMemberTransactionList({
					id: member.id,
					dateRange: dateRange,
					type: 'other',
				}),
			[dateRange, member.id]
		)
	)

	const { data: txData, page, pageCount, setPage } = usePagination<MemberTransaction>(data || [])
	useEffect(() => {
		setPage(0)
	}, [data, setPage])

	return (
		<ContentContainer>
			<DateRange onChange={setDateRange} />

			{loading ? (
				<TabDataLoading />
			) : (
				<FirstSection>
					<Card padding="24px 0 0 0">
						<CardTitleWrapper>
							<CardTitletRowWrapperWithOutBorder>
								<CardTitle>รายการอื่น ๆ</CardTitle>
								<CardSubTitle>{format(data?.length || 0, 0)} รายการ</CardSubTitle>
							</CardTitletRowWrapperWithOutBorder>
						</CardTitleWrapper>
						<TableWrapper>
							<OtherListTable data={txData} />
							<PaginateWrapper>
								<CountItemList></CountItemList>
								<ReactPaginate
									forcePage={page}
									pageCount={pageCount}
									pageRangeDisplayed={5}
									marginPagesDisplayed={2}
									containerClassName={'pagination'}
									pageClassName={'pages pagination-pages'}
									activeClassName={'active-pagination'}
									onPageChange={(e) => {
										setPage(e.selected)
									}}
								/>
							</PaginateWrapper>
						</TableWrapper>
					</Card>
				</FirstSection>
			)}
		</ContentContainer>
	)
}

export default OtherTab

const FirstSection = styled.div`
	margin-top: 32px;
	@media only screen and (max-width: ${MobileSize}px) {
		margin-top: 12px;
	}
`
