import React, { FC } from 'react'
import styled from 'styled-components'
import Modal from '.'
import alarmIcon from '../../../assets/images/alarm-icon.svg'
import { Colors } from '../../../helpers/color'
import { Fonts } from '../../../helpers/font'
import PrimaryButton from '../Button/PrimaryButton'
import SecondButton from '../Button/SecondButton'

interface ConfirmModalProps {
	title?: string
	description?: string
	icon?: string
	loading?: boolean

	onSubmit?: () => void
	onClose?: () => void
}
const ConfirmModal: FC<ConfirmModalProps> = ({
	title,
	description,
	icon,
	loading = false,
	children,

	onSubmit,
	onClose,
}) => (
	<Modal width="350px">
		<ModalStatusWrapper>
			<ModalIcon src={icon || alarmIcon} alt="icon" />
			{title && <ModalTitle>{title}</ModalTitle>}
			{description && <ModalDescription>{description}</ModalDescription>}

			{children && <ModalContent>{children}</ModalContent>}

			<ModalActionGroup>
				<PrimaryButton
					type="button"
					onClick={() => onSubmit && onSubmit()}
					loading={loading}
				>
					ยืนยัน
				</PrimaryButton>
				<SecondButton
					type="button"
					onClick={() => !loading && onClose && onClose()}
				>
					ยกเลิก
				</SecondButton>
			</ModalActionGroup>
		</ModalStatusWrapper>
	</Modal>
)

export default ConfirmModal

const ModalStatusWrapper = styled.div`
	align-items: center;
	padding: 24px;
`
const ModalIcon = styled.img``
const ModalTitle = styled.div`
	font-family: ${Fonts.primary};
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	color: ${Colors.black2};
	margin-top: 12px;
`
const ModalDescription = styled.div`
	font-family: ${Fonts.primary};
	font-style: normal;
	font-size: 14px;
	color: ${Colors.slateGray};
	margin-top: 4px;
`
const ModalContent = styled.div`
	width: 100%;
	margin-top: 12px;
`
const ModalActionGroup = styled.div`
	flex-direction: row;
	display: grid;
	width: 100%;
	margin-top: 20px;
	grid-template-columns: repeat(2, 50%);

	button:nth-child(1) {
		width: 97%;
		margin-right: 3%;
	}
	button:nth-child(2) {
		width: 97%;
		margin-left: 3%;
	}
`
