import useSWR from 'swr'
import { DateRangeType } from '../@types/dashboard'
import { getSummaryWithPath } from '../api/summary/summary'

export const useFetchSummary = <T>(path: string, dateRange: DateRangeType) => {
	const { data } = useSWR([`/summary/${path}`, dateRange.start, dateRange.end], () =>
		getSummaryWithPath<T>(path, dateRange)
	)

	return {
		data,
	}
}

export default useFetchSummary
