import { useFormik } from 'formik'
import React, { FC } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { CentralAccountConfig } from '../../@types/prefix'
import { bankNames } from '../../helpers/bank'
import PrefixService from '../../services/prefix'
import PrimaryButton from '../Common/Button/PrimaryButton'
import Card, { CardTitle, CardTitleWrapper } from '../Common/Card'
import Input from '../Common/Input'
import Select from '../Common/Select'
import ContentContainer from '../Common/ContentContainer'

interface CentralAccountSettingProps {
	config: CentralAccountConfig
}
const CentralAccountSetting: FC<CentralAccountSettingProps> = ({ config }) => {
	const { values, isSubmitting, handleChange, handleSubmit } = useFormik({
		initialValues: config,
		onSubmit: async (values) => {
			try {
				const res = await PrefixService.saveCentralAccount(values)
				toast.success(res.message)
			} catch ({ message }) {
				toast.error(message as string)
			}
		},
	})

	return (
		<ContentContainer>
			<Card padding="24px 0" mobilePadding="12px 0">
				<CardTitleWrapper>
					<CardTitle>ตั้งค่าถอนเงินเข้าบัญชีกลาง</CardTitle>
				</CardTitleWrapper>

				<CardForm onSubmit={handleSubmit}>
					<InputSection>
						<Input
							type="number"
							name="minAmount"
							label="ยอดคงเหลือขั้นต่ำ"
							defaultValue={values.minAmount}
							required
							onChange={handleChange}
						/>
					</InputSection>
					<InputSection>
						<Select
							items={Object.keys(bankNames).map((k) => ({
								label: bankNames[k],
								value: k,
							}))}
							name="bankCode"
							label="ธนาคาร"
							defaultValue={values.bankCode}
							required
							onChange={handleChange}
						/>
					</InputSection>
					<InputSection>
						<Input
							type="text"
							name="accountNumber"
							label="เลขที่บัญชีกลาง"
							defaultValue={values.accountNumber}
							required
							onChange={handleChange}
						/>
					</InputSection>

					<PrimaryButton
						type="submit"
						width="120px"
						loading={isSubmitting}
					>
						บันทึกข้อมูล
					</PrimaryButton>
				</CardForm>
			</Card>
		</ContentContainer>
	)
}

export default CentralAccountSetting

const InputSection = styled.div`
	margin-bottom: 18px;
	flex: 1;
`
const CardForm = styled.form`
	padding: 32px 32px 0 32px;
`
