import React, { FC } from 'react'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import { usersConfig } from '../../@types/prefix'
import settingIcon from '../../assets/images/setting-button-icon.svg'
// import deleteIcon from '../../assets/images/trash-icon.svg'
import { BodyTR, Td, Th } from '../Common/Table'
import EnhancedTable from '../Common/Table/EnhancedTable'
import moment from 'moment'
import CustomButton from '../Common/Button/CustomButton'

interface UserListTableProps {
	data: usersConfig[]
	fetchInfo: (id: number, action: string) => void

}

const MemberListTable: FC<UserListTableProps> = ({ data, fetchInfo }) => {
	const renderRow = (row: usersConfig) => (
		<BodyTR key={`members.${row.id}`}>
			<Td>
				<b>
					{row.username}
				</b>
			</Td>
			<Td style={{ textAlign: 'center' }}>
				{moment(row.updatedAt).format('DD/MM/YYYY HH:mm:ss')}<br />
                <span>{moment(row.updatedAt).fromNow()}</span>
			</Td>
			<Td>
				<MemberActionGroup>
					<ActionItem onClick={() => fetchInfo(row.id, 'edit')}>
						<SettingButton src={settingIcon} alt="setting" data-tip data-for={`edit_${row.id}`} />
						<ReactTooltip id={`edit_${row.id}`} effect="solid">
							<span>แก้ไข</span>
						</ReactTooltip>
					</ActionItem>
					{row.twoFactorSecret ? 
					<CustomButton onClick={() => fetchInfo(row.id, '2faColse')} color="#C64C44" >
						<AddButtonWrapper>
							<span>ปิด 2FA</span>
						</AddButtonWrapper>
					</CustomButton>
					: 
					<CustomButton onClick={() => fetchInfo(row.id, '2faOpen')} color="#398349" >
						<AddButtonWrapper>
							<span>เปิด 2FA</span>
						</AddButtonWrapper>
					</CustomButton>
					}
					
				</MemberActionGroup>
			</Td>
		</BodyTR>
	)

	return (
		<EnhancedTable
			loading={!data}
			data={data}
			theadSize={4}
			thead={
				<tr>
					<Th style={{ textAlign: 'center' }}>ชื่อผู้ใช้งาน</Th>
					<Th style={{ textAlign: 'center' }}>ใช้งานล่าสุด</Th>
					<Th>จัดการ</Th>
				</tr>
			}
		>
			{(row: usersConfig) => renderRow(row)}
		</EnhancedTable>
	)
}

export default MemberListTable

const MemberActionGroup = styled.div`
	flex-direction: row;
	justify-content: center;
`

const SettingButton = styled.img`
	cursor: pointer;
`

const ActionItem = styled.div`
	margin: 0 4px;
`



const AddButtonWrapper = styled.div`
	flex-direction: row;
	align-items: center;
	padding: 0 8px;
`