// import debounce from 'lodash/debounce'
import React, { FC, useEffect } from 'react'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { ambPaymentConfig } from '../../../@types/prefix'
import { MobileSize } from '../../../helpers/responsive'
import Card, {
	CardTitle,
	CardTitletRowWrapperWithOutBorder,
	CardTitleWrapperWithOutBorder,
} from '../../Common/Card'

import { requestAuthInit } from '../../../store/auth/auth.actions'
import PrimaryButton from '../../Common/Button/PrimaryButton'
import Input from '../../Common/Input'
import ContentContainer from '../../Common/ContentContainer'
import PrefixService from '../../../services/prefix'
import 'antd/dist/antd.css';


interface AmbPaymentProps {
	data: ambPaymentConfig | any

	// update: () => void
}

const AmbPayment: FC<AmbPaymentProps> = ({data}) => {
    
	const dispatch = useDispatch()
    
    const {
		values,
		isSubmitting,
        // setValues,
		// setFieldValue,
		handleChange,
		handleSubmit,
	} = useFormik({
		initialValues: {
            username : data.ambPayment?.username,
            secret : data.ambPayment?.secret,
        },
        enableReinitialize: true,
		onSubmit: async (values) => {
			try {
				const res = await PrefixService.updateAmbPayment(values)
				dispatch(requestAuthInit(false))
				toast.success(res.message)
			} catch ({ message }) {
				toast.error(message as string)
			}
		},
	})

    useEffect(() => {
        if (typeof(data) != "undefined"){
            // setValues(data)
        }
    }, [data])
    


	return (
		<>
        <ContentContainer>
			<Card padding="24px 0 0 0">
				<CardTitleWrapperWithOutBorder>
					<CardTitletRowWrapperWithOutBorder>
						<CardTitle>ตั้งค่า AMB Payment</CardTitle>
					</CardTitletRowWrapperWithOutBorder>
				</CardTitleWrapperWithOutBorder>
                <WheelCardBottomContentWrapper>
					<form onSubmit={handleSubmit}>
                        <InputSection key={1}>
                            <Input
                                type="text"
                                name="username"
                                label={'Username'}
                                defaultValue={values?.username}
                                onChange={handleChange}
                                // onChange={(v) =>
                                //     setFieldValue('username', v.target.value)
                                // }
                                symbol=""
                            />
                        </InputSection>
                        <InputSection key={2}>
                            <Input
                                type="text"
                                name="secret"
                                label={'Secret'}
                                defaultValue={values?.secret}
                                onChange={handleChange}
                                // onChange={(v) =>
                                //     setFieldValue('secret', v.target.value)
                                // }
                                symbol=""
                            />
                        </InputSection>


						<InputSection>
							<PrimaryButton
								type="submit"
								width="120px"
								loading={isSubmitting}
							>
								บันทึกข้อมูล
							</PrimaryButton>
						</InputSection>
					</form>
				</WheelCardBottomContentWrapper>
			</Card>
		</ContentContainer>
			
		</>
	)
}

export default AmbPayment


const InputSection = styled.div`
	margin-bottom: 18px;
	flex: 1;
`
const WheelCardBottomContentWrapper = styled.div`
	padding: 10px 32px;
	justify-content: space-between;
	grid-template-columns: repeat(auto-fit, 50% - 24px);
	@media only screen and (max-width: ${MobileSize}px) {
		grid-template-columns: repeat(auto-fit, 100%);
		flex-direction: column-reverse;
	}
`
