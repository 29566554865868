import React, { ButtonHTMLAttributes, FC } from 'react'
// import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { PromotionConfig } from '../../../@types/prefix'
import editPromotionCardIcon from '../../../assets/images/edit-promotion-card-icon.svg'
import { Colors } from '../../../helpers/color'
import { Fonts } from '../../../helpers/font'
import { MobileSize } from '../../../helpers/responsive'
import { DeleteFilled } from '@ant-design/icons'


interface BonusCardItemProps {
	k: string
	data: PromotionConfig
	setKey: (key: string) => void
	setKeyDel: (key: string) => void
	toggleDelete: () => void
}
const BonusCardItem: FC<BonusCardItemProps> = ({ k, data, setKey, setKeyDel, toggleDelete}) => (
	<CardWrapper>
		<TopContent>
			<Title active={data.enable}>{data.name}</Title>
			<Description>{data.description}</Description>
		</TopContent>

		<BottomContent>
			<PromotionStatusBadge active={data.enable}>
				<span>{data.enable ? 'เปิดใช้งาน' : 'ปิดใช้งาน'}</span>
			</PromotionStatusBadge>
		</BottomContent>

		<EditButton active={data.enable} onClick={() => setKey(k)}>
			<EditButtonIcon src={editPromotionCardIcon} alt="edit" />
		</EditButton>
		<DeleteButton onClick={() => {
			setKeyDel(k)
			toggleDelete()
			}}>
			{/* <DeleteButtonIcon src={removePromotionCardIcon} alt="delete" /> */}
			<DeleteFilled />
		</DeleteButton>
	</CardWrapper>
)

export default BonusCardItem

const CardWrapper = styled.div`
	background: ${Colors.white};
	border-radius: 25px;
	padding: 24px;
	align-items: space-between;
	font-family: ${Fonts.primary};
	@media only screen and (max-width: ${MobileSize}px) {
		padding: 16px;
	}
`

const TopContent = styled.div``

interface TitleStyledProps {
	active: boolean
}
const Title = styled.div<TitleStyledProps>`
	color: ${({ active }) => (active ? Colors.dodgerBlue : Colors.black)};
	font-size: 17px;
	font-weight: bold;
`
const Description = styled.div`
	font-size: 14px;
	color: ${Colors.casper2};
	margin-top: 8px;
`
const BottomContent = styled.div`
	margin-top: 42px;
`

interface PromotionStatusBadgeStyledProps {
	active: boolean
}
const PromotionStatusBadge = styled.div<PromotionStatusBadgeStyledProps>`
	font-size: 10px;
	color: ${({ active }) => (active ? Colors.positive3 : Colors.negative3)};
	width: fit-content;
	border-radius: 10000px;
	background: ${({ active }) =>
		active ? Colors.positive2 : Colors.negative2};
	padding: 4px 10px 4px 4px;
	align-items: center;
	flex-direction: row;
	:before {
		content: '';
		width: 14px;
		height: 14px;
		background: ${({ active }) =>
			active ? Colors.positive : Colors.negative};
		border-radius: 1000px;
		margin-right: 12px;
	}
`

interface EditButtonStyledProps {
	active: boolean
}
const EditButton = styled.div<
	EditButtonStyledProps & ButtonHTMLAttributes<HTMLButtonElement>
>`
	background: ${({ active }) => (active ? Colors.dodgerBlue : Colors.casper)};
	border-radius: 25px 0px;
	position: absolute;
	bottom: 0;
	right: 0;
	width: 59px;
	height: 59px;
	align-items: center;
	justify-content: center;
	cursor: ${({ active }) => (active ? 'pointer' : 'normal')};
`
const EditButtonIcon = styled.img``

const DeleteButton = styled.div<
	ButtonHTMLAttributes<HTMLButtonElement>
>`
	background: #f5365c;
	border-radius: 0px 25px;
	position: absolute;
	top: 0;
	right: 0;
	width: 59px;
	height: 59px;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	color: #ffffff;
`
const DeleteButtonIcon = styled.img``