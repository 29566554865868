import React, { FC } from 'react'
import styled from 'styled-components'
import phoneIcon from '../../../assets/images/phone-icon.svg'
import { Colors } from '../../../helpers/color'

interface PhoneNumberWithIconProps {
	phoneNumber: string
	position?: string
}
const PhoneNumberWithIcon: FC<PhoneNumberWithIconProps> = ({
	position,
	phoneNumber,
}) => (
	<Wrapper position={position}>
		<Icon src={phoneIcon} alt="phone" />
		<Number>{phoneNumber}</Number>
	</Wrapper>
)

export default PhoneNumberWithIcon

interface WrapperStyledProps {
	position?: string
}
const Wrapper = styled.div<WrapperStyledProps>`
	margin-top: 0.25rem;
	flex-direction: row;
	width: fit-content;
	${({ position }) => position === 'center' && 'margin:0 auto;'}
	${({ position }) => position === 'left' && 'margin:0 auto 0 0;'}
  ${({ position }) => position === 'right' && 'margin:0 0 0 auto;'}
`

const Icon = styled.img``

const Number = styled.span`
	margin-left: 6px;
	font-size: 14px;
	color: ${Colors.casper};
`
