import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'
// import styled from 'styled-components'
import EditBankForm from '../../components/EditBank/EditBankForm'
// import { Colors } from '../../helpers/color'
// import { MobileSize } from '../../helpers/responsive'
import { ApplicationState } from '../../store'
import { Modal } from 'reactstrap'

interface EditAccountProps {
    accountNumber: string
	isOpenModal: boolean
    deleteModal?: () => void
	toggleEditAccount: () => void
}

const EditAccount: FC<EditAccountProps> = ({ deleteModal, accountNumber, isOpenModal, toggleEditAccount }) => {
	const prefix = useSelector((state: ApplicationState) => state.prefix).prefix
	const params = accountNumber;
	if (!prefix.banks.hasOwnProperty(params)) {
		return <Redirect to="/bank" />
	}

	const bank = prefix.banks[params]

	return (
        <Modal isOpen={isOpenModal} className="flex-row modal-dialog-centered" fade={true} toggle={() => toggleEditAccount()}>
            <div className="modal-body p-lg-4">
                <div className="d-flex flex-row justify-content-between align-items-center mb-4">
                    <h3 className="mb-0"><strong>แก้ไขบัญชี {bank.accountName}</strong></h3>
                    <div className="modal-close">
                        <i className="far fa-times" onClick={() => toggleEditAccount()}></i>
                    </div>
                </div>
                <EditBankForm data={bank} toggle={() => toggleEditAccount()} />
            </div>
        </Modal>
	)
}
export default EditAccount