import { useFormik } from 'formik'
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { PrefixConfig } from '../../../@types/prefix'
import { Colors } from '../../../helpers/color'
import { Fonts } from '../../../helpers/font'
import { MobileSize } from '../../../helpers/responsive'
import { getRewardItems } from '../../../helpers/wheel'
import PrefixService from '../../../services/prefix'
import { requestAuthInit } from '../../../store/auth/auth.actions'
import PrimaryButton from '../../Common/Button/PrimaryButton'
import Card, { CardTitleWrapper } from '../../Common/Card'
import CheckboxItem from '../../Common/Checkbox/CheckboxItem'
import TwoColumn from '../../Common/Columns/TwoColumn'
import Input from '../../Common/Input'
import Radio from '../../Common/Radio/Radio'
import Select from '../../Common/Select'
import { TitleTable } from '../../Common/Table'
import Toggle from '../../Common/Toggle'
import ContentContainer from '../../Common/ContentContainer'

interface WheelSettingProps {
	data: PrefixConfig
}
const WheelSetting: FC<WheelSettingProps> = ({ data }) => {
	const dispatch = useDispatch()
	const {
		values,
		isSubmitting,
		setFieldValue,
		handleChange,
		handleSubmit,
	} = useFormik({
		initialValues: data.setting.wheel,
		onSubmit: async (values) => {
			try {
				values.minDeposit = parseFloat(values.minDeposit.toString())
				values.rewardTurn = parseFloat(values.rewardTurn.toString())
				for (let reward in values.rewards) {
					values.rewards[reward].chance = parseFloat(
						values.rewards[reward].chance.toString()
					)
				}

				const res = await PrefixService.saveWheelConfig(values)

				dispatch(requestAuthInit(false))
				toast.success(res.message)
			} catch ({ message }) {
				toast.error(message as string)
			}
		},
	})
	const setRewardType = (t: string) => {
		setFieldValue('rewardType', t)
		setFieldValue('rewards', getRewardItems(t))
	}

	const rewardItemInputs = []
	const rewardItemKeys = Object.keys(values.rewards).sort((a, b) => {
		if (values.rewards[a].index > values.rewards[b].index) {
			return 1
		}
		return -1
	})
	for (let i = 0; i < rewardItemKeys.length / 2; i++) {
		const reward1Key = rewardItemKeys[i * 2],
			reward2Key = rewardItemKeys[i * 2 + 1]
		const reward1 = values.rewards[reward1Key]
		const reward2 = values.rewards[reward2Key]

		rewardItemInputs.push(
			<InputSection key={`reward-${i}`}>
				<TwoColumn margin="24px">
					{reward1 && (
						<Input
							type="text"
							name={`rewards[${reward1Key}].chance`}
							label={reward1.name}
							defaultValue={reward1.chance}
							required
							onChange={handleChange}
							symbol="%"
						/>
					)}
					{reward2 && (
						<Input
							type="text"
							name={`rewards[${reward2Key}].chance`}
							label={reward2.name}
							defaultValue={reward2.chance}
							required
							onChange={handleChange}
							symbol="%"
						/>
					)}
				</TwoColumn>
			</InputSection>
		)
	}

	return (
		<ContentContainer>
			<Card padding="24px 0">
				<CardTitleWrapper>
					<TitleTable>จัดการกงล้อเสี่ยงโชค</TitleTable>
					<SwitchWrapper>
						<Toggle
							activeLabel="เปิด"
							disableLabel="ปิด"
							active={values.enable}
							setActive={(v) => setFieldValue('enable', v)}
						/>
					</SwitchWrapper>
				</CardTitleWrapper>
				<WheelTypeCheckBoxWrapper>
					<InputSection>
						<WheelRadioButtonWrapper>
							<WheelLabel>ประเภทรางวัล</WheelLabel>
							<WheelRadioButtonItemWrapper>
								<RadioButtonItem>
									<Radio
										active={values.rewardType === 'type1'}
										onClick={() => setRewardType('type1')}
									>
										ประเภทที่ 1
									</Radio>
								</RadioButtonItem>
								<RadioButtonItem>
									<Radio
										active={values.rewardType === 'type2'}
										onClick={() => setRewardType('type2')}
									>
										ประเภทที่ 2
									</Radio>
								</RadioButtonItem>
							</WheelRadioButtonItemWrapper>
						</WheelRadioButtonWrapper>
					</InputSection>
				</WheelTypeCheckBoxWrapper>

				<WheelCardBottomContentWrapper>
					<form onSubmit={handleSubmit}>
						<InputSection>
							<TwoColumn margin="24px">
								<Select
									items={[
										{
											label: 'เล่นได้ทุก 1 ชั่วโมง',
											value: 'every_hour',
										},
										{
											label: 'เล่นได้ทุกวัน',
											value: 'every_day',
										},
										{
											label: 'เล่นได้ทุกสัปดาห์',
											value: 'every_week',
										},
										{
											label: 'เล่นได้ทุกเดือน',
											value: 'every_month',
										},
									]}
									name="rewardLimit"
									label="จำกัดการเล่น"
									placeholder="--- เลือกการจำกัด ---"
									defaultValue={values.rewardLimit}
									required
									onChange={handleChange}
								/>
								<Input
									type="number"
									name="minDeposit"
									label="ยอดฝากขั้นต่ำ"
									defaultValue={values.minDeposit}
									required
									onChange={handleChange}
								/>
							</TwoColumn>
						</InputSection>
						<InputSection>
							<TwoColumn margin="24px">
								<Select
									items={[
										{
											label: 'ยอดฝากรวม',
											value: 'sum',
										},
										{
											label: 'ยอดฝากรายครั้ง',
											value: 'tx',
										},
									]}
									name="depositType"
									label="ประเภทยอดฝาก"
									placeholder="--- เลือกประเภท ---"
									defaultValue={values.depositType}
									required
									onChange={handleChange}
								/>
								<Input
									type="number"
									name="rewardTurn"
									label="เทิร์น (เท่า)"
									defaultValue={values.rewardTurn}
									required
									onChange={handleChange}
								/>
							</TwoColumn>
						</InputSection>

						{rewardItemInputs}

						<InputSection>
							<RuleTitle>เงื่อนไขกงล้อเสี่ยงโชค</RuleTitle>
							<RuleCheckBoxWrapper>
								<CheckboxItem
									label={`ต้องมีเครดิตคงเหลือน้อยกว่า ${data.setting.minCredit}`}
									checked={values.rules.minCredit}
									padding="0 0 8px 0"
									onChange={(v) =>
										setFieldValue('rules.minCredit', v)
									}
								/>
								<CheckboxItem
									label="ต้องไม่มีรายการถอน (ของรายการฝาก)"
									checked={values.rules.noWithdraw}
									padding="0 0 8px 0"
									onChange={(v) =>
										setFieldValue('rules.noWithdraw', v)
									}
								/>
								<CheckboxItem
									label="ต้องไม่มีรายการรับโบนัส (ของรายการฝาก)"
									checked={values.rules.noBonus}
									padding="0 0 8px 0"
									onChange={(v) =>
										setFieldValue('rules.noBonus', v)
									}
								/>
							</RuleCheckBoxWrapper>
						</InputSection>

						<InputSection>
							<PrimaryButton
								type="submit"
								width="120px"
								loading={isSubmitting}
							>
								บันทึกข้อมูล
							</PrimaryButton>
						</InputSection>
					</form>
				</WheelCardBottomContentWrapper>
			</Card>
		</ContentContainer>
	)
}

export default WheelSetting

const SwitchWrapper = styled.div`
	max-width: 162px;
	margin: 0 0 0 auto;
	width: 100%;
	justify-content: flex-end;
	@media only screen and (max-width: ${MobileSize}px) {
		max-width: 99px;
		flex: 0 0 auto;
	}
`
const InputSection = styled.div`
	margin-bottom: 18px;
	flex: 1;
`
const WheelTypeCheckBoxWrapper = styled.div`
	margin-top: 20px;
	padding: 0 24px;
`
const WheelRadioButtonWrapper = styled.div`
	flex-direction: row;
	justify-content: space-between;
	@media only screen and (max-width: ${MobileSize}px) {
		flex-direction: column;
	}
`
const WheelLabel = styled.div`
	font-size: 16px;
	font-family: ${Fonts.primary};
	color: ${Colors.slateGray};
	@media only screen and (max-width: ${MobileSize}px) {
		margin-bottom: 8px;
	}
`
const WheelRadioButtonItemWrapper = styled.div`
	flex-direction: row;
`
const RadioButtonItem = styled.div`
	:not(:last-child) {
		margin-right: 4px;
	}
`
const WheelCardBottomContentWrapper = styled.div`
	padding: 0 32px;
	justify-content: space-between;
	grid-template-columns: repeat(auto-fit, 50% - 24px);
	@media only screen and (max-width: ${MobileSize}px) {
		grid-template-columns: repeat(auto-fit, 100%);
		flex-direction: column-reverse;
	}
`
const RuleTitle = styled.div`
	font-size: 18px;
	color: ${Colors.slateGray};
	font-weight: bold;
`
const RuleCheckBoxWrapper = styled.div`
	margin-top: 12px;
`
