import { FC } from 'react'
import styled from 'styled-components'
import { MobileSize } from '../../../helpers/responsive'

interface TwoColumnProps {
	margin?: string
	justifyContent?: 'space-between' | 'space-between'
	alignItems?: 'flex-end' | 'flex-start' | 'center'
}
const TwoColumn: FC<TwoColumnProps> = ({
	children,
	margin,
	justifyContent,
	alignItems,
}) => (
	<TwoColumnWrapper
		margin={margin}
		justifyContent={justifyContent}
		alignItems={alignItems}>
		{children}
	</TwoColumnWrapper>
)

export default TwoColumn

interface TwoColumnWrapperStyledProps {
	margin?: string
	justifyContent?: 'space-between' | 'space-between'
	alignItems?: 'flex-end' | 'flex-start' | 'center'
}
const TwoColumnWrapper = styled.div<TwoColumnWrapperStyledProps>`
	display: grid;
	width: 100%;
	justify-content: space-between;
	align-items: ${({ alignItems }) => alignItems};
	grid-template-columns: repeat(
		auto-fit,
		calc(50% - ${({ margin }) => (margin ? margin : '0px')})
	);
	@media only screen and (max-width: ${MobileSize}px) {
		grid-template-columns: repeat(auto-fit, 100%);
	}
`
