import { FC } from 'react'
import { BadgeType } from '../../@types/badge'
import BadgeStatus from '../Common/BadgeStatus'

interface TxStatusBadgeProps {
	status: string
}
const TxStatusBadge: FC<TxStatusBadgeProps> = ({ status }) => {
	switch (status) {
		case 'pending': {
			return <BadgeStatus type={BadgeType.warning}>รอดำเนินการ</BadgeStatus>
		}
		case 'success': {
			return <BadgeStatus type={BadgeType.sucess}>รายการสำเร็จ</BadgeStatus>
		}
		case 'reject': {
			return <BadgeStatus type={BadgeType.failed}>รายการไม่สำเร็จ</BadgeStatus>
		}
		case 'member_notfound': {
			return <BadgeStatus type={BadgeType.failed}>ไม่พบสมาชิก</BadgeStatus>
		}
		case 'duplicate': {
			return <BadgeStatus type={BadgeType.failed}>รายการซ้ำ</BadgeStatus>
		}
		default: {
			return <BadgeStatus type={BadgeType.warning}>{status.toUpperCase()}</BadgeStatus>
		}
	}
}

export default TxStatusBadge
