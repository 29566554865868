import moment from 'moment'
import React, { FC } from 'react'
import { MemberTransaction } from '../../../@types/member'
import { format } from '../../../helpers/number'
import { getTypeName } from '../../../helpers/transactionType'
import MoneyWithCurrency from '../../Common/Money/MoneyWithCurrency'
import {
	BodyTR,
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Time,
	TimeDate,
	TimeWrapper,
} from '../../Common/Table'
import TxStatusBadge from '../../TxStatusBadge/TxStatusBadge'

interface OtherListTableProps {
	data: MemberTransaction[]
}
const OtherListTable: FC<OtherListTableProps> = ({ data }) => (
	<Table>
		<Thead>
			<tr>
				<Th style={{ textAlign: 'left' }}>โอนไปยัง</Th>
				<Th style={{ textAlign: 'center' }}>จำนวนเงิน</Th>
				<Th style={{ textAlign: 'center' }}>สถานะ</Th>
				<Th style={{ textAlign: 'left' }}>เวลา</Th>
				<Th style={{ textAlign: 'left' }}>อัพเดตล่าสุดเมื่อ</Th>
			</tr>
		</Thead>
		<Tbody>
			{data.length <= 0 ? (
				<BodyTR>
					<Td colSpan={6} style={{ textAlign: 'center' }}>
						ไม่มีข้อมูล
					</Td>
				</BodyTR>
			) : (
				data.map((row) => (
					<BodyTR key={row.createdAt}>
						<Td>
							<b>{getTypeName(row.type)}</b>
						</Td>
						<Td>
							<TimeWrapper>
								<MoneyWithCurrency
									value={format(row.amount, 2)}
									fontSize="16px"
									position="center"
								/>
								<Time style={{ textAlign: 'center' }}>{row.remark}</Time>
							</TimeWrapper>
						</Td>
						<Td>
							<TxStatusBadge status={row.status} />
						</Td>
						<Td>
							<TimeWrapper>
								<TimeDate>{moment(row.createdAt).format('DD/MM/YYYY')}</TimeDate>
								<Time>{moment(row.createdAt).format('HH:mm:ss')}</Time>
							</TimeWrapper>
						</Td>
						<Td>
							<TimeWrapper>
								<TimeDate>{moment(row.updatedAt).format('DD/MM/YYYY')}</TimeDate>
								<Time>{moment(row.updatedAt).format('HH:mm:ss')}</Time>
							</TimeWrapper>
						</Td>
					</BodyTR>
				))
			)}
		</Tbody>
	</Table>
)

export default OtherListTable
