import React, { FC } from 'react'
import styled from 'styled-components'
import { MobileSize } from '../../../helpers/responsive'

const Wrapper = styled.div`
	width: calc(100% - 124px);
	margin: 0 auto;
	@media only screen and (max-width: ${MobileSize}px) {
		width: calc(100% - 32px);
		margin: 0 auto;
	}
`
const ContentContainer: FC = ({ children }) => <Wrapper>{children}</Wrapper>

export default ContentContainer
