import moment from 'moment'
import React, { FC, useState } from 'react'
import styled from 'styled-components'
import useSWR from 'swr'
import { FixTransaction } from '../@types/transactions'
import bankNotWorkIcon from '../assets/images/bank-not-work-icon.svg'
import totalCreditIcon from '../assets/images/total-credit-icon.svg'
import totalDeductIcon from '../assets/images/total-deduct-icon.svg'
import twNotWorkIcon from '../assets/images/true-not-work-icon.svg'
import Card from '../components/Common/Card'
import ContentContainer from '../components/Common/ContentContainer'
import DateRange from '../components/Common/DateRange'
import MoneyWithCurrency from '../components/Common/Money/MoneyWithCurrency'
import FixTxList from '../components/FixTx/FixTxList'
import PageLayout from '../components/PageLayout'
import { Colors } from '../helpers/color'
import { Fonts } from '../helpers/font'
import { MobileSize } from '../helpers/responsive'
import { sumFixAddCredit, sumFixBank, sumFixDeductCredit, sumFixTW } from '../helpers/tx'
import PrefixService from '../services/prefix'

interface FixTxResultListProps {
	data: FixTransaction[]
}
const FixTxResultList: FC<FixTxResultListProps> = ({ data }) => (
	<TotalResultWrapper>
		<TotalResultItemWrapper>
			<Card padding="4px" mobilePadding="4px 0px">
				<TotalResultItem>
					<LeftTotalResultItem>
						<TotalResultIcon src={bankNotWorkIcon} alt="bank" />
					</LeftTotalResultItem>
					<RightTotalResultItem>
						<TotalResultLabel color={Colors.malibu}>ธนาคารไม่รัน</TotalResultLabel>
						<MoneyWithCurrency value={sumFixBank(data)} fontSize="24px" />
					</RightTotalResultItem>
				</TotalResultItem>
			</Card>
		</TotalResultItemWrapper>
		<TotalResultItemWrapper>
			<Card padding="4px" mobilePadding="4px 0px">
				<TotalResultItem>
					<LeftTotalResultItem>
						<TotalResultIcon src={twNotWorkIcon} alt="tw" />
					</LeftTotalResultItem>
					<RightTotalResultItem>
						<TotalResultLabel color={Colors.true}>
							ทรูมันนี่วอลเล็ทไม่รัน
						</TotalResultLabel>
						<MoneyWithCurrency value={sumFixTW(data)} fontSize="24px" />
					</RightTotalResultItem>
				</TotalResultItem>
			</Card>
		</TotalResultItemWrapper>
		<TotalResultItemWrapper>
			<Card padding="4px" mobilePadding="4px 0px">
				<TotalResultItem>
					<LeftTotalResultItem>
						<TotalResultIcon src={totalCreditIcon} alt="add" />
					</LeftTotalResultItem>
					<RightTotalResultItem>
						<TotalResultLabel color={Colors.positive}>เติมเครดิต</TotalResultLabel>
						<MoneyWithCurrency value={sumFixAddCredit(data)} fontSize="24px" />
					</RightTotalResultItem>
				</TotalResultItem>
			</Card>
		</TotalResultItemWrapper>
		<TotalResultItemWrapper>
			<Card padding="4px" mobilePadding="4px 0px">
				<TotalResultItem>
					<LeftTotalResultItem>
						<TotalResultIcon src={totalDeductIcon} alt="deduct" />
					</LeftTotalResultItem>
					<RightTotalResultItem>
						<TotalResultLabel color={Colors.negative}>ตัดเครดิต</TotalResultLabel>
						<MoneyWithCurrency value={sumFixDeductCredit(data)} fontSize="24px" />
					</RightTotalResultItem>
				</TotalResultItem>
			</Card>
		</TotalResultItemWrapper>
	</TotalResultWrapper>
)

const FixTx = () => {
	const [dateRange, setDateRange] = useState({
		start: moment().format('YYYY-MM-DD'),
		end: moment().format('YYYY-MM-DD'),
	})

	const { data, mutate } = useSWR(`/fix-tx?start=${dateRange.start}&end=${dateRange.end}`, () =>
		PrefixService.getFixTxList({
			dateRange: dateRange,
		})
	)

	return (
		<PageLayout title="แก้ไขรายการผิดพลาด" subTitle="จัดการเว็บไซต์">
			<Body>
				<ContentContainer>
					<DateRange onChange={setDateRange} />

					<FirstSection>
						<FixTxResultList data={data || []} />
					</FirstSection>
					<SecondSection>
						<FixTxList
							loading={!data}
							data={data || []}
							update={mutate}
						/>
					</SecondSection>
				</ContentContainer>
			</Body>
		</PageLayout>
	)
}

export default FixTx

const Body = styled.div`
	background: ${Colors.zircon2};
	width: 100%;
	flex: 1;
	margin-top: 32px;
	padding: 46px 0;
	@media only screen and (max-width: ${MobileSize}px) {
		padding: 20px 0;
		margin-top: 20px;
	}
`

const FirstSection = styled.div`
	margin-top: 32px;
	@media only screen and (max-width: ${MobileSize}px) {
		margin-top: 12px;
	}
`
const SecondSection = styled.div`
	margin-top: 32px;
	@media only screen and (max-width: ${MobileSize}px) {
		margin-top: 12px;
	}
`

const TotalResultWrapper = styled.div`
	flex-direction: row;
	@media only screen and (max-width: ${MobileSize}px) {
		flex-wrap: wrap;
	}
`

const TotalResultItemWrapper = styled.div`
	width: 100%;
	max-width: 243px;
	margin-right: 24px;
	@media only screen and (max-width: ${MobileSize}px) {
		max-width: calc(50% - 12px);
		margin-right: 12px;
		margin-bottom: 8px;
	}
`
const TotalResultItem = styled.div`
	flex-direction: row;
	align-items: center;
	padding: 12px;
`
const LeftTotalResultItem = styled.div`
	align-items: center;
	justify-content: center;
	margin-right: 16px;
	@media only screen and (max-width: ${MobileSize}px) {
		margin-right: 4px;
	}
`
const TotalResultIcon = styled.img`
	@media only screen and (max-width: ${MobileSize}px) {
		width: 30px;
	}
`
const RightTotalResultItem = styled.div``

interface TotalResultLabelStyledProps {
	color: string
}
const TotalResultLabel = styled.div<TotalResultLabelStyledProps>`
	font-family: ${Fonts.primary};
	font-style: normal;
	font-weight: 800;
	font-size: 14px;
	color: ${({ color }) => color};
	@media only screen and (max-width: ${MobileSize}px) {
		font-size: 12px;
	}
`
