import React, { FC } from 'react'
import styled from 'styled-components'
import { BonusSummary } from '../../../api/summary/types'
import { Colors } from '../../../helpers/color'
import { Fonts } from '../../../helpers/font'
import { format } from '../../../helpers/number'
import { bonusSummarySort } from '../../../utilities/bonus'
import MoneyWithCurrency from '../../Common/Money/MoneyWithCurrency'
import { BodyTR, Table, TableWrapper, Tbody, Td, Th, Thead } from '../../Common/Table'

interface BonusSummaryTableProps {
	data: BonusSummary
}
const BonusSummaryTable: FC<BonusSummaryTableProps> = ({ data }) => {
	const renderContent = () =>
		data.summary.sort(bonusSummarySort).map((row) => (
			<BodyTR key={row.name}>
				<Td>
					<BonusStatusWrapper>
						<BonusStatusText>{row.name}</BonusStatusText>
					</BonusStatusWrapper>
				</Td>
				<Td>
					<ActiveCount>{format(row.count, 0)}</ActiveCount>
				</Td>
				<Td>
					<MoneyWithCurrency value={format(row.amount, 2)} fontSize="16px" />
				</Td>
			</BodyTR>
		))

	return (
		<TableWrapper>
			<Table>
				<Thead>
					<tr>
						<Th>โบนัส</Th>
						<Th style={{ textAlign: 'left' }}>จำนวนครั้ง</Th>
						<Th style={{ textAlign: 'left' }}>จำนวนเงิน</Th>
					</tr>
				</Thead>
				<Tbody>{renderContent()}</Tbody>
			</Table>
		</TableWrapper>
	)
}

export default BonusSummaryTable

const BonusStatusWrapper = styled.div`
	flex-direction: row;
	align-items: center;
`
const BonusStatusText = styled.span`
	margin-left: 12px;
	font-size: 16px;
	color: ${Colors.slateGray};
	font-family: ${Fonts.primary};
`
const ActiveCount = styled.div`
	font-size: 16px;
	color: ${Colors.slateGray};
	font-family: ${Fonts.primary};
	font-weight: bold;
`
