import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { BankConfig, SCBAccountConfig } from '../../@types/prefix'
import { getBankIcon } from '../../helpers/bank'
import { Colors } from '../../helpers/color'
import { Fonts } from '../../helpers/font'
import EnhancedTable from '../Common/Table/EnhancedTable'
import moment from 'moment'
import { Switch, Button, Tag } from 'antd'
import PrefixService from '../../services/prefix'
import { useDispatch } from 'react-redux'
import { requestAuthInit } from '../../store/auth/auth.actions'
import { toast } from 'react-toastify'
import { EyeOutlined } from '@ant-design/icons';

interface BankListItemProps {
	banks: BankConfig[]
	scbAccounts: SCBAccountConfig[]
	currentAccountNumber: (number: string) => void
	toggleEditAccount: () => void
	toggleDeleteAccountModal: () => void
}
const BankListItem: FC<BankListItemProps> = ({ banks, scbAccounts, currentAccountNumber, toggleEditAccount, toggleDeleteAccountModal }) => {
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)
	const [balanceSCB, setBalanceSCB] = useState<any>([])

	const toggleScbAccount = async (accountNumber: string) => {
		setLoading(true)
		try {
			const res = await PrefixService.toggleScbAccount(accountNumber)

			dispatch(requestAuthInit(false))
			toast.success(res.message)
			setLoading(false)
		} catch ({ message }) {
			toast.error(message as string)
		}
	}

	const withdrawToCentral = async (accountNumber: string) => {
		try {
			const res = await PrefixService.toggleScbAccount(accountNumber)

			dispatch(requestAuthInit(false))
			toast.success(res.message)
		} catch ({ message }) {
			toast.error(message as string)
		}
	}

	const getBalance = async (accountNumber: string) => {
		try {
			const res = await PrefixService.getBalance(accountNumber)
			// return res.balance
			const objBalance = {
				'accountNumber': accountNumber,
				'balance': res.balance
			}
			setBalanceSCB([...balanceSCB, objBalance])
		} catch ({ message }) {
			toast.error(message as string)
		}
	}

	const validScbAccount = (accountNumber: string) => {
		return Object.values(scbAccounts).filter((account) => (account.accountNumber === accountNumber))
	}

	const scbAccountStatus = (accountNumber: string) => {
		return Object.values(scbAccounts).find((account) => (account.accountNumber === accountNumber))?.enable
	}

	return (
		<TableWrapper>
			<Table className="table custom-table table-responsive">
				<thead>
					<tr>
						<th>บัญชี</th>
						<th>สถานะ</th>
						<th>SMS</th>
						<th>Internet Banking</th>
						<th>Application</th>
						<th>จัดการ</th>
					</tr>
				</thead>
				<tbody>
					{banks.map((bank, index) => {
						return (
							<tr key={index}>
								<td style={{ minWidth: '200px' }}>
									<Bank className="flex-row">
										{getBankIcon(bank.bank) && (<img src={getBankIcon(bank.bank)} width={24} alt="bank" />)}
										<div className="bank-info">
											<div className="flex-row">
												<span className="me-2">{bank.accountNumber}</span>
												{index === 0 && (<Tag color="blue">ฝากหลัก</Tag>)}
												{
													bank.bank === 'scb'
													&& validScbAccount(bank.accountNumber).length > 0
													&& (balanceSCB.find((x: any, index: any) => x.accountNumber === bank.accountNumber)
														? <Tag color="purple">{balanceSCB.find((x: any, index: any) => x.accountNumber === bank.accountNumber).balance}</Tag>
														: <Button type="primary" shape="circle" className="btn-purple" icon={<EyeOutlined />} onClick={() => getBalance(bank.accountNumber)} />)
												}
											</div>
											<div><small>{bank.accountName}</small></div>
										</div>
									</Bank>
								</td>
								<td>
									<Switch
										checkedChildren="เปิด"
										unCheckedChildren="ปิด"
										checked={bank.enable}
									/>
								</td>
								<td><Switch checkedChildren="เปิด" unCheckedChildren="ปิด" defaultChecked /></td>
								<td><Switch checkedChildren="เปิด" unCheckedChildren="ปิด" defaultChecked /></td>
								<td>
									<Switch
										checkedChildren="เปิด"
										unCheckedChildren="ปิด"
										loading={loading}
										checked={scbAccountStatus(bank.accountNumber)}
										disabled={validScbAccount(bank.accountNumber).length > 0 ? false : true}
										onChange={() => toggleScbAccount(bank.accountNumber)}
									/>
								</td>
								<td style={{ width: '300px' }}>
									<div className="bank-action">
										<Button
											type="primary"
											size={'small'}
											onClick={() => {
												toggleEditAccount()
												currentAccountNumber(bank.accountNumber)
											}}
										>
											แก้ไข
										</Button>

										{/* SCB API */}
										{bank.bank === 'scb' && (<Button size={'small'} disabled={true}>ตั้งค่า SCB API</Button>)}

										{/* ตั้งบัญชีฝากหลัก */}
										{index !== 0 && (<Button size={'small'} disabled={true}>ตั้งบัญชีฝากหลัก</Button>)}

										{/* ตั้งบัญชีถอน */}
										{
											bank.bank === 'scb'
											&& validScbAccount(bank.accountNumber).length > 0
											&& (<Button size={'small'} disabled={true}>ตั้งบัญชีถอน</Button>)}

										{/* โอนเข้าบัญชีกลาง */}
										<Button size={'small'} onClick={() => withdrawToCentral(bank.accountNumber)}>โอนเข้าบัญชีกลาง</Button>

										<Button
											type="primary"
											size={'small'}
											danger
											onClick={() => {
												toggleDeleteAccountModal()
												currentAccountNumber(bank.accountNumber)
											}}
										>
											ลบ
										</Button>
									</div>
								</td>
							</tr>
						)
					})}
				</tbody>
			</Table>
		</TableWrapper>
	)
}

export default BankListItem

const TableWrapper = styled.div`
	width: 100%;
	overflow: auto;

	@media only screen and (max-width: 576px) {
		width: 100vw;
		overflow: auto;
		margin-left: -16px;
		margin-right: -16px;
		padding-left: 16px;
		padding-right: 16px;
	}
`

const Table = styled.table`
	margin: 1rem 0 3rem;
	font-family: ${Fonts.primary};
	color: ${Colors.slateGray};
	border-collapse: separate;
 	border-spacing: 0 12px;

	& > :not(:first-child) {
		border-top: 0;
	}

	& > :not(caption) > * > * {
		border-bottom-width: 0;
	}

	tbody {
		tr {
			box-shadow: 0px 10px 35px rgba(87, 106,154, 0.08);
			border-radius: 24px;

			td {
				background: #fff;
				line-height: 1.45;
				vertical-align: middle;
				border-collapse: separate;
				border-spacing: 0 12px;

				&:first-child {
					border-radius: 16px 0 0 16px
				}

				&:last-child {
					border-radius: 0 16px 16px 0;
				}

				small {
					color: #95a0b8;
				}
			}

			&.spacer td {
				padding: 0 !important;
				height: 10px;
				border-radius: 0 !important;
				background: transparent !important;
			}
		}
	}

	th, td {
		&:first-child {
			padding-left: 1rem;
		}

		&:last-child {
			padding-right: 1rem;
		}
	}

	th {
		white-space: nowrap;

		@media only screen and (max-width: 576px) {
			font-size: 14px;
			padding: 0 0.5rem;
		}
	}

	.bank-action {
		flex-direction: row;

		button {
			margin-right: 8px;
		}
	}
`

const Bank = styled.div`
	align-items: center;

	img {
		width: 36px;
		min-width: 36px;
	}

	.bank-info {
		padding-left: 8px;
	}
`
const Text = styled.div`
	font-family: ${Fonts.primary};
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	color: ${Colors.slateGray};
`