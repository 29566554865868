import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import AuthInitContainer from './components/Container/AuthInitContainer'
import Layout from './components/Layout'
import AddBank from './views/AddBank'
import AddFixTx from './views/AddFixTx'
import AddSCBAccount from './views/AddSCBAccount'
import AddTruewallet from './views/AddTruewallet'
import Bank from './views/Bank'
import Main from './views/Main'
import Report from './views/Report'
import EditBank from './views/EditBank'
import EditBonus from './views/EditBonus'
import EditMember from './views/EditMember'
import EditSCBAccount from './views/EditSCBAccount'
import FixTx from './views/FixTx'
import Logout from './views/Logout'
import Member from './views/Member'
import MemberHistory from './views/MemberHistory'
import Promotions from './views/Promotions'
import Transactions from './views/Transactions'
import Setting from './views/Setting'
import Users from './views/Users'
import Log from './views/Log'

const App = () => (
	<AuthInitContainer>
		<Layout>
			<Switch>
				<Route exact path="/main" component={Main} />
				<Route exact path="/report" component={Report} />
				<Route exact path="/member" component={Member} />
				<Route exact path="/member/:id/edit" component={EditMember} />
				<Route
					exact
					path="/member/:id/history"
					component={MemberHistory}
				/>

				<Route exact path="/transactions" component={Transactions} />

				<Route exact path="/promotions" component={Promotions} />
				<Route
					exact
					path="/promotions/:key/edit"
					component={EditBonus}
				/>

				<Route exact path="/fix-tx" component={FixTx} />
				<Route exact path="/fix-tx/add" component={AddFixTx} />

				<Route exact path="/bank" component={Bank} />
				<Route exact path="/bank/add" component={AddBank} />
				<Route
					exact
					path="/bank/add-scb-api"
					component={AddSCBAccount}
				/>
				<Route
					exact
					path="/bank/add-truewallet"
					component={AddTruewallet}
				/>
				<Route
					exact
					path="/bank/:accountNumber/edit"
					component={EditBank}
				/>
				<Route
					exact
					path="/bank/:accountNumber/edit-scb-api"
					component={EditSCBAccount}
				/>

				<Route exact path="/users" component={Users} />
				<Route exact path="/setting" component={Setting} />
				<Route exact path="/log" component={Log} />

				<Route exact path="/logout" component={Logout} />

				<Redirect to="/main" />
			</Switch>
		</Layout>
	</AuthInitContainer>
)

export default App
