import React, { FC, useEffect, useState} from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Modal } from 'reactstrap'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import PrefixService from '../../services/prefix'
import { usersConfig } from '../../@types/prefix'
import { requestAuthInit } from '../../store/auth/auth.actions'
import PrimaryButton from '../Common/Button/PrimaryButton'

import Input from '../Common/Input'

interface Open2faModalProps {
    userInfo: usersConfig | any
	isOpenModal: boolean
	toggle: () => void
	update: () => void
}

const Open2faModal: FC<Open2faModalProps> = ({ userInfo, isOpenModal, toggle, update}) => {
    
	const dispatch = useDispatch()
	const {
		values,
		isSubmitting,
		setFieldValue,
		resetForm,
		handleChange,
		handleSubmit,
	} = useFormik({
		initialValues: {
			secret: '',
			otpCode: '',
		},
		enableReinitialize: true,
		onSubmit: async (values, { resetForm }) => {
			try {
				const res = await PrefixService.enable2FA(userInfo.id, values)
				toggle()
				resetForm()
				dispatch(requestAuthInit(false))
				toast.success(res.message)
				update()
			} catch ({ message }) {
				toast.error(message as string)
			}
		},
	})

    const [dataImageQrCode, setDataImageQrCode] = useState<string>('')

	const generate2FA = async () => {
		try {
			const res:any = await PrefixService.generate2FA(userInfo.id)
            setFieldValue('secret', res.secret)
            setDataImageQrCode(res.qrCode)
		} catch ({ message }) {
			toast.error(message as string)
		}
	}


	useEffect(() => {
		if (isOpenModal) {
            resetForm()
            setDataImageQrCode('')
            generate2FA()
		}
	}, [isOpenModal, resetForm]);
    
    
	return (
        <Modal isOpen={isOpenModal} className="flex-row modal-dialog-centered" fade={true} toggle={() => toggle()}>
            <div className="modal-body p-lg-4">
                <div className="d-flex flex-row justify-content-between align-items-center mb-4">
                    <h3 className="mb-0"><strong>เปิดใช้งาน 2FA (Two Factor Authentication)</strong></h3>
                    <div className="modal-close">
                        <i className="far fa-times" onClick={() => toggle()}></i>
                    </div>
                </div>
                <ImageQRCode>{dataImageQrCode && <img src={dataImageQrCode} alt="qrcode" style={{width:'150px'}} />}</ImageQRCode>
                <Form onSubmit={handleSubmit}>
                    <InputSection>
                        <Input
                            type="text"
                            name="secret"
                            label="Secret"
                            defaultValue={values.secret}
                            required
                            disabled
                            onChange={handleChange}
                        />
                    </InputSection>
                    <InputSection>
                        <Input
                            type="text"
                            name="otpCode"
                            label="OTP Code"
                            defaultValue={values.otpCode}
                            required
                            onChange={handleChange}
                        />
                    </InputSection>
                    
                    { isOpenModal &&
                    <InputSection>
                        <PrimaryButton
                            type="submit"
                            width="150px"
                            loading={isSubmitting}
                        >
                            บันทึกข้อมูล
                        </PrimaryButton>
                    </InputSection>
                    }
                </Form>
            </div>
        </Modal>
	)
}
export default Open2faModal


const Form = styled.form`
	width: 100%;
`
const InputSection = styled.div`
	margin-bottom: 20px;
	width: 100%;
`


const ImageQRCode = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`