import debounce from 'lodash/debounce'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import ReactPaginate from 'react-paginate'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { RedeemConfig } from '../../../@types/prefix'
import { format } from '../../../helpers/number'
import { usePagination } from '../../../hooks/usePagination'
import Card, {
	// CardActionHeaderWrapper,
	// CardActionItem,
	CardSubTitle,
	CardTitle,
	CardTitletRowWrapperWithOutBorder,
	CardTitleWrapperWithOutBorder,
} from '../../Common/Card'
// import SearchBar from '../../Common/Input/SearchBar'
import { CountItemList, PaginateWrapper, TableWrapper } from '../../Common/Table'
import RedeemListTable from './RedeemListTable'
import ContentContainer from '../../Common/ContentContainer'
import PrimaryButton from '../../Common/Button/PrimaryButton'
import IconAdd from '../../../assets/images/add-icon.svg'
import PrefixService from '../../../services/prefix'
import { requestAuthInit } from '../../../store/auth/auth.actions'
import AddRedeemModal from './AddRedeemModal'
import ConfirmDelete from './ConfirmDelete'
import EditRedeemModal from './EditRedeemModal'

interface RedeemListContainerProps {
	data: RedeemConfig[]

	update: () => void
	// setSearch: (v: string) => void
}

const RedeemListContainer: FC<RedeemListContainerProps> = ({ data, update }) => {
	const dispatch = useDispatch()

	const [addRedeemModal, setAddRedeemModal] = useState(false)
	const toggleAddRedeem = () => setAddRedeemModal(!addRedeemModal)
	const [deleteRedeemModal, setDeleteRedeemModal] = useState(false)
	const toggleDeleteRedeem = () => setDeleteRedeemModal(!deleteRedeemModal)
	const [IdRedeem, setIdRedeem] = useState(0)
	const [editRedeem, setEditRedeem] = useState(false)
	const toggleEditRedeem = () => setEditRedeem(!editRedeem)
    
	const { data: _data, page, pageCount, setPage } = usePagination(data)

	useEffect(() => {
		setPage(0)
	}, [data, setPage])

	// // Edit Member
	const [redeemInfo, setRedeemInfo] = useState<RedeemConfig>()


	const fetchRedeemInfo = async (id: number, action: string) => {
		if (action === 'delete') {
			toggleDeleteRedeem()
			setIdRedeem(id)
		}else if(action === 'edit') {
			toggleEditRedeem()
			setIdRedeem(id)
			const dataEdit = _data.find((item:RedeemConfig) => item.ID === id)
			setRedeemInfo(dataEdit);
			
		}
        
	}

	const submitDeleteRedeem = async () => {
		try {
			const res = await PrefixService.deleteRedeem(IdRedeem)
			dispatch(requestAuthInit(false))
			toast.success(res.message)
			toggleDeleteRedeem()
			update();
		} catch ({ message }) {
			toast.error(message as string)
		}
	}

	// // AddFixTxModal
	// const [phoneNumber, setPhoneNumber] = useState('')
	// const [addFixTxModal, setAddFixTxModal] = useState(false)
	// const toggleCreditManagement = () => setAddFixTxModal(!addFixTxModal)
	// const openFixCreditForm = async (phone: string) => {
	// 	setPhoneNumber(phone)
	// 	toggleCreditManagement()
	// }

	return (
		<>
        <ContentContainer>
			<Card padding="24px 0 0 0">
				<CardTitleWrapperWithOutBorder>
					<CardTitletRowWrapperWithOutBorder>
						<CardTitle>รายการโค๊ดโปรโมชั่น</CardTitle>
						<CardSubTitle>{format(data?.length || 0, 0)} รายการ</CardSubTitle>
					</CardTitletRowWrapperWithOutBorder>
                    <PrimaryButton onClick={() => toggleAddRedeem()}>
						<AddButtonWrapper>
							<AddIcon src={IconAdd} alt="add" />
							<span>เพิ่มโค๊ดโปรโมชั่น</span>
						</AddButtonWrapper>
					</PrimaryButton>
				</CardTitleWrapperWithOutBorder>
				<TableWrapper>
					<RedeemListTable
						data={_data}
						fetchRedeemInfo={fetchRedeemInfo}
					/>
					<PaginateWrapper>
						<CountItemList></CountItemList>
						<ReactPaginate
							pageCount={pageCount}
							forcePage={page}
							pageRangeDisplayed={5}
							marginPagesDisplayed={2}
							containerClassName={'pagination'}
							pageClassName={'pages pagination-pages'}
							activeClassName={'active-pagination'}
							onPageChange={(e) => {
								setPage(e.selected)
							}}
						/>
					</PaginateWrapper>
				</TableWrapper>
			</Card>
		</ContentContainer>

			<AddRedeemModal
				// phone={phoneNumber}
				update={update}
                isOpenModal={addRedeemModal}
                toggle={toggleAddRedeem}
            />

			<ConfirmDelete
				onSubmit={() => submitDeleteRedeem()}
				isOpenModal={deleteRedeemModal}
				toggleDelete={() => toggleDeleteRedeem()}
			/>

			<EditRedeemModal
				redeemInfo={redeemInfo}
                isOpenModal={editRedeem}
				update={update}
                toggle={toggleEditRedeem}
            />

			
		</>
	)
}

export default RedeemListContainer


const AddButtonWrapper = styled.div`
	flex-direction: row;
	align-items: center;
	padding: 0 8px;
`
const AddIcon = styled.img`
	margin-right: 8px;
`