import debounce from 'lodash/debounce'
import React, { FC, useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { toast } from 'react-toastify'
import { Member } from '../../@types/member'
import { format } from '../../helpers/number'
import { usePagination } from '../../hooks/usePagination'
import Card, {
	CardActionHeaderWrapper,
	CardActionItem,
	CardSubTitle,
	CardTitle,
	CardTitletRowWrapperWithOutBorder,
	CardTitleWrapperWithOutBorder,
} from '../Common/Card'
import SearchBar from '../Common/Input/SearchBar'
import { CountItemList, PaginateWrapper, TableWrapper } from '../Common/Table'
import MemberListTable from './MemberListTable'
import EditMember from './EditMember'
import AddFixTxModal from '../AddFixTx/AddFixTxModal'
import PrefixService from '../../services/prefix'

interface MemberListContainerProps {
	data: Member[]

	update: () => void
	setSearch: (v: string) => void
}

const MemberListContainer: FC<MemberListContainerProps> = ({ data, setSearch, update }) => {
	const _setSearch = debounce((v: string) => {
		setSearch(v)
	}, 500)

	const setMemberActive = async (member: Member, v: boolean) => {
		try {
			const responseSetStatus = await PrefixService.setMemberActive({
				id: member.id,
				status: v
			})

			toast.success(responseSetStatus.message)
			update()
		} catch ({ message }) {
			toast.error(message as string)
		}
	}

	const { data: _data, page, pageCount, setPage } = usePagination(data)

	useEffect(() => {
		setPage(0)
	}, [data, setPage])

	// Edit Member
	const [editMember, setEditMember] = useState(false)
	const toggleEditMember = () => setEditMember(!editMember)
	const [memberInfo, setMemberInfo] = useState<Member>()
	const fetchMemberInfo = async (id: number) => {
		const responseMemberInfo = await PrefixService.getMemberInfo(id).then()
		setMemberInfo(responseMemberInfo.member)
		toggleEditMember()
	}

	// AddFixTxModal
	const [phoneNumber, setPhoneNumber] = useState('')
	const [addFixTxModal, setAddFixTxModal] = useState(false)
	const toggleCreditManagement = () => setAddFixTxModal(!addFixTxModal)
	const openFixCreditForm = async (phone: string) => {
		setPhoneNumber(phone)
		toggleCreditManagement()
	}

	return (
		<>
			<Card padding="24px 0 0 0">
				<CardTitleWrapperWithOutBorder>
					<CardTitletRowWrapperWithOutBorder>
						<CardTitle>รายการสมาชิก</CardTitle>
						<CardSubTitle>{format(data?.length || 0, 0)} รายการ</CardSubTitle>
					</CardTitletRowWrapperWithOutBorder>
					<CardActionHeaderWrapper>
						<CardActionItem>
							<SearchBar
								type="text"
								placeholder="ค้นหา ..."
								onChange={(e) => _setSearch(e.currentTarget.value)}
							/>
						</CardActionItem>
					</CardActionHeaderWrapper>
				</CardTitleWrapperWithOutBorder>
				<TableWrapper>
					<MemberListTable
						data={_data}
						setMemberActive={setMemberActive}
						fetchMemberInfo={fetchMemberInfo}
						creditManagementModal={openFixCreditForm}
					/>
					<PaginateWrapper>
						<CountItemList></CountItemList>
						<ReactPaginate
							pageCount={pageCount}
							forcePage={page}
							pageRangeDisplayed={5}
							marginPagesDisplayed={2}
							containerClassName={'pagination'}
							pageClassName={'pages pagination-pages'}
							activeClassName={'active-pagination'}
							onPageChange={(e) => {
								setPage(e.selected)
							}}
						/>
					</PaginateWrapper>
				</TableWrapper>
			</Card>

			<EditMember
				memberInfo={memberInfo}
                isOpenModal={editMember}
				update={update}
                toggleEditMember={toggleEditMember}
            />

			<AddFixTxModal
				phone={phoneNumber}
				update={update}
                isOpenModal={addFixTxModal}
                toggleAddFixTxModal={toggleCreditManagement}
            />
		</>
	)
}

export default MemberListContainer
