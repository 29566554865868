import React, { FC, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ApplicationState } from '../../../store'
import { useHistory } from 'react-router'
import { Modal } from 'reactstrap'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import PrefixService from '../../../services/prefix'
import AddBonusForm from '../../Bonus/AddBonusForm'
import { requestAuthInit } from '../../../store/auth/auth.actions'

interface AddBonusModalProps {
    dataKey: string
	isOpenModal: boolean
	toggle: () => void
}

const AddBonusModal: FC<AddBonusModalProps> = ({ dataKey, isOpenModal, toggle }) => {

	const dispatch = useDispatch()
	const history = useHistory()
	const {
		values,
		isSubmitting,
		setFieldValue,
		resetForm,
		handleChange,
		handleSubmit,
	} = useFormik({
		initialValues: {
			name: '',
			description: '',
		},
		enableReinitialize: true,
		onSubmit: async (values, { resetForm }) => {
			try {
				const res = await PrefixService.addPromotionConfig(values)

				toggle()
				resetForm()
				dispatch(requestAuthInit(false))
				toast.success(res.message)
				history.push('/promotions')
			} catch ({ message }) {
				toast.error(message as string)
			}
		},
	})

	useEffect(() => {
		if (isOpenModal) {
			resetForm()
		}
	}, [isOpenModal, resetForm]);

	return (
        <Modal isOpen={isOpenModal} className="flex-row modal-dialog-centered" fade={true} toggle={() => toggle()}>
            <div className="modal-body p-lg-4">
                <div className="d-flex flex-row justify-content-between align-items-center mb-4">
                    <h3 className="mb-0"><strong>เพิ่มโบนัส</strong></h3>
                    <div className="modal-close">
                        <i className="far fa-times" onClick={() => toggle()}></i>
                    </div>
                </div>
				<AddBonusForm
						data={values}
						setFieldValue={setFieldValue}
                        loading={isSubmitting}
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                    />
            </div>
        </Modal>
	)
}
export default AddBonusModal