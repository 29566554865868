import { useFormik } from 'formik'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import PrimaryButton from '../components/Common/Button/PrimaryButton'
import ContentContainer from '../components/Common/ContentContainer'
import Input from '../components/Common/Input'
import Select from '../components/Common/Select'
import PageLayout from '../components/PageLayout'
import { Colors } from '../helpers/color'
import { Fonts } from '../helpers/font'
import { MobileSize } from '../helpers/responsive'
import PrefixService from '../services/prefix'
import { requestAuthInit } from '../store/auth/auth.actions'

export interface AddBankParams {
	accountName: string
	accountNumber: string
	bank: 'scb'
}

const AddBank = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	const { values, isSubmitting, handleChange, handleSubmit } = useFormik<AddBankParams>({
		initialValues: {
			accountName: '',
			accountNumber: '',
			bank: 'scb',
		},
		onSubmit: async (values) => {
			try {
				const res = await PrefixService.addBankAccount(values)

				dispatch(requestAuthInit(false))
				toast.success(res.message)
				history.push('/bank')
			} catch ({ message }) {
				toast.error(message as string)
			}
		},
	})

	return (
		<PageLayout title="เพิ่มบัญชีธนาคาร" goBack="/bank">
			<Body>
				<ContentContainer>
					<InnerBody>
						<AddBankForm autoComplete="off" onSubmit={handleSubmit}>
							<MainTitleWrapper>
								<FormTitle>รายละเอียดธนาคาร</FormTitle>
							</MainTitleWrapper>
							<InputSection>
								<Input
									type="text"
									name="accountName"
									label="ชื่อบัญชี"
									required
									onChange={handleChange}
								/>
							</InputSection>
							<InputSection>
								<Input
									type="text"
									name="accountNumber"
									label="เลขที่บัญชี"
									required
									onChange={handleChange}
								/>
							</InputSection>
							<InputSection>
								<Select
									items={[
										{
											label: 'ธนาคารไทยพาณิชย์',
											value: 'scb',
										},
									]}
									name="bank"
									label="ธนาคาร"
									defaultValue={values.bank}
									required
									onChange={handleChange}
								/>
							</InputSection>
							<InputSection>
								<PrimaryButton type="submit" loading={isSubmitting}>
									เพิ่มบัญชี
								</PrimaryButton>
							</InputSection>
						</AddBankForm>
					</InnerBody>
				</ContentContainer>
			</Body>
		</PageLayout>
	)
}

export default AddBank

const Body = styled.div`
	background: ${Colors.white};
	width: 100%;
	height: 100%;
	margin-top: 18px;
`
const AddBankForm = styled.form`
	width: 100%;
`
const InnerBody = styled.div`
	max-width: 43%;
	width: 100%;
	margin: auto;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	@media only screen and (max-width: ${MobileSize}px) {
		max-width: 100%;
	}
`
const MainTitleWrapper = styled.div`
	flex-direction: row;
	width: 100%;
	justify-content: flex-end;
	align-items: center;
`
const FormTitle = styled.div`
	flex: 1;
	font-size: 24px;
	color: ${Colors.black2};
	font-weight: bold;
	font-family: ${Fonts.primary};
	margin: 20px 0 20px 0;
	padding: 0;
`
const InputSection = styled.div`
	margin-bottom: 20px;
	width: 100%;
`
