import { AuthInitResponse, LoginParam, LoginResponse } from '../@types/auth'
import api from '../helpers/api'
import PrefixService from './prefix'

class AuthService {
	private token: string = ''

	constructor() {
		const token = localStorage.getItem('x-bauth')
		if (token) {
			this.setToken(token)
		}
	}

	getToken(): string {
		return this.token
	}
	setToken(token: string, save: boolean = false) {
		this.token = token

		if (save) {
			localStorage.setItem('x-bauth', token)
		}
	}
	revokeToken() {
		this.token = ''

		delete api.defaults.headers['Authorization']
		localStorage.removeItem('x-bauth')
	}

	async login(data: LoginParam): Promise<boolean> {
		PrefixService.setPrefix(data.prefix, true)

		try {
			const { token } = await api.post<void, LoginResponse>(
				'/login',
				data
			)

			this.setToken(token, true)
			return false
		} catch (e) {
			return Promise.reject(e)
		}
	}

	async getAuthInit(): Promise<AuthInitResponse> {
		try {
			const res = await api.get<void, AuthInitResponse>('/auth/init')

			return res
		} catch (e) {
			return Promise.reject(e)
		}
	}
}

export default new AuthService()
