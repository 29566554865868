import React, { FC, useState, useEffect } from 'react'
import { useFormik } from 'formik'
import EditMemberForm from '../../components/EditMember/EditMemberForm'
import { Modal, ModalHeader, ModalFooter } from 'reactstrap'
import { Member } from '../../@types/member'
import PrefixService from '../../services/prefix'
import { toast } from 'react-toastify'
import PrimaryButton from '../Common/Button/PrimaryButton'
import SecondButton from '../Common/Button/SecondButton'

interface EditMemberProps {
	isOpenModal: boolean
	memberInfo: any

	update: () => void
	toggleEditMember: () => void
}

const EditMember: FC<EditMemberProps> = ({ memberInfo, isOpenModal, update, toggleEditMember }) => {
	const { isSubmitting, setValues, handleChange, handleSubmit, setFieldValue } = useFormik({
		initialValues: {
			phone: '',
			twID: '',
			firstName: '',
			lastName: '',
			firstNameEN: '',
			lastNameEN: '',
			bankCode: '',
			bankNumber: '',

			affiliate: {
				balance: 0,
				id: 0
			},
			totalTurn: 0,
			minWithdraw: 0,
			maxWithdraw: 0,
		},
		enableReinitialize: true,
		onSubmit: async (values) => {
			try {
				const res = await PrefixService.updateMemberInfo(values)

				toast.success(res.message)
				update()
				toggleEditMember()
			} catch ({ message }) {
				toast.error(message as string)
			}
		},
	})

	const [member, setMember] = useState<Member>()

	useEffect(() => {
		setMember(memberInfo)
		setValues(memberInfo)
	}, [memberInfo, setValues]);

	return (
		<Modal isOpen={isOpenModal} className="flex-row modal-dialog-scrollable modal-dialog-centered" fade={true} toggle={toggleEditMember}>
			<ModalHeader toggle={toggleEditMember}>
				<strong>แก้ไข / ตั้งค่าสมาชิก</strong>
			</ModalHeader>
			<EditMemberForm
				member={member as Member}
				loading={isSubmitting}
				setFieldValue={setFieldValue}
				onChange={handleChange}
				onSubmit={handleSubmit}
				isModal={true}
			/>
			<ModalFooter>
				<SecondButton type="button" width="120px" loading={isSubmitting} onClick={toggleEditMember}>
					ยกเลิก
				</SecondButton>
				<PrimaryButton type="submit" form="updateMemberForm" width="120px" loading={isSubmitting}>
					บันทึกข้อมูล
				</PrimaryButton>
			</ModalFooter>
		</Modal>
	)
}
export default EditMember
