import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import { Member, memberHistoryTabs } from '../@types/member'
import ContentContainer from '../components/Common/ContentContainer'
import Loading from '../components/Common/Loading'
import Tabs from '../components/Common/Tabs'
import DepositTab from '../components/MemberHistory/DepositTab'
import OtherTab from '../components/MemberHistory/OtherTab/OtherTab'
import WithdrawTab from '../components/MemberHistory/WithdrawTab/WithdrawTab'
import PageLayout from '../components/PageLayout'
import { Colors } from '../helpers/color'
import { getFullName } from '../helpers/member'
import { MobileSize } from '../helpers/responsive'
import PrefixService from '../services/prefix'

interface MemberHistoryParams {
	id: string
}
const MemberHistory = () => {
	const params = useParams<MemberHistoryParams>()
	const [activeTab, setActiveTab] = useState('deposit')

	const [member, setMember] = useState<Member>()
	useEffect(() => {
		PrefixService.getMemberInfo(params.id).then((res) => {
			setMember(res.member)
		})
	}, [params])

	if (!member) {
		return <Loading />
	}
	return (
		<PageLayout title={getFullName(member)} subTitle="ประวัติการทำรายการ" goBack="/member">
			<ContentContainer>
				<TabController>
					<Tabs tabs={memberHistoryTabs} activeTab={activeTab} selectTab={setActiveTab} />
				</TabController>
			</ContentContainer>
			<Body>
				{activeTab === 'deposit' && <DepositTab member={member} />}
				{activeTab === 'withdraw' && <WithdrawTab member={member} />}
				{activeTab === 'other' && <OtherTab member={member} />}
			</Body>
		</PageLayout>
	)
}

export default MemberHistory

const TabController = styled.div`
	flex-direction: row;
	margin-top: 32px;
	@media only screen and (max-width: ${MobileSize}px) {
		margin-top: 24px;
	}
`

const Body = styled.div`
	background: ${Colors.zircon2};
	width: 100%;
	flex: 1;
	margin-top: -3px;
	padding: 46px 0;
	@media only screen and (max-width: ${MobileSize}px) {
		padding: 20px 0;
	}
`
