import React, { useState } from 'react'
import styled from 'styled-components'
import { dashboardTabs } from '../@types/dashboard'
import ContentContainer from '../components/Common/ContentContainer'
import Tabs from '../components/Common/Tabs'
import AffiliateTab from '../components/Dashboard/AffiliateTab'
import BonusTab from '../components/Dashboard/BonusTab'
import CashbackTab from '../components/Dashboard/CashbackTab'
import OverviewTab from '../components/Dashboard/OverviewTab'
import WheelTab from '../components/Dashboard/WheelTab'
import RedeemTab from '../components/Dashboard/RedeemTab'
import ReportByMemberTab from '../components/Dashboard/ReportByMemberTab'
import PageLayout from '../components/PageLayout'
import { Colors } from '../helpers/color'
import { MobileSize } from '../helpers/responsive'

const TabController = styled.div`
	flex-direction: row;
	margin-top: 32px;
	@media only screen and (max-width: ${MobileSize}px) {
		width: 100%;
		overflow-x: scroll;
	}
`
const Content = styled.div`
	background: ${Colors.zircon2};
	width: 100%;
	flex: 1;
	margin-top: -3px;
	padding: 46px 0;
	@media only screen and (max-width: ${MobileSize}px) {
		padding: 20px 0;
	}
`

const Report = () => {
	const [activeTab, setActiveTab] = useState('overview')

	return (
		<PageLayout title="ข้อมูลทั้งหมด" subTitle="รายงานสรุป">
			<ContentContainer>
				<TabController>
					<Tabs tabs={dashboardTabs} activeTab={activeTab} selectTab={setActiveTab} />
				</TabController>
			</ContentContainer>

			<Content>
				{activeTab === 'overview' && <OverviewTab />}
				{activeTab === 'bonus' && <BonusTab />}
				{activeTab === 'cashback' && <CashbackTab />}
				{activeTab === 'wheel' && <WheelTab />}
				{activeTab === 'redeem' && <RedeemTab />}
				{activeTab === 'affiliate' && <AffiliateTab />}
				{activeTab === 'report-by-member' && <ReportByMemberTab />}
			</Content>
		</PageLayout>
	)
}

export default Report
