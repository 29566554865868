import React, { FC } from 'react'
// import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
// import { BadgeType } from '../../../@types/badge'
import { RedeemConfig } from '../../../@types/prefix'
// import activeToggleIcon from '../../../assets/images/active-toggle-icon.svg'
// import addCreditIcon from '../../../assets/images/currency-button-icon.svg'
// import historyIcon from '../../../assets/images/history-button-icon.svg'
// import noneActiveToggleIcon from '../../../assets/images/non-active-toggle-icon.svg'
import settingIcon from '../../../assets/images/setting-button-icon.svg'
import deleteIcon from '../../../assets/images/trash-icon.svg'
// import BadgeStatus from '../../Common/BadgeStatus'
// import BankType from '../../Common/BankType/BankType'
// import PhoneNumberWithIcon from '../../Common/PhoneNumberWithIcon/PhoneNumberWithIcon'
import { BodyTR, Td, Th } from '../../Common/Table'
import EnhancedTable from '../../Common/Table/EnhancedTable'
// import ToggleWithIcon from '../../Common/Toggle/ToggleWithIcon'

interface RedeemListTableProps {
	data: RedeemConfig[]
	fetchRedeemInfo: (id: number, action: string) => void

}

const MemberListTable: FC<RedeemListTableProps> = ({ data, fetchRedeemInfo }) => {
	const renderRow = (row: RedeemConfig) => (
		<BodyTR key={`members.${row.ID}`}>
			<Td>
				<b>
					{row.Code}
				</b>
			</Td>
			<Td>
				{row.Amount}
			</Td>
			<Td>
				{row.Limit}
			</Td>
			<Td>
				{row.Used}
			</Td>
			<Td>
				<MemberActionGroup>
					<ActionItem onClick={() => fetchRedeemInfo(row.ID, 'edit')}>
						<SettingButton src={settingIcon} alt="setting" data-tip data-for={`edit_${row.ID}`} />
						<ReactTooltip id={`edit_${row.ID}`} effect="solid">
							<span>แก้ไข</span>
						</ReactTooltip>
					</ActionItem>
					<ActionItem onClick={() => fetchRedeemInfo(row.ID, 'delete')}>
						<SettingButton src={deleteIcon} alt="setting" data-tip data-for={`del_${row.ID}`} />
						<ReactTooltip id={`del_${row.ID}`} effect="solid">
							<span>ลบ</span>
						</ReactTooltip>
					</ActionItem>
				</MemberActionGroup>
			</Td>
		</BodyTR>
	)

	return (
		<EnhancedTable
			loading={!data}
			data={data}
			theadSize={4}
			thead={
				<tr>
					<Th style={{ textAlign: 'left' }}>โค๊ดโปรโมชั่น</Th>
					<Th style={{ textAlign: 'left' }}>เครดิต</Th>
					<Th>จำนวนโค๊ด</Th>
					<Th>ใช้ไปแล้ว</Th>
					<Th>จัดการ</Th>
				</tr>
			}
		>
			{(row: RedeemConfig) => renderRow(row)}
		</EnhancedTable>
	)
}

export default MemberListTable

const MemberActionGroup = styled.div`
	flex-direction: row;
	justify-content: center;
`

// const ToggleWithIconWrapper = styled.div`
// 	width: 70px;
// 	margin-right: 8px;
// `
// const HistoryButton = styled.img`
// 	cursor: pointer;
// `
// const AddCreditButton = styled.img`
// 	cursor: pointer;
// `
const SettingButton = styled.img`
	cursor: pointer;
`

const ActionItem = styled.div`
	margin: 0 4px;
`
