import { BonusSummaryItem } from '../api/summary/types'

export const bonusSummarySort = (a: BonusSummaryItem, b: BonusSummaryItem) => {
	if (a.amount > b.amount) {
		return -1
	} else if (a.amount < b.amount) {
		return 1
	}
	return 0
}
