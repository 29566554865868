import React, { useState, useEffect } from 'react'
// import { useSelector } from 'react-redux'
import styled from 'styled-components'
// import BankList from '../components/Bank/BankList'
// import CentralAccountSetting from '../components/Bank/CentralAccountSetting'
// import SCBAccountList from '../components/Bank/SCBAccount/SCBAccountList'
import PrefixPage from '../components/SettingPrefix/Prefix'
import AmbPayment from '../components/SettingPrefix/Amb/AmbPayment'
import AmbGames from '../components/SettingPrefix/Amb/AmbGames'
import ContentContainer from '../components/Common/ContentContainer'
import Loading from '../components/Common/Loading'
import PageLayout from '../components/PageLayout'
import { Colors } from '../helpers/color'
import { Fonts } from '../helpers/font'
// import { format } from '../helpers/number'
import { MobileSize } from '../helpers/responsive'
// import { ApplicationState } from '../store'
// import Tabs from '../components/Common/Tabs'
import { settingWebConfig } from '../@types/prefix'
import PrefixService from '../services/prefix'
import { toast } from 'react-toastify'

const Setting = () => {
	// const prefix = useSelector((state: ApplicationState) => state.prefix).prefix
	const [tabActive, setTabActive] = useState('website')
	const [listTabs, setListTab] = useState<any>([])
	const [showAmbGames, setShowAmbGames] = useState<boolean>(false)
	const [loadingShowAmbGames, setLoadingShowAmbGames] = useState<boolean>(true)
	
	// const listTabs = 


	const [dataSetting, setDataSetting] = useState<settingWebConfig>()

	const getSettingPage = async () => {
		try {
			const res = await PrefixService.getSetting()
			setDataSetting(res.setting)
			setShowAmbGames(res.setting.ambbetGames === null ? false : true)
			setLoadingShowAmbGames(false)
		} catch ({ message }) {
			toast.error(message as string)
		}
	}
    
	useEffect(() => {
		getSettingPage()
	}, [])

	useEffect(() => {
		setListTab([
			{
				key: 'website',
				text: `ตั้งค่าเว็บไซต์`,
				show: true
			},
			{
				key: 'website-amb-payment',
				text: `ตั้งค่า AMB Payment`,
				show: true
			},
			{
				key: 'website-amb-games',
				text: `ตั้งค่าเกม Ambbet`,
				show: showAmbGames
			},
		])
	}, [dataSetting, showAmbGames])
	
	return (
		<PageLayout title="ตั้งค่า Prefix" subTitle="จัดการเว็บไซต์">
			<ContentContainer>
				<TabsController>
					{/* <Tabs tabs={listTabs} activeTab={tabActive} selectTab={setTabActive} /> */}
					{listTabs.map((tab : any, index:number) => (
						<div key={index}>
						{tab.show ? 
							<TabStyled
								key={tab.key}
								active={tab.key === tabActive}
								onClick={() => setTabActive(tab.key)}>
								
								{tab.text}
							</TabStyled>
						:
						<div key={index}>{loadingShowAmbGames && <i className="fad fa-spinner-third fa-spin fa-1x" />}</div>
						}
						</div>
					))}
				</TabsController>
			</ContentContainer>
			<Body>
				{tabActive === 'website' && <PrefixPage data={dataSetting || {}} />}
				{tabActive === 'website-amb-payment' && <AmbPayment data={dataSetting || {}}  />}
				{tabActive === 'website-amb-games' && showAmbGames === true && <AmbGames data={dataSetting || {}}  />}
			</Body>
		</PageLayout>
	)
}

export default Setting

const Body = styled.div`
	background: ${Colors.zircon2};
	width: 100%;
	flex: 1;
	margin-top: -3px;
	padding: 46px 0;
	@media only screen and (max-width: ${MobileSize}px) {
		padding: 20px 0;
	}
`
const TabsController = styled.div`
	flex-direction: row;
	margin-top: 32px;
	@media only screen and (max-width: ${MobileSize}px) {
		margin-top: 12px;
		width: 100%;
		overflow-x: scroll;
	}
`


interface TabStyledProps {
	active: boolean
}
const TabStyled = styled.div<TabStyledProps>`
	cursor: pointer;
	color: ${Colors.regentGray};
	font-weight: 600;
	font-size: 16px;
	line-height: 21px;
	font-family: ${Fonts.primary};
	margin-right: 30px;
	/* justify-content: center; */
	text-align: center;
	align-items: center;
	padding: 0 18px;
	@media only screen and (max-width: ${MobileSize}px) {
		margin-right: 4px;
	}
	&:after {
		${({ active }) => active && 'content: "";'}
		height: 3px;
		width: 100%;
		bottom: 0;
		background: ${Colors.dodgerBlue};
		margin-top: 16px;
		padding: 0 18px;
		z-index: 3;
		@media only screen and (max-width: ${MobileSize}px) {
			padding: 0 8px;
		}
	}
`