import { useFormik } from 'formik'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { AddTruewalletParams } from '../@types/truewallet'
import ConfirmTruewalletOTPModal from '../components/Bank/ConfirmTruewalletOTPModal'
import PrimaryButton from '../components/Common/Button/PrimaryButton'
import ContentContainer from '../components/Common/ContentContainer'
import Input from '../components/Common/Input'
import PageLayout from '../components/PageLayout'
import { Colors } from '../helpers/color'
import { Fonts } from '../helpers/font'
import { MobileSize } from '../helpers/responsive'
import PrefixService from '../services/prefix'
import { requestAuthInit } from '../store/auth/auth.actions'

const AddTruewallet = () => {
	const history = useHistory()
	const dispatch = useDispatch()

	const { values, isSubmitting, setSubmitting, setFieldValue, handleChange, handleSubmit } =
		useFormik<AddTruewalletParams>({
			initialValues: {
				phone: '',
				password: '',
				pin: '',
				otpRef: '',
				otpCode: '',
			},
			onSubmit: async (values, { setFieldValue }) => {
				try {
					const res = await PrefixService.requestTruewalletOTP(values)

					setFieldValue('otpRef', res.otpRef)
				} catch ({ message }) {
					toast.error(message as string)
				}
			},
		})
	const confirmOTP = async () => {
		setSubmitting(true)

		try {
			const res = await PrefixService.confirmTruewalletOTP(values)

			dispatch(requestAuthInit(false))
			toast.success(res.message)
			history.push('/bank')
		} catch ({ message }) {
			toast.error(message as string)
			setSubmitting(false)
		}
	}

	return (
		<PageLayout title="เพิ่มบัญชีทรูมันนี่วอลเล็ท" goBack="/bank">
			<Body>
				<ContentContainer>
					<InnerBody>
						<AddTruewalletForm autoComplete="off" onSubmit={handleSubmit}>
							<MainTitleWrapper>
								<FormTitle>รายละเอียดบัญชีทรูมันนี่วอลเล็ท</FormTitle>
							</MainTitleWrapper>
							<InputSection>
								<Input
									type="text"
									name="phone"
									label="เบอร์โทรศัพท์"
									maxLength={10}
									required
									onChange={handleChange}
								/>
							</InputSection>
							<InputSection>
								<Input
									type="text"
									name="password"
									label="รหัสผ่าน"
									required
									onChange={handleChange}
								/>
							</InputSection>
							<InputSection>
								<Input
									type="text"
									name="pin"
									label="PIN"
									maxLength={6}
									required
									onChange={handleChange}
								/>
							</InputSection>
							<InputSection>
								<PrimaryButton type="submit" loading={isSubmitting}>
									ส่งรหัส OTP
								</PrimaryButton>
							</InputSection>
						</AddTruewalletForm>
					</InnerBody>
				</ContentContainer>
			</Body>

			{values.otpRef && (
				<ConfirmTruewalletOTPModal
					loading={isSubmitting}
					otpRef={values.otpRef}
					onChange={handleChange}
					onClose={() => setFieldValue('otpRef', '')}
					onSubmit={confirmOTP}
				/>
			)}
		</PageLayout>
	)
}

export default AddTruewallet

const Body = styled.div`
	background: ${Colors.white};
	width: 100%;
	height: 100%;
	margin-top: 18px;
`
const AddTruewalletForm = styled.form`
	width: 100%;
`
const InnerBody = styled.div`
	max-width: 43%;
	width: 100%;
	margin: auto;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	@media only screen and (max-width: ${MobileSize}px) {
		max-width: 100%;
	}
`
const MainTitleWrapper = styled.div`
	flex-direction: row;
	width: 100%;
	justify-content: flex-end;
	align-items: center;
`
const FormTitle = styled.div`
	flex: 1;
	font-size: 24px;
	color: ${Colors.black2};
	font-weight: bold;
	font-family: ${Fonts.primary};
	margin: 20px 0 20px 0;
	padding: 0;
`
const InputSection = styled.div`
	margin-bottom: 20px;
	width: 100%;
`
