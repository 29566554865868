import { FC, InputHTMLAttributes } from 'react'
import styled from 'styled-components'
import { Colors } from '../../../helpers/color'
import { Fonts } from '../../../helpers/font'

const Wrapper = styled.div`
	width: 100%;
`

interface DateInputWrapperStyledProps {
	background?: string
}
const DateInputWrapper = styled.div<DateInputWrapperStyledProps>`
	border: 2px solid ${Colors.linkWater};
	flex: 1;
	width: 100%;
	border-radius: 8px;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	${({ background }) => `background:${background};`}
`
const DateInput = styled.input`
	flex: 1;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	height: 100%;
	width: 100%;
	background: none;
	z-index: 2;
	border: none;
	padding: 0.5rem 0rem;

	::-webkit-datetime-edit {
		font-family: ${Fonts.primary};
		font-weight: bold;
		padding-left: 16px;
		color: ${Colors.slateGray};
		font-size: 13px;
		display: flex;
		align-items: center;
	}
	::-webkit-calendar-picker-indicator {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: auto;
		height: auto;
		color: transparent;
		background: transparent;
	}
`

const DateInputIconWrapper = styled.div`
	border-left: 2px solid ${Colors.linkWater};
	position: absolute;
	right: 0;
	height: 100%;
	justify-content: center;
	z-index: 1;
`
const DateInputIcon = styled.img`
	padding: 12px;
`
const Label = styled.div`
	margin-bottom: 8px;
	color: ${Colors.slateGray};
	font-size: 16px;
	font-family: ${Fonts.primary};
`

interface DatePickerProps {
	label?: string
	background?: string
}
const DatePicker: FC<DatePickerProps & InputHTMLAttributes<HTMLInputElement>> = ({
	label,
	background,
	...rest
}) => (
	<Wrapper>
		{label && <Label>{label}</Label>}
		<DateInputWrapper background={background}>
			<DateInput type="date" {...rest} />
		</DateInputWrapper>
	</Wrapper>
)

export default DatePicker
