import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import Tabs from '../components/Common/Tabs'
import { promotionTabs } from '../@types/prefix'
import ContentContainer from '../components/Common/ContentContainer'
import PageLayout from '../components/PageLayout'
import AffiliateSetting from '../components/Promotions/Affiliate/AffiliateSetting'
import BonusSection from '../components/Promotions/Bonus/BonusSection'
import CashbackSetting from '../components/Promotions/Cashback/CashbackSetting'
import WheelSetting from '../components/Promotions/Wheel/WheelSetting'
import CreditSetting from '../components/Promotions/Credit/CreditSetting'
import RedeemListContainer from '../components/Promotions/Redeem/RedeemListContainer'
import { MobileSize } from '../helpers/responsive'
import { ApplicationState } from '../store'
import { Colors } from '../helpers/color'
import PrefixService from '../services/prefix'
import { toast } from 'react-toastify'

const Promotions = () => {
	const { prefix, features } = useSelector((state: ApplicationState) => state.prefix)
	const [activeTab, setActiveTab] = useState('bonus')

	const [dataNewRegBonus, setDataNewRegBonus] = useState<any>()
	const [dataRedeem, setDataRedeem] = useState<any>()

	const getNewRegBonus = async () => {
		try {
			const res = await PrefixService.getNewRegBonus()
			setDataNewRegBonus(res)
		} catch ({ message }) {
			toast.error(message as string)
		}
	}

	const getRedeem = async () => {
		try {
			const res = await PrefixService.getRedeem()
			setDataRedeem(res)
		} catch ({ message }) {
			toast.error(message as string)
		}
	}

	useEffect(() => {
		getNewRegBonus()
		getRedeem()
	}, [])
	
	

	return (
		<PageLayout title="โปรโมชั่น" subTitle="จัดการเว็บไซต์">
			<ContentContainer>
				<TabController>
					<Tabs tabs={promotionTabs} activeTab={activeTab} selectTab={setActiveTab} />
				</TabController>
			</ContentContainer>

			<Content>
				{activeTab === 'bonus' && <BonusSection data={prefix.promotions} />}
				{activeTab === 'new-reg-bonus' && <CreditSetting data={dataNewRegBonus} />}
				{activeTab === 'wheel' && <WheelSetting data={prefix} />}
				{activeTab === 'redeem' && <RedeemListContainer data={dataRedeem} update={getRedeem}/>}
				{features['cashback'] && (activeTab === 'cashback' && <CashbackSetting data={prefix} />)}
				{activeTab === 'affiliate' && <AffiliateSetting data={prefix} />}
			</Content>
		</PageLayout>
	)
}

export default Promotions

const TabController = styled.div`
	flex-direction: row;
	margin-top: 32px;
	@media only screen and (max-width: ${MobileSize}px) {
		width: 100%;
		overflow-x: scroll;
	}
`

const Content = styled.div`
	background: ${Colors.zircon2};
	width: 100%;
	flex: 1;
	margin-top: -3px;
	padding: 46px 0;
	@media only screen and (max-width: ${MobileSize}px) {
		padding: 20px 0;
	}
`