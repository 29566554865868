// import debounce from 'lodash/debounce'
import React, { FC, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
// import ReactPaginate from 'react-paginate'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { settingWebConfig } from '../../../@types/prefix'
// import { format } from '../../../helpers/number'
import { MobileSize } from '../../../helpers/responsive'
import { Colors } from '../../../helpers/color'
import { Fonts } from '../../../helpers/font'
// import { usePagination } from '../../../hooks/usePagination'
import Card, {
    // CardActionHeaderWrapper,
    // CardActionItem,
    // CardSubTitle,
    CardTitle,
    CardTitletRowWrapperWithOutBorder,
    CardTitleWrapperWithOutBorder,
} from '../../Common/Card'

import { requestAuthInit } from '../../../store/auth/auth.actions'
import PrimaryButton from '../../Common/Button/PrimaryButton'
import TwoColumn from '../../Common/Columns/TwoColumn'
import Input from '../../Common/Input'
// import Radio from '../../Common/Radio/Radio'
// import Select from '../../Common/Select'
// import { TitleTable } from '../../Common/Table'
// import Toggle from '../../Common/Toggle'
import ContentContainer from '../../Common/ContentContainer'
import Separator from '../../Common/Separator'
import CheckboxItem from '../../Common/Checkbox/CheckboxItem'
// import PrimaryButton from '../../Common/Button/PrimaryButton'
// import IconAdd from '../../../assets/images/add-icon.svg'
import PrefixService from '../../../services/prefix'
import 'antd/dist/antd.css';
import { Upload, Button } from 'antd';
import { LoadingOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';


interface SettingPrefixContainerProps {
    data: settingWebConfig | any

    // update: () => void
}

const SettingPrefixContainer: FC<SettingPrefixContainerProps> = ({ data }) => {

    const dispatch = useDispatch()
    const [imageUrl, setImageUrl] = useState<string>(data?.logo) //data.image
    const [imageiConUrl, setImageiConUrl] = useState<string>() //data.image
    const [loadingImage, setLoadingImage] = useState<boolean>(false)

    const {
        values,
        isSubmitting,
        // setValues,
        setFieldValue,
        handleChange,
        handleSubmit,
    } = useFormik({
        initialValues: data,
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                const res = await PrefixService.updateSetting(values)
                dispatch(requestAuthInit(false))
                toast.success(res.message)
            } catch ({ message }) {
                toast.error(message as string)
            }
        },
    })

    useEffect(() => {
        if (typeof (data) != "undefined") {
            // setValues(data)
            setImageUrl(data?.logo)
        }
    }, [data])

    const getBase64 = (img: any, callback: any) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const uploadButton = (
        <div>
            {loadingImage ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const uploadImage = async (response: any) => {
        setLoadingImage(true)
        let formData: FormData = new FormData();
        formData.append('file', response.file);
        formData.append('setting', 'logo');

        try {
            const res = await PrefixService.uploadSettingImageLogo(formData)
            toast.success(res.message)

            setLoadingImage(false)
            getBase64(response.file, (imageUrl: any) =>
                setImageUrl(imageUrl)
            );
        } catch ({ message }) {
            toast.error(message as string)
            // setLoadingImage(false)
        }
    }

    // const deleteImage = async () => {
    // 	try {
    // 		// await PrefixService.deletePromotionImage(dataKey ? dataKey : '')
    // 		toast.success('ลบรูปภาพสำเร็จ')
    // 		setImageUrl('')
    // 	} catch ({ message }) {
    // 		toast.error(message as string)
    // 	}
    // }

    return (
        <>
            <ContentContainer>
                <Card padding="24px 0 0 0">
                    <CardTitleWrapperWithOutBorder>
                        <CardTitletRowWrapperWithOutBorder>
                            <CardTitle>ตั้งค่าเว็บไซต์</CardTitle>
                        </CardTitletRowWrapperWithOutBorder>
                    </CardTitleWrapperWithOutBorder>
                    <WheelCardBottomContentWrapper>
                        <form onSubmit={handleSubmit}>
                            <InputSection key={1}>
                                <Input
                                    type="text"
                                    name="title"
                                    label={'ชื่อเว็บไซต์'}
                                    defaultValue={values?.title}
                                    required
                                    onChange={handleChange}
                                    symbol=""
                                />
                            </InputSection>
                            <InputSection key={2}>
                                <TwoColumn margin="24px">
                                    <Input
                                        type="text"
                                        name="game"
                                        label={'เกม'}
                                        defaultValue={values?.game?.toUpperCase()}
                                        required
                                        symbol=""
                                        disabled
                                    />
                                    <Input
                                        type="text"
                                        name="agent"
                                        label={'Agent'}
                                        defaultValue={values?.agent}
                                        required
                                        symbol=""
                                        disabled
                                    />
                                </TwoColumn>
                            </InputSection>
                            <SeparatorWrapper>
                                <Separator color={Colors.linkWater} />
                            </SeparatorWrapper>
                            <InputSection key={3}>
                                <TwoColumn margin="24px">
                                    <Input
                                        type="text"
                                        name="gameLink"
                                        label={'ลิงก์เข้าเล่นเกม'}
                                        defaultValue={values?.gameLink}
                                        onChange={handleChange}
                                        // onChange={(v) => 
                                        //     setFieldValue('gameLink', v.target.value ? v.target.value : '')
                                        // }
                                        symbol=""
                                    />
                                    <Input
                                        type="text"
                                        name="lineLink"
                                        label={'ลิงก์ไลน์ (แจ้งปัญหา)'}
                                        defaultValue={values?.lineLink}
                                        onChange={handleChange}
                                        // onBlur={(v) =>
                                        // 	setFieldValue('lineLink', v.target.value ? v.target.value : '')
                                        // }
                                        symbol=""
                                    />
                                </TwoColumn>
                            </InputSection>
                            <InputSection key={4}>
                                <TwoColumn margin="24px">
                                    <Input
                                        type="text"
                                        name="linkDownloadIOS"
                                        label={'ลิงก์ดาวน์โหลด (iOS)'}
                                        defaultValue={values?.linkDownloadIOS}
                                        onChange={handleChange}
                                        // onBlur={(v) =>
                                        // 	setFieldValue('linkDownloadIOS', v.target.value ? v.target.value : '')
                                        // }
                                        symbol=""
                                    />
                                    <Input
                                        type="text"
                                        name="linkDownloadAndroid"
                                        label={'ลิงก์ดาวน์โหลด (Android)'}
                                        defaultValue={values?.linkDownloadAndroid}
                                        onChange={handleChange}
                                        // onBlur={(v) =>
                                        // 	setFieldValue('linkDownloadAndroid', v.target.value ? v.target.value : '')
                                        // }
                                        symbol=""
                                    />
                                </TwoColumn>
                            </InputSection>
                            <SeparatorWrapper>
                                <Separator color={Colors.linkWater} />
                            </SeparatorWrapper>
                            <InputSection key={5}>
                                <Input
                                    type="text"
                                    name="lineToken"
                                    label={'Line Token (แจ้งเตือนรายการฝาก - ถอน)'}
                                    defaultValue={values?.lineToken}
                                    onChange={handleChange}
                                    symbol=""
                                />
                            </InputSection>
                            <SeparatorWrapper>
                                <Separator color={Colors.linkWater} />
                            </SeparatorWrapper>
                            {/* Upload Image */}
                            <InputSection>
                                <InputSection key={3}>
                                    <TwoColumn margin="24px">
                                        <div>
                                            <Label style={{ marginBottom: '16px' }}>โลโก้เว็บไซต์</Label>
                                            <Upload
                                                name="logo-image"
                                                listType="picture-card"
                                                showUploadList={false}
                                                customRequest={uploadImage}
                                            >
                                                {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                            </Upload>
                                        </div>
                                        <div>
                                            <Label style={{ marginBottom: '16px' }}>ไอคอนเว็บไซต์</Label>
                                            <Upload
                                                name="icon-image"
                                                listType="picture-card"
                                                showUploadList={false}
                                                customRequest={uploadImage}
                                            >
                                                {imageiConUrl ? <img src={imageiConUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                            </Upload>
                                        </div>
                                    </TwoColumn>
                                </InputSection>
                                {/* {imageUrl &&
                            <Button
                                danger
                                onClick={deleteImage}
                                icon={<DeleteOutlined />}
                                size='small'
                                style={{
                                    maxWidth: '120px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginBottom: '18px'
                                }}
                            >
                                ลบรูปภาพ
                            </Button>
                        } */}
                            </InputSection>
                            <SeparatorWrapper>
                                <Separator color={Colors.linkWater} />
                            </SeparatorWrapper>
                            <InputSection key={6}>
                                <TwoColumn margin="24px">
                                    <Input
                                        type="number"
                                        name="minCredit"
                                        label={'ยอดคงเหลือขั้นต่ำ (เติมเครดิต)'}
                                        defaultValue={values?.minCredit}
                                        required
                                        onChange={handleChange}
                                        symbol=""
                                    />
                                    <Input
                                        type="number"
                                        name="minWithdraw"
                                        label={'ยอดถอนขั้นต่ำ'}
                                        defaultValue={values?.minWithdraw}
                                        required
                                        onChange={handleChange}
                                        symbol=""
                                    />
                                </TwoColumn>
                            </InputSection>
                            <SeparatorWrapper>
                                <Separator color={Colors.linkWater} />
                            </SeparatorWrapper>
                            <InputSection>
                                <RuleTitle>เงื่อนไขกงล้อเสี่ยงโชค</RuleTitle>
                                <RuleCheckBoxWrapper>
                                    <CheckboxItem
                                        label={`รันรายการฝากเข้าเกม`}
                                        checked={values?.functions?.depositToGame}
                                        padding="0 0 8px 0"
                                        onChange={(v) =>
                                            setFieldValue('functions.depositToGame', v)
                                            // console.log('รันรายการฝากเข้าเกม')

                                        }
                                    />
                                    <CheckboxItem
                                        label="ถอนเงินจากเกม"
                                        checked={values?.functions?.withdrawFromGame}
                                        padding="0 0 8px 0"
                                        onChange={(v) =>
                                            setFieldValue('functions.withdrawFromGame', v)
                                            // console.log('ถอนเงินจากเกม')
                                        }
                                    />
                                    <CheckboxItem
                                        label="ตรวจสอบรายการถอน (สมาชิกใหม่)"
                                        checked={values?.functions?.bonusHunterGuard}
                                        padding="0 0 8px 0"
                                        onChange={(v) =>
                                            setFieldValue('functions.bonusHunterGuard', v)
                                            // console.log('ตรวจสอบรายการถอน (สมาชิกใหม่)')
                                        }
                                    />
                                </RuleCheckBoxWrapper>
                            </InputSection>


                            <InputSection>
                                <PrimaryButton
                                    type="submit"
                                    width="120px"
                                    loading={isSubmitting}
                                >
                                    บันทึกข้อมูล
                                </PrimaryButton>
                            </InputSection>
                        </form>
                    </WheelCardBottomContentWrapper>
                </Card>
            </ContentContainer>

        </>
    )
}

export default SettingPrefixContainer


const InputSection = styled.div`
	margin-bottom: 18px;
	flex: 1;
`
const WheelCardBottomContentWrapper = styled.div`
	padding: 10px 32px;
	justify-content: space-between;
	grid-template-columns: repeat(auto-fit, 50% - 24px);
	@media only screen and (max-width: ${MobileSize}px) {
		grid-template-columns: repeat(auto-fit, 100%);
		flex-direction: column-reverse;
	}
`

const SeparatorWrapper = styled.div`
	margin: 0 0 24px 0;
	width: 100%;
`

const Label = styled.div`
	font-size: 16px;
	font-family: ${Fonts.primary};
	color: ${Colors.slateGray};
`

const RuleTitle = styled.div`
	font-size: 18px;
	color: ${Colors.slateGray};
	font-weight: bold;
`

const RuleCheckBoxWrapper = styled.div`
	margin-top: 12px;
`
