import { FC, useState } from 'react'
import styled from 'styled-components'
import alarmIcon from '../../../../assets/images/alarm-icon.svg'
import { Colors } from '../../../../helpers/color'
import { Fonts } from '../../../../helpers/font'
import PrimaryButton from '../../../Common/Button/PrimaryButton'
import SecondButton from '../../../Common/Button/SecondButton'
import Textarea from '../../../Common/Input/Textarea'
import Modal from '../../../Common/Modal'

interface WithdrawBaseModalProps {
	title: string
	description: string
	remark?: boolean
	loading?: boolean

	onSubmit: (v?: string) => void
	onClose: () => void
}
const WithdrawBaseModal: FC<WithdrawBaseModalProps> = ({
	title,
	description,
	remark: _remark,
	loading = false,

	onSubmit,
	onClose,
}) => {
	const [remark, setRemark] = useState('')

	return (
		<Modal>
			<ModalStatusWrapper>
				<ModalIcon src={alarmIcon} alt="icon" />
				<ModalTitle>{title}</ModalTitle>
				<ModalDescription>{description}</ModalDescription>

				{_remark && (
					<ModalStatusTextAreaWrapper>
						<Textarea
							label="หมายเหตุ"
							onChange={(e) => setRemark(e.currentTarget.value)}
						/>
					</ModalStatusTextAreaWrapper>
				)}

				<ModalActionGroup>
					<PrimaryButton
						type="button"
						onClick={() => onSubmit(remark)}
						loading={loading}>
						ยืนยัน
					</PrimaryButton>
					<SecondButton type="button" onClick={onClose}>
						ยกเลิก
					</SecondButton>
				</ModalActionGroup>
			</ModalStatusWrapper>
		</Modal>
	)
}

export default WithdrawBaseModal

const ModalStatusWrapper = styled.div`
	align-items: center;
	padding: 24px;
`
const ModalIcon = styled.img``
const ModalTitle = styled.div`
	font-family: ${Fonts.primary};
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	color: ${Colors.black2};
	margin-top: 12px;
`
const ModalDescription = styled.div`
	font-family: ${Fonts.primary};
	font-style: normal;
	font-size: 14px;
	color: ${Colors.slateGray};
	margin-top: 4px;
`
const ModalActionGroup = styled.div`
	flex-direction: row;
	display: grid;
	width: 100%;
	margin-top: 20px;
	grid-template-columns: repeat(2, 50%);

	button:nth-child(1) {
		width: 99%;
		margin-right: 1%;
	}
	button:nth-child(2) {
		width: 99%;
		margin-left: 1%;
	}
`

const ModalStatusTextAreaWrapper = styled.div`
	margin-top: 12px;
	padding-bottom: 25px;
	width: 100%;
`
