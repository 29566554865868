import { DateRangeType } from './dashboard'
import { Member } from './member'

export const transactionTabs = [
	{
		key: 'deposit',
		text: 'รายการฝาก',
	},
	{
		key: 'withdraw',
		text: 'รายการถอน',
	},
	{
		key: 'bank-transactions',
		text: 'รายการเงินเข้า',
	},
]

export interface GetTransactionListParams {
	dateRange?: DateRangeType
	limit?: number
	type?: string
	offset?: number
	status?: string
}

export interface TransactionListResponse<T> {
	count: number
	transactions: T[]
}

export interface DepositTransaction {
	id: number
	amount: number
	transferFrom: string
	transferTime: string
	remark: string
	status: string
	createdAt: string
	updatedAt: string

	member: Member
}

export interface WithdrawTransaction {
	id: number
	amount: number
	transferTo: string
	transferTime: string
	remark: string
	status: string
	createdAt: string
	updatedAt: string
	withdraw: {
		qrString: string
		transactionID: string
	}

	member: Member
}

export interface BankTransaction {
	id: number
	amount: number
	bankAccount: string
	bankType: string
	createdAt: string
	provider: string
	remark: string
	status: string
}

export interface GetFixTxListParams {
	dateRange: DateRangeType
}
export interface FixTransaction {
	amount: number
	transferFrom: string
	type: string
	remark: string
	operator: string
	createdAt: string
	updatedAt: string

	member: Member
}

export interface GetBalance {
	balance: number
}

export interface FixTransactionParams {
	fixType: string
	phone: string
	amount: number
	turn: number
	maxWithdraw: number
	transferTime: string
	remark: string
}