import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import BankList from '../components/Bank/BankList'
import CentralAccountSetting from '../components/Bank/CentralAccountSetting'
import SCBAccountList from '../components/Bank/SCBAccount/SCBAccountList'
import WithdrawSetting from '../components/Bank/WithdrawSetting'
// import {
// 	CardSubTitle,
// 	CardTitle,
// 	CardTitletRowWrapperWithOutBorder,
// } from '../components/Common/Card'
// import TwoColumn from '../components/Common/Columns/TwoColumn'
import ContentContainer from '../components/Common/ContentContainer'
import PageLayout from '../components/PageLayout'
import { Colors } from '../helpers/color'
import { format } from '../helpers/number'
import { MobileSize } from '../helpers/responsive'
import { ApplicationState } from '../store'
import Tabs from '../components/Common/Tabs'

const Bank = () => {
	const prefix = useSelector((state: ApplicationState) => state.prefix).prefix
	const [bankTab, setBankTab] = useState('bank')


	const bankTabs = [
		{
			key: 'bank',
			text: `บัญชีธนาคาร (${format(Object.keys(prefix.banks).length, 0)} รายการ)`,
		},
		{
			key: 'scb-api',
			text: `SCB API (${format(Object.keys(prefix.scbAccounts).length, 0)} รายการ)`,
		},
		{
			key: 'automatic-withdrawal',
			text: 'ตั้งค่าถอนเงินอัตโนมัติ',
		},
		{
			key: 'central-account-withdrawal',
			text: 'ตั้งค่าถอนเงินเข้าบัญชีกลาง',
		},
	]

	return (
		<PageLayout title="ธนาคาร" subTitle="จัดการเว็บไซต์">
			<ContentContainer>
				<TabsController>
					<Tabs tabs={bankTabs} activeTab={bankTab} selectTab={setBankTab} />
				</TabsController>
			</ContentContainer>
			<Body>
				{bankTab === 'bank' && <BankList tab={bankTab} banks={Object.values(prefix.banks)} scbAccounts={Object.values(prefix.scbAccounts)} />}
				{bankTab === 'scb-api' && <SCBAccountList tab={bankTab} accounts={Object.values(prefix.scbAccounts)} />}
				{bankTab === 'automatic-withdrawal' && <WithdrawSetting config={prefix.setting.withdrawAccount} banks={Object.values(prefix.banks)} scbAccounts={Object.values(prefix.scbAccounts)} />}
				{bankTab === 'central-account-withdrawal' && <CentralAccountSetting config={prefix.setting.centralAccount} />}
			</Body>
		</PageLayout>
	)
}

export default Bank

const Body = styled.div`
	background: ${Colors.zircon2};
	width: 100%;
	flex: 1;
	margin-top: -3px;
	padding: 46px 0;
	@media only screen and (max-width: ${MobileSize}px) {
		padding: 20px 0;
	}
`
const TabsController = styled.div`
	flex-direction: row;
	margin-top: 32px;
	@media only screen and (max-width: ${MobileSize}px) {
		margin-top: 12px;
		width: 100%;
		overflow-x: scroll;
	}
`


// const FirstSection = styled.div`
// 	margin-top: 32px;
// 	@media only screen and (max-width: ${MobileSize}px) {
// 		margin-top: 12px;
// 	}
// `
// const SecondSection = styled.div`
// 	margin-top: 32px;
// `
// const MobileTab = styled.div`
// 	background: ${Colors.linkWater};
// 	flex-direction: row;
// 	margin-bottom: 12px;
// 	border-radius: 8px;
// 	display: none;
// 	@media only screen and (max-width: ${MobileSize}px) {
// 		display: flex;
// 	}
// `

// interface TabStyledProps {
// 	active: boolean
// }
// const Tab = styled.div<TabStyledProps>`
// 	width: 50%;
// 	font-family: Sarabun;
// 	font-style: normal;
// 	font-weight: bold;
// 	font-size: 18px;
// 	padding: 12px 20px;
// 	align-items: center;
// 	border-radius: 8px;
// 	color: ${({ active }) => (active ? Colors.white : Colors.casper2)};
// 	background: ${({ active }) => (active ? Colors.dodgerBlue : 'none')};
// `
// const TitleHeader = styled.div`
// 	flex-direction: row;
// 	flex-wrap: wrap;
// 	align-items: center;
// 	margin: 0 24px 12px auto;
// 	width: calc(100% - 86px);
// 	@media only screen and (max-width: ${MobileSize}px) {
// 		display: none;
// 	}
// `
