import React, { FC, useEffect, useMemo, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { LogTransaction } from '../../@types/log'
import { format } from '../../helpers/number'
import { usePagination } from '../../hooks/usePagination'
import Card, {
	CardActionHeaderWrapper,
	CardActionItem,
	CardSubTitle,
	CardTitle,
	CardTitletRowWrapperWithOutBorder,
	CardTitleWrapperWithOutBorder,
} from '../Common/Card'
import SearchBar from '../Common/Input/SearchBar'
import { CountItemList, PaginateWrapper, TableWrapper } from '../Common/Table'
import LogListTable from './LogListTable'
import moment from 'moment'

interface LogListContainerProps {
	dataTx: LogTransaction[]
}

const LogListContainer: FC<LogListContainerProps> = ({ dataTx }) => {
	const [filter, setFilter] = useState<LogTransaction[]>(dataTx)
	const { data: _data, page, pageCount, setPage } = usePagination(filter)

	useEffect(() => {
		setPage(0)
	}, [setPage])

	useMemo(() => {
		setFilter(dataTx)
	}, [dataTx])

	const handleSearch = (value: string) => {
		if (value) {
			const filtered = dataTx.filter(item =>
				moment(item?.UpdatedAt).format('DD/MM/YYYY HH:mm:ss').includes(value) ||
				item?.Description.includes(value) ||
				item?.IPAddress.includes(value) ||
				item?.Operator.includes(value)
			)
			setFilter(filtered)
		} else {
			setFilter(dataTx)
		}
	}

	return (
		<>
			<Card padding="24px 0 0 0">
				<CardTitleWrapperWithOutBorder>
					<CardTitletRowWrapperWithOutBorder>
						<CardTitle>รายการกิจกรรม</CardTitle>
						<CardSubTitle>{format(filter?.length || 0, 0)} รายการ</CardSubTitle>
					</CardTitletRowWrapperWithOutBorder>
					<CardActionHeaderWrapper>
						<CardActionItem>
							<SearchBar
								type="text"
								placeholder="ค้นหา ..."
								onChange={(e) => handleSearch(e.currentTarget.value)}
							/>
						</CardActionItem>
					</CardActionHeaderWrapper>
				</CardTitleWrapperWithOutBorder>
				<TableWrapper>
					<LogListTable data={_data}/>
					<PaginateWrapper>
						<CountItemList></CountItemList>
						<ReactPaginate
							pageCount={pageCount}
							forcePage={page}
							pageRangeDisplayed={5}
							marginPagesDisplayed={2}
							containerClassName={'pagination'}
							pageClassName={'pages pagination-pages'}
							activeClassName={'active-pagination'}
							onPageChange={(e) => {
								setPage(e.selected)
							}}
						/>
					</PaginateWrapper>
				</TableWrapper>
			</Card>
		</>
	)
}

export default LogListContainer
