import { FC } from 'react'
import styled from 'styled-components'
import { Colors } from '../../../helpers/color'
import { Fonts } from '../../../helpers/font'

interface ToggleProps {
	activeLabel: string
	disableLabel: string

	active: boolean
	setActive: (v: boolean) => void
}
const Toggle: FC<ToggleProps> = ({
	activeLabel,
	disableLabel,
	active,
	setActive,
}) => (
	<ToggleWrapper>
		<DisableButton active={active} onClick={() => setActive(false)}>
			{disableLabel}
		</DisableButton>
		<ActiveButton active={active} onClick={() => setActive(true)}>
			{activeLabel}
		</ActiveButton>
	</ToggleWrapper>
)

export default Toggle

const ToggleWrapper = styled.div`
	background: ${Colors.linkWater};
	border-radius: 4000px;
	width: 100%;
	padding: 4px;
	display: grid;
	justify-content: space-around;
	grid-template-columns: repeat(auto-fit, calc(100% / 2));
`

interface DisableButtonStyledProps {
	active: boolean
}
const DisableButton = styled.div<DisableButtonStyledProps>`
	flex: 1;
	cursor: ${({ active }) => (!active ? 'normal' : 'pointer')};
	text-align: center;
	padding: 8px 14px;
	font-family: ${Fonts.primary};
	background: ${({ active }) => (!active ? Colors.white : 'none')};
	color: ${({ active }) => (!active ? Colors.negative3 : Colors.casper2)};
	border: ${({ active }) =>
		!active ? `1px solid ${Colors.negative}` : 'none'};
	font-weight: bold;
	border-radius: 4000px;
	font-size: 10px;
	align-items: center;
	justify-content: center;
`

interface ActiveButtonStyledProps {
	active: boolean
}
const ActiveButton = styled.div<ActiveButtonStyledProps>`
	flex: 1;
	cursor: ${({ active }) => (active ? 'normal' : 'pointer')};
	text-align: center;
	padding: 8px 14px;
	font-family: ${Fonts.primary};
	background: ${({ active }) => (active ? Colors.white : 'none')};
	color: ${({ active }) => (active ? Colors.positive3 : Colors.casper2)};
	border: ${({ active }) =>
		active ? `1px solid ${Colors.positive}` : 'none'};
	font-weight: bold;
	border-radius: 4000px;
	font-size: 10px;
	align-items: center;
	justify-content: center;
`
