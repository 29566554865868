import { FC } from 'react'
import styled from 'styled-components'
import { BadgeType } from '../../../@types/badge'
import { getBadgeBG, getBadgeColor } from '../../../helpers/badge'

interface BadgeStatusProps {
	type: BadgeType
}
const BadgeStatus: FC<BadgeStatusProps> = ({ children, type }) => (
	<Badge type={type}>{children}</Badge>
)

export default BadgeStatus

interface BadgeStyledProps {
	type: BadgeType
}
const Badge = styled.div<BadgeStyledProps>`
	background: ${({ type }) => getBadgeBG(type)};
	color: ${({ type }) => getBadgeColor(type)};
	padding: 10px 20px;
	max-height: 36px;
	font-size: 14px;
	border-radius: 10px;
	text-align: center;
	font-weight: bold;
	width: fit-content;
	margin: auto;
`
