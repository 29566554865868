import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory, useParams } from 'react-router'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import ContentContainer from '../components/Common/ContentContainer'
import ConfirmModal from '../components/Common/Modal/ConfirmModal'
import EditSCBAccountForm from '../components/EditBank/SCBAccount/EditSCBAccountForm'
import PageLayout from '../components/PageLayout'
import { Colors } from '../helpers/color'
import { MobileSize } from '../helpers/responsive'
import PrefixService from '../services/prefix'
import { ApplicationState } from '../store'
import { requestAuthInit } from '../store/auth/auth.actions'

export interface EditSCBAccountParams {
	accountNumber: string
}

const EditSCBAccount = () => {
	const history = useHistory()
	const dispatch = useDispatch()

	const [openDeleteModal, setOpenDeleteModal] = useState<string>('')
	const [deleteLoading, setDeleteLoading] = useState(false)
	const deleteAccount = async (v: string) => {
		setDeleteLoading(true)

		try {
			const res = await PrefixService.deleteSCBAccount(openDeleteModal)

			dispatch(requestAuthInit(false))
			toast.success(res.message)
			history.push('/bank')
		} catch ({ message }) {
			toast.error(message as string)
			setDeleteLoading(false)
		}
	}

	const prefix = useSelector((state: ApplicationState) => state.prefix).prefix
	const params = useParams<EditSCBAccountParams>()
	if (!prefix.scbAccounts.hasOwnProperty(params.accountNumber)) {
		return <Redirect to="/bank" />
	}

	const account = prefix.scbAccounts[params.accountNumber]

	return (
		<PageLayout title={account.accountName} subTitle="จัดการธนาคาร" goBack="/bank">
			<Body>
				<ContentContainer>
					<InnerBody>
						<EditSCBAccountForm data={account} onClickDelete={setOpenDeleteModal} />
					</InnerBody>
				</ContentContainer>
			</Body>

			{openDeleteModal && (
				<ConfirmModal
					title="คุณต้องการลบบัญชี?"
					description="ตรวจสอบให้แน่ใจว่าคุณต้องการลบบัญชีดังกล่าว"
					loading={deleteLoading}
					onSubmit={() => deleteAccount(openDeleteModal)}
					onClose={() => setOpenDeleteModal('')}
				/>
			)}
		</PageLayout>
	)
}

export default EditSCBAccount

const Body = styled.div`
	background: ${Colors.white};
	width: 100%;
	height: 100%;
	padding-bottom: 20px;
	margin-top: 18px;
	@media only screen and (max-width: ${MobileSize}px) {
		height: auto;
	}
`
const InnerBody = styled.div`
	max-width: 43%;
	width: 100%;
	margin: auto;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	@media only screen and (max-width: ${MobileSize}px) {
		max-width: 100%;
	}
`
