import React, { FC } from 'react'
import styled from 'styled-components'
import { SCBAccountConfig } from '../../../@types/prefix'
import whitePencilIcon from '../../../assets/images/white-pencil.svg'
import { getBankIcon, getBankName } from '../../../helpers/bank'
import { Colors } from '../../../helpers/color'
import { Fonts } from '../../../helpers/font'

interface SCBAccountListItemProps {
	account: SCBAccountConfig

	onClickEdit: (accountNumber: string) => void
}
const SCBAccountListItem: FC<SCBAccountListItemProps> = ({
	account,
	onClickEdit,
}) => (
	<BankItem>
		<BankOwnerName>
			<LeftBankOwnerName>
				<BankStatus active={account.enable} />
				<span>{account.accountName}</span>
			</LeftBankOwnerName>
		</BankOwnerName>
		<BankInfo>
			<LeftBankInfo>
				<BankIcon src={getBankIcon('scb')} alt="scb" />
			</LeftBankInfo>
			<RightBankInfo>
				<BankNumber>{account.accountNumber}</BankNumber>
				<BankName>{getBankName('scb')}</BankName>
			</RightBankInfo>
		</BankInfo>
		<EditBankItemButton onClick={() => onClickEdit(account.accountNumber)}>
			<EditBankItemButtonIcon src={whitePencilIcon} alt="edit" />
		</EditBankItemButton>
	</BankItem>
)

export default SCBAccountListItem

const BankItem = styled.div`
	background: ${Colors.white};
	box-shadow: 0px 5px 15px rgba(87, 106, 154, 0.08);
	border-radius: 25px;
	padding: 20px;
	width: 100%;
	max-height: 108px;
	margin-bottom: 20px;
`
const BankOwnerName = styled.div`
	flex-direction: row;
	margin-bottom: 8px;
	justify-content: space-between;
`
const LeftBankOwnerName = styled.div`
	flex-direction: row;
	align-items: center;
	font-family: ${Fonts.primary};
	font-style: normal;
	font-weight: 800;
	font-size: 18px;
	color: ${Colors.dodgerBlue};
`

interface BankStatusStyledProps {
	active: boolean
}
const BankStatus = styled.div<BankStatusStyledProps>`
	width: 14px;
	height: 14px;
	border-radius: 1000px;
	margin-right: 12px;
	background: ${({ active }) => (active ? Colors.positive : Colors.negative)};
`

const BankInfo = styled.div`
	flex-direction: row;
	align-items: center;
`
const LeftBankInfo = styled.div``
const BankIcon = styled.img``
const RightBankInfo = styled.div`
	margin-left: 8px;
`
const BankNumber = styled.div`
	font-family: ${Fonts.primary};
	font-weight: bold;
	font-size: 16px;
	color: ${Colors.casper2};
`
const BankName = styled.div`
	font-family: ${Fonts.primary};
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	color: ${Colors.casper};
`
const EditBankItemButton = styled.div`
	background: ${Colors.dodgerBlue};
	width: 48px;
	height: 48px;
	border-radius: 25px 0px;
	right: 0;
	bottom: 0;
	position: absolute;
	align-items: center;
	justify-content: center;
	cursor: pointer;
`
const EditBankItemButtonIcon = styled.img``
