import React, { FC } from 'react'
import useSWR from 'swr'
import PrefixService from '../../services/prefix'
import styled from 'styled-components'
import moment from 'moment'
import Card, { CardTitle } from '../Common/Card'
import { Colors } from '../../helpers/color'
import { format } from '../../helpers/number'
import { BankTransaction } from '../../@types/transactions'

interface TxStatusProps {
	status: string
}
const TxStatus: FC<TxStatusProps> = ({ status }) => {
	switch (status) {
		case 'pending': {
			return <small style={{ color: '#f09b38' }}>รอดำเนินการ</small>
		}
		case 'reject': {
			return <small style={{ color: '#ff4d4f' }}>รายการไม่สำเร็จ</small>
		}
		case 'member_notfound': {
			return <small style={{ color: '#ff4d4f' }}>ไม่พบสมาชิก</small>
		}
		default: {
			return <small style={{ color: '#f09b38' }}>{status.toUpperCase()}</small>
		}
	}
}


const BankTransactionLastest = () => {
	const { data } = useSWR(
		`/bank-transactions`,
		() =>
			PrefixService.getTransactionList<BankTransaction>({
				type: 'bank-transactions',
				dateRange: {
					start: moment().format('YYYY-MM-DD'),
					end: moment().format('YYYY-MM-DD')
				},
			}),
		{
			refreshInterval: 10000,
		}
	)

	const listTransaction = data !== undefined && Object.values(data as any).filter((item:any, index: number) => (
		item.status !== 'success' && item.status !== 'duplicate' 
	))
	const listTransactionLimit = listTransaction !== undefined && Object.values(listTransaction as any).filter((item:any, index: number) => index < 20)

	return (
		<Card>
			<CardTitle>รายการเงินเข้า</CardTitle>
			<div className="mt-3">
				{ data !== undefined
					?
						Object.values(listTransactionLimit as any).length !== 0
						?
							Object.values(listTransactionLimit as any).map((item:any, index: number) =>
								<Card padding="12px 16px" marginBottom="12px" border="1px" key={index}>
									<Row>
										<div className="d-flex flex-row justify-content-between">
											<div>
												<strong>{item.bankType}</strong>
												<small>{item.bankAccount}</small>
											</div>
											<div className="text-end">
												<strong><span>฿</span> {format(item.amount, 2)}</strong>
												<TxStatus status={item.status} />
											</div>
										</div>
										<div className="pt-2 mt-2" style={{ borderTop: '1px solid #eee', alignItems: 'flex-start'}}>
											<small>หมายเหตุ : {item.remark}</small>
											<small>ทำรายการโอน : {moment(item?.createdAt).format('DD/MM/YYYY HH:mm:ss')}</small>
										</div>
									</Row>
								</Card>
								)
						:
							<div style={{ color: '#95a0b8' }}>ไม่มีข้อมูล</div>
					:
						<Loading>
							<i className="fad fa-spinner-third fa-spin fa-2x" />
						</Loading>
				}
			</div>
		</Card>
	)
}

export default BankTransactionLastest

const Loading = styled.div`
	width: 100%;
	text-align: center;
	color: ${Colors.anakiwa};
	margin-top: 2.5rem;
`

const Row = styled.div`
	font-family: Sarabun, sans-serif;
	font-style: normal;

	strong {
		color: #68748d;
	}

	small, span {
		color: #95a0b8;
	}
`