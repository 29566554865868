import React, { FC } from 'react'
import styled from 'styled-components'

interface SeparatorProps {
	color?: string
}
const Separator: FC<SeparatorProps> = ({ color }) => (
	<SeparatorWrapper color={color} />
)

export default Separator

interface SeparatorWrapperStyledProps {
	color?: string
}
const SeparatorWrapper = styled.div<SeparatorWrapperStyledProps>`
	flex: 0 0 auto;
	height: 1px;
	width: 100%;
	background: ${({ color }) => color};
`
