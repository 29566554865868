import React, { FC, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { WithdrawTransaction } from '../../../@types/transactions'
import { format } from '../../../helpers/number'
import { MobileSize } from '../../../helpers/responsive'
import { usePagination } from '../../../hooks/usePagination'
import PrefixService from '../../../services/prefix'
import Card, {
	CardSubTitle,
	CardTitle,
	CardTitletRowWrapperWithOutBorder,
	CardTitleWrapper,
} from '../../Common/Card'
import { CountItemList, PaginateWrapper, TableWrapper } from '../../Common/Table'
import WithdrawBaseModal from './Modal/WithdrawBaseModal'
import WithdrawListTable from './WithdrawListTable'

export interface WithdrawActionModal {
	type: string
	tx: WithdrawTransaction
}

interface WithdrawListTableContainerProps {
	loading: boolean
	count: number
	data: WithdrawTransaction[]

	update: () => void
}
const WithdrawListTableContainer: FC<WithdrawListTableContainerProps> = ({
	loading,
	count,
	data,

	update,
}) => {
	const { data: txData, page, pageCount, setPage } = usePagination<WithdrawTransaction>(data)

	const [actionLoading, setActionLoading] = useState(false)
	const [actionModal, setActionModal] = useState<WithdrawActionModal>()
	const redoWithdraw = async (tx: WithdrawTransaction) => {
		setActionLoading(true)

		try {
			const res = await PrefixService.redoWithdraw(tx)

			toast.success(res.message)
		} catch ({ message }) {
			toast.error(message as string)
		} finally {
			setActionLoading(false)
			setActionModal(undefined)

			update()
		}
	}
	const confirmWithdraw = async (tx: WithdrawTransaction, transfer: string) => {
		setActionLoading(true)

		try {
			const res = await PrefixService.confirmWithdraw(tx, transfer)
			toast.success(res.message)
		} catch ({ message }) {
			toast.error(message as string)
		} finally {
			setActionLoading(false)
			setActionModal(undefined)

			update()
		}
	}
	const rejectWithdraw = async (tx: WithdrawTransaction, remark: string) => {
		setActionLoading(true)

		try {
			const res = await PrefixService.rejectWithdraw(tx, remark)
			toast.success(res.message)
		} catch ({ message }) {
			toast.error(message as string)
		} finally {
			setActionLoading(false)
			setActionModal(undefined)

			update()
		}
	}

	return (
		<FirstSection>
			<Card padding="24px 0 0 0">
				<CardTitleWrapper>
					<CardTitletRowWrapperWithOutBorder>
						<CardTitle>รายการถอน</CardTitle>
						<CardSubTitle>{format(count || 0, 0)} รายการ</CardSubTitle>
					</CardTitletRowWrapperWithOutBorder>
				</CardTitleWrapper>
				<TableWrapper>
					<WithdrawListTable
						loading={loading}
						data={txData}
						setActionModal={setActionModal}
					/>
					<PaginateWrapper>
						<CountItemList></CountItemList>
						<ReactPaginate
							forcePage={page}
							pageCount={pageCount}
							pageRangeDisplayed={5}
							marginPagesDisplayed={2}
							containerClassName={'pagination'}
							pageClassName={'pages pagination-pages'}
							activeClassName={'active-pagination'}
							onPageChange={(e) => {
								setPage(e.selected)
							}}
						/>
					</PaginateWrapper>
				</TableWrapper>
			</Card>

			{actionModal?.type === 'redo' && (
				<WithdrawBaseModal
					title="คุณต้องการทำรายการใหม่?"
					description="ตรวจสอบให้แน่ใจว่าคุณต้องการทำรายการถอนเงินใหม่อีกครั้ง"
					loading={actionLoading}
					onSubmit={() => redoWithdraw(actionModal.tx)}
					onClose={() => setActionModal(undefined)}
				/>
			)}
			{actionModal?.type === 'reject' && (
				<WithdrawBaseModal
					title="คุณต้องการยกเลิกรายการ?"
					description="ตรวจสอบให้แน่ใจว่าคุณต้องการยกเลิกรายการถอนเงิน"
					remark={true}
					loading={actionLoading}
					onSubmit={(v) => rejectWithdraw(actionModal.tx, v || '')}
					onClose={() => setActionModal(undefined)}
				/>
			)}
			{(actionModal?.type === 'confirm' || actionModal?.type === 'transfer') && (
				<WithdrawBaseModal
					title="คุณต้องการยืนยันรายการ?"
					description="ตรวจสอบให้แน่ใจว่าคุณต้องการยืนยันรายการถอนเงิน"
					loading={actionLoading}
					onSubmit={() =>
						confirmWithdraw(
							actionModal.tx,
							actionModal?.type === 'confirm' ? 'false' : 'true'
						)
					}
					onClose={() => setActionModal(undefined)}
				/>
			)}
		</FirstSection>
	)
}

export default WithdrawListTableContainer

const FirstSection = styled.div`
	margin-top: 32px;
	@media only screen and (max-width: ${MobileSize}px) {
		margin-top: 12px;
	}
`
