import { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { MobileSize } from '../../helpers/responsive'
import MobileHeader from './MobileHeader'
import Sidebar from './Sidebar'

const Wrapper = styled.div`
	flex-direction: row;
	flex-wrap: wrap;
`
const Content = styled.div`
	width: calc(100% - 18.88%);
	height: 100vh;
	overflow-y: scroll;
	@media only screen and (max-width: ${MobileSize}px) {
		width: 100%;
		height: calc(100vh - 52px);
		overflow-y: scroll;
	}
`

const Layout: FC = ({ children }) => {
	const [showSidebar, setShowSidebar] = useState(false)
	const toggleSidebar = () => {
		setShowSidebar(!showSidebar)
	}

	const history = useHistory()
	useEffect(() => {
		setShowSidebar(false)
	}, [history.location])

	return (
		<Wrapper>
			<MobileHeader toggleSidebar={toggleSidebar} />
			<Sidebar show={showSidebar} />
			<Content>{children}</Content>
		</Wrapper>
	)
}

export default Layout
