import React from 'react'
import styled from 'styled-components'
import ContentContainer from '../components/Common/ContentContainer'
import DateRange from '../components/Common/DateRange'
import LogListContainer from '../components/Log/LogListContainer'
import PageLayout from '../components/PageLayout'
import { Colors } from '../helpers/color'
import { MobileSize } from '../helpers/responsive'
import useDateRange from '../hooks/useDateRange'
import useSWR from 'swr'
import PrefixService from '../services/prefix'

const Member = () => {
	const { dateRange, setDateRange } = useDateRange()

    const { data } = useSWR(
		[`/log`, dateRange.start, dateRange.end],
		() =>
			PrefixService.getLogList({
				dateRange: dateRange,
			}),
		{
			refreshInterval: 10000,
		}
	)

	return (
		<PageLayout title="บันทึกกิจกรรม" subTitle="จัดการเว็บไซต์">
			<Body>
				<ContentContainer>
					<DateRange onChange={setDateRange} />

					<SeconedSection>
						<LogListContainer dataTx={data || []}/>
					</SeconedSection>
				</ContentContainer>
			</Body>
		</PageLayout>
	)
}

export default Member

const Body = styled.div`
	background: ${Colors.zircon2};
	width: 100%;
	flex: 1;
	margin-top: 32px;
	padding: 46px 0;
	@media only screen and (max-width: ${MobileSize}px) {
		padding: 20px 0;
		margin-top: 20px;
	}
`
const SeconedSection = styled.div`
	margin-top: 32px;
	@media only screen and (max-width: ${MobileSize}px) {
		margin-top: 12px;
	}
`
