import React from 'react'
import styled from 'styled-components'
import { OverviewSummary } from '../../../api/summary/types'
import { MobileSize } from '../../../helpers/responsive'
import useDateRange from '../../../hooks/useDateRange'
import useFetchSummary from '../../../hooks/useFetchSummary'
import ContentContainer from '../../Common/ContentContainer'
import DateRange from '../../Common/DateRange'
import TabDataLoading from '../TabDataLoading'
import IncomeCard from './IncomeCard'
import SummaryDetail from './SummaryDetail'

const OverviewTab = () => {
	const { dateRange, setDateRange } = useDateRange()
	const { data } = useFetchSummary<OverviewSummary>('overview', dateRange)

	return (
		<ContentContainer>
			<DateRange onChange={setDateRange} />

			{!data ? (
				<TabDataLoading />
			) : (
				<WrapperContent>
					<LeftContent>
						<IncomeCard data={data} />
					</LeftContent>
					<RightContent>
						<SummaryDetail data={data} />
					</RightContent>
				</WrapperContent>
			)}
		</ContentContainer>
	)
}

export default OverviewTab

const WrapperContent = styled.div`
	margin-top: 20px;
	flex-direction: row;
	@media only screen and (max-width: ${MobileSize}px) {
		flex-direction: column;
	}
`

const LeftContent = styled.div`
	width: 50%;
	@media only screen and (max-width: ${MobileSize}px) {
		width: 100%;
	}
`
const RightContent = styled.div`
	width: calc(50% - 2%);
	margin-left: 2%;
	@media only screen and (max-width: ${MobileSize}px) {
		width: 100%;
	}
`
