// import debounce from 'lodash/debounce'
import React, { FC, useEffect } from 'react'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { ambGamesConfigEdit } from '../../../@types/prefix'
import { MobileSize } from '../../../helpers/responsive'
import Card, {
	CardTitle,
	CardTitletRowWrapperWithOutBorder,
	CardTitleWrapperWithOutBorder,
} from '../../Common/Card'
import { requestAuthInit } from '../../../store/auth/auth.actions'
import PrimaryButton from '../../Common/Button/PrimaryButton'
import CheckboxItem from '../../Common/Checkbox/CheckboxItem'
import ContentContainer from '../../Common/ContentContainer'
import PrefixService from '../../../services/prefix'
import 'antd/dist/antd.css'
import { BodyTR, Table, Tbody, Td, Th, Thead } from '../../Common/Table'

interface AmbGamesProps {
	data: ambGamesConfigEdit | any
	// update: () => void
}

const AmbGames: FC<AmbGamesProps> = ({data}) => {
    
	const dispatch = useDispatch()
    
    const {
		values,
		isSubmitting,
        setValues,
		// setFieldValue,
		// handleChange,
		handleSubmit,
	} = useFormik({
		initialValues: data.ambbetGames,
        enableReinitialize: true,
		onSubmit: async (values) => {
			try {
				const res = await PrefixService.updateAmbGames({games : values})
				dispatch(requestAuthInit(false))
				toast.success(res.message)
			} catch ({ message }) {
				toast.error(message as string)
			}
		},
	})

    useEffect(() => {
        if (typeof(data) != "undefined"){
            // setValues(objValues)
        }
    }, [data])
    
    const listGames =  Object.keys(values).length !== 0 && Object.values(values).map((item:any, index:number) => {
        return (
            <BodyTR key={index}>
                <Td>
                    <RuleCheckBoxWrapper>
                        <CheckboxItem
                            key={index}
                            label={''}
                            name={item.game}
                            checked={item.enable}
                            padding="0 0 8px 0"
                            onChange={(value) => {
                                values[index].enable = value
                                setValues(values)
                            }}
                        />
                    </RuleCheckBoxWrapper>
                </Td>
                <Td>
                    {item.label}
                </Td>
                
            </BodyTR>
            
        )
    })
    
	return (
		<>
        <ContentContainer>
			<Card padding="24px 0 0 0">
				<CardTitleWrapperWithOutBorder>
					<CardTitletRowWrapperWithOutBorder>
						<CardTitle>ตั้งค่าเกม Ambbet</CardTitle>
					</CardTitletRowWrapperWithOutBorder>
				</CardTitleWrapperWithOutBorder>
                <WheelCardBottomContentWrapper>
					<form onSubmit={handleSubmit}>
                        <InputSection>
                            <Table width={280}>
                                <Thead>
                                    <tr>
                                        <Th>เปิดใช้งาน</Th>
                                        <Th>เกม</Th>
                                    </tr>
                                </Thead>
                                <Tbody>
                                {listGames}
                                </Tbody>
                            </Table>
                        </InputSection>
						<InputSection>
							<PrimaryButton
								type="submit"
								width="120px"
								loading={isSubmitting}
							>
								บันทึกข้อมูล
							</PrimaryButton>
						</InputSection>
					</form>
				</WheelCardBottomContentWrapper>
			</Card>
		</ContentContainer>
			
		</>
	)
}

export default AmbGames

const InputSection = styled.div`
	margin-bottom: 18px;
	flex: 1;
`
const WheelCardBottomContentWrapper = styled.div`
	padding: 10px 32px;
	justify-content: space-between;
	grid-template-columns: repeat(auto-fit, 50% - 24px);
	@media only screen and (max-width: ${MobileSize}px) {
		grid-template-columns: repeat(auto-fit, 100%);
		flex-direction: column-reverse;
	}
`

const RuleCheckBoxWrapper = styled.div`
	margin-top: 12px;
`