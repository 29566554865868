import { FC } from "react";
import styled from 'styled-components';
import { Colors } from "../../../helpers/color";
import { Fonts } from "../../../helpers/font";
import { MobileSize } from "../../../helpers/responsive";

interface MoneyWithCurrencyProps {
	value: string
	fontSize?: string
	mobileFontSize?: string
	position?: 'center' | 'left' | 'right'
  remark?: string
}
const MoneyWithCurrency: FC<MoneyWithCurrencyProps> = ({ value, fontSize,mobileFontSize, position, remark }) => (
    <Wrapper position={position}>
        <Currency fontSize={fontSize}>฿</Currency>
        <Money fontSize={fontSize}>{value}</Money>
        <Remark>{remark}</Remark>
    </Wrapper>
)

export default MoneyWithCurrency

interface WrapperStyledProps {
    position?: "center" | "left" | "right"
}
const Wrapper = styled.div<WrapperStyledProps>`
  flex-direction: row;
  width: fit-content;
  ${({ position }) => position === "center" && "margin:0 auto;"}
  ${({ position }) => position === "left" && "margin:0 auto 0 0;"}
  ${({ position }) => position === "right" && "margin:0 0 0 auto;"}
`;

interface CurrencyStyledProps {
    fontSize?: string;
  mobileFontSize?: string;
}

const Currency = styled.b<CurrencyStyledProps>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "24px")};
  color: ${Colors.casper};
  font-family: ${Fonts.primary};
  margin-right: 4px;
  @media only screen and (max-width: ${MobileSize}px) {
    font-size: ${({ mobileFontSize }) =>
      mobileFontSize ? mobileFontSize : "16px"};
  }
`;

interface MoneyStyledProps {
    fontSize?: string;
    mobileFontSize?: string;
}

const Money = styled.b<MoneyStyledProps>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "24px")};
  color: ${Colors.black};
  font-family: ${Fonts.primary};
  @media only screen and (max-width: ${MobileSize}px) {
    font-size: ${({ mobileFontSize }) =>
      mobileFontSize ? mobileFontSize : "16px"};
  }
`;

const Remark = styled.span`
  font-size: 14px;
  color: ${Colors.casper};
  font-family: ${Fonts.primary};
  line-height: 1.8;
  margin-left: 6px;
`;