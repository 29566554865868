import React from 'react'
import styled from 'styled-components'
import ContentContainer from '../components/Common/ContentContainer'
import DateRange from '../components/Common/DateRange'
import MemberListContainer from '../components/Member/MemberListContainer'
import PageLayout from '../components/PageLayout'
import { Colors } from '../helpers/color'
import { MobileSize } from '../helpers/responsive'
import useDateRange from '../hooks/useDateRange'
import useFetchMember from '../hooks/useFetchMember'

const Member = () => {
	const { dateRange, setDateRange } = useDateRange()
	const { data, mutate, setSearch } = useFetchMember(dateRange)

	return (
		<PageLayout title="รายการสมาชิก" subTitle="จัดการเว็บไซต์">
			<Body>
				<ContentContainer>
					<DateRange onChange={setDateRange} />

					<SeconedSection>
						<MemberListContainer
							data={data || []}
							update={mutate}
							setSearch={setSearch}
						/>
					</SeconedSection>
				</ContentContainer>
			</Body>
		</PageLayout>
	)
}

export default Member

const Body = styled.div`
	background: ${Colors.zircon2};
	width: 100%;
	flex: 1;
	margin-top: 32px;
	padding: 46px 0;
	@media only screen and (max-width: ${MobileSize}px) {
		padding: 20px 0;
		margin-top: 20px;
	}
`
const SeconedSection = styled.div`
	margin-top: 32px;
	@media only screen and (max-width: ${MobileSize}px) {
		margin-top: 12px;
	}
`
