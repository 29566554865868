import { FC } from 'react'
import styled from 'styled-components'
import { Colors } from '../../../helpers/color'

interface ToggleWithIconProps {
	active: boolean

	leftIcon: string
	rightIcon: string
	onChange?: (v: boolean) => void
}
const ToggleWithIcon: FC<ToggleWithIconProps> = ({
	active,
	leftIcon,
	rightIcon,
	onChange,
}) => (
	<ToggleWrapper
		active={active}
		onClick={() => onChange && onChange(!active)}>
		<DisableButton active={active}>
			<img src={leftIcon} alt="on" />
		</DisableButton>
		<ActiveButton active={active}>
			<img src={rightIcon} alt="off" />
		</ActiveButton>
	</ToggleWrapper>
)

export default ToggleWithIcon

interface ToggleWrapperStyledProps {
	active: boolean
}
const ToggleWrapper = styled.div<ToggleWrapperStyledProps>`
	border-radius: 10px;
	cursor: pointer;
	width: 100%;
	max-height: 40px;
	padding: 4px;
	align-items: center;
	justify-content: space-around;
	flex-direction: row;
	background: ${({ active }) => (active ? Colors.positive : Colors.negative)};
`

interface DisableButtonStyledProps {
	active: boolean
}
const DisableButton = styled.div<DisableButtonStyledProps>`
	flex: 1;
	text-align: center;
	padding: 8px;
	background: ${({ active }) => (!active ? Colors.white : 'none')};
	border-radius: 10px;
	align-items: center;
	max-width: 32px;
	max-height: 32px;
	margin: 0 auto 0 0;
	justify-content: center;
	img {
		${({ active }) => active && 'opacity:0;'}
	}
`

interface ActiveButtonStyledProps {
	active: boolean
}
const ActiveButton = styled.div<ActiveButtonStyledProps>`
	flex: 1;
	text-align: center;
	padding: 8px;
	background: ${({ active }) => (active ? Colors.white : 'none')};
	border-radius: 10px;
	align-items: center;
	max-width: 32px;
	max-height: 32px;
	margin: 0 0 0 auto;
	img {
		${({ active }) => !active && 'opacity:0;'}
	}
`
