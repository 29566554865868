import api from '../../helpers/api'
import { DateRangeType } from '../types'
import { Member } from './types'

interface fetchMemberParams {
	dateRange?: DateRangeType
	search?: string
}
export const fetchMember = ({ dateRange, search }: fetchMemberParams) =>
	api.get<void, Member[]>('/members', {
		params: {
			start: dateRange?.start,
			end: dateRange?.end,
			search: search,
		},
	})
