import moment from 'moment'
import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import styled from 'styled-components'
import useSWR from 'swr'
import { ReportByMemberTransaction, NewReportByMemberTransaction } from '../../../api/summary/types'
import { format } from '../../../helpers/number'
import { MobileSize } from '../../../helpers/responsive'
import { usePagination } from '../../../hooks/usePagination'
import PrefixService from '../../../services/prefix'
import Card, {
	CardSubTitle,
	CardTitle,
	CardTitletRowWrapperWithOutBorder,
	CardTitleWrapper,
} from '../../Common/Card'
import ContentContainer from '../../Common/ContentContainer'
import DateRange from '../../Common/DateRange'
import { CountItemList, PaginateWrapper, TableWrapper } from '../../Common/Table'
import ReportByMemberListTable from './ReportByMemberListTable'
import Select from '../../Common/Select'

const ReportByMemberTab = () => {
	const [newData, setNewData] = useState([])
	const [defaultSort, setDefaultSort] = useState('')
	const [dateRange, setDateRange] = useState({
		start: moment().format('YYYY-MM-DD'),
		end: moment().format('YYYY-MM-DD'),
	})

	const { data } = useSWR(
		`/summary/report-by-member?start=${dateRange.start}&end=${dateRange.end}`,
		() =>
			PrefixService.getReportByMemberList({
				dateRange: dateRange,
			}),
	)

	const calcReportTransaction = (
		type: string,
		typeCompare: string,
		value: number,
		added: number
	) => (
		type === typeCompare ? (value ? value : 0) + added : (value ? value : 0)
	)

	const reduceData = data?.reduce((item: any, {amount, type, member} : ReportByMemberTransaction) => {
		item[member.id] = {
			member: member,
			deposit: calcReportTransaction(type, 'deposit', item[member.id]?.deposit, amount),
			depositCount: calcReportTransaction(type, 'deposit', item[member.id]?.depositCount, 1),
			withdraw: calcReportTransaction(type, 'withdraw', item[member.id]?.withdraw, amount),
			withdrawCount: calcReportTransaction(type, 'withdraw', item[member.id]?.withdrawCount, 1)
		}
		return item;
	}, {})

	const {
		data: txData,
		page,
		pageCount,
		setPage
	} = usePagination<NewReportByMemberTransaction>(newData || [])

	const handleSortReport = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const value = event.target.value

		setDefaultSort(value)
		setNewData(Object.values(reduceData || []).sort((a: any, b: any) => (
			value === 'balance'
			? (parseFloat(b.deposit) - parseFloat(b.withdraw)) - (parseFloat(a.deposit) - parseFloat(a.withdraw)) 
			: parseFloat(b[value]) - parseFloat(a[value])
		)) as any)
	}

	useEffect(() => {
		setPage(0)
		setDefaultSort('deposit')
		setNewData(Object.values(reduceData || []).sort((a: any, b: any) => parseFloat(b.deposit) - parseFloat(a.deposit)) as any)
	}, [setPage, data, reduceData])

	return (
		<ContentContainer>
			<DateRange onChange={setDateRange} />

			<FirstSection>
				<Card padding="24px 0 0 0">
					<CardTitleWrapper>
						<CardTitletRowWrapperWithOutBorder>
							<CardTitle>รายการฝาก/ถอนรายบุคคล</CardTitle>
							<CardSubTitle>{format(newData?.length || 0, 0)} รายการ</CardSubTitle>
						</CardTitletRowWrapperWithOutBorder>
						<SortReport>
							<span>เรียงจาก</span>
							<Select
								name="sortReport"
								items={[
									{ label: 'ยอดฝาก', value: 'deposit' },
									{ label: 'ยอดถอน', value: 'withdraw' },
									{ label: 'กำไร/ขาดทุน', value: 'balance' },
								]}
								value={defaultSort}
								onChange={handleSortReport}
								required
								style={{minWidth: '160px'}}
							/>
						</SortReport>
					</CardTitleWrapper>
					<TableWrapper>
						<ReportByMemberListTable loading={!data} data={txData} />
						<PaginateWrapper>
							<CountItemList></CountItemList>
							<ReactPaginate
								forcePage={page}
								pageCount={pageCount}
								pageRangeDisplayed={5}
								marginPagesDisplayed={2}
								containerClassName={'pagination'}
								pageClassName={'pages pagination-pages'}
								activeClassName={'active-pagination'}
								onPageChange={(e) => {
									setPage(e.selected)
								}}
							/>
						</PaginateWrapper>
					</TableWrapper>
				</Card>
			</FirstSection>
		</ContentContainer>

	)
}

export default ReportByMemberTab

const FirstSection = styled.div`
	margin-top: 32px;

	@media only screen and (max-width: ${MobileSize}px) {
		margin-top: 12px;
	}
`

const SortReport = styled.div`
	flex-direction: row;
	align-items: center;

	span {
		font-size: 16px;
		margin-right: 8px;
	}

	@media only screen and (max-width: ${MobileSize}px) {
		flex-direction: column;
		align-items: stretch;
		margin-bottom: 8px;

		span {
			font-size: 14px;
		}
	}
`