import { useFormik } from 'formik'
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { PrefixConfig } from '../../../@types/prefix'
import { Colors } from '../../../helpers/color'
import { MobileSize } from '../../../helpers/responsive'
import PrefixService from '../../../services/prefix'
import { requestAuthInit } from '../../../store/auth/auth.actions'
import PrimaryButton from '../../Common/Button/PrimaryButton'
import Card, { CardTitleWrapper } from '../../Common/Card'
import CheckboxItem from '../../Common/Checkbox/CheckboxItem'
import TwoColumn from '../../Common/Columns/TwoColumn'
import Input from '../../Common/Input'
import { TitleTable } from '../../Common/Table'
import Toggle from '../../Common/Toggle'
import ContentContainer from '../../Common/ContentContainer'

interface CashbackSettingProps {
	data: PrefixConfig
}
const CashbackSetting: FC<CashbackSettingProps> = ({ data }) => {
	const dispatch = useDispatch()
	const {
		values,
		isSubmitting,
		setFieldValue,
		handleChange,
		handleSubmit,
	} = useFormik({
		initialValues: data.setting.cashback,
		onSubmit: async (values) => {
			try {
				values.maxAmount = parseFloat(values.maxAmount.toString())
				values.min = parseFloat(values.min.toString())
				values.turn = parseFloat(values.turn.toString())

				const res = await PrefixService.saveCashbackConfig(values)

				dispatch(requestAuthInit(false))
				toast.success(res.message)
			} catch ({ message }) {
				toast.error(message as string)
			}
		},
	})

	return (
		<ContentContainer>
			<Card padding="24px 0">
				<CardTitleWrapper>
					<TitleTable>จัดการแคชแบ็ก</TitleTable>
					<SwitchWrapper>
						<Toggle
							activeLabel="เปิด"
							disableLabel="ปิด"
							active={values.enable}
							setActive={(v) => setFieldValue('enable', v)}
						/>
					</SwitchWrapper>
				</CardTitleWrapper>
				<CardBodyWrapper>
					<form onSubmit={handleSubmit}>
						<InputSection>
							<TwoColumn margin="24px">
								<Input
									type="text"
									name="amount"
									label="แคชแบ็ก"
									placeholder="0%"
									defaultValue={values.amount}
									onChange={handleChange}
								/>
								<Input
									type="number"
									name="maxAmount"
									label="จำนวนแคชแบ็กสูงสุด"
									defaultValue={values.maxAmount}
									onChange={handleChange}
								/>
							</TwoColumn>
						</InputSection>
						<InputSection>
							<TwoColumn margin="24px">
								<Input
									type="number"
									name="min"
									label="ยอดฝากขั้นต่ำ"
									defaultValue={values.min}
									onChange={handleChange}
								/>
								<Input
									type="number"
									name="turn"
									label="เทิร์น (เท่า)"
									defaultValue={values.turn}
									onChange={handleChange}
								/>
							</TwoColumn>
						</InputSection>
						<InputSection>
							<RuleTitle>เงื่อนไขแคชแบ็ก</RuleTitle>
							<RuleCheckBoxWrapper>
								<CheckboxItem
									label={`ต้องมีเครดิตคงเหลือน้อยกว่า ${data.setting.minCredit}`}
									checked={values.rules.minCredit}
									padding="0 0 8px 0"
									onChange={(v) =>
										setFieldValue('rules.minCredit', v)
									}
								/>
								<CheckboxItem
									label="ต้องไม่มีรายการถอน (ตลอดทั้งวัน)"
									checked={values.rules.noWithdraw}
									padding="0 0 8px 0"
									onChange={(v) =>
										setFieldValue('rules.noWithdraw', v)
									}
								/>
								<CheckboxItem
									label="ต้องไม่มีรายการรับโบนัส (ตลอดทั้งวัน)"
									checked={values.rules.noBonus}
									padding="0 0 8px 0"
									onChange={(v) =>
										setFieldValue('rules.noBonus', v)
									}
								/>
								<CheckboxItem
									label="ต้องไม่มีรายการหมุนกงล้อ (ตลอดทั้งวัน)"
									checked={values.rules.noWheel}
									padding="0 0 8px 0"
									onChange={(v) =>
										setFieldValue('rules.noWheel', v)
									}
								/>
								<CheckboxItem
									label="รับแคชแบ็กได้ 1 ครั้งต่อวัน"
									padding="0 0 8px 0"
									checked={values.rules.oncePerDay}
									onChange={(v) =>
										setFieldValue('rules.oncePerDay', v)
									}
								/>
							</RuleCheckBoxWrapper>
						</InputSection>
						<InputSection>
							<PrimaryButton
								type="submit"
								width="120px"
								loading={isSubmitting}
							>
								บันทึกข้อมูล
							</PrimaryButton>
						</InputSection>
					</form>
				</CardBodyWrapper>
			</Card>
		</ContentContainer>
	)
}

export default CashbackSetting

const SwitchWrapper = styled.div`
	max-width: 162px;
	margin: 0 0 0 auto;
	width: 100%;
	justify-content: flex-end;
	@media only screen and (max-width: ${MobileSize}px) {
		max-width: 99px;
		flex: 0 0 auto;
	}
`
const CardBodyWrapper = styled.div`
	padding: 0 32px;
	margin-top: 20px;
	@media only screen and (max-width: ${MobileSize}px) {
		padding: 0 16px;
	}
`
const InputSection = styled.div`
	margin-bottom: 18px;
	flex: 1;
`
const RuleTitle = styled.div`
	font-size: 18px;
	color: ${Colors.slateGray};
	font-weight: bold;
`
const RuleCheckBoxWrapper = styled.div`
	margin-top: 12px;
`
