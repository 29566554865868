import { useFormik } from 'formik'
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { NewRegBonusConfig } from '../../../@types/prefix'
import { Colors } from '../../../helpers/color'
import { Fonts } from '../../../helpers/font'
import { MobileSize } from '../../../helpers/responsive'
import { getRewardItems } from '../../../helpers/wheel'
import PrefixService from '../../../services/prefix'
import { requestAuthInit } from '../../../store/auth/auth.actions'
import PrimaryButton from '../../Common/Button/PrimaryButton'
import Card, { CardTitleWrapper } from '../../Common/Card'
import CheckboxItem from '../../Common/Checkbox/CheckboxItem'
import TwoColumn from '../../Common/Columns/TwoColumn'
import Input from '../../Common/Input'
import Radio from '../../Common/Radio/Radio'
import Select from '../../Common/Select'
import { TitleTable } from '../../Common/Table'
import Toggle from '../../Common/Toggle'
import ContentContainer from '../../Common/ContentContainer'

interface CreditSettingProps {
	data: NewRegBonusConfig
}
const CreditSetting: FC<CreditSettingProps> = ({ data }) => {
	const dispatch = useDispatch()
	const {
		values,
		isSubmitting,
		setFieldValue,
		handleChange,
		handleSubmit,
	} = useFormik({
		initialValues: {
			'amount': data?.amount,
			'turn': data?.turn,
			'maxWithdraw': data?.maxWithdraw
		},
		onSubmit: async (values) => {
			try {
				values.amount = parseFloat(values.amount.toString())
				values.turn = parseFloat(values.turn.toString())
				values.maxWithdraw = parseFloat(values.maxWithdraw.toString())
				// console.log(values);
				
				// values.minDeposit = parseFloat(values.minDeposit.toString())
				// values.rewardTurn = parseFloat(values.rewardTurn.toString())
				// for (let reward in values.rewards) {
				// 	values.rewards[reward].chance = parseFloat(
				// 		values.rewards[reward].chance.toString()
				// 	)
				// }

				const res = await PrefixService.saveNewRegBonus(values)

				dispatch(requestAuthInit(false))
				toast.success(res.message)
			} catch ({ message }) {
				toast.error(message as string)
			}
		},
	})
	

    

	return (
		<ContentContainer>
			<Card padding="24px 0">
				<CardTitleWrapper>
					<TitleTable>ตั้งค่าเครดิตฟรีสำหรับสมาชิกใหม่</TitleTable>
				</CardTitleWrapper>

				<WheelCardBottomContentWrapper>
					<form onSubmit={handleSubmit}>
                        <InputSection key={1}>
                            <Input
                                type="number"
                                name="amount"
                                label={'จำนวน'}
                                defaultValue={values?.amount}
                                required
                                onChange={handleChange}
                                symbol=""
                            />
                        </InputSection>
                        <InputSection key={2}>
                            <TwoColumn margin="24px">
                                <Input
                                    type="number"
                                    name="turn"
                                    label={'เทิร์น (เท่า)'}
                                    defaultValue={values?.turn}
                                    required
                                    onChange={handleChange}
                                    symbol=""
                                />
                                <Input
                                    type="number"
                                    name="maxWithdraw"
                                    label={'ยอดถอนสูงสุด (เท่า)'}
                                    defaultValue={values?.maxWithdraw}
                                    required
                                    onChange={handleChange}
                                    symbol=""
                                />
                            </TwoColumn>
                        </InputSection>
						<InputSection>
							<PrimaryButton
								type="submit"
								width="120px"
								loading={isSubmitting}
							>
								บันทึกข้อมูล
							</PrimaryButton>
						</InputSection>
					</form>
				</WheelCardBottomContentWrapper>
			</Card>
		</ContentContainer>
	)
}

export default CreditSetting

// const SwitchWrapper = styled.div`
// 	max-width: 162px;
// 	margin: 0 0 0 auto;
// 	width: 100%;
// 	justify-content: flex-end;
// 	@media only screen and (max-width: ${MobileSize}px) {
// 		max-width: 99px;
// 		flex: 0 0 auto;
// 	}
// `
const InputSection = styled.div`
	margin-bottom: 18px;
	flex: 1;
`
const WheelCardBottomContentWrapper = styled.div`
	padding: 10px 32px;
	justify-content: space-between;
	grid-template-columns: repeat(auto-fit, 50% - 24px);
	@media only screen and (max-width: ${MobileSize}px) {
		grid-template-columns: repeat(auto-fit, 100%);
		flex-direction: column-reverse;
	}
`
