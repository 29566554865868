import React, { FC } from 'react'
import moment from 'moment'
import { BankTransaction } from '../../../@types/transactions'
import styled from 'styled-components'
import { format } from '../../../helpers/number'
import BankType from '../../Common/BankType/BankType'
import MoneyWithCurrency from '../../Common/Money/MoneyWithCurrency'
import { BodyTR, Td, Th, Time, TimeDate, TimeWrapper } from '../../Common/Table'
import EnhancedTable from '../../Common/Table/EnhancedTable'
import TxStatusBadge from '../../TxStatusBadge/TxStatusBadge'
import { Colors } from '../../../helpers/color'
import { Fonts } from '../../../helpers/font'

interface BankTransactionsListTableProps {
	loading: boolean
	data: BankTransaction[]
}
const BankTransactionsListTable: FC<BankTransactionsListTableProps> = ({ loading, data }) => (
	<EnhancedTable
		loading={loading}
		data={data}
		theadSize={5}
		thead={
			<tr>
				<Th style={{ textAlign: 'left' }}>เวลา</Th>
				<Th style={{ textAlign: 'left' }}>ธนาคาร</Th>
				<Th style={{ textAlign: 'center' }}>จำนวนเงิน</Th>
				<Th style={{ textAlign: 'left' }}>รายละเอียด</Th>
				<Th style={{ textAlign: 'center' }}>สถานะ</Th>
			</tr>
		}
	>
		{(row: BankTransaction) => (
			<BodyTR key={row.id}>
				<Td>
					<TimeWrapper>
						<TimeDate>{moment(row?.createdAt).format('DD/MM/YYYY')}</TimeDate>
						<Time>{moment(row?.createdAt).format('HH:mm:ss')}</Time>
					</TimeWrapper>
				</Td>
				<Td>
					<BankType
						bank={row?.bankType}
						bankNumber={row?.bankAccount}
					/>
				</Td>
				<Td>
					<MoneyWithCurrency
						value={format(row?.amount, 2)}
						fontSize="16px"
						position="center"
					/>
				</Td>
				<Td>
					<Remark>{row?.remark}</Remark>
				</Td>
				<Td>
					<TxStatusBadge status={row.status} />
				</Td>
			</BodyTR>
		)}
	</EnhancedTable>
)

export default BankTransactionsListTable

const Remark = styled.div`
	font-family: ${Fonts.primary};
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	color: ${Colors.slateGray};
`