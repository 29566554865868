import React, { FC } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { SCBAccountConfig } from '../../../@types/prefix'
import { Colors } from '../../../helpers/color'
import { MobileSize } from '../../../helpers/responsive'
import PrimaryButton from '../../Common/Button/PrimaryButton'
import SCBAccountListItem from './SCBAccountListItem'
import ContentContainer from '../../Common/ContentContainer'

interface SCBAccountListProps {
	tab: string
	accounts: SCBAccountConfig[]
}
const SCBAccountList: FC<SCBAccountListProps> = ({ tab, accounts }) => {
	const history = useHistory()
	const onAddClick = () => {
		history.push('/bank/add-scb-api')
	}

	return (
		<RightBankWrapper show={tab === 'scb'}>
			<ContentContainer>
				<BankHeader>
					<BankHeaderInner>
						<BankHeaderActionItem>
							<PrimaryButton
								type="button"
								width="120px"
								onClick={onAddClick}
							>
								เพิ่ม SCB API
							</PrimaryButton>
						</BankHeaderActionItem>
					</BankHeaderInner>
				</BankHeader>
				<BankWrapperBody>
					<RightBankItemContainer>
						<BankItemWrapper>
							{accounts.map((acc) => (
								<SCBAccountListItem
									key={`scb-api-${acc.accountNumber}`}
									account={acc}
									onClickEdit={() => {
										history.push(
											`/bank/${acc.accountNumber}/edit-scb-api`
										)
									}}
								/>
							))}
						</BankItemWrapper>
					</RightBankItemContainer>
				</BankWrapperBody>
			</ContentContainer>
		</RightBankWrapper>
	)
}

export default SCBAccountList

interface BankWrapperSyledProps {
	show: boolean
}
const RightBankWrapper = styled.div<BankWrapperSyledProps>`
	background: ${Colors.linkWater};
	width: 100%;
	border-radius: 25px 25px 25px 25px;
	@media only screen and (max-width: ${MobileSize}px) {
		display: ${({ show }) => (show ? 'flex' : 'none')};
	}
`
const BankWrapperBody = styled.div`
	// height: 384px;
	// overflow-y: scroll;
	padding-top: 20px;
`
const RightBankItemContainer = styled.div`
	margin: 0 auto 0 24px;
	width: calc(100% - 86px);
	@media only screen and (max-width: ${MobileSize}px) {
		width: calc(100% - 32px);
		margin: 0 auto;
	}
`
const BankItemWrapper = styled.div`
	align-items: center;
	width: 100%;
	height: calc(100% - 5px * 108px);
`

const BankHeader = styled.div`
	padding: 24px 0;
	border-bottom: 1px solid ${Colors.linkWater2};
`
const BankHeaderInner = styled.div`
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	margin: 0 auto 0 24px;
	width: calc(100% - 86px);
	@media only screen and (max-width: ${MobileSize}px) {
		width: calc(100% - 32px);
		margin: 0 auto;
	}
`
const BankHeaderActionItem = styled.div`
	margin-right: 12px;
	flex: 0 0 auto;
	@media only screen and (max-width: ${MobileSize}px) {
		width: 100%;
		margin-bottom: 12px;
	}
`
