import React, { FC } from 'react'
import styled from 'styled-components'
import { Colors } from '../../../helpers/color'
import { MobileSize } from '../../../helpers/responsive'

interface ModalProps {
	width?: string
}
const Modal: FC<ModalProps> = ({ children, width }) => (
	<Wrapper>
		<Card width={width}>{children}</Card>
	</Wrapper>
)

export default Modal

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	background: rgba(33, 38, 50, 0.2);
	backdrop-filter: blur(5px);
	z-index: 99999;
`

interface CardStyledProps {
	width?: string
}
const Card = styled.div<CardStyledProps>`
	top: 50%;
	transform: translate(0, -50%);
	background: ${Colors.white};
	border-radius: 10px;
	width: ${({ width }) => (width ? width : 'fit-content')};
	height: auto;
	margin: 0 auto;
	@media only screen and (max-width: ${MobileSize}px) {
		width: 90%;
	}
`
