import React, { ChangeEvent, FC, FormEvent } from 'react'
import styled from 'styled-components'
import {
	PromotionConfigAdd,
} from '../../@types/prefix'
import PrimaryButton from '../Common/Button/PrimaryButton'
import Input from '../Common/Input'
import Textarea from '../Common/Input/Textarea'
// import PeriodSetting from './PeriodSetting'
// import StepSetting from './StepSetting'
// import ConsecutiveDepositBonus from './ConsecutiveDepositBonus'
// import NoDepositBetween from './NoDepositBetween'
// import PrefixService from '../../services/prefix'
// import { toast } from 'react-toastify'

interface AddBonusFormProps {
	data: PromotionConfigAdd
	loading: boolean
	isModal?: boolean

	setFieldValue: (field: string, v: any) => void
	onChange: (e: ChangeEvent<any>) => void
	onSubmit: (e: FormEvent<any>) => void
}

const AddBonusForm: FC<AddBonusFormProps> = ({
	data,
	loading,
    isModal,

	setFieldValue,
	onChange,
	onSubmit,
}) => {
	

	return (
		<Form onSubmit={onSubmit}>
			<InputSection>
				<Input
					type="text"
					name="name"
					label="ชื่อโบนัส"
					defaultValue={data.name || ''}
					required
					onChange={onChange}
				/>
			</InputSection>
			<InputSection>
				<DescriptionTextAreaWrapper>
					<Textarea
						name="description"
						label="รายละเอียด"
						defaultValue={data.description}
						required
						onChange={onChange}
					/>
				</DescriptionTextAreaWrapper>
			</InputSection>
            { isModal ? '' :
			<InputSection>
				<PrimaryButton
					type="submit"
					width="150px"
					loading={loading}
				>
					บันทึกข้อมูล
				</PrimaryButton>
			</InputSection>
            }
		</Form>
	)
}

export default AddBonusForm

const Form = styled.form`
	width: 100%;
`
const InputSection = styled.div`
	margin-bottom: 20px;
	width: 100%;
`
const DescriptionTextAreaWrapper = styled.div`
	height: 126px;
`

// const SwitchWraper = styled.div`
// 	display: flex;
// 	flex-direction: row;

// 	.ant-switch {
// 		margin-bottom: 24px;
// 		margin-right: 12px;
// 	}

// 	.ant-switch-checked {
// 		background-color: #7FD592;
// 	}
// `