import moment from 'moment'
import React, { FC } from 'react'
import { MemberTransaction } from '../../../@types/member'
import { getBankName } from '../../../helpers/bank'
import { format } from '../../../helpers/number'
import MoneyWithCurrency from '../../Common/Money/MoneyWithCurrency'
import { BodyTR, Td, Th, Time, TimeDate, TimeWrapper } from '../../Common/Table'
import EnhancedTable from '../../Common/Table/EnhancedTable'
import TxStatusBadge from '../../TxStatusBadge/TxStatusBadge'

interface DepositListTableProps {
	loading: boolean
	data: MemberTransaction[]
}
const DepositListTable: FC<DepositListTableProps> = ({ loading, data }) => (
	<EnhancedTable
		loading={loading}
		data={data}
		theadSize={4}
		thead={
			<tr>
				<Th style={{ textAlign: 'left' }}>เวลา</Th>
				<Th style={{ textAlign: 'left' }}>จาก</Th>
				<Th style={{ textAlign: 'center' }}>จำนวนเงิน</Th>
				<Th style={{ textAlign: 'center' }}>สถานะ</Th>
			</tr>
		}
	>
		{(row: MemberTransaction) => (
			<BodyTR key={`${row.type}.${row.createdAt}`}>
				<Td>
					<TimeWrapper>
						<TimeDate>{moment(row.transferTime).format('DD/MM/YYYY')}</TimeDate>
						<Time>{moment(row.transferTime).format('HH:mm:ss')}</Time>
					</TimeWrapper>
				</Td>
				<Td>
					<b>{getBankName(row.transferFrom)}</b>
				</Td>
				<Td>
					<MoneyWithCurrency
						value={format(row.amount, 2)}
						fontSize="16px"
						position="center"
					/>
				</Td>
				<Td>
					<TxStatusBadge status={row.status} />
				</Td>
			</BodyTR>
		)}
	</EnhancedTable>
)

export default DepositListTable
