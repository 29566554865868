import moment from 'moment'
import React, { FC, useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import styled from 'styled-components'
import { BadgeType } from '../../@types/badge'
import { FixTransaction } from '../../@types/transactions'
import editErrorListIcon from '../../assets/images/edit-error-list-icon.svg'
import { getFullName } from '../../helpers/member'
import { format } from '../../helpers/number'
import { usePagination } from '../../hooks/usePagination'
import BadgeStatus from '../Common/BadgeStatus'
import PrimaryButton from '../Common/Button/PrimaryButton'
import Card, { CardSubTitle, CardTitle, CardTitleWrapper } from '../Common/Card'
import MoneyWithCurrency from '../Common/Money/MoneyWithCurrency'
import PhoneNumberWithIcon from '../Common/PhoneNumberWithIcon/PhoneNumberWithIcon'
import AddFixTxModal from '../AddFixTx/AddFixTxModal'
import {
	BodyTR,
	CountItemList,
	PaginateWrapper,
	TableWrapper,
	Td,
	Th,
	Time,
	TimeDate,
	TimeWrapper,
} from '../Common/Table'
import EnhancedTable from '../Common/Table/EnhancedTable'

interface FixTxBadgeTypeProps {
	tx: FixTransaction
}

const FixTxBadgeType: FC<FixTxBadgeTypeProps> = ({ tx }) => {
	if (tx.type === 'deduct') {
		return <BadgeStatus type={BadgeType.failed}>ตัดเครดิต</BadgeStatus>
	}
	if (tx.type === 'add') {
		return <BadgeStatus type={BadgeType.sucess}>เติมเครดิต</BadgeStatus>
	}
	if (tx.type === 'deposit' && tx.transferFrom === 'TRUEWALLET') {
		return <BadgeStatus type={BadgeType.warning}>ทรูมันนี่วอลเล็ทไม่รัน</BadgeStatus>
	}
	return <BadgeStatus type={BadgeType.info}>ธนาคารไม่รัน</BadgeStatus>
}

interface FixTxListProps {
	loading: boolean

	data: FixTransaction[]
	update: () => void
}

const FixTxList: FC<FixTxListProps> = ({ loading, data, update }) => {
	const {
		data: txData,
		page: txPage,
		pageCount: txPageCount,
		setPage: txSetPage,
	} = usePagination(data)

	useEffect(() => {
		txSetPage(0)
	}, [data, txSetPage])

	// AddFixTxModal
	const [addFixTxModal, setAddFixTxModal] = useState(false)
	const toggleCreditManagement = () => setAddFixTxModal(!addFixTxModal)

	return (
		<>
			<Card padding="24px 0 0 0">
				<CardTitleWrapper>
					<CardTitleTextWrapper>
						<CardTitle>รายการแก้ไข</CardTitle>
						<CardSubTitle>{format(data.length, 0)} รายการ</CardSubTitle>
					</CardTitleTextWrapper>
					<PrimaryButton onClick={() => toggleCreditManagement()}>
						<AddFixTxButtonWrapper>
							<AddFixTxIcon src={editErrorListIcon} alt="add" />
							<span>แก้ไขรายการผิดพลาด</span>
						</AddFixTxButtonWrapper>
					</PrimaryButton>
				</CardTitleWrapper>
				<TableWrapper>
					<EnhancedTable
						loading={loading}
						data={txData}
						theadSize={5}
						thead={
							<tr>
								<Th>เวลา</Th>
								<Th style={{ textAlign: 'left' }}>สมาชิก</Th>
								<Th style={{ textAlign: 'left' }}>รายละเอียด</Th>
								<Th>จำนวนเงิน</Th>
								<Th>ประเภท</Th>
							</tr>
						}
					>
						{(row: FixTransaction) => (
							<BodyTR key={`fix.${row.type}.${row.createdAt}`}>
								<Td>
									<TimeWrapper>
										<TimeDate>
											{moment(row.createdAt).format('DD/MM/YYYY')}
										</TimeDate>
										<Time>{moment(row.createdAt).format('HH:mm:ss')}</Time>
									</TimeWrapper>
								</Td>
								<Td>
									<b>{getFullName(row.member)}</b>
									<br />
									<PhoneNumberWithIcon phoneNumber={row.member.phone} />
								</Td>
								<Td>
									<b>{row.remark ? row.remark : '-'}</b>
									<br />
									ผู้ทำรายการ: {row.operator}
								</Td>
								<Td>
									<MoneyWithCurrency
										value={format(row.amount, 2)}
										fontSize="16px"
										position="center"
									/>
								</Td>
								<Td>
									<FixTxBadgeType tx={row} />
								</Td>
							</BodyTR>
						)}
					</EnhancedTable>
				</TableWrapper>
				<PaginateWrapper>
					<CountItemList></CountItemList>
					<ReactPaginate
						forcePage={txPage}
						pageCount={txPageCount}
						pageRangeDisplayed={5}
						marginPagesDisplayed={2}
						containerClassName={'pagination'}
						pageClassName={'pages pagination-pages'}
						activeClassName={'active-pagination'}
						onPageChange={(e) => {
							txSetPage(e.selected)
						}}
					/>
				</PaginateWrapper>
			</Card>

			<AddFixTxModal
				isOpenModal={addFixTxModal}
				update={update}
				toggleAddFixTxModal={toggleCreditManagement}
			/>
		</>
	)
}

export default FixTxList

const CardTitleTextWrapper = styled.div`
	flex-direction: row;
	margin-bottom: 12px;
	align-items: flex-end;
`
const AddFixTxButtonWrapper = styled.div`
	flex-direction: row;
	align-items: center;
	padding: 0 8px;
`
const AddFixTxIcon = styled.img`
	margin-right: 8px;
`
