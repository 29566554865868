import { FC, InputHTMLAttributes } from 'react'
import styled from 'styled-components'
import checkedIcon from '../../../assets/images/checked-icon.svg'
import { Colors } from '../../../helpers/color'
import { Fonts } from '../../../helpers/font'

interface CheckboxItemProps {
	label: string
	padding?: string

	onChange: (v: boolean) => void
}
const CheckboxItem: FC<
	CheckboxItemProps & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>
> = ({ label, padding, checked = false, onChange }) => (
	<CheckBoxWrapper padding={padding}>
		<InputElementWrapper
			checked={checked}
			onClick={() => onChange(!checked)}
		>
			<InputElement type="checkbox" defaultChecked={checked} />

			<InputCheckedElement checked={checked}>
				<CheckedIcon src={checkedIcon} alt="checked" />
			</InputCheckedElement>
		</InputElementWrapper>
		<Label checked={checked}>{label}</Label>
	</CheckBoxWrapper>
)

export default CheckboxItem

interface CheckBoxWrapperProps {
	padding?: string
}
const CheckBoxWrapper = styled.div<CheckBoxWrapperProps>`
	flex-direction: row;
	align-items: center;
	padding: ${({ padding }) => padding};
`

interface InputElementWrapperStyledProps {
	checked: boolean
}
const InputElementWrapper = styled.div<InputElementWrapperStyledProps>`
	width: 27px;
	height: 27px;
	border-radius: 8px;
	border: 2px solid
		${({ checked }) => (checked ? Colors.dodgerBlue : Colors.linkWater)};
	background: none;
	cursor: pointer;
`

const InputElement = styled.input`
	display: none;
`

interface InputCheckedElementStyledProps {
	checked: boolean
}
const InputCheckedElement = styled.label<InputCheckedElementStyledProps>`
	display: ${({ checked }) => (checked ? 'flex' : 'none')};
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: ${Colors.dodgerBlue};
	align-items: center;
	justify-content: center;
`

interface LabelStyledProps {
	checked: boolean
}
const Label = styled.label<LabelStyledProps>`
	color: ${({ checked }) => (checked ? Colors.dodgerBlue : Colors.slateGray)};
	font-size: 16px;
	font-family: ${Fonts.primary};
	margin-left: 12px;
	flex: 1;
`

const CheckedIcon = styled.img`
	width: 18px;
`
