import React from 'react'
import { BodyTR, Table, Tbody, Td, Thead } from './Table'

interface EnhancedTableProps<T> {
	loading: boolean
	data: T[]

	theadSize: number
	thead: JSX.Element
	children: (value: T) => JSX.Element
}
const EnhancedTable = <T extends object>(props: EnhancedTableProps<T>) => {
	const renderBody = () => {
		if (props.loading) {
			return (
				<BodyTR>
					<Td colSpan={props.theadSize} style={{ textAlign: 'center' }}>
						<i className="fad fa-spinner-third fa-spin fa-2x" />
					</Td>
				</BodyTR>
			)
		}
		if (props.data.length <= 0) {
			return (
				<BodyTR>
					<Td colSpan={props.theadSize} style={{ textAlign: 'center' }}>
						ไม่มีข้อมูล
					</Td>
				</BodyTR>
			)
		}

		return props.data.map(row => props.children(row))
	}

	return (
		<Table>
			<Thead>{props.thead}</Thead>
			<Tbody>{renderBody()}</Tbody>
		</Table>
	)
}

export default EnhancedTable
