import { ButtonHTMLAttributes, FC } from 'react'
import styled from 'styled-components'
import { Colors } from '../../../helpers/color'
import { Fonts } from '../../../helpers/font'
import { MobileSize } from '../../../helpers/responsive'

interface CustomButtonProps {
	height?: string
	width?: string
	mobileWidth?: string
	color?: string
	textColor?: string

	loading?: boolean
}
const CustomButton: FC<
	CustomButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children,
	height,
	width,
	mobileWidth,
	color,
	textColor,
	loading,
	...rest
}) => (
	<Button
		{...rest}
		height={height}
		width={width}
		mobileWidth={mobileWidth}
		color={color}
		textColor={textColor}
		disabled={loading}>
		{loading ? <i className="fad fa-spinner fa-spin" /> : children}
	</Button>
)

export default CustomButton

interface ButtonStyledProps {
	height?: string
	width?: string
	mobileWidth?: string
	color?: string
	textColor?: string
}
const Button = styled.button<ButtonStyledProps>`
	cursor: pointer;
	width: ${({ width }) => width && width};
	height: ${({ height }) => (height ? height : '39px')};
	background: ${({ color }) => color};
	border-radius: 8px;
	color: ${({ textColor }) => textColor || Colors.white};
	align-items: center;
	justify-content: center;
	font-family: ${Fonts.primary};
	font-weight: bold;
	border: 0;
	outline: none;
	@media only screen and (max-width: ${MobileSize}px) {
		width: ${({ mobileWidth }) => (mobileWidth ? mobileWidth : '100%')};
	}

	&:disabled {
		background-color: ${Colors.casper};
	}
`
