import React, { FC } from 'react'
import { PieChart } from 'react-minimal-pie-chart'
import styled from 'styled-components'
import { OverviewSummary } from '../../../api/summary/types'
import graphIcon from '../../../assets/images/center-result-graph-icon.svg'
import depositBonusIcon from '../../../assets/images/deposit-bonus-graph-result.svg'
import depositIcon from '../../../assets/images/deposit-graph-result.svg'
import rejectWithdrawIcon from '../../../assets/images/rejected-withdraw-graph-result.svg'
import withdrawIcon from '../../../assets/images/withdraw-graph-result.svg'
import { Colors } from '../../../helpers/color'
import { calculateNewProfit, depositAndbonus } from '../../../helpers/dashboard'
import { Fonts } from '../../../helpers/font'
import { format } from '../../../helpers/number'
import { MobileSize } from '../../../helpers/responsive'
import Card from '../../Common/Card'
import MoneyWithCurrency from '../../Common/Money/MoneyWithCurrency'

interface LeftGraphProps {
	data: OverviewSummary
}
const LeftGraph: FC<LeftGraphProps> = ({ data }) => (
	<Card>
		<GraphWrapper>
			<GraphLeft>
				<GraphTitle>กำไรทั้งหมด</GraphTitle>

				<Graph>
					<CenterGraph>
						<CenterGraphIcon src={graphIcon} alt="graph" />
					</CenterGraph>
					<PieChart
						lineWidth={34}
						data={[
							{
								title: 'Deposit',
								value: data.depositTotal,
								color: Colors.positive,
							},
							{
								title: 'Deposit and Bonus',
								value: depositAndbonus(data),
								color: Colors.scb,
							},
							{
								title: 'Withdraw',
								value: data.withdrawTotal.success,
								color: Colors.warning,
							},
							{
								title: 'Rejected Withdraw',
								value: data.withdrawTotal.reject,
								color: Colors.negative,
							},
						]}
					/>
				</Graph>
				<TotalResult>
					<TotalResultTop>
						<MoneyWithCurrency value={format(calculateNewProfit(data), 0)} fontSize="28px" />
					</TotalResultTop>
				</TotalResult>
			</GraphLeft>
			<GraphRight>
				<GraphResultListWrapper>
					<GraphResultListTitleWrapper>
						<GraphResultListTitleIcon />
						<GraphResultListTitle>สรุปยอดฝากถอน</GraphResultListTitle>
					</GraphResultListTitleWrapper>

					{/* Deposit */}
					<GraphResultListItem>
						<GraphResultListItemIcon src={depositIcon} alt="deposit" />
						<GraphResultListItemDetail>
							<GraphResultListItemLabel>ยอดฝาก</GraphResultListItemLabel>
							<GraphResultListItemMoney>
								<MoneyWithCurrency value={format(data.depositTotal, 0)} fontSize="20px" />
							</GraphResultListItemMoney>
						</GraphResultListItemDetail>
					</GraphResultListItem>

					{/* Deposit and Bonus */}
					<GraphResultListItem>
						<GraphResultListItemIcon src={depositBonusIcon} alt="deposit" />
						<GraphResultListItemDetail>
							<GraphResultListItemLabel>ยอดฝาก + โบนัส</GraphResultListItemLabel>
							<GraphResultListItemMoney>
								<MoneyWithCurrency value={format(depositAndbonus(data), 0)} fontSize="20px" />
							</GraphResultListItemMoney>
						</GraphResultListItemDetail>
					</GraphResultListItem>

					{/* Success withdraw */}
					<GraphResultListItem>
						<GraphResultListItemIcon src={withdrawIcon} alt="withdraw" />
						<GraphResultListItemDetail>
							<GraphResultListItemLabel>ยอดถอน (สำเร็จ)</GraphResultListItemLabel>
							<GraphResultListItemMoney>
								<MoneyWithCurrency value={format(data.withdrawTotal.success, 0)} fontSize="20px" />
							</GraphResultListItemMoney>
						</GraphResultListItemDetail>
					</GraphResultListItem>

					{/* Reject withdraw */}
					<GraphResultListItem>
						<GraphResultListItemIcon src={rejectWithdrawIcon} alt="withdraw" />
						<GraphResultListItemDetail>
							<GraphResultListItemLabel>ยอดถอน (ไมสำเร็จ)</GraphResultListItemLabel>
							<GraphResultListItemMoney>
								<MoneyWithCurrency value={format(data.withdrawTotal.reject, 0)} fontSize="20px" />
							</GraphResultListItemMoney>
						</GraphResultListItemDetail>
					</GraphResultListItem>
				</GraphResultListWrapper>
			</GraphRight>
		</GraphWrapper>
	</Card>
)

export default LeftGraph

const GraphWrapper = styled.div`
	width: 100%;
	flex-direction: row;
`

const GraphTitle = styled.b`
	font-size: 18px;
	font-family: ${Fonts.primary};
	color: ${Colors.dodgerBlue};
`
const GraphLeft = styled.div`
	width: 50%;
	flex: 0 0 auto;
`
const GraphRight = styled.div`
	width: 50%;
	flex: 0 0 auto;
`

const GraphResultListWrapper = styled.div`
	background: ${Colors.zircon};
	border-radius: 20px;
`

const GraphResultListTitleWrapper = styled.div`
	padding: 20px;
	border-bottom: 1px solid ${Colors.linkWater};
	margin-bottom: 18px;
`
const GraphResultListTitleIcon = styled.img``
const GraphResultListTitle = styled.b`
	font-size: 18px;
	font-family: ${Fonts.primary};
	color: ${Colors.dodgerBlue};
`

const GraphResultListItem = styled.div`
	margin-bottom: 18px;
	flex-direction: row;
	padding: 0 20px;
`

const GraphResultListItemIcon = styled.img`
	margin-right: 18px;
`

const GraphResultListItemDetail = styled.div``

const GraphResultListItemLabel = styled.span`
	color: ${Colors.casper};
	font-size: 12px;
	font-family: ${Fonts.primary};
`

const GraphResultListItemMoney = styled.div`
	flex-direction: row;
`
const TotalResult = styled.div``

const TotalResultTop = styled.div`
	flex-direction: row;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

const Graph = styled.div`
	width: 70%;
	max-width: 159px;
	margin: 24px auto;
	align-items: center;
	justify-content: center;
	@media only screen and (max-width: ${MobileSize}px) {
		width: 90%;
	}
`

const CenterGraph = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	width: 100%;
	text-align: center;
`

const CenterGraphIcon = styled.img`
	width: 50px;
	margin: auto;
`
