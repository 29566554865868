import React, { FC, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ApplicationState } from '../../../store'
import styled from 'styled-components'
import { useHistory } from 'react-router'
import { Modal } from 'reactstrap'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import PrefixService from '../../../services/prefix'
import { RedeemConfig } from '../../../@types/prefix'
import { requestAuthInit } from '../../../store/auth/auth.actions'
import PrimaryButton from '../../Common/Button/PrimaryButton'
import Input from '../../Common/Input'
import moment from 'moment'

interface AddRedeemModalProps {
    redeemInfo: RedeemConfig | any
	isOpenModal: boolean
	toggle: () => void
	update: () => void
}

const AddRedeemModal: FC<AddRedeemModalProps> = ({ redeemInfo, isOpenModal, toggle, update}) => {

	const dispatch = useDispatch()
	const history = useHistory()
	const {
		values,
		isSubmitting,
        setValues,
		setFieldValue,
		resetForm,
		handleChange,
		handleSubmit,
	} = useFormik({
		initialValues: {
			code: '',
            amount: 0,
            limit: 0,
            maxWithdraw: 0,
            turn: 0
		},
		enableReinitialize: true,
		onSubmit: async (values, { resetForm }) => {
			try {
                // console.log(redeemInfo);
                // console.log(values);
                // redeemInfo.Code = values.code
                redeemInfo.Amount = values.amount
                redeemInfo.Limit = values.limit
                redeemInfo.MaxWithdraw = values.maxWithdraw
                redeemInfo.Turn = values.turn
                // console.log(redeemInfo);
                
				const res = await PrefixService.editRedeem(redeemInfo)
				toggle()
				resetForm()
				dispatch(requestAuthInit(false))
				toast.success(res.message)
				update()
			} catch ({ message }) {
				toast.error(message as string)
			}
		},
	})

	useEffect(() => {
		if (isOpenModal) {
            resetForm()
            setValues({
                code: redeemInfo?.Code,
                amount: redeemInfo?.Amount,
                limit: redeemInfo?.Limit,
                maxWithdraw: redeemInfo?.MaxWithdraw,
                turn: redeemInfo?.Turn
            })
		}
	}, [isOpenModal, resetForm, setFieldValue, redeemInfo, setValues]);

	return (
        <Modal isOpen={isOpenModal} className="flex-row modal-dialog-centered" fade={true} toggle={() => toggle()}>
            <div className="modal-body p-lg-4">
                <div className="d-flex flex-row justify-content-between align-items-center mb-4">
                    <h3 className="mb-0"><strong>เพิ่มโค๊ดโปรโมชั่น</strong></h3>
                    <div className="modal-close">
                        <i className="far fa-times" onClick={() => toggle()}></i>
                    </div>
                </div>
                <Form onSubmit={handleSubmit}>
                    <InputSection>
                        <Input
                            type="text"
                            name="code"
                            label="โค๊ดโปรโมชั่น"
                            defaultValue={values.code}
                            required
                            onChange={handleChange}
                            disabled
                        />
                    </InputSection>
                    <InputSection>
                        <Input
                            type="number"
                            name="amount"
                            label="จำนวนเครดิต"
                            defaultValue={values.amount}
                            required
                            onChange={handleChange}
                        />
                    </InputSection>
                    <InputSection>
                        <Input
                            type="number"
                            name="limit"
                            label="จำนวนโค๊ด"
                            defaultValue={values.limit}
                            required
                            onChange={handleChange}
                        />
                    </InputSection>
                    <InputSection>
                        <Input
                            type="number"
                            name="maxWithdraw"
                            label="ยอดถอนสูงสุด (เท่า)"
                            defaultValue={values.maxWithdraw}
                            required
                            onChange={handleChange}
                        />
                    </InputSection>
                    <InputSection>
                        <Input
                            type="number"
                            name="turn"
                            label="เทิร์น (เท่า)"
                            defaultValue={values.turn}
                            required
                            onChange={handleChange}
                        />
                    </InputSection>
                    { isOpenModal &&
                    <InputSection>
                        <PrimaryButton
                            type="submit"
                            width="150px"
                            loading={isSubmitting}
                        >
                            บันทึกข้อมูล
                        </PrimaryButton>
                    </InputSection>
                    }
                </Form>
            </div>
        </Modal>
	)
}
export default AddRedeemModal


const Form = styled.form`
	width: 100%;
`
const InputSection = styled.div`
	margin-bottom: 20px;
	width: 100%;
`
const DescriptionTextAreaWrapper = styled.div`
	height: 126px;
`