import { FC } from 'react'
import styled from 'styled-components'
import hambergerIcon from '../../../assets/images/hamberger-menu-icon.svg'
import { Colors } from '../../../helpers/color'
import { MobileSize } from '../../../helpers/responsive'
import PrefixService from '../../../services/prefix'

const Wrapper = styled.div`
	display: none;
	@media only screen and (max-width: ${MobileSize}px) {
		display: flex;
		width: 100%;
		border-radius: 0px 0px 10px 10px;
		background: ${Colors.black2};
		flex-direction: row;
		padding: 12px 20px;
		height: 52px;
	}
`

const ActionItem = styled.div`
	@media only screen and (max-width: ${MobileSize}px) {
		width: 24px;
	}
`

const Hamberger = styled.img``

const Logo = styled.div`
	@media only screen and (max-width: ${MobileSize}px) {
		flex: 1;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
`
const LogoText = styled.h1`
	font-weight: 900;
	font-size: 24px;
	color: ${Colors.white};
	margin-left: 4px;
`
// const LogoIcon = styled.img`
// 	width: 23px;
// 	height: 23px;
// `

interface MobileHeaderProps {
	toggleSidebar: () => void
}
const MobileHeader: FC<MobileHeaderProps> = ({ toggleSidebar }) => (
	<Wrapper>
		<ActionItem onClick={toggleSidebar}>
			<Hamberger src={hambergerIcon} alt="Menu" />
		</ActionItem>
		<Logo>
			<LogoText>{PrefixService.getPrefix()}</LogoText>
		</Logo>
	</Wrapper>
)

export default MobileHeader
