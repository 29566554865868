import { useFormik } from 'formik'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import PrimaryButton from '../components/Common/Button/PrimaryButton'
import Input from '../components/Common/Input'
import { Colors } from '../helpers/color'
import { Fonts } from '../helpers/font'
import { MobileSize } from '../helpers/responsive'
import AuthService from '../services/auth'
import { requestAuthInit } from '../store/auth/auth.actions'

const Login = () => {
	const history = useHistory()
	const dispatch = useDispatch()

	const { isSubmitting, handleChange, handleSubmit } = useFormik({
		initialValues: {
			username: '',
			password: '',
			prefix: '',
		},
		onSubmit: async (values) => {
			try {
				await AuthService.login(values)

				dispatch(requestAuthInit(true))
				history.push('/main')
			} catch ({ message }) {
				toast.error(message as string)
			}
		},
	})

	return (
		<Wrapper>
			<LoginForm>
				<Logo>
					<LogoIcon src="https://msn.cdnbet.co/logo.png" />
				</Logo>
				<form onSubmit={handleSubmit}>
					<InputSection>
						<Label>ชื่อผู้ใช้งาน</Label>
						<Input
							type="text"
							name="username"
							placeholder="ชื่อผู้ใช้งาน"
							required
							onChange={handleChange}
						/>
					</InputSection>
					<InputSection>
						<Label>รหัสผ่าน</Label>
						<Input
							type="password"
							name="password"
							placeholder="รหัสผ่าน"
							required
							onChange={handleChange}
						/>
					</InputSection>
					<InputSection>
						<Label>Prefix</Label>
						<Input type="text" name="prefix" placeholder="Prefix" required onChange={handleChange} />
					</InputSection>
					<ButtonSection>
						<PrimaryButton type="submit" width="154px" loading={isSubmitting}>
							เข้าสู่ระบบ
						</PrimaryButton>
					</ButtonSection>
				</form>
			</LoginForm>
		</Wrapper>
	)
}

export default Login

const Wrapper = styled.div`
	background: ${Colors.black2};
	width: 100%;
	height: 100%;
	position: fixed;
	align-items: center;
	justify-content: center;
`

const LoginForm = styled.div`
	max-width: 332px;
	width: 100%;
	margin: -5% auto 0 auto;
	@media only screen and (max-width: ${MobileSize}px) {
		margin: -40% auto 0 auto;
	}
`

const Logo = styled.div`
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: 70px 0 40px 0;
`
const LogoIcon = styled.img``

const Label = styled.div`
	font-family: ${Fonts.primary};
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 21px;
	margin-bottom: 8px;
	color: ${Colors.linkWater};
`
const InputSection = styled.div`
	margin-bottom: 16px;
`

const ButtonSection = styled.div`
	align-items: center;
	margin-top: 36px;
`
