import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import authReducer, {
	defaultState as authDefaultState,
	State as AuthState,
} from './auth/auth.reducer'
import prefixReducer, {
	defaultState as prefixDefaultState,
	State as PrefixState,
} from './prefix/prefix.reducer'

const reduxDevTools = (window as any).__REDUX_DEVTOOLS_EXTENSION__

export interface ApplicationState {
	auth: AuthState
	prefix: PrefixState
}

export const defaultState: ApplicationState = {
	auth: authDefaultState,
	prefix: prefixDefaultState,
}

const reducers = combineReducers({
	auth: authReducer,
	prefix: prefixReducer,
})

const enhancers = compose(
	applyMiddleware(thunk),
	process.env.NODE_ENV !== 'production' && reduxDevTools
		? reduxDevTools()
		: (f: any) => f
) as any

export default createStore(reducers, defaultState, enhancers)
