import React, { ChangeEvent, FC, useEffect } from 'react'
import styled from 'styled-components'
import { PromotionConfig } from '../../@types/prefix'
import { Colors } from '../../helpers/color'
import CheckboxWithContent from '../Common/Checkbox/CheckboxWithContent'
import CheckboxItem from '../Common/Checkbox/CheckboxItem'
import Input from '../Common/Input'

interface ConsecutiveDepositBonusProps {
	data: PromotionConfig

	onChange: (e: ChangeEvent<any>) => void
	setFieldValue: (field: string, value: any) => void
}
const ConsecutiveDepositBonus: FC<ConsecutiveDepositBonusProps> = ({
	data,
	onChange,
	setFieldValue,
}) => {
	const rules = data.consecutiveDepositBonus.rules || {
		noBonus: false,
		noCashback: false,
		noLuckyWheel: false,
		noWithdraw: false
	}

	useEffect(() => {
		if(!data.consecutiveDepositBonus.rules) {
			setFieldValue('consecutiveDepositBonus.rules', rules)
		}
	}, [])

	return (
		<CheckboxWithContent
			label="โบนัสฝากติดต่อกัน"
			checked={data.consecutiveDepositBonus.enable}
			setChecked={(v: boolean) => setFieldValue('consecutiveDepositBonus.enable', v)}
		>
			<CheckboxWithContentBody>
				<TopCheckboxWithContentSection>
					<LeftCheckboxWithContentBody>
						<CheckboxWithContentInputItemWrapper>
							<Input
								type="text"
								name={`consecutiveDepositBonus.consecutiveDepositDays`}
								label="ฝากติดต่อกัน (วัน)"
								defaultValue={data.consecutiveDepositBonus.consecutiveDepositDays}
								required
								onChange={onChange}
							/>
						</CheckboxWithContentInputItemWrapper>
						<CheckboxWithContentInputItemWrapper>
							<Input
								type="text"
								name={`consecutiveDepositBonus.minimumDepositPerDay`}
								label="ยอดฝากขั้นต่ำต่อวัน"
								defaultValue={data.consecutiveDepositBonus.minimumDepositPerDay}
								required
								onChange={onChange}
							/>
						</CheckboxWithContentInputItemWrapper>
					</LeftCheckboxWithContentBody>
				</TopCheckboxWithContentSection>
				<CheckboxContainer>
					<CheckboxItem
						label={'ไม่มีรายการถอน'}
						checked={rules.noWithdraw}
						padding={'0 0 15px 0'}
						onChange={(v: boolean) => setFieldValue('consecutiveDepositBonus.rules.noWithdraw', v)}
					/>
					<CheckboxItem
						label={'ไม่มีรายการรับโบนัส'}
						checked={rules.noBonus}
						padding={'0 0 15px 0'}
						onChange={(v: boolean) => setFieldValue('consecutiveDepositBonus.rules.noBonus', v)}
					/>
					<CheckboxItem
						label={'ไม่มีรายการรับแคชแบ็ก'}
						checked={rules.noCashback}
						padding={'0 0 15px 0'}
						onChange={(v: boolean) => setFieldValue('consecutiveDepositBonus.rules.noCashback', v)}
					/>
					<CheckboxItem
						label={'ไม่มีรายการหมุนกงล้อเสี่ยงโชค'}
						checked={rules.noLuckyWheel}
						padding={'0 0 15px 0'}
						onChange={(v: boolean) => setFieldValue('consecutiveDepositBonus.rules.noLuckyWheel', v)}
					/>
				</CheckboxContainer>
			</CheckboxWithContentBody>
		</CheckboxWithContent>
	)
}
export default ConsecutiveDepositBonus

const CheckboxWithContentBody = styled.div``

const TopCheckboxWithContentSection = styled.div`
	padding: 0 16px;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	margin: 0 0 15px;
`

const LeftCheckboxWithContentBody = styled.div`
	flex-direction: row;
	margin: 0 -6px;
	flex: 1;
	align-items: center;
`
const CheckboxWithContentInputItemWrapper = styled.div`
	margin: 0 6px;
	flex: 1;
	width: fit-content;
	margin: 0 12px 0 0;
`

const CheckboxContainer = styled.div`
	padding: 15px 15px 0;
	background-color: ${Colors.zircon}
`