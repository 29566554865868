import React, { FC } from 'react'
import styled from 'styled-components'
import { Colors } from '../../../helpers/color'
import { MobileSize } from '../../../helpers/responsive'

interface CardProps {
	padding?: string
	marginBottom?: string
	border?: string
	mobilePadding?: string
}
const Card: FC<CardProps> = ({ children, padding, marginBottom, border, mobilePadding }) => (
	<CardWrapper padding={padding} marginBottom={marginBottom} border={border} mobilePadding={mobilePadding}>
		{children}
	</CardWrapper>
)

export default Card

interface CardWrapperStyledProps {
	padding?: string
	marginBottom?: string
	border?: string
	mobilePadding?: string
}
const CardWrapper = styled.div<CardWrapperStyledProps>`
	width: 100%;
	padding: ${({ padding }) => (padding ? padding : '20px')};
	background: ${Colors.white};
	box-shadow: 0px 10px 35px rgba(87, 106, 154, 0.08);
	border-radius: 25px;
	border: ${({ border }) => (border ? border : '0')} solid #eee;
	margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '24px')};
	@media only screen and (max-width: ${MobileSize}px) {
		padding: ${({ mobilePadding }) =>
			mobilePadding ? mobilePadding : '16px 0'};
	}
`
