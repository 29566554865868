import moment from 'moment'
import React, { FC } from 'react'
import styled from 'styled-components'
import { WheelSummaryItem } from '../../../api/summary/types'
import { Colors } from '../../../helpers/color'
import { format } from '../../../helpers/number'
import MoneyWithCurrency from '../../Common/Money/MoneyWithCurrency'
import PhoneNumberWithIcon from '../../Common/PhoneNumberWithIcon/PhoneNumberWithIcon'
import { BodyTR, Table, TableWrapper, Tbody, Td, Th, Thead, Time, TimeDate, TimeWrapper } from '../../Common/Table'

interface WheelTxListTableProps {
	data: WheelSummaryItem[]
}
const WheelTxListTable: FC<WheelTxListTableProps> = ({ data }) => (
	<TableWrapper>
		<Table>
			<Thead>
				<tr>
					<Th style={{ textAlign: 'left' }}>เวลา</Th>
					<Th style={{ textAlign: 'left' }}>สมาชิก</Th>
					<Th style={{ textAlign: 'left' }}>ยอดเทิร์น</Th>
					<Th style={{ textAlign: 'left' }}>เทิร์น</Th>
					<Th style={{ textAlign: 'left' }}>จำนวนเงิน</Th>
				</tr>
			</Thead>
			<Tbody>
				{data.map((row) => (
					<BodyTR key={row.createdAt}>
						<Td>
							<TimeWrapper>
								<TimeDate>{moment(row.createdAt).format('DD/MM/YYYY')}</TimeDate>
								<Time>{moment(row.createdAt).format('HH:mm:ss')}</Time>
							</TimeWrapper>
						</Td>
						<Td>
							<PhoneNumberWrapper>
								<span>
									{row.member.firstName} {row.member.lastName}
								</span>
								<PhoneNumberWithIcon phoneNumber={row.member.phone} />
							</PhoneNumberWrapper>
						</Td>
						<Td>
							<MoneyWithCurrency value={format(row.totalTurn, 2)} fontSize="16px" />
						</Td>
						<Td>
							<b>{format(row.turn, 0)}</b> เท่า
						</Td>
						<Td>
							<MoneyWithCurrency value={format(row.amount, 2)} fontSize="16px" />
						</Td>
					</BodyTR>
				))}
			</Tbody>
		</Table>
	</TableWrapper>
)

export default WheelTxListTable

const PhoneNumberWrapper = styled.div`
	font-size: 16px;
	color: ${Colors.slateGray};
	font-weight: bold;
`
