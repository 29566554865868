import styled from 'styled-components'
import { Colors } from '../../../helpers/color'
import { Fonts } from '../../../helpers/font'
import { MobileSize } from '../../../helpers/responsive'

export const CardTitle = styled.div`
	font-family: ${Fonts.primary};
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	color: ${Colors.dodgerBlue};
`

export const CardSubTitle = styled.div`
	font-family: ${Fonts.primary};
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	color: ${Colors.casper};
	margin-left: 8px;
	@media only screen and (max-width: ${MobileSize}px) {
		margin-left: 0;
	}
`

export const CardTitleWrapper = styled.div`
	width: 100%;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid ${Colors.linkWater};
	padding: 0 32px 24px 32px;
	@media only screen and (max-width: ${MobileSize}px) {
		flex-direction: row;
		padding: 0 16px 8px 16px;
		flex-wrap: wrap;
	}
`

export const CardTitleWrapperWithOutBorder = styled.div`
	width: 100%;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 0 32px 24px 32px;
	@media only screen and (max-width: ${MobileSize}px) {
		flex-direction: column;
	}
`

export const CardActionItem = styled.div`
	margin-left: 24px;
	@media only screen and (max-width: ${MobileSize}px) {
		margin: 0;
		margin: 12px 0 0 0;
	}
`

export const CardActionHeaderWrapper = styled.div`
	flex-direction: row;
	@media only screen and (max-width: ${MobileSize}px) {
		flex-direction: column;
		width: 100%;
	}
`

export const CardTitletRowWrapperWithOutBorder = styled.div`
	flex-direction: row;
	align-items: flex-end;
	flex: 1;
	width: 100%;
	@media only screen and (max-width: ${MobileSize}px) {
		flex-direction: column;
		align-items: flex-start;
	}
`
