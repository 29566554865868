import React, { useState } from 'react'
import styled from 'styled-components'
import { transactionTabs } from '../@types/transactions'
import ContentContainer from '../components/Common/ContentContainer'
import Tabs from '../components/Common/Tabs'
import PageLayout from '../components/PageLayout'
import DepositTab from '../components/Transactions/DepositTab'
import WithdrawTab from '../components/Transactions/WithdrawTab'
import BankTransactionsTab from '../components/Transactions/BankTransactionsTab'
import { Colors } from '../helpers/color'
import { MobileSize } from '../helpers/responsive'

const Transactions = () => {
	const [activeTab, setActiveTab] = useState('deposit')

	return (
		<PageLayout title="รายการฝาก / ถอน" subTitle="จัดการเว็บไซต์">
			<ContentContainer>
				<TabsController>
					<Tabs tabs={transactionTabs} activeTab={activeTab} selectTab={setActiveTab} />
				</TabsController>
			</ContentContainer>
			<Body>
				{activeTab === 'deposit' && <DepositTab />}
				{activeTab === 'withdraw' && <WithdrawTab />}
				{activeTab === 'bank-transactions' && <BankTransactionsTab />}
			</Body>
		</PageLayout>
	)
}

export default Transactions

const Body = styled.div`
	background: ${Colors.zircon2};
	width: 100%;
	flex: 1;
	margin-top: -3px;
	padding: 46px 0;
	@media only screen and (max-width: ${MobileSize}px) {
		padding: 20px 0;
	}
`
const TabsController = styled.div`
	flex-direction: row;
	margin-top: 32px;
	@media only screen and (max-width: ${MobileSize}px) {
		margin-top: 12px;
		width: 100%;
		overflow-x: scroll;
	}
`
