import React, { useState, useEffect } from 'react'
import PageLayout from '../components/PageLayout'
import UsersListContainer from '../components/Users/UsersListContainer'
import { toast } from 'react-toastify'
import PrefixService from '../services/prefix'

const Setting = () => {
	

	const [dataUsers, setDataUsers] = useState<any>([])
	const [dataPermisstions, setDataPermisstions] = useState<object>({})

	const getUsers = async () => {
		try {
			const res = await PrefixService.getUsers()
			setDataUsers(res)
			setDataOjects(res)
		} catch ({ message }) {
			toast.error(message as string)
		}
	}
    
	const getUsersPermissions = async () => {
		try {
			const res = await PrefixService.getUsersPermissions()
			setDataPermisstions(res)
		} catch ({ message }) {
			toast.error(message as string)
		}
	}
    

	useEffect(() => {
		getUsers()
        getUsersPermissions()
	}, [])

    const [dataOjects, setDataOjects] = useState<any>(dataUsers)
    const [filterText, setFilterText] = useState('')
	useEffect(() => {
        if (Object.keys(dataUsers).length > 0) {
            let filteredItems = (dataUsers as any).filter(
                (item: any) => (
                    (item.username && item.username.toLowerCase().includes(filterText.toLowerCase())) 
                ),
            );
            setDataOjects(filteredItems)
        }
    }, [filterText, dataUsers])
	return (
		<PageLayout title="ผู้ใช้งาน" subTitle="จัดการเว็บไซต์">
			<UsersListContainer 
				data={dataOjects} 
				permissions={dataPermisstions} 
				update={() => getUsers()}
				setSearch={setFilterText}
				/>
		</PageLayout>
	)
}

export default Setting