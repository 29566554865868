import React, { ChangeEvent, FC, FormEvent, useEffect, useState } from 'react'
import styled from 'styled-components'
import {
	bonusAmountTypes,
	bonusGameTypes,
	PromotionConfig,
	promotionLimits,
	PromotionLimitTypes,
	PromotionUfaCashbacks,
	withdrawLimitTypes,
} from '../../@types/prefix'
import { Colors } from '../../helpers/color'
import { Fonts } from '../../helpers/font'
import { MobileSize } from '../../helpers/responsive'
import PrimaryButton from '../Common/Button/PrimaryButton'
import TwoColumn from '../Common/Columns/TwoColumn'
import Input from '../Common/Input'
import Textarea from '../Common/Input/Textarea'
import Radio from '../Common/Radio/Radio'
import Separator from '../Common/Separator'
import PeriodSetting from './PeriodSetting'
import StepSetting from './StepSetting'
import ConsecutiveDepositBonus from './ConsecutiveDepositBonus'
import NoDepositBetween from './NoDepositBetween'
import Select from '../Common/Select'
import PrefixService from '../../services/prefix'
import { toast } from 'react-toastify'
import 'antd/dist/antd.css';
import { Upload, Button, Switch } from 'antd';
import { LoadingOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';

interface EditBonusFormProps {
	data: PromotionConfig
	dataKey?: string
	loading: boolean

	setValues: (v: PromotionConfig) => void
	setFieldValue: (field: string, v: any) => void
	onChange: (e: ChangeEvent<any>) => void
	onSubmit: (e: FormEvent<any>) => void
}

const EditBonusForm: FC<EditBonusFormProps> = ({
	data,
	dataKey,
	loading,

	setValues,
	setFieldValue,
	onChange,
	onSubmit,
}) => {
	const [category, setCategory] = useState(data.category)
	const [imageUrl, setImageUrl] = useState<string>(data.image)
	const [loadingImage, setLoadingImage] = useState<boolean>(false)
	const [withdrawLimitType, setWithdrawLimitType] = useState(data.withdrawLimitType)
	const handleRadioCatagory = (e: ChangeEvent<any>) => setCategory(e.target.value)
	const handleInputWithdrawLimit = (e: ChangeEvent<any>) => {
		setWithdrawLimitType(e.target.value)
		setFieldValue('withdrawLimitType', e.target.value)
	}
	const gameType = data.gameType ? data.gameType : 'none'


	const getBase64 = (img:any, callback:any) => {
		const reader = new FileReader();
		reader.addEventListener('load', () => callback(reader.result));
		reader.readAsDataURL(img);
	}

	const uploadButton = (
		<div>
		  {loadingImage ? <LoadingOutlined /> : <PlusOutlined />}
		  <div style={{ marginTop: 8 }}>Upload</div>
		</div>
	);

	const uploadPromotionImage = async(response: any) => {
		setLoadingImage(true)
		let formData: FormData = new FormData();
    	formData.append('file', response.file);
    	formData.append('setting', 'bonusImage');

		try {
			const res = await PrefixService.uploadPromotionImage(dataKey ? dataKey : '', formData)
			toast.success(res.message)

			setLoadingImage(false)
			getBase64(response.file, (imageUrl : any) =>
				setImageUrl(imageUrl)
			);
		} catch ({ message }) {
			toast.error(message as string)
			setLoadingImage(false)
		}
	}

	const deleteImage = async () => {
		try {
			await PrefixService.deletePromotionImage(dataKey ? dataKey : '')
			toast.success('ลบรูปภาพสำเร็จ')
			setImageUrl('')
		} catch ({ message }) {
			toast.error(message as string)
		}
	}

	const onChangeSwitch = (checked: boolean) => {
		setFieldValue('enable', checked)
	}

	useEffect(() => {
		if (!data.gameType) {
			setFieldValue('gameType', gameType)
		}
	}, [data, gameType, setFieldValue])

	return (
		<Form onSubmit={onSubmit}>
			<SwitchWraper>
				<Switch checked={data.enable} onChange={onChangeSwitch} /> เปิดใช้งาน
			</SwitchWraper>
			<InputSection>
				<Input
					type="text"
					name="name"
					label="ชื่อโบนัส"
					defaultValue={data.name || ''}
					required
					onChange={onChange}
				/>
			</InputSection>
			<InputSection>
				<DescriptionTextAreaWrapper>
					<Textarea
						name="description"
						label="รายละเอียด"
						defaultValue={data.description}
						required
						onChange={onChange}
					/>
				</DescriptionTextAreaWrapper>
			</InputSection>
			<SeparatorWrapper>
				<Separator color={Colors.linkWater} />
			</SeparatorWrapper>

			<FormTitle>ประเภทโบนัส</FormTitle>
			<InputSection>
				<Select
					name="category"
					items={PromotionLimitTypes.map(({ value, label }) => ({
						label: label,
						value: value,
					}))}
					defaultValue={data.category}
					onChange={handleRadioCatagory}
					required
					style={{ minWidth: '160px' }}
				/>
			</InputSection>
			{category === 'deposit' && (
				<BonusLimitCheckboxWrapper>
					{promotionLimits.map(({ limit, label }) => (
						<BonusLimitCheckboxItemWrapper key={limit}>
							<Radio
								className={'radio-custom'}
								active={limit === data.limit}
								onClick={() => setFieldValue('limit', limit)}
							>
								{label}
							</Radio>
						</BonusLimitCheckboxItemWrapper>
					))}
				</BonusLimitCheckboxWrapper>
			)}
			{category === 'ufa-sport-wl-cashback' && (
				<BonusLimitCheckboxWrapper>
					{PromotionUfaCashbacks.map(({ limit, label }) => (
						<BonusLimitCheckboxItemWrapper key={limit}>
							<Radio
								className={'radio-custom'}
								active={limit === data.limit}
								onClick={() => setFieldValue('limit', limit)}
							>
								{label}
							</Radio>
						</BonusLimitCheckboxItemWrapper>
					))}
				</BonusLimitCheckboxWrapper>
			)}

			<SeparatorWrapper>
				<Separator color={Colors.linkWater} />
			</SeparatorWrapper>

			{/* Amount Type */}
			<InputSection>
				<BounusAmountWrapper>
					<Label>ประเภทจำนวน</Label>
					<BounusAmountCheckboxWrapper>
						{bonusAmountTypes.map(({ type, label }) => (
							<BounusAmountItemWrapper key={type}>
								<Radio
									active={type === data.type}
									onClick={() => setFieldValue('type', type)}
								>
									{label}
								</Radio>
							</BounusAmountItemWrapper>
						))}
					</BounusAmountCheckboxWrapper>
				</BounusAmountWrapper>
			</InputSection>

			{/* Game Type */}
			<InputSection>
				<GameTypeWrapper>
					<Label>ประเภทเกม</Label>
					<GameCheckboxWrapper>
						{bonusGameTypes.map(({ game, label }) => (
							<GameCheckboxItemWrapper key={game}>
								<Radio
									active={game === gameType}
									onClick={() => setFieldValue('gameType', game)}
								>
									{label}
								</Radio>
							</GameCheckboxItemWrapper>
						))}
					</GameCheckboxWrapper>
				</GameTypeWrapper>
			</InputSection>

			{/* Bonus Setting */}
			<TwoColumn margin="24px">
				<InputSection>
					<Input
						type="number"
						name="order"
						label="ลำดับ"
						defaultValue={data.order}
						required
						onChange={onChange}
					/>
				</InputSection>
				<InputSection>
					<Input
						type="number"
						name="amount"
						label="โบนัส"
						defaultValue={data.amount}
						required
						onChange={onChange}
					/>
				</InputSection>
			</TwoColumn>
			<TwoColumn margin="24px">
				<InputSection>
					<Input
						type="number"
						name="minBonus"
						label="โบนัสขั้นต่ำ"
						defaultValue={data.minBonus}
						required
						onChange={onChange}
					/>
				</InputSection>
				<InputSection>
					<Input
						type="number"
						name="maxBonus"
						label="โบนัสสูงสุด"
						defaultValue={data.maxBonus}
						required
						onChange={onChange}
					/>
				</InputSection>
			</TwoColumn>
			<TwoColumn margin="24px">
				<InputSection>
					<Input
						type="amount"
						name="minimum"
						label="ยอดฝากขั้นต่ำ"
						defaultValue={data.minimum}
						required
						onChange={onChange}
					/>
				</InputSection>
				<InputSection>
					<Input
						type="number"
						name="turn"
						label="เทิร์น (เท่า)"
						defaultValue={data.turn}
						required
						onChange={onChange}
					/>
				</InputSection>
			</TwoColumn>
			<TwoColumn margin="24px">
				<InputSection>
					<LabelSelector>ประเภทถอนสูงสุด</LabelSelector>
					<Select
						name="withdrawLimitType"
						items={withdrawLimitTypes.map(({ value, label }) => ({
							label: label,
							value: value,
						}))}
						defaultValue={data.withdrawLimitType}
						onChange={handleInputWithdrawLimit}
						required
						style={{ minWidth: '160px' }}
					/>
				</InputSection>
				<InputSection>
					<Input
						type="number"
						name="withdrawLimit"
						label={withdrawLimitType === 'fixed' ? 'จำนวนถอนสูงสุด' : 'ถอนสูงสุด (เท่า)'}
						defaultValue={data.withdrawLimit}
						required
						onChange={onChange}
					/>
				</InputSection>
			</TwoColumn>

			{/* Step Setting */}
			<InputSection>
				<StepSetting data={data} onChange={onChange} setFieldValue={setFieldValue} />
			</InputSection>

			{/* Period Setting */}
			<InputSection>
				<PeriodSetting data={data} onChange={onChange} setFieldValue={setFieldValue} />
			</InputSection>

			{/* Consecutive Deposit Bonus */}
			<InputSection>
				<ConsecutiveDepositBonus data={data} onChange={onChange} setFieldValue={setFieldValue} />
			</InputSection>
			{/* No Deposit Between */}
			<InputSection>
				<NoDepositBetween data={data} onChange={onChange} setFieldValue={setFieldValue} />
			</InputSection>

			{/* Upload Image */}
			<InputSection>
			<Label style={{ marginBottom: '16px' }}>รูปภาพโปรโมชั่น</Label>
			<Upload
				name="promotion-image"
				listType="picture-card"
				showUploadList={false}
				customRequest={uploadPromotionImage}
			>
				{imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
			</Upload>
			{imageUrl &&
				<Button
					danger
					onClick={deleteImage}
					icon={<DeleteOutlined />}
					size='small'
					style={{
						maxWidth: '120px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						marginBottom: '18px'
					}}
				>
					ลบรูปภาพ
				</Button>
			}
			</InputSection>

			<InputSection>
				<PrimaryButton
					type="submit"
					width="150px"
					loading={loading}
				>
					บันทึกข้อมูล
				</PrimaryButton>
			</InputSection>
		</Form>
	)
}

export default EditBonusForm

const Form = styled.form`
	width: 100%;
`
const InputSection = styled.div`
	margin-bottom: 20px;
	width: 100%;
`
const DescriptionTextAreaWrapper = styled.div`
	height: 126px;
`
const SeparatorWrapper = styled.div`
	margin: 0 0 24px 0;
	width: 100%;
`
const FormTitle = styled.div`
	flex: 1;
	font-size: 24px;
	color: ${Colors.black2};
	font-weight: bold;
	font-family: ${Fonts.primary};
	margin: 0 0 20px 0;
	padding: 0;
`
const Label = styled.div`
	font-size: 16px;
	font-family: ${Fonts.primary};
	color: ${Colors.slateGray};
`
const BonusLimitCheckboxWrapper = styled.div`
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0 -6px 24px;

	@media only screen and (max-width: ${MobileSize}px) {
		flex-direction: column;
		margin: 0 -4px 24px;
	}
`
const BonusLimitCheckboxItemWrapper = styled.div`
	margin: 0 6px 12px;
	width: calc(50% - 12px);

	@media only screen and (max-width: ${MobileSize}px) {
		margin: 0 4px 8px;
		width: calc(100% - 8px);
	}
`

const BounusAmountWrapper = styled.div`
	flex-direction: row;
	justify-content: space-between;
	@media only screen and (max-width: ${MobileSize}px) {
		flex-direction: column;
	}
`
const BounusAmountCheckboxWrapper = styled.div`
	flex-direction: row;
	@media only screen and (max-width: ${MobileSize}px) {
		margin-top: 8px;
	}
`
const BounusAmountItemWrapper = styled.div`
	margin-left: 12px;
	@media only screen and (max-width: ${MobileSize}px) {
		margin-left: 0;
		margin-right: 12px;
	}
`

const GameTypeWrapper = styled.div`
	flex-direction: row;
	justify-content: space-between;
	@media only screen and (max-width: ${MobileSize}px) {
		flex-direction: column;
	}
`
const GameCheckboxWrapper = styled.div`
	flex-direction: row;
	@media only screen and (max-width: ${MobileSize}px) {
		margin-top: 8px;
		flex-wrap: wrap;
	}
`
const GameCheckboxItemWrapper = styled.div`
	margin-left: 12px;
	@media only screen and (max-width: ${MobileSize}px) {
		margin-bottom: 8px;
		margin-left: 0;
		margin-right: 12px;
	}
`

const LabelSelector = styled.div`
	margin-bottom: 8px;
	color: ${Colors.slateGray};
	font-size: 16px;
	font-family: ${Fonts.primary};
`

// const DeleteButton = styled.div`
// 	font-size: 16px;
// 	font-family: ${Fonts.primary};
// 	cursor: pointer;
// `

const SwitchWraper = styled.div`
	display: flex;
	flex-direction: row;

	.ant-switch {
		margin-bottom: 24px;
		margin-right: 12px;
	}

	.ant-switch-checked {
		background-color: #7FD592;
	}
`