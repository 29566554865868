import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router'
import AuthService from '../services/auth'

const Logout = () => {
	const [showChildren, setShowChildren] = useState(false)
	useEffect(() => {
		AuthService.revokeToken()

		setShowChildren(true)
	}, [])

	if (!showChildren) {
		return null
	}
	return <Redirect to="/login" />
}

export default Logout
