import { useFormik } from 'formik'
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { PrefixConfig } from '../../../@types/prefix'
import { MobileSize } from '../../../helpers/responsive'
import PrefixService from '../../../services/prefix'
import { requestAuthInit } from '../../../store/auth/auth.actions'
import PrimaryButton from '../../Common/Button/PrimaryButton'
import Card, { CardTitleWrapper } from '../../Common/Card'
import CheckboxWithContent from '../../Common/Checkbox/CheckboxWithContent'
import TwoColumn from '../../Common/Columns/TwoColumn'
import Input from '../../Common/Input'
import { TitleTable } from '../../Common/Table'
import ContentContainer from '../../Common/ContentContainer'

interface AffiliateSettingProps {
	data: PrefixConfig
}
const AffiliateSetting: FC<AffiliateSettingProps> = ({ data }) => {
	const dispatch = useDispatch()
	const {
		values,
		isSubmitting,
		setFieldValue,
		handleChange,
		handleSubmit,
	} = useFormik({
		initialValues: data.setting.affiliate,
		onSubmit: async (values) => {
			try {
				values.min = parseFloat(values.min.toString())
				values.max = parseFloat(values.max.toString())

				const res = await PrefixService.saveAffiliateConfig(values)

				dispatch(requestAuthInit(false))
				toast.success(res.message)
			} catch ({ message }) {
				toast.error(message as string)
			}
		},
	})

	return (
		<ContentContainer>
			<Card padding="24px 0">
				<CardTitleWrapper>
					<TitleTable>จัดการแนะนำเพื่อน</TitleTable>
				</CardTitleWrapper>
				<CardBodyWrapper>
					<form onSubmit={handleSubmit}>
						<InputSection>
							<TwoColumn margin="24px">
								<Input
									type="text"
									name="amount"
									label="ยอดแนะนำเพื่อน"
									placeholder="0%"
									defaultValue={values.amount}
									onChange={handleChange}
								/>
								<Input
									type="number"
									name="max"
									label="ยอดแนะสูงสุด"
									defaultValue={values.max}
									onChange={handleChange}
								/>
							</TwoColumn>
						</InputSection>
						<InputSection>
							<TwoColumn margin="24px">
								<Input
									type="number"
									name="min"
									label="ยอดฝากขั้นต่ำ"
									defaultValue={values.min}
									onChange={handleChange}
								/>
							</TwoColumn>
						</InputSection>
						<InputSection>
							<CheckboxWithContent
								label="โยกยอดแนะนำเพื่อนเข้าเกม"
								checked={values.withdrawToGame}
								setChecked={(v: boolean) =>
									setFieldValue('withdrawToGame', v)
								}
							>
								<AffiliateTransferTurnWrapper>
									<Input
										type="number"
										name="toGameTurn"
										label="เทิร์น (เท่า)"
										defaultValue={values.toGameTurn}
										onChange={handleChange}
									/>
								</AffiliateTransferTurnWrapper>
							</CheckboxWithContent>
						</InputSection>
						<InputSection>
							<PrimaryButton
								type="submit"
								width="120px"
								loading={isSubmitting}
							>
								บันทึกข้อมูล
							</PrimaryButton>
						</InputSection>
					</form>
				</CardBodyWrapper>
			</Card>
		</ContentContainer>
	)
}

export default AffiliateSetting

const CardBodyWrapper = styled.div`
	padding: 0 32px;
	margin-top: 20px;
	@media only screen and (max-width: ${MobileSize}px) {
		padding: 0 16px;
	}
`
const InputSection = styled.div`
	margin-bottom: 18px;
	flex: 1;
`
const AffiliateTransferTurnWrapper = styled.div`
	padding: 4px 16px 16px 16px;
`
