import React, { FC } from 'react'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { BankConfig } from '../../@types/prefix'
import { Colors } from '../../helpers/color'
import { Fonts } from '../../helpers/font'
import { MobileSize } from '../../helpers/responsive'
import PrefixService from '../../services/prefix'
import { requestAuthInit } from '../../store/auth/auth.actions'
import PrimaryButton from '../Common/Button/PrimaryButton'
import TwoColumn from '../Common/Columns/TwoColumn'
import Input from '../Common/Input'
import Toggle from '../Common/Toggle'

interface EditBankFormProps {
	data: BankConfig

	toggle: () => void
}
const EditBankForm: FC<EditBankFormProps> = ({ data, toggle }) => {
	const dispatch = useDispatch()
	const history = useHistory()

	const {
		values,
		isSubmitting,
		setFieldValue,
		handleChange,
		handleSubmit,
	} = useFormik({
		initialValues: data,
		onSubmit: async (values) => {
			try {
				const res = await PrefixService.saveBankAccount(values)

				dispatch(requestAuthInit(false))
				toast.success(res.message)
				history.push('/bank')
				toggle()
			} catch ({ message }) {
				toast.error(message as string)
			}
		},
	})

	return (
		<EditForm onSubmit={handleSubmit}>
			<MainTitleWrapper>
				<EditBankToggleWrapper>
					<Label>สถานะใช้งานบัญชี</Label>
					<Toggle
						active={values.enable}
						activeLabel="เปิดใช้งาน"
						disableLabel="ปิดใช้งาน"
						setActive={(v) => setFieldValue('enable', v)}
					/>
				</EditBankToggleWrapper>
			</MainTitleWrapper>

			<InputSection>
				<Input
					type="text"
					name="accountNumber"
					label="เลขที่บัญชี"
					defaultValue={values.accountNumber}
					required
					onChange={handleChange}
				/>
			</InputSection>
			<InputSection>
				<Input
					type="text"
					name="accountName"
					label="ชื่อบัญชี"
					defaultValue={values.accountName}
					required
					onChange={handleChange}
				/>
			</InputSection>

			{values.bank === 'scb' && (
				<TwoColumn margin="12px">
					<InputSection>
						<Input
							type="text"
							name="meta.username"
							label="ชื่อผู้ใช้งาน (Easy Net)"
							defaultValue={values.meta.username}
							onChange={handleChange}
						/>
					</InputSection>
					<InputSection>
						<Input
							type="text"
							name="meta.password"
							label="รหัสผ่าน (Easy Net)"
							onChange={handleChange}
						/>
					</InputSection>
				</TwoColumn>
			)}

			{values.bank === 'kbank' && (
				<TwoColumn margin="12px">
					<InputSection>
						<Input
							type="text"
							name="meta.username"
							label="ชื่อผู้ใช้งาน (KBiz)"
							defaultValue={values.meta.username}
							onChange={handleChange}
						/>
					</InputSection>
					<InputSection>
						<Input
							type="text"
							name="meta.password"
							label="รหัสผ่าน (KBiz)"
							onChange={handleChange}
						/>
					</InputSection>
				</TwoColumn>
			)}

			{values.bank === 'bay' && (
				<TwoColumn margin="12px">
					<InputSection>
						<Input
							type="text"
							name="meta.username"
							label="ชื่อผู้ใช้งาน (Krungsri Biz)"
							defaultValue={values.meta.username}
							onChange={handleChange}
						/>
					</InputSection>
					<InputSection>
						<Input
							type="text"
							name="meta.password"
							label="รหัสผ่าน (Krungsri Biz)"
							onChange={handleChange}
						/>
					</InputSection>
				</TwoColumn>
			)}

			{values.apiURL && (
				<InputSection>
					<Input
						label="API ENDPOINT"
						defaultValue={values.apiURL}
						readOnly={true}
					/>
				</InputSection>
			)}

			<ButtonGroup>
				<PrimaryButton
					type="submit"
					width="120px"
					loading={isSubmitting}
				>
					บันทึกข้อมูล
				</PrimaryButton>
			</ButtonGroup>
		</EditForm>
	)
}

export default EditBankForm

const EditForm = styled.form`
	width: 100%;
`
// const FormTitle = styled.div`
// 	flex: 1;
// 	font-size: 24px;
// 	color: ${Colors.black2};
// 	font-weight: bold;
// 	font-family: ${Fonts.primary};
// 	margin: 0 0 20px 0;
// 	padding: 0;
// `
const InputSection = styled.div`
	margin-bottom: 20px;
	width: 100%;
`
const MainTitleWrapper = styled.div`
	flex-direction: row;
	width: 100%;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 24px;
`
const EditBankToggleWrapper = styled.div`
	width: 162px;
`
const ButtonGroup = styled.div`
	width: 100%;
	flex-direction: row;
	justify-content: space-between;
	@media only screen and (max-width: ${MobileSize}px) {
		flex-direction: column-reverse;
	}
`

const Label = styled.div`
	margin-bottom: 8px;
	color: ${Colors.slateGray};
	font-size: 16px;
	font-family: ${Fonts.primary};
`