import { DateRangeType } from './dashboard'

export interface Member {
	id: number
	phone: string
	twID: string
	bankCode: string
	bankNumber: string
	firstName: string
	lastName: string
	firstNameEN: string
	lastNameEN: string

	affiliate: MemberAffiliate
	totalTurn: number
	minWithdraw: number
	maxWithdraw: number

	status: boolean
	createdAt: string
}

export interface GetMemberResponse {
	member: Member
}

export type UpdateMemberInfoParams = Partial<Member>

export const memberHistoryTabs = [
	{
		key: 'deposit',
		text: 'รายการฝาก',
	},
	{
		key: 'withdraw',
		text: 'รายการถอน',
	},
	{
		key: 'other',
		text: 'รายการอื่น ๆ',
	},
]

export interface GetMemberTransactionListParams {
	id: string | number
	dateRange: DateRangeType
	type: string
}

export interface MemberTransaction {
	twID: string
	amount: number
	transferTo: string
	transferFrom: string
	turn: number
	totalTurn: number
	type: string
	remark: string
	status: string
	transferTime: string
	createdAt: string
	updatedAt: string
}

export interface MemberAffiliate {
	balance: number
	id: number
}

export interface MemberBankAccount {
	firstName: string
	lastName: string
}