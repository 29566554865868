import { FC } from 'react'
import styled from 'styled-components'
import { getBankIcon, getBankName } from '../../../helpers/bank'
import { Colors } from '../../../helpers/color'
import { Fonts } from '../../../helpers/font'

interface BankTypeProps {
	bank: string
	bankNumber: string
}
const BankType: FC<BankTypeProps> = ({ bank, bankNumber }) => {
	const currentBankIcon = getBankIcon(bank)

	return (
		<Wrapper>
			<TopSection>
				{currentBankIcon && (
					<PaymentIcon src={currentBankIcon} alt="bank" />
				)}
				<PaymentInformation>
					<Label>{getBankName(bank)}</Label>
					<BankNumberWrapper>
						<BankNumber>{bankNumber}</BankNumber>
					</BankNumberWrapper>
				</PaymentInformation>
			</TopSection>
		</Wrapper>
	)
}

export default BankType

const Wrapper = styled.div``
const TopSection = styled.div`
	flex-direction: row;
	align-items: center;
`
const PaymentIcon = styled.img`
	width: 24px;
	margin-right: 12px;
`
const PaymentInformation = styled.div``
const Label = styled.div`
	font-family: ${Fonts.primary};
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	color: ${Colors.slateGray};
`
const BankNumberWrapper = styled.div`
	margin-top: 0.25rem;
	flex-direction: row;
	align-items: center;
`
const BankNumber = styled.div`
	font-family: ${Fonts.primary};
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	color: ${Colors.casper};
`