import React, { FC, useEffect } from 'react'
import { useFormik } from 'formik'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import PrefixService from '../../services/prefix'
import { toast } from 'react-toastify'
import PrimaryButton from '../Common/Button/PrimaryButton'
import SecondButton from '../Common/Button/SecondButton'
import moment from 'moment'
import AddFixTxForm from './AddFixTxForm'

interface AddFixTxModalProps {
	isOpenModal: boolean
	phone?: any

	update: () => void
	toggleAddFixTxModal: () => void
}

const AddFixTxModal: FC<AddFixTxModalProps> = ({ phone, isOpenModal, toggleAddFixTxModal, update }) => {
	const {
		values,
		isSubmitting,
		setFieldValue,
		resetForm,
		handleChange,
		handleSubmit,
	} = useFormik({
		initialValues: {
			fixType: 'depositMemberBank',
			phone: '',
			amount: 0,
			turn: 0,
			maxWithdraw: 0,
			transferTime: moment().format('DD/MM/YYYY 00:00'),
			remark: '',
		},
		enableReinitialize: true,
		onSubmit: async (values, { resetForm }) => {
			try {
				values.amount = parseFloat(values.amount.toString())
				values.turn = parseFloat(values.turn.toString())
				values.maxWithdraw = parseFloat(values.maxWithdraw.toString())
				const res = await PrefixService.fixTx(values)

				toggleAddFixTxModal()
				resetForm()
				update()
				toast.success(res.message)
			} catch ({ message }) {
				toast.error(message as string)
			}
		},
	})

	useEffect(() => {
		if (phone) {
			resetForm()
			setFieldValue('phone', phone)
		}
	}, [phone, setFieldValue, resetForm]);


	return (
        <Modal isOpen={isOpenModal} className="flex-row modal-dialog-scrollable modal-dialog-centered" fade={true} toggle={toggleAddFixTxModal}>
			<ModalHeader toggle={toggleAddFixTxModal}>
				<strong>แก้ไขรายการผิดพลาด</strong>
			</ModalHeader>
			<ModalBody>
				<AddFixTxForm
					values={values}
					loading={isSubmitting}
					setFieldValue={setFieldValue}
					handleChange={handleChange}
					handleSubmit={handleSubmit}
					isModal={true}
				/>
			</ModalBody>
			<ModalFooter>
				<SecondButton type="button" width="120px" loading={isSubmitting} onClick={toggleAddFixTxModal}>
					ยกเลิก
				</SecondButton>
				<PrimaryButton type="submit" form="creditManagement" width="120px" loading={isSubmitting}>
					บันทึกข้อมูล
				</PrimaryButton>
			</ModalFooter>
        </Modal>
	)
}
export default AddFixTxModal
