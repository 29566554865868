import React, { FC, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { ApplicationState } from '../../../store'
import { Modal } from 'reactstrap'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import PrefixService from '../../../services/prefix'
import EditBonusForm from '../../../components/EditBonus/EditBonusForm'
import { requestAuthInit } from '../../../store/auth/auth.actions'

interface EditBonusModalProps {
    dataKey: string
	isOpenModal: boolean
	toggle: () => void
}

const EditBonusModal: FC<EditBonusModalProps> = ({ dataKey, isOpenModal, toggle }) => {
	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const bonus = prefixState.prefix.promotions[dataKey]
	const dispatch = useDispatch()
	const history = useHistory()

	const { values, isSubmitting, setValues, setFieldValue, handleChange, handleSubmit } =
		useFormik({
			initialValues: bonus,
            enableReinitialize: true,
			onSubmit: async (values) => {
				if (!values.limit) {
					toast.error('กรุณาระบุประเภทโบนัส')
					return false
				}
				if (!values.type) {
					toast.error('กรุณาระบุประเภทจำนวน')
					return false
				}
				if (!values.gameType) {
					toast.error('กรุณาระบุประเภทเกม')
					return false
				}

				values.amount = parseFloat(values.amount.toString())
				values.minimum = parseFloat(values.minimum.toString())
				values.turn = parseFloat(values.turn.toString())
				values.maxWithdraw = parseFloat(values.maxWithdraw.toString())
				values.minBonus = parseFloat(values.minBonus.toString())
				values.maxBonus = parseFloat(values.maxBonus.toString())
				values.consecutiveDepositBonus.consecutiveDepositDays = parseFloat(values.consecutiveDepositBonus.consecutiveDepositDays.toString())
				values.consecutiveDepositBonus.minimumDepositPerDay = parseFloat(values.consecutiveDepositBonus.minimumDepositPerDay.toString())
				values.steps = values.steps.filter((x) => !x.deleted)
				values.periods = values.periods.filter((x) => !x.deleted)

				try {
					const res = await PrefixService.savePromotionConfig(dataKey, values)

					dispatch(requestAuthInit(false))
					toast.success(res.message)
					toggle()
					history.push('/promotions')
				} catch ({ message }) {
					toast.error(message as string)
				}
			},
		})

	return (
        <Modal isOpen={isOpenModal} className="flex-row modal-dialog-centered" fade={true} toggle={() => toggle()}>
            <div className="modal-body p-lg-4">
                <div className="d-flex flex-row justify-content-between align-items-center mb-4">
                    <h3 className="mb-0"><strong>แก้ไขโบนัส</strong></h3>
                    <div className="modal-close">
                        <i className="far fa-times" onClick={() => toggle()}></i>
                    </div>
                </div>
                { values && (
                    <EditBonusForm
						dataKey={dataKey}
                        data={values}
                        loading={isSubmitting}
                        setValues={setValues}
                        setFieldValue={setFieldValue}
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                    />
                )}
            </div>
        </Modal>
	)
}
export default EditBonusModal