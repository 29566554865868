import { FC } from 'react'
import styled from 'styled-components'
import { Colors } from '../../../helpers/color'
import { Fonts } from '../../../helpers/font'
import { MobileSize } from '../../../helpers/responsive'

interface Tab {
	key: string
	text: string
}

interface TabsProps {
	tabs: Tab[]
	activeTab: string

	selectTab: (tab: string) => void
}

const Tabs: FC<TabsProps> = ({ tabs, activeTab, selectTab }) => (
	<>
		{tabs.map((tab) => (
			<TabStyled
				key={tab.key}
				active={tab.key === activeTab}
				onClick={() => selectTab(tab.key)}>
				{tab.text}
			</TabStyled>
		))}
	</>
)

export default Tabs

interface TabStyledProps {
	active: boolean
}
const TabStyled = styled.div<TabStyledProps>`
	cursor: pointer;
	color: ${Colors.regentGray};
	font-weight: 600;
	font-size: 16px;
	line-height: 21px;
	font-family: ${Fonts.primary};
	margin-right: 30px;
	/* justify-content: center; */
	text-align: center;
	align-items: center;
	padding: 0 18px;
	@media only screen and (max-width: ${MobileSize}px) {
		margin-right: 4px;
	}
	&:after {
		${({ active }) => active && 'content: "";'}
		height: 3px;
		width: 100%;
		bottom: 0;
		background: ${Colors.dodgerBlue};
		margin-top: 16px;
		padding: 0 18px;
		z-index: 3;
		@media only screen and (max-width: ${MobileSize}px) {
			padding: 0 8px;
		}
	}
`
