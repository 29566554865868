import { FC } from 'react'
import styled from 'styled-components'
import { Colors } from '../../../helpers/color'
import CheckboxItem from './CheckboxItem'

interface CheckboxWithContentProps {
	label: string
	checked: boolean
	padding?: string

	setChecked: (v: boolean) => void
}
const CheckboxWithContent: FC<CheckboxWithContentProps> = ({
	children,

	label,
	checked,
	padding,

	setChecked,
}) => (
	<Wrapper>
		<TopSection>
			<CheckboxItem
				label={label}
				checked={checked}
				padding={padding}
				onChange={(v: boolean) => setChecked(v)}
			/>
		</TopSection>
		{checked && <BottomSection>{children}</BottomSection>}
	</Wrapper>
)

export default CheckboxWithContent

const Wrapper = styled.div`
	background: ${Colors.linkWater};
	border-radius: 10px;
	padding: 1px;
`
const TopSection = styled.div`
	background: ${Colors.zircon2};
	padding: 14px 16px;
	border-radius: 10px;
`
const BottomSection = styled.div`
	margin-top: 16px;
`
