import { FC, InputHTMLAttributes } from 'react'
import styled from 'styled-components'
import { Colors } from '../../../helpers/color'
import { Fonts } from '../../../helpers/font'

export interface InputProps {
	label?: string
	symbol?: string
}
const Input: FC<InputProps & InputHTMLAttributes<HTMLInputElement>> = ({
	label,
	placeholder,
	width,
	symbol,
	...rest
}) => (
	<div>
		{label && <Label>{label}</Label>}
		<InputWrapper block={rest.readOnly || rest.disabled || false}>
			<InputElement {...rest} placeholder={placeholder ? placeholder : label} />
			{symbol && (
				<SymbolWrapper>
					{/* <img src={symbol} alt={symbol} /> */}
					{symbol}
				</SymbolWrapper>
			)}
		</InputWrapper>
	</div>
)

export default Input

interface InputWrapperStyledProps {
	block: boolean
}
const InputWrapper = styled.div<InputWrapperStyledProps>`
	width: 100%;
	height: 100%;
	max-height: 39px;
	border-radius: 8px;
	background: ${({ block }) => (block ? Colors.linkWater2 : Colors.zircon2)};
	border: 2px solid ${Colors.linkWater};
	align-items: center;
	flex-direction: row;

	&:focus-within {
		border-color: ${Colors.dodgerBlue};
	}
`

const InputElement = styled.input`
	width: 100%;
	height: 100%;
	padding: 12px 12px 12px 12px;
	font-size: 14px;
	color: ${Colors.slateGray};
	font-weight: 500;
	font-family: ${Fonts.primary};
	border: none;
	background: none;
	outline: none;
	display: flex;
	align-items: center;
	flex-direction: row;

	::placeholder {
		color: ${Colors.casper};
	}
`

const SymbolWrapper = styled.div`
	width: 40px;
	height: 100%;
	border-left: 2px solid ${Colors.linkWater};
	position: absolute;
	right: 0px;
	top: 0px;
	align-items: center;
	justify-content: center;
	font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #adb5bd;
    text-align: center;
    white-space: nowrap;
	-webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
	-moz-user-select: none;
    -ms-user-select: none;
	user-select: none;
`

const Label = styled.div`
	margin-bottom: 8px;
	color: ${Colors.slateGray};
	font-size: 16px;
	font-family: ${Fonts.primary};
`
