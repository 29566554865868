import React, { FC } from 'react'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { Modal } from 'reactstrap'
import PrimaryButton from '../../components/Common/Button/PrimaryButton'
import Input from '../../components/Common/Input'
import Select from '../../components/Common/Select'
import PrefixService from '../../services/prefix'
import { requestAuthInit } from '../../store/auth/auth.actions'

interface CreateAccountProps {
	isOpenModal: boolean
	toggleCreateAccount: () => void
}

export interface AddBankParams {
	accountName: string
	accountNumber: string
	bank: 'scb'
}

const CreateAccount: FC<CreateAccountProps> = ({ isOpenModal, toggleCreateAccount }) => {
	const dispatch = useDispatch()
	const history = useHistory()
	const { values, isSubmitting, handleChange, handleSubmit } = useFormik<AddBankParams>({
		initialValues: {
			accountName: '',
			accountNumber: '',
			bank: 'scb',
		},
		onSubmit: async (values) => {
			try {
				const res = await PrefixService.addBankAccount(values)

				dispatch(requestAuthInit(false))
				toast.success(res.message)
				history.push('/bank')
				toggleCreateAccount()
			} catch ({ message }) {
				toast.error(message as string)
			}
		},
	})

	return (
        <Modal isOpen={isOpenModal} className="flex-row modal-dialog-centered" fade={true} toggle={() => toggleCreateAccount()}>
            <div className="modal-body p-lg-4">
                <div className="d-flex flex-row justify-content-between align-items-center mb-4">
                    <h3 className="mb-0"><strong>เพิ่มบัญชี</strong></h3>
                    <div className="modal-close">
                        <i className="far fa-times" onClick={() => toggleCreateAccount()}></i>
                    </div>
                </div>

                <AddBankForm autoComplete="off" onSubmit={handleSubmit}>
					<InputSection>
						<Input
							type="text"
							name="accountName"
							label="ชื่อบัญชี"
							required
							onChange={handleChange}
						/>
					</InputSection>
					<InputSection>
						<Input
							type="text"
							name="accountNumber"
							label="เลขที่บัญชี"
							required
							onChange={handleChange}
						/>
					</InputSection>
					<InputSection>
						<Select
							items={[
								{
									label: 'ธนาคารไทยพาณิชย์',
									value: 'scb',
								},
								{
									label: 'ธนาคารกสิกรไทย',
									value: 'kbank',
								},
								{
									label: 'ธนาคารกรุงศรีอยุธยา',
									value: 'bay',
								},
							]}
							name="bank"
							label="ธนาคาร"
							defaultValue={values.bank}
							required
							onChange={handleChange}
						/>
					</InputSection>
					<InputSection>
						<PrimaryButton type="submit" loading={isSubmitting}>
							เพิ่มบัญชี
						</PrimaryButton>
					</InputSection>
				</AddBankForm>
            </div>
        </Modal>
	)
}
export default CreateAccount

const AddBankForm = styled.form`
	width: 100%;
`

const InputSection = styled.div`
	margin-bottom: 20px;
	width: 100%;
`
