import React, { FC, TextareaHTMLAttributes } from 'react'
import styled from 'styled-components'
import { Colors } from '../../../helpers/color'
import { Fonts } from '../../../helpers/font'

interface TextareaProps {
	label?: string
}
const Textarea: FC<
	TextareaProps & TextareaHTMLAttributes<HTMLTextAreaElement>
> = ({ label, ...rest }) => (
	<Wrapper>
		{label && <Label>{label}</Label>}
		<TextAreaElement {...rest} />
	</Wrapper>
)

export default Textarea

const TextAreaElement = styled.textarea`
	border-radius: 8px;
	background: ${Colors.zircon2};
	border: 2px solid ${Colors.linkWater};
	width: 100%;
	height: 100%;
	flex: 0 0 auto;
	resize: none;
	padding: 12px 12px 12px 12px;
	font-size: 14px;
	color: ${Colors.black2};
	font-weight: 500;
	font-family: ${Fonts.primary};
	display: flex;
	align-items: center;
	flex-direction: row;
	::placeholder {
		color: ${Colors.casper};
	}
`

const Label = styled.div`
	margin-bottom: 8px;
	color: ${Colors.slateGray};
	font-size: 16px;
	font-family: ${Fonts.primary};
`

const Wrapper = styled.div`
	width: 100%;
`
