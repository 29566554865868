export interface PrefixConfig {
	banks: Record<string, BankConfig>
	scbAccounts: Record<string, SCBAccountConfig>

	promotions: Record<string, PromotionConfig>
	setting: {
		title: string
		turnType: string
		gameLink: string
		lineLink: string
		lineToken: string
		minCredit: number
		minWithdraw: number
		functions: Record<PrefixFunction, boolean>

		cashback: CashbackConfig
		affiliate: AffiliateConfig
		wheel: WheelConfig
		withdrawAccount: WithdrawAccountConfig
		centralAccount: CentralAccountConfig
	}
}

export interface SCBAccountConfig {
	enable: boolean
	accountName: string
	accountNumber: string
	easynet: {
		username: string
		password: string
	}
}

export interface CentralAccountConfig {
	minAmount: number
	accountNumber: string
	bankCode: string
}

export interface WithdrawAccountConfig {
	type: string
	accountNumber: string
	maxAmount: number
	mobileNo: string
}

export interface BankConfig {
	enable: boolean
	accountName: string
	accountNumber: string
	bank: string
	apiURL: string
	meta: { [key: string]: string }
}

export type CashbackRule = 'minCredit' | 'noBonus' | 'noWheel' | 'noWithdraw' | 'oncePerDay'
export interface CashbackConfig {
	enable: boolean
	amount: string
	min: number
	maxAmount: number
	turn: number
	rules: Record<CashbackRule, boolean>
}

export interface AffiliateConfig {
	amount: string
	min: number
	max: number
	toGameTurn: number
	withdrawToGame: boolean
}

export type RewardRule = 'minCredit' | 'noBonus' | 'noWithdraw'
export interface RewardType {
	name: string
	amount: number
	chance: number
	index: number
}
export interface WheelConfig {
	enable: boolean
	minDeposit: number
	depositType: string
	rewardLimit: number
	rewardTurn: number
	rewardType: string
	rewards: Record<string, RewardType>
	rules: Record<RewardRule, boolean>
}

export interface PromotionLimitType {
	value: string
	label: string
}

export const PromotionLimitTypes: PromotionLimitType[] = [
	{
		value: 'deposit',
		label: 'โบนัสทั่วไป',
	},
	{
		value: 'ufa-sport-wl-cashback',
		label: '[UFA] คืนยอดเสียกีฬา',
	},
]


export interface PromotionLimit {
	limit: string
	label: string
}

export const promotionLimits: PromotionLimit[] = [
	{
		limit: 'newReg',
		label: 'สมาชิกใหม่',
	},
	{
		limit: 'dailyFirstDeposit',
		label: 'ฝากแรกของวัน',
	},
	{
		limit: 'allDeposit',
		label: 'ทุกยอดฝาก',
	},
	{
		limit: 'once',
		label: 'ครั้งเดียว',
	},
]

export interface PromotionUfaCashback {
	limit: string
	label: string
}

export const PromotionUfaCashbacks: PromotionUfaCashback[] = [
	{
		limit: 'every-day',
		label: 'รับได้ทุกวัน',
	},
	{
		limit: 'every-week',
		label: 'รับได้ทุกสัปดาห์',
	},
]

export interface withdrawLimitType {
	value: string
	label: string
}

export const withdrawLimitTypes: withdrawLimitType[] = [
	{
		value: 'fixed',
		label: 'จำนวน',
	},
	{
		value: 'sum',
		label: 'ยอดโบนัส (เท่า)',
	},
]

export interface BonusAmountType {
	type: string
	label: string
}
export const bonusAmountTypes: BonusAmountType[] = [
	{
		type: 'amount',
		label: 'จำนวน',
	},
	{
		type: 'percent',
		label: 'เปอร์เซ็นต์',
	},
]

export interface BonusGameType {
	game: string
	label: string
}
export const bonusGameTypes: BonusGameType[] = [
	{
		game: 'slot',
		label: 'สล็อต',
	},
	{
		game: 'casino',
		label: 'คาสิโน',
	},
	{
		game: 'none',
		label: 'ไม่ระบุ',
	},
]

export interface PromotionPeriod {
	from: string
	to: string

	deleted: boolean
}
export interface PromotionStep {
	min: number
	max: number
	bonus: number

	deleted?: boolean
}
export interface PromotionConsecutiveDepositBonus {
	consecutiveDepositDays: number
	enable: boolean
	minimumDepositPerDay: number
	rules: {
		noBonus: boolean
		noCashback: boolean
		noLuckyWheel: boolean
		noWithdraw: boolean
	}
}
export interface PromotionNoDepositBetween {
	enable: boolean
	startDate: string
	endDate: string
	rules: {
		noBonus: boolean
		noCashback: boolean
		noLuckyWheel: boolean
		noWithdraw: boolean
	}
}
export interface PromotionConfig {
	enable: boolean
	name: string
	description: string
	limit: string
	image: string
	gameType: string
	category: string
	order: number

	type: string
	amount: number
	turn: number
	minimum: number // min deposit
	minBonus: number
	maxBonus: number
	maxWithdraw: number
	withdrawLimit: number
	withdrawLimitType: string

	period: boolean
	periods: PromotionPeriod[]

	step: boolean
	steps: PromotionStep[]

	consecutiveDepositBonus: PromotionConsecutiveDepositBonus
	noDepositBetween: PromotionNoDepositBetween
}
export interface PromotionConfigAdd {
	name: string
	description: string
}

export type PrefixFunction = 'checkSlip' | 'depositToGame' | 'withdrawFromGame'

export const promotionTabs = [
	{
		key: 'bonus',
		text: 'โบนัส',
	},
	{
		key: 'new-reg-bonus',
		text: 'แจกเครดิต',
	},
	{
		key: 'wheel',
		text: 'กงล้อเสี่ยงโชค',
	},
	{
		key: 'redeem',
		text: 'โค้ดโปรโมชั่น',
	},
	{
		key: 'cashback',
		text: 'คืนยอดเสีย',
	},
	{
		key: 'affiliate',
		text: 'แนะนำเพื่อน',
	},
]

export interface NewRegBonusConfig {
	amount: number
	turn: number
	maxWithdraw: number
}
export interface RedeemConfig {
	ID: number
	Code: string
	Amount: number
	Limit: number
	Used: number
	Turn: number
	MaxWithdraw: number
	CreatedAt: string
	UpdatedAt: string
}
export interface RedeemAddConfig {
	code: string
	amount: number
	limit: number
	turn: number
	maxWithdraw: number
}
export interface settingPageConfig {
	isCasino: boolean
	setting: {
		version: string
		title: string
		game: string
		agent: string
		gameMeta: {
			baseURL: string
			clientName: string
			hash: string
			key: string
		}
		turnType: string
		logo: string
		gameLink: string
		lineLink: string
		linkDownloadIOS: string
		linkDownloadAndroid: string
		lineToken: string
		minCredit: number
		minWithdraw: number
		mainDeposit: string
		// functions: Record<PrefixFunction, boolean>

		cashback: CashbackConfig
		// affiliate: AffiliateConfig
		// wheel: WheelConfig
		// withdrawAccount: WithdrawAccountConfig
		// centralAccount: CentralAccountConfig
		ambPayment: ambPaymentConfig
		ambbetGames: ambGamesConfig[]
	}
}

export interface settingWebConfig {
	version: string
	title: string
	game: string
	agent: string
	gameMeta: {
		baseURL: string
		clientName: string
		hash: string
		key: string
	}
	turnType: string
	logo: string
	gameLink: string
	lineLink: string
	linkDownloadIOS: string
	linkDownloadAndroid: string
	lineToken: string
	minCredit: number
	minWithdraw: number
	mainDeposit: string
	// functions: Record<PrefixFunction, boolean>

	cashback: CashbackConfig
	// affiliate: AffiliateConfig
	// wheel: WheelConfig
	// withdrawAccount: WithdrawAccountConfig
	// centralAccount: CentralAccountConfig
	ambPayment: ambPaymentConfig
	ambbetGames: ambGamesConfig[]
}
export interface ambPaymentConfig {
	username: string
	secret: string
}
export interface ambGamesConfigEdit {
	games: ambGamesConfig[]
}

export interface ambGamesConfig {
	enable: boolean
	game: string
	label: string
}
export interface usersConfig {
	id: number
	PermissionBuff: object
	createdAt: string
	permissions: Record<string, boolean>
	twoFactorSecret: string
	updatedAt: string
	username: string
	password: string
}

export interface usersAddConfig {
	permissions: Record<string, boolean>
	username: string
	password: string
}
export interface usersEditConfig {
	permissions: Record<string, boolean>
	password?: string
}
