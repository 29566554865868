import React, { ChangeEvent, FC } from 'react'
import Input from '../Common/Input'
import ConfirmModal from '../Common/Modal/ConfirmModal'

interface ConfirmTruewalletOTPModalProps {
	loading: boolean
	otpRef: string

	onChange: (e: ChangeEvent<any>) => void
	onClose: () => void
	onSubmit: () => void
}
const ConfirmTruewalletOTPModal: FC<ConfirmTruewalletOTPModalProps> = ({
	loading,
	otpRef,
	onChange,
	onClose,
	onSubmit,
}) => (
	<ConfirmModal
		title="ยืนยันบัญชีทรูมันนี่วอลเล็ท"
		loading={loading}
		onClose={onClose}
		onSubmit={onSubmit}
	>
		<Input
			type="text"
			name="otpCode"
			label={`OTP (Ref. ${otpRef})`}
			onChange={onChange}
		/>
	</ConfirmModal>
)

export default ConfirmTruewalletOTPModal
