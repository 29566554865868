import React from 'react'
import useSWR from 'swr'
import PrefixService from '../../services/prefix'
import styled from 'styled-components'
import moment from 'moment'
import Card, { CardTitle } from '../Common/Card'
import { Colors } from '../../helpers/color'
import { format } from '../../helpers/number'
import { WithdrawTransaction } from '../../@types/transactions'

const WithDrawTransactionLastest = () => {
	const { data } = useSWR(
		`/withdraw`,
		() =>
			PrefixService.getTransactionList<WithdrawTransaction>({
				dateRange: {
					start: moment().add(-1, 'days').format('YYYY-MM-DD'),
					end: moment().format('YYYY-MM-DD')
				},
				type: 'withdraw',
				status: 'pending',
				limit: 20
			}),
		{
			refreshInterval: 10000,
		}
	)

	return (
		<Card mobilePadding="16px">
			<CardTitle>รายการถอน</CardTitle>
			<div className="mt-3">
				{ data !== undefined
					?
						data?.count !== 0
						?
							Object.values(data?.transactions as any).map((item:any, index: number) => (
								<Card padding="12px 16px" mobilePadding="16px" marginBottom="12px" border="1px" key={index}>
									<Row>
										<div className="d-flex flex-row justify-content-between">
											<div>
												<strong>{item.member.firstName} {item.member.lastName}</strong>
												<small>{item.member.game.username}</small>
											</div>
											<div className="text-end">
												<strong><span>฿</span> {format(item.amount, 2)}</strong>
												<small style={{ color: '#f09b38' }} >รอดำเนินการ</small>
											</div>
										</div>
										<div className="pt-2 mt-2" style={{ borderTop: '1px solid #eee', alignItems: 'flex-start'}}>
											<small>ธนาคารที่โอนเข้ามา : {item.transferFrom}</small>
											<small>ทำรายการโอน : {moment(item?.createdAt).format('DD/MM/YYYY HH:mm:ss')}</small>
											<small className="mb-2">ดึงเข้าระบบ : {moment(item?.transferTime).format('DD/MM/YYYY HH:mm:ss')}</small>
										</div>
									</Row>
								</Card>
							))
						:
							<div style={{ color: '#95a0b8' }}>ไม่มีข้อมูล</div>
					:
						<Loading>
							<i className="fad fa-spinner-third fa-spin fa-2x" />
						</Loading>
				}
			</div>
		</Card>
	)
}

export default WithDrawTransactionLastest

const Loading = styled.div`
	width: 100%;
	text-align: center;
	color: ${Colors.anakiwa};
	margin-top: 2.5rem;
`

const Row = styled.div`
	font-family: Sarabun, sans-serif;
	font-style: normal;

	strong {
		color: #68748d;
	}

	small, span {
		color: #95a0b8;
	}
`