export type StaticDateRange = 'today' | 'yesterday' | 'this_week' | 'this_month'

interface RangeType {
	key: StaticDateRange
	text: string
}
export const dateRanges: RangeType[] = [
	{
		key: 'today',
		text: 'วันนี้',
	},
	{
		key: 'yesterday',
		text: 'เมื่อวาน',
	},
	{
		key: 'this_week',
		text: 'อาทิตย์นี้',
	},
	{
		key: 'this_month',
		text: 'เดือนนี้',
	},
]

export const dashboardTabs = [
	{
		key: 'overview',
		text: 'สรุปข้อมูล',
	},
	{
		key: 'bonus',
		text: 'โบนัส',
	},
	{
		key: 'cashback',
		text: 'แคชแบ็ก',
	},
	{
		key: 'wheel',
		text: 'กงล้อเสี่ยงโชค',
	},
	{
		key: 'affiliate',
		text: 'แนะนำเพื่อน',
	},
	{
		key: 'redeem',
		text: 'โค๊ดโปรโมชั่น',
	},
	{
		key: 'report-by-member',
		text: 'ฝากถอนรายบุคคล',
	},
]

export interface DateRangeType {
	start: string
	end: string
}
