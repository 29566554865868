import React, { FC } from 'react'
import { Modal } from 'reactstrap'
import styled from 'styled-components'
import PrimaryButton from '../Common/Button/PrimaryButton'
import SecondButton from '../Common/Button/SecondButton'

interface Close2FAProps {
	isOpenModal?: boolean
	onSubmit: () => void
	toggleModal: () => void
}

const Close2FA: FC<Close2FAProps> = ({ onSubmit, isOpenModal, toggleModal }) => {
	return (
        <Modal isOpen={isOpenModal} className="flex-row modal-dialog-centered modal-sm" fade={true} toggle={() => toggleModal()}>
            <div className="modal-body p-lg-4">
				<ConfirmBody>
					<h1>คุณต้องการปิดการใช้งาน 2FA?</h1>
					<p>ตรวจสอบให้แน่ใจว่า<br />คุณต้องการปิดใช้งาน 2FA (Two Factor Authentication) ดังกล่าว</p>
					<div className='mb-2'>
						<PrimaryButton
							type='button'
							onClick={() => onSubmit()}
						>
							ยืนยัน
						</PrimaryButton>
					</div>
					<SecondButton
						type='button'
						onClick={() => toggleModal()}
					>
						ยกเลิก
					</SecondButton>
				</ConfirmBody>
            </div>
        </Modal>
	)
}
export default Close2FA

const ConfirmBody = styled.div`
	text-align: center;

	h1 {
		font-size: 24px;
		font-weight: bold;
		margin-bottom: 12px
	}
`