import React, { FC, InputHTMLAttributes } from 'react'
import styled from 'styled-components'
import searchIcon from '../../../assets/images/search-icon.svg'
import { Colors } from '../../../helpers/color'
import { Fonts } from '../../../helpers/font'
import { MobileSize } from '../../../helpers/responsive'

interface SearchBarProps {
	width?: string
}
const SearchBar: FC<SearchBarProps & InputHTMLAttributes<HTMLInputElement>> = ({
	width,
	...rest
}) => (
	<SearchWrapper width={width}>
		<SearchIcon src={searchIcon} alt="search" />
		<SearchInput {...rest} />
	</SearchWrapper>
)

export default SearchBar

interface SearchWrapperStyledProps {
	width?: string
}
const SearchWrapper = styled.div<SearchWrapperStyledProps>`
	flex-direction: row;
	align-items: center;
	background: ${Colors.zircon2};
	border: 2px solid ${Colors.linkWater2};
	width: ${({ width }) => (width ? width : '100%')};
	height: 39px;
	padding: 0 12px;
	border-radius: 8px;
	@media only screen and (max-width: ${MobileSize}px) {
		width: 100%;
	}

	&:focus-within {
		border-color: ${Colors.dodgerBlue};
	}
`

const SearchIcon = styled.img`
	z-index: 1;
	position: absoulte;
	left: 0;
`
const SearchInput = styled.input`
	width: 100%;
	flex: 1;
	z-index: 2;
	background: none;
	border: none;
	outline: none;
	font-size: 13px;
	font-family: ${Fonts.primary};
	color: ${Colors.black};
	margin-left: 12px;
	padding-right: 12px;
	::placeholder {
		color: ${Colors.casper};
	}
`
