import { FC, SelectHTMLAttributes } from 'react'
import styled from 'styled-components'
import selectIcon from '../../../assets/images/select-down-arrow.svg'
import { Colors } from '../../../helpers/color'
import { Fonts } from '../../../helpers/font'
import { MobileSize } from '../../../helpers/responsive'

export interface OptionType {
	label: string
	value: string
}
interface SelectProps {
	label?: string
	width?: string
	items: OptionType[]
}
const Select: FC<SelectProps & SelectHTMLAttributes<HTMLSelectElement>> = ({
	label,
	width,
	items,
	placeholder,
	...rest
}) => (
	<Wrapper>
		{label && <Label>{label}</Label>}
		<SelectWrapper width={width}>
			<SelectElement {...rest}>
				{placeholder && <Option defaultChecked>{placeholder}</Option>}
				{items.map((item) => (
					<Option value={item.value} key={item.value}>
						{item.label}
					</Option>
				))}
			</SelectElement>
			<SelectIconWrapper>
				<SelectIcon src={selectIcon} alt="select" />
			</SelectIconWrapper>
		</SelectWrapper>
	</Wrapper>
)

export default Select

interface SelectWrapperStyledProps {
	width?: string
}
const SelectWrapper = styled.div<SelectWrapperStyledProps>`
	flex-direction: row;
	align-items: center;
	background: ${Colors.zircon2};
	border: 2px solid ${Colors.linkWater2};
	width: ${({ width }) => (width ? width : '100%')};
	height: 39px;
	padding: 0 0 0 12px;
	border-radius: 8px;
	@media only screen and (max-width: ${MobileSize}px) {
		width: 100%;
	}

	&:focus-within {
		border-color: ${Colors.dodgerBlue};
	}
`
const SelectIconWrapper = styled.div`
	width: 40px;
	flex: 0 0 auto;
	height: 100%;
	border-left: 2px solid ${Colors.linkWater2};
	border-radius: 0px 8px 8px 0px;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: 0;
	z-index: 1;
`

const SelectIcon = styled.img``

const SelectElement = styled.select`
	flex: 1;
	font-weight: bold;
	font-size: 13px;
	color: ${Colors.slateGray};
	border: none;
	outline: none;
	z-index: 2;
	background: none;
	width: 100%;
`

const Option = styled.option``

const Label = styled.div`
	margin-bottom: 8px;
	color: ${Colors.slateGray};
	font-size: 16px;
	font-family: ${Fonts.primary};
	width: 100%;
`

const Wrapper = styled.div``
