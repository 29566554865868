import React from 'react'
import { BonusSummary } from '../../../api/summary/types'
import useDateRange from '../../../hooks/useDateRange'
import useFetchSummary from '../../../hooks/useFetchSummary'
import ContentContainer from '../../Common/ContentContainer'
import DateRange from '../../Common/DateRange'
import TabDataLoading from '../TabDataLoading'
import BonusTabContainer from './BonusTabContainer'

const BonusTab = () => {
	const { dateRange, setDateRange } = useDateRange()
	const { data } = useFetchSummary<BonusSummary>('promotion', dateRange)

	return (
		<ContentContainer>
			<DateRange onChange={setDateRange} />

			{!data ? <TabDataLoading /> : <BonusTabContainer data={data} />}
		</ContentContainer>
	)
}

export default BonusTab
