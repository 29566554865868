import moment from 'moment'
import React, { FC } from 'react'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import { WithdrawTransaction } from '../../../@types/transactions'
import reloadIcon from '../../../assets/images/reload-icon.svg'
import { Colors } from '../../../helpers/color'
import { Fonts } from '../../../helpers/font'
import { getFullName } from '../../../helpers/member'
import { format } from '../../../helpers/number'
import PrefixService from '../../../services/prefix'
import BankType from '../../Common/BankType/BankType'
import MoneyWithCurrency from '../../Common/Money/MoneyWithCurrency'
import PhoneNumberWithIcon from '../../Common/PhoneNumberWithIcon/PhoneNumberWithIcon'
import { BodyTR, Td, Th, Time, TimeDate, TimeWrapper } from '../../Common/Table'
import EnhancedTable from '../../Common/Table/EnhancedTable'
import TxStatusBadge from '../../TxStatusBadge'
import { WithdrawActionModal } from './WithdrawListTableContainer'

interface WithdrawActionsProps {
	tx: WithdrawTransaction

	setActionModal: (v: WithdrawActionModal) => void
}
const WithdrawActions: FC<WithdrawActionsProps> = ({ tx, setActionModal }) => (
	<StatusActionGroup>
		<StatusActionItem
			color={Colors.dodgerBlue}
			onClick={() => setActionModal({ type: 'confirm', tx: tx })}
		>
			ปรับสถานะ
		</StatusActionItem>
		<StatusActionItem
			color={Colors.positive}
			onClick={() => setActionModal({ type: 'transfer', tx: tx })}
		>
			ยืนยัน
		</StatusActionItem>
		<StatusActionItem
			color={Colors.negative}
			onClick={() => setActionModal({ type: 'reject', tx: tx })}
		>
			ยกเลิก
		</StatusActionItem>
	</StatusActionGroup>
)

interface WithdrawSlipActionsProps {
	tx: WithdrawTransaction
}
export const WithdrawSlipActions: FC<WithdrawSlipActionsProps> = ({ tx }) => (
	<StatusActionGroup>
		<StatusActionItem
			color={Colors.dodgerBlue}
			onClick={() => {
				if (tx.withdraw.qrString) {
					window.open(
						`https://chart.apis.google.com/chart?cht=qr&chs=256x256&chl=${tx.withdraw.qrString}`,
						'_blank'
					)
				} else {
					window.open(
						`https://msn.cdnbet.co/withdraw/${PrefixService.getPrefix()}/${
							tx.withdraw.transactionID
						}.png`,
						'_blank'
					)
				}
			}}
		>
			ตรวจสอบการถอน
		</StatusActionItem>
	</StatusActionGroup>
)

interface WithdrawListTableProps {
	loading: boolean
	data: WithdrawTransaction[]

	setActionModal: (v: WithdrawActionModal) => void
}
const WithdrawListTable: FC<WithdrawListTableProps> = ({ loading, data, setActionModal }) => (
	<EnhancedTable
		loading={loading}
		data={data}
		theadSize={5}
		thead={
			<tr>
				<Th style={{ textAlign: 'left' }}>เวลา</Th>
				<Th style={{ textAlign: 'left' }}>สมาชิก</Th>
				<Th style={{ textAlign: 'left' }}>ไปยัง</Th>
				<Th style={{ textAlign: 'center' }}>จำนวนเงิน</Th>
				<Th style={{ textAlign: 'center' }}>สถานะ</Th>
			</tr>
		}
	>
		{(row: WithdrawTransaction) => (
			<BodyTR key={`withdraw.${row.id}`}>
				<Td>
					<TimeWrapper>
						<TimeDate>{moment(row.transferTime).format('DD/MM/YYYY')}</TimeDate>
						<Time>{moment(row.transferTime).format('HH:mm:ss')}</Time>
					</TimeWrapper>
				</Td>
				<Td>
					<b>{getFullName(row.member)}</b>
					<br />
					<PhoneNumberWithIcon phoneNumber={row.member.phone} />
				</Td>
				<Td>
					<BankType bank={row.transferTo} bankNumber={row.member.bankNumber} />{' '}
					{row.remark && (
						<BottomSection>
							<Remark>{row.remark}</Remark>
						</BottomSection>
					)}
				</Td>
				<Td>
					<MoneyWithCurrency
						value={format(row.amount, 2)}
						fontSize="16px"
						position="center"
					/>
				</Td>
				<Td>
					<StatusWrapper>
						<TxStatusBadge status={row.status} />

						{row.status === 'success' && row.withdraw.transactionID && (
							<WithdrawSlipActions tx={row} />
						)}
						{row.status === 'pending' && (
							<WithdrawActions tx={row} setActionModal={setActionModal} />
						)}

						<ReloadDataButton
							src={reloadIcon}
							alt="redo"
							onClick={() =>
								setActionModal({
									type: 'redo',
									tx: row,
								})
							}
							data-tip
							data-for={`re_withdraw_${row.id}`}
						/>
					</StatusWrapper>
					<ReactTooltip id={`re_withdraw_${row.id}`} effect="solid">
						<span>ทำรายการถอนใหม่</span>
					</ReactTooltip>
				</Td>
			</BodyTR>
		)}
	</EnhancedTable>
)

export default WithdrawListTable

const BottomSection = styled.div`
	margin-top: 8px;
`
const Remark = styled.div`
	width: 100%;
	height: 29px;
	font-family: ${Fonts.primary};
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	align-items: center;
	justify-content: center;
	background: ${Colors.zircon};
	color: ${Colors.slateGray};
	border-radius: 5px;
`

const StatusWrapper = styled.div``
const StatusActionGroup = styled.div`
	flex-direction: row;
	justify-content: center;
	background: ${Colors.linkWater};
	width: fit-content;
	margin: 8px auto 0 auto;
	padding: 4px;
	border-radius: 5px;
`

interface StatusActionItemStyledProps {
	color: string
}

const StatusActionItem = styled.div<StatusActionItemStyledProps>`
	cursor: pointer;
	margin: 0 2px;
	color: ${Colors.white};
	font-size: 10px;
	font-weight: bold;
	height: 24px;
	padding: 0 8px;
	border-radius: 5px;
	background: ${({ color }) => color};
	align-items: center;
	justify-content: center;
`

const ReloadDataButton = styled.img`
	position: absolute;
	right: 0;
	top: 10px;
	cursor: pointer;
`
