import React, { FC, useEffect } from 'react'
import ReactPaginate from 'react-paginate'
import styled from 'styled-components'
import { CashbackSummaryItem } from '../../../api/summary/types'
import { format } from '../../../helpers/number'
import { MobileSize } from '../../../helpers/responsive'
import { usePagination } from '../../../hooks/usePagination'
import Card from '../../Common/Card'
import { CountItemList, PaginateWrapper, SubTitleTable, TableHeader, TitleTable } from '../../Common/Table'
import CashbackTxListTable from './CashbackTxListTable'

interface CashbackSummaryContainerProps {
	data: CashbackSummaryItem[]
}
const CashbackSummaryContainer: FC<CashbackSummaryContainerProps> = ({ data }) => {
	const { data: txData, page: txPage, pageCount: txPageCount, setPage: txSetPage } = usePagination(data)
	useEffect(() => {
		txSetPage(0)
	}, [data, txSetPage])

	return (
		<CardWrapper>
			<Card padding="20px 0 0 0">
				<TableHeader>
					<TableWithPaymentLeftHeader>
						<TitleTable>รายการรับแคชแบ็ก</TitleTable>
						<SubTitleTable>{format(data.length, 0)} รายการ</SubTitleTable>
					</TableWithPaymentLeftHeader>
				</TableHeader>
				<CashbackTxListTable data={txData} />
				<PaginateWrapper>
					<CountItemList></CountItemList>
					<ReactPaginate
						pageCount={txPageCount}
						forcePage={txPage}
						pageRangeDisplayed={5}
						marginPagesDisplayed={2}
						containerClassName={'pagination'}
						pageClassName={'pages pagination-pages'}
						activeClassName={'active-pagination'}
						onPageChange={(e) => {
							txSetPage(e.selected)
						}}
					/>
				</PaginateWrapper>
			</Card>
		</CardWrapper>
	)
}

export default CashbackSummaryContainer

const CardWrapper = styled.div`
	margin-top: 20px;
	@media only screen and (max-width: ${MobileSize}px) {
		margin-top: 0;
	}
`
const TableWithPaymentLeftHeader = styled.div`
	flex-direction: row;
	align-items: flex-end;
	@media only screen and (max-width: ${MobileSize}px) {
		flex-direction: column;
		align-items: flex-start;
	}
`
