import styled from 'styled-components'

export const MobileSize = 762
export const DesktopSize = 1200
export const Desktop = styled.div`
	@media only screen and (max-width: ${MobileSize}px) {
		display: none;
	}
`
export const Mobile = styled.div`
	display: none;
	@media only screen and (max-width: ${MobileSize}px) {
		display: flex;
	}
`
