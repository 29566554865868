import { CommonResponse } from '../@types/api'
import { DateRangeType } from '../@types/dashboard'
import {
	GetMemberResponse,
	GetMemberTransactionListParams,
	Member,
	MemberTransaction,
	UpdateMemberInfoParams,
	MemberBankAccount
} from '../@types/member'
import {
	AffiliateConfig,
	BankConfig,
	CashbackConfig,
	CentralAccountConfig,
	PromotionConfig,
	PromotionConfigAdd,
	SCBAccountConfig,
	WheelConfig,
	WithdrawAccountConfig,
	NewRegBonusConfig,
	RedeemConfig,
	RedeemAddConfig,
	settingPageConfig,
	usersConfig,
	usersAddConfig,
	usersEditConfig,
	ambPaymentConfig,
	ambGamesConfigEdit,
} from '../@types/prefix'
import { AddSCBAccountData, AddSCBAccountResponse, ConfirmSCBAccountOTPData } from '../@types/scb'
import {
	FixTransaction,
	FixTransactionParams,
	GetFixTxListParams,
	GetTransactionListParams,
	TransactionListResponse,
	WithdrawTransaction,
	GetBalance
} from '../@types/transactions'
import { GetLogListParams, LogTransaction } from '../@types/log'
import { AddTruewalletParams, RequestTruewalletOTPResponse } from '../@types/truewallet'
import api from '../helpers/api'
import { SetMemberActiveParams } from '../store/prefix/prefix.actions'
import { AddBankParams } from '../views/AddBank'
import { ReportByMemberTransaction } from '../api/summary/types'

class PrefixService {
	private prefix: string = ''

	constructor() {
		const prefix = localStorage.getItem('x-prefix')
		if (prefix) {
			this.setPrefix(prefix)
		}
	}

	getPrefix(): string {
		return this.prefix
	}
	setPrefix(prefix: string, save: boolean = false) {
		this.prefix = prefix

		if (save) {
			localStorage.setItem('x-prefix', prefix.toUpperCase())
		}
	}

	getSummaryWithPath<T>(path: string, dateRange: DateRangeType): Promise<T> {
		return api.get(path, {
			params: {
				start: dateRange.start,
				end: dateRange.end,
			},
		})
	}

	getMemberList(dateRange: DateRangeType, search: string = ''): Promise<Member[]> {
		return api.get<void, Member[]>('/members', {
			params: {
				start: dateRange.start,
				end: dateRange.end,
				search: search,
			},
		})
	}

	getMemberInfo(id: number | string): Promise<GetMemberResponse> {
		return api.get<void, GetMemberResponse>(`/members/${id}`)
	}

	updateMemberInfo(params: UpdateMemberInfoParams): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/members/${params.id}`, params)
	}

	setMemberActive(params: SetMemberActiveParams): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/members/${params.id}/status`, {
			status: params.status,
		})
	}

	getMemberBankAccount(id: number | string): Promise<MemberBankAccount> {
		return api.get<void, MemberBankAccount>(`/members/${id}/bank-account`)
	}

	getMemberTransactionList(params: GetMemberTransactionListParams): Promise<MemberTransaction[]> {
		return api.get<void, MemberTransaction[]>(`/members/${params.id}/transactions`, {
			params: {
				start: params.dateRange.start,
				end: params.dateRange.end,
				type: params.type,
			},
		})
	}

	getTransactionList<T>(params: GetTransactionListParams): Promise<TransactionListResponse<T>> {
		return api.get<void, TransactionListResponse<T>>(`/${params.type}`, {
			params: {
				start: params.dateRange?.start,
				end: params.dateRange?.end,

				offset: params.offset,
				status: params.status,
				limit: params.limit
			},
		})
	}

	redoWithdraw(tx: WithdrawTransaction): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/withdraw/${tx.id}/redo`)
	}
	confirmWithdraw(tx: WithdrawTransaction, transfer: string): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/withdraw/${tx.id}/confirm?transfer=${transfer}`)
	}
	rejectWithdraw(tx: WithdrawTransaction, remark: string): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/withdraw/${tx.id}/reject`, {
			remark: remark,
		})
	}

	getNewRegBonus(): Promise<NewRegBonusConfig> {
		return api.get<void, NewRegBonusConfig>(`/new-reg-bonus/setting`)
	}

	saveNewRegBonus(data: NewRegBonusConfig): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/new-reg-bonus/setting`, data)
	}


	getRedeem(): Promise<RedeemConfig> {
		return api.get<void, RedeemConfig>(`/setting/website/redeem`)
	}

	addRedeem(data: RedeemAddConfig): Promise<CommonResponse> {
		return api.post<void, CommonResponse>('/setting/website/redeem', data)
	}

	editRedeem(data: RedeemAddConfig): Promise<CommonResponse> {
		return api.patch<void, CommonResponse>('/setting/website/redeem', data)
	}

	deleteRedeem(key: number): Promise<CommonResponse> {
		return api.delete<void, CommonResponse>(`/setting/website/redeem/${key}`)
	}

	getSetting(): Promise<settingPageConfig> {
		return api.get<void, settingPageConfig>(`/setting/website`)
	}

	updateSetting(data: settingPageConfig): Promise<CommonResponse> {
		return api.post<void, CommonResponse>('/setting/website', data)
	}

	updateAmbPayment(data: ambPaymentConfig): Promise<CommonResponse> {
		return api.post<void, CommonResponse>('/setting/website/amb-payment', data)
	}

	updateAmbGames(data: ambGamesConfigEdit): Promise<CommonResponse> {
		return api.post<void, CommonResponse>('/setting/website/ambbet-games', data)
	}

	getUsers(): Promise<usersConfig> {
		return api.get<void, usersConfig>(`/users`)
	}

	addUsers(data: usersAddConfig): Promise<CommonResponse> {
		return api.post<void, CommonResponse>('/users', data)
	}

	editUsers(username: string, data: usersEditConfig): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/users/${username}`, data)
	}

	generate2FA(userID: number): Promise<CommonResponse> {
		return api.get<void, CommonResponse>(`/users/2fa/${userID}/generate`)
	}
	enable2FA(userID: number, payload: any): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/users/2fa/${userID}/enable`, payload)
	}
	disable2FA(userID: number): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/users/2fa/${userID}/disable`)
	}

	getUsersPermissions(): Promise<object> {
		return api.get<void, object>(`/users/permissions`)
	}


	savePromotionConfig(key: string, data: PromotionConfig): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/promotions/${key}`, data)
	}

	addPromotionConfig(data: PromotionConfigAdd): Promise<CommonResponse> {
		return api.post<void, CommonResponse>('/promotions', data)
	}

	deletePromotionConfig(keyPromotion: string): Promise<CommonResponse> {
		return api.delete<void, CommonResponse>(`/promotions/${keyPromotion}`)
	}

	saveCashbackConfig(data: CashbackConfig): Promise<CommonResponse> {
		return api.post<void, CommonResponse>('/website/setting/cashback', data)
	}

	saveAffiliateConfig(data: AffiliateConfig): Promise<CommonResponse> {
		return api.post<void, CommonResponse>('/affiliate/setting', data)
	}

	saveWheelConfig(data: WheelConfig): Promise<CommonResponse> {
		return api.post<void, CommonResponse>('/wheel', data)
	}

	getFixTxList(params: GetFixTxListParams): Promise<FixTransaction[]> {
		return api.get<void, FixTransaction[]>('/fix-tx', {
			params: {
				start: params.dateRange.start,
				end: params.dateRange.end,
			},
		})
	}
	fixTx(data: FixTransactionParams): Promise<CommonResponse> {
		return api.post<void, CommonResponse>('/fix-tx', data)
	}

	saveWithdrawAccount(data: WithdrawAccountConfig): Promise<CommonResponse> {
		return api.post<void, CommonResponse>('/setting/website/withdraw-account', data)
	}
	saveCentralAccount(data: CentralAccountConfig): Promise<CommonResponse> {
		return api.post<void, CommonResponse>('/setting/scb-auto', data)
	}

	saveBankAccount(data: BankConfig): Promise<CommonResponse> {
		return api.patch<void, CommonResponse>(`/setting/banks/${data.accountNumber}`, data)
	}
	addBankAccount(data: AddBankParams): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/setting/banks`, data)
	}
	deleteBankAccount(accountNumber: string): Promise<CommonResponse> {
		return api.delete<void, CommonResponse>(`/setting/banks/${accountNumber}`)
	}
	toggleScbAccount(accountNumber: string): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/setting/scb/${accountNumber}/toggle`)
	}
	withdrawToCentral(accountNumber: string): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/setting/scb/${accountNumber}/withdraw-central`)
	}
	getBalance(accountNumber: string): Promise<GetBalance> {
		return api.get<void, GetBalance>(`/setting/scb/${accountNumber}/balance`)
	}

	saveSCBAccount(data: SCBAccountConfig): Promise<CommonResponse> {
		return api.patch<void, CommonResponse>(`/setting/scb/${data.accountNumber}`, data)
	}
	deleteSCBAccount(accountNumber: string): Promise<CommonResponse> {
		return api.delete<void, CommonResponse>(`/setting/scb/${accountNumber}`)
	}

	requestSCBAccountOTP(data: AddSCBAccountData): Promise<AddSCBAccountResponse> {
		return api.post<void, AddSCBAccountResponse>('/setting/scb', data)
	}
	confirmSCBAccountOTP(
		deviceID: string,
		data: ConfirmSCBAccountOTPData
	): Promise<CommonResponse> {
		return api.post<void, CommonResponse>(`/setting/scb/${deviceID}/confirm-otp`, data)
	}

	requestTruewalletOTP(data: AddTruewalletParams): Promise<RequestTruewalletOTPResponse> {
		return api.post<void, RequestTruewalletOTPResponse>('/setting/truewallet/request-otp', data)
	}
	confirmTruewalletOTP(data: AddTruewalletParams): Promise<CommonResponse> {
		return api.post<void, CommonResponse>('/setting/truewallet/confirm-otp', data)
	}

	getLogList(params: GetLogListParams): Promise<LogTransaction[]> {
		return api.get<void, LogTransaction[]>('/log', {
			params: {
				start: params.dateRange.start,
				end: params.dateRange.end,
			},
		})
	}

	getReportByMemberList(params: GetTransactionListParams): Promise<ReportByMemberTransaction[]> {
		return api.get<void, ReportByMemberTransaction[]>(`/summary/report-by-member`, {
			params: {
				start: params.dateRange?.start,
				end: params.dateRange?.end,
			},
		})
	}

	uploadPromotionImage(
		promotionID: string,
		data: any
	): Promise<CommonResponse> {
		return api.post<void, CommonResponse>('/upload', data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			params: {
				key: promotionID
			}
		})
	}

	deletePromotionImage(
		promotionID: string,
	): Promise<CommonResponse> {
		return api.delete<void, CommonResponse>(`/promotions/${promotionID}/image`)
	}

	uploadSettingImageLogo(
		data: any
	): Promise<CommonResponse> {
		return api.post<void, CommonResponse>('/upload', data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		})
	}

	// deleteSettingImage(
	// 	promotionID: string,
	// ): Promise<CommonResponse> {
	// 	return api.delete<void, CommonResponse>(`/promotions/${promotionID}/image`)
	// }
}

export default new PrefixService()
