import React, { FC } from 'react'
import styled from 'styled-components'
import { OverviewSummary } from '../../../api/summary/types'
import activeUserIcon from '../../../assets/images/active-user-icon.svg'
import affiliateCountIcon from '../../../assets/images/affiliate-count-item-icon.svg'
import bonusCountIcon from '../../../assets/images/bonus-count-item-icon.svg'
import cashbackCountIcon from '../../../assets/images/cash-back-count-item-icon.svg'
import luckyDrawCountIcon from '../../../assets/images/lucky-draw-count-item-icon.svg'
import newUserIcon from '../../../assets/images/new-user-icon.svg'
import { Colors } from '../../../helpers/color'
import { format } from '../../../helpers/number'
import { MobileSize } from '../../../helpers/responsive'
import Card from '../../Common/Card'
import MoneyWithCurrency from '../../Common/Money/MoneyWithCurrency'

interface SummaryDetailProps {
	data: OverviewSummary
}
const SummaryDetail: FC<SummaryDetailProps> = ({ data }) => {
	return (
		<>
			{/* User sumamry section */}
			<UserCountWrapper>
				<NewUserCard>
					<LeftNewUserCard>
						<NewUserLabel>เปิดยูสเซอร์ใหม่</NewUserLabel>
					</LeftNewUserCard>
					<RightNewUserCard>
						<NewUserIcon src={newUserIcon} alt="new-user" />
						<NewUserCount>
							<b>{format(data.memberCount, 0)}</b>
							<span>บัญชี</span>
						</NewUserCount>
					</RightNewUserCard>
				</NewUserCard>
				<ActiveUserCard>
					<ActiveUserIcon src={activeUserIcon} alt="active-user" />
					<ActiveUserCount>
						<b>{format(data.memberFirstDepositCount, 0)}</b>
						<span>บัญชี</span>
					</ActiveUserCount>
					<ActiveUserLabel>ฝากครั้งแรก</ActiveUserLabel>
				</ActiveUserCard>
			</UserCountWrapper>

			{/* Promotion summary section */}
			<CountItemListWrapper>
				{/* bonus */}
				<CountItemWrapper>
					<Card mobilePadding="8px">
						<CountItem>
							<CountIcon src={bonusCountIcon} alt="bonus" />
							<CountDetail>
								<CountLabel color={Colors.studio}>ยอดรับโบนัส</CountLabel>
								<MoneyWithCurrency value={format(data.promotionTotal, 0)} fontSize="20px" />
							</CountDetail>
						</CountItem>
					</Card>
				</CountItemWrapper>

				{/* affiliate */}
				<CountItemWrapper>
					<Card mobilePadding="8px">
						<CountItem>
							<CountIcon src={affiliateCountIcon} alt="affiliate" />
							<CountDetail>
								<CountLabel color={Colors.dodgerBlue}>ยอดแนะนำเพื่อน</CountLabel>
								<MoneyWithCurrency value={format(data.affiliateTotal, 0)} fontSize="20px" />
							</CountDetail>
						</CountItem>
					</Card>
				</CountItemWrapper>

				{/* cashback */}
				<CountItemWrapper>
					<Card mobilePadding="8px">
						<CountItem>
							<CountIcon src={cashbackCountIcon} alt="cashback" />
							<CountDetail>
								<CountLabel color={Colors.warning}>ยอดรับแคชแบ็ก</CountLabel>
								<MoneyWithCurrency value={format(data.cashbackTotal, 0)} fontSize="20px" />
							</CountDetail>
						</CountItem>
					</Card>
				</CountItemWrapper>

				{/* wheel */}
				<CountItemWrapper>
					<Card mobilePadding="8px">
						<CountItem>
							<CountIcon src={luckyDrawCountIcon} alt="lucky-draw" />
							<CountDetail>
								<CountLabel color={Colors.negative}>ยอดหมุนกงล้อ</CountLabel>
								<MoneyWithCurrency value={format(data.wheelTotal, 0)} fontSize="20px" />
							</CountDetail>
						</CountItem>
					</Card>
				</CountItemWrapper>
			</CountItemListWrapper>
		</>
	)
}

export default SummaryDetail

const UserCountWrapper = styled.div`
	flex-direction: row;
	@media only screen and (max-width: ${MobileSize}px) {
		margin-top: 12px;
	}
`

const LeftNewUserCard = styled.div`
	justify-content: flex-end;
`

const RightNewUserCard = styled.div`
	align-items: flex-end;
	justify-content: space-between;
`

const NewUserCard = styled.div`
	width: 67%;
	flex-direction: row;
	justify-content: space-between;
	padding: 32px;
	background: ${Colors.dodgerBlue};
	box-shadow: 0px 10px 35px rgba(87, 106, 154, 0.08);
	border-radius: 25px;
	z-index: 2;
	@media only screen and (max-width: ${MobileSize}px) {
		padding: 16px;
		width: 70%;
		align-items: flex-end;
	}
`

const NewUserLabel = styled.b`
	color: ${Colors.white};
	font-size: 18px;
	@media only screen and (max-width: ${MobileSize}px) {
		font-size: 14px;
	}
`

const NewUserCount = styled.div`
	flex-direction: row;
	align-items: flex-end;
	margin-top: 16px;
	b {
		font-size: 24px;
		color: ${Colors.white};
		@media only screen and (max-width: ${MobileSize}px) {
			font-size: 24px;
		}
	}
	span {
		margin-left: 8px;
		font-size: 16px;
		color: ${Colors.anakiwa};
		@media only screen and (max-width: ${MobileSize}px) {
			font-size: 14px;
		}
	}
`

const NewUserIcon = styled.img`
	@media only screen and (max-width: ${MobileSize}px) {
		display: none;
	}
`

const ActiveUserCard = styled.div`
	width: calc(33% + 40px);
	border-radius: 0 25px 25px 0;
	background: ${Colors.malibu};
	margin-left: -40px;
	padding: 20px;
	padding-left: 40px;
	z-index: 1;
	min-width: 154px;
	@media only screen and (max-width: ${MobileSize}px) {
		width: calc(30% + 40px);
		min-width: 0px;
		padding: 16px 16px 16px 24px;
	}
`

const ActiveUserIcon = styled.img`
	margin: 0 0 0 auto;
	@media only screen and (max-width: ${MobileSize}px) {
		display: none;
	}
`
const ActiveUserCount = styled.div`
	flex-direction: row;
	align-items: flex-end;
	justify-content: center;
	margin-top: 12px;
	b {
		font-size: 24px;
		color: #ffffff;
		@media only screen and (max-width: ${MobileSize}px) {
			font-size: 24px;
		}
	}
	span {
		font-weight: 500;
		font-size: 16px;
		margin-left: 8px;
		color: #ccdcff;
		@media only screen and (max-width: ${MobileSize}px) {
			font-size: 14px;
		}
	}
`
const ActiveUserLabel = styled.div`
	color: ${Colors.zumthor};
	font-size: 11px;
	font-weight: bold;
	width: 100%;
	text-align: center;
`

const CountItemListWrapper = styled.div`
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0 -24px 0 0;
	@media only screen and (max-width: ${MobileSize}px) {
		margin: 0;
	}
`

const CountItemWrapper = styled.div`
	width: calc(50% - 24px);
	margin-top: 24px;
	margin-right: 24px;
	@media only screen and (max-width: ${MobileSize}px) {
		width: calc(50% - 12px);
		margin-right: 12px;
		margin-top: 12px;
	}
`
const CountItem = styled.div`
	flex-direction: row;
`
const CountIcon = styled.img`
	margin-right: 16px;
	@media only screen and (max-width: ${MobileSize}px) {
		margin-right: 8px;
		width: 34px;
	}
`
const CountDetail = styled.div``

interface CountLabelStyledProps {
	color: string
}
const CountLabel = styled.b<CountLabelStyledProps>`
	color: ${({ color }) => (color ? color : Colors.mirage)};
	@media only screen and (max-width: ${MobileSize}px) {
		font-size: 12px;
	}
`
