import { useFormik } from 'formik'
import moment from 'moment'
import React, { FC, useCallback, useState } from 'react'
import styled from 'styled-components'
import { DateRangeType, StaticDateRange } from '../../../@types/dashboard'
import { MobileSize } from '../../../helpers/responsive'
import PrimaryButton from '../Button/PrimaryButton'
import DatePicker from '../DatePicker'
import StaticRangeSelector from './StaticRangeSelector'

interface DateRangeProps {
	onChange?: (v: DateRangeType) => void
}
const DateRange: FC<DateRangeProps> = ({ onChange }) => {
	const [staticDateRange, setStaticDateRange] = useState<StaticDateRange>(
		'today'
	)

	const {
		values,
		setFieldValue,
		submitForm,
		handleChange,
		handleSubmit,
	} = useFormik({
		initialValues: {
			start: moment().format('YYYY-MM-DD'),
			end: moment().format('YYYY-MM-DD'),
		},
		onSubmit: async (values) => {
			onChange && onChange(values)
		},
	})
	const selectStaticRange = useCallback(
		(v: StaticDateRange) => {
			let start = moment(),
				end = moment()

			switch (v) {
				case 'yesterday': {
					start = start.subtract(1, 'day')
					end = end.subtract(1, 'day')
					break
				}
				case 'this_week': {
					start = start.startOf('week')
					end = end.endOf('week')
					break
				}
				case 'this_month': {
					start = start.startOf('month')
					end = end.endOf('month')
					break
				}
			}

			setFieldValue('start', start.format('YYYY-MM-DD'))
			setFieldValue('end', end.format('YYYY-MM-DD'))
			submitForm()
		},
		[setFieldValue, submitForm]
	)

	return (
		<form onSubmit={handleSubmit}>
			<DateRangePanel>
				<DateRangeItemLeft>
					<StaticRangeSelector
						selected={staticDateRange}
						onSelect={(v: StaticDateRange) => {
							selectStaticRange(v)
							setStaticDateRange(v)
						}}
					/>
				</DateRangeItemLeft>
				<DateRangeItemRight>
					<DateRangeItem>
						<DatePicker
							name="start"
							value={values.start}
							required
							onChange={handleChange}
						/>
					</DateRangeItem>
					<DateRangeItem>
						<DatePicker
							name="end"
							value={values.end}
							required
							onChange={handleChange}
						/>
					</DateRangeItem>
					<DateRangeItem>
						<PrimaryButton type="submit">ค้นหา</PrimaryButton>
					</DateRangeItem>
				</DateRangeItemRight>
			</DateRangePanel>
		</form>
	)
}

export default DateRange

const DateRangePanel = styled.div`
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	/* width: calc(100% - 124px); */
	margin: 0 auto;
	@media only screen and (max-width: ${MobileSize}px) {
		width: 100%;
		flex-direction: column;
	}
`
const DateRangeItemLeft = styled.div`
	@media only screen and (max-width: ${MobileSize}px) {
		margin-bottom: 12px;
		width: 100%;
	}
`
const DateRangeItemRight = styled.div`
	align-items: center;
	flex-direction: row;
	@media only screen and (max-width: ${MobileSize}px) {
		width: calc(100% - 32px);
		margin: 0 auto;
		flex-direction: column;
	}
`
const DateRangeItem = styled.div`
	flex: 0 0 auto;
	:nth-child(1),
	:nth-child(2),
	:nth-child(3) {
		width: 154px;
	}
	:not(:last-child) {
		margin-right: 24px;
	}
	@media only screen and (max-width: ${MobileSize}px) {
		margin-bottom: 12px;
		:nth-child(1),
		:nth-child(2),
		:nth-child(3) {
			width: 100%;
		}
		:not(:last-child) {
			margin-right: 0;
		}
	}
`
