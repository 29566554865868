import moment from 'moment'
import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import styled from 'styled-components'
import useSWR from 'swr'
import { BankTransaction } from '../../../@types/transactions'
import { format } from '../../../helpers/number'
import { MobileSize } from '../../../helpers/responsive'
import { usePagination } from '../../../hooks/usePagination'
import PrefixService from '../../../services/prefix'
import Card, {
	CardSubTitle,
	CardTitle,
	CardTitletRowWrapperWithOutBorder,
	CardTitleWrapper,
} from '../../Common/Card'
import ContentContainer from '../../Common/ContentContainer'
import DateRange from '../../Common/DateRange'
import { CountItemList, PaginateWrapper, TableWrapper } from '../../Common/Table'
import BankTransactionsListTable from './BankTransactionsListTable'
import Select from '../../Common/Select'
import { statusTransaction } from '../../../helpers/status'

const BankTransactionsTab = () => {
	const [dateRange, setDateRange] = useState({
		start: moment().format('YYYY-MM-DD'),
		end: moment().format('YYYY-MM-DD'),
	})

	// Filter status
	const [status, setStatus] = useState('')
	const handleFilterStatus = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const value = event.target.value
		setStatus(value === 'all' ? '' : value)
	}

	const { data } = useSWR(['/bank-transactions', dateRange.start, dateRange.end, status],
		() =>
			PrefixService.getTransactionList<BankTransaction>({
				type: 'bank-transactions',
				dateRange: dateRange,
				status: status
			}),
		{
			refreshInterval: 10000,
		}
	)

	const {
		data: txData,
		page,
		pageCount,
		setPage,
	} = usePagination<BankTransaction>(data as any || [])

	useEffect(() => {
		setPage(0)
	}, [data, setPage])

	return (
		<ContentContainer>
			<DateRange onChange={setDateRange} />

			<FirstSection>
				<Card padding="24px 0 0 0">
					<CardTitleWrapper>
						<CardTitletRowWrapperWithOutBorder>
							<CardTitle>รายการเงินเข้า</CardTitle>
							<CardSubTitle>{format(Object.keys(data ?? []).length || 0, 0)} รายการ</CardSubTitle>
						</CardTitletRowWrapperWithOutBorder>
						<FilterStatus>
							<span>สถานะ</span>
							<Select
								name="bankCode"
								items={Object.keys(statusTransaction).map((key) => ({
									label: statusTransaction[key],
									value: key,
								}))}
								placeholder="-- เลือกสถานะ --"
								defaultValue={'all'}
								onChange={handleFilterStatus}
								required
								style={{minWidth: '160px'}}
							/>
						</FilterStatus>
					</CardTitleWrapper>
					<TableWrapper>
						<BankTransactionsListTable loading={!data} data={txData} />
						<PaginateWrapper>
							<CountItemList></CountItemList>
							<ReactPaginate
								forcePage={page}
								pageCount={pageCount}
								pageRangeDisplayed={5}
								marginPagesDisplayed={2}
								containerClassName={'pagination'}
								pageClassName={'pages pagination-pages'}
								activeClassName={'active-pagination'}
								onPageChange={(e) => {
									setPage(e.selected)
								}}
							/>
						</PaginateWrapper>
					</TableWrapper>
				</Card>
			</FirstSection>
		</ContentContainer>
	)
}

export default BankTransactionsTab

const FirstSection = styled.div`
	margin-top: 32px;
	@media only screen and (max-width: ${MobileSize}px) {
		margin-top: 12px;
	}
`

const FilterStatus = styled.div`
	flex-direction: row;
	align-items: center;

	span {
		font-size: 16px;
		margin-right: 8px;
	}

	@media only screen and (max-width: ${MobileSize}px) {
		flex-direction: column;
		align-items: stretch;
		margin-bottom: 8px;

		span {
			font-size: 14px;
		}
	}
`