import React, { FC } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import backButton from '../../assets/images/back-button.svg'
import { Colors } from '../../helpers/color'
import { Fonts } from '../../helpers/font'
import { MobileSize } from '../../helpers/responsive'
import ContentContainer from '../Common/ContentContainer'

interface PageLayoutProps {
	title: string
	subTitle?: string
	goBack?: string
}
const PageLayout: FC<PageLayoutProps> = ({
	children,
	title,
	subTitle,
	goBack,
}) => {
	const history = useHistory()

	return (
		<PageWrapper>
			<ContentContainer>
				<Wrapper>
					<HeaderLeft>
						<SubTitle>{subTitle && subTitle}</SubTitle>
						<HeaderTitle>
							{goBack && (
								<BackButton
									src={backButton}
									alt="back"
									onClick={() => history.push(goBack)}
								/>
							)}

							{title}
						</HeaderTitle>
					</HeaderLeft>
				</Wrapper>
			</ContentContainer>

			{children}
		</PageWrapper>
	)
}

export default PageLayout

const PageWrapper = styled.div`
	@media only screen and (max-width: ${MobileSize}px) {
		padding-bottom: 75px;
	}
`
const Wrapper = styled.div`
	flex: 1;
	justify-content: space-between;
	flex-direction: row;
	margin: 46px 0 0 0;
	@media only screen and (max-width: ${MobileSize}px) {
		margin: 16px 0 0 0;
	}
`

const HeaderLeft = styled.div`
	align-items: flex-start;
`
const SubTitle = styled.div`
	font-size: 18px;
	font-weight: 600;
	font-family: ${Fonts.primary};
	color: ${Colors.casper};
	@media only screen and (max-width: ${MobileSize}px) {
		margin-bottom: 8px;
	}
`
const HeaderTitle = styled.div`
	font-weight: bold;
	font-size: 36px;
	padding-top: 14px;
	font-family: ${Fonts.primary};
	color: ${Colors.ebonyClay};
	align-items: center;
	flex-direction: row;
	@media only screen and (max-width: ${MobileSize}px) {
		font-size: 24px;
	}
`
const BackButton = styled.img`
	margin-top: 4px;
	margin-right: 24px;
	cursor: pointer;
`
