import moment from 'moment'
import React, { FC } from 'react'
import { DepositTransaction } from '../../../@types/transactions'
import { getFullName } from '../../../helpers/member'
import { format } from '../../../helpers/number'
import BankType from '../../Common/BankType/BankType'
import MoneyWithCurrency from '../../Common/Money/MoneyWithCurrency'
import PhoneNumberWithIcon from '../../Common/PhoneNumberWithIcon/PhoneNumberWithIcon'
import { BodyTR, Td, Th, Time, TimeDate, TimeWrapper } from '../../Common/Table'
import EnhancedTable from '../../Common/Table/EnhancedTable'
import TxStatusBadge from '../../TxStatusBadge/TxStatusBadge'

interface BonusListTableProps {
	loading: boolean
	data: DepositTransaction[]
}
const BonusListTable: FC<BonusListTableProps> = ({ loading, data }) => (
	<EnhancedTable
		loading={loading}
		data={data}
		theadSize={5}
		thead={
			<tr>
				<Th style={{ textAlign: 'left' }}>เวลา</Th>
				<Th style={{ textAlign: 'left' }}>สมาชิก</Th>
				<Th style={{ textAlign: 'left' }}>จาก</Th>
				<Th style={{ textAlign: 'center' }}>จำนวนเงิน</Th>
				<Th style={{ textAlign: 'center' }}>สถานะ</Th>
			</tr>
		}
	>
		{(row: DepositTransaction) => (
			<BodyTR key={row.createdAt}>
				<Td>
					<TimeWrapper>
						<TimeDate>{moment(row.transferTime).format('DD/MM/YYYY')}</TimeDate>
						<Time>{moment(row.transferTime).format('HH:mm:ss')}</Time>
					</TimeWrapper>
				</Td>
				<Td>
					<b>{getFullName(row.member)}</b>
					<br />
					<PhoneNumberWithIcon phoneNumber={row.member.phone} />
				</Td>
				<Td>
					<BankType
						bank={row.transferFrom}
						bankNumber={
							row.transferFrom === 'TRUEWALLET'
								? row.member.phone
								: row.member.bankNumber
						}
					/>
				</Td>
				<Td>
					<MoneyWithCurrency
						value={format(row.amount, 2)}
						fontSize="16px"
						position="center"
					/>
				</Td>
				<Td>
					<TxStatusBadge status={row.status} />
				</Td>
			</BodyTR>
		)}
	</EnhancedTable>
)

export default BonusListTable
