import React, { ChangeEvent, FC, FormEvent } from 'react'
import NumberFormat from 'react-number-format'
import styled from 'styled-components'
import { FixTransactionParams } from '../../@types/transactions'
import { Colors } from '../../helpers/color'
import { Fonts } from '../../helpers/font'
import PrimaryButton from '../Common/Button/PrimaryButton'
import TwoColumn from '../Common/Columns/TwoColumn'
import Input from '../Common/Input'
import Textarea from '../Common/Input/Textarea'
import Radio from '../Common/Radio/Radio'

interface AddFixTxFormProps {
	values: FixTransactionParams
	loading: boolean
	isModal?: boolean

	setFieldValue: (k: string, v: any) => void
	handleChange: (e: ChangeEvent<any>) => void
	handleSubmit: (e: FormEvent<any>) => void
}
const AddFixTxForm: FC<AddFixTxFormProps> = ({
	values,
	loading,
	isModal,

	setFieldValue,
	handleChange,
	handleSubmit,
}) => (
	<form id="creditManagement" onSubmit={handleSubmit}>
		<FormTitile>รายละเอียดรายการ</FormTitile>
		<InputSection>
			<Label>ประเภทรายการ</Label>
			<FixTxListTypeItemWrapper>
				<FixTxListTypeItem>
					<Radio
						active={values.fixType === 'depositMemberBank'}
						onClick={() =>
							setFieldValue('fixType', 'depositMemberBank')
						}
					>
						ธนาคารไม่รัน
					</Radio>
				</FixTxListTypeItem>
				<FixTxListTypeItem>
					<Radio
						active={values.fixType === 'depositMemberTruewallet'}
						onClick={() =>
							setFieldValue('fixType', 'depositMemberTruewallet')
						}
					>
						ทรูมันนี่วอลเล็ทไม่รัน
					</Radio>
				</FixTxListTypeItem>
				<FixTxListTypeItem>
					<Radio
						active={values.fixType === 'addCredit'}
						onClick={() => setFieldValue('fixType', 'addCredit')}
					>
						เติมเครดิต
					</Radio>
				</FixTxListTypeItem>
				<FixTxListTypeItem>
					<Radio
						active={values.fixType === 'subCredit'}
						onClick={() => setFieldValue('fixType', 'subCredit')}
					>
						ตัดเครดิต
					</Radio>
				</FixTxListTypeItem>
			</FixTxListTypeItemWrapper>
		</InputSection>

		<InputSection>
			<TwoColumn margin="12px">
				<Input
					type="text"
					name="phone"
					label="เบอร์โทรศัพท์"
					defaultValue={values.phone}
					required
					onChange={handleChange}
				/>
				<Input
					type="number"
					name="amount"
					label="จำนวนเงิน"
					defaultValue={values.amount}
					required
					onChange={handleChange}
				/>
			</TwoColumn>
		</InputSection>

		{values.fixType === 'addCredit' && (
			<InputSection>
				<TwoColumn margin="12px">
					<Input
						type="number"
						name="turn"
						label="เทิร์น (เท่า)"
						defaultValue={values.turn}
						required
						onChange={handleChange}
					/>
					<Input
						type="number"
						name="maxWithdraw"
						label="ถอนสูงสุด (เท่า)"
						defaultValue={values.maxWithdraw}
						required
						onChange={handleChange}
					/>
				</TwoColumn>
			</InputSection>
		)}

		{(values.fixType === 'depositMemberBank' ||
			values.fixType === 'depositMemberTruewallet') && (
			<InputSection>
				<NumberFormat
					type="text"
					name="transferTime"
					label="วัน/เดือน/ปี เวลาที่โอนเงิน"
					format="##/##/#### ##:##"
					mask="-"
					customInput={Input}
					defaultValue={values.transferTime}
					required
					onChange={handleChange}
				/>
			</InputSection>
		)}

		<InputSection>
			<Textarea
				name="remark"
				label="หมายเหตุ"
				rows={4}
				required
				onChange={handleChange}
			/>
		</InputSection>

		{ isModal ? '' :
			<InputSection>
				<PrimaryButton type="submit" loading={loading}>
					บันทึกข้อมูล
				</PrimaryButton>
			</InputSection>
		}
	</form>
)

export default AddFixTxForm

const FormTitile = styled.div`
	flex: 1;
	font-size: 24px;
	color: ${Colors.black2};
	font-weight: bold;
	font-family: ${Fonts.primary};
	margin: 20px 0 32px 0;
	padding: 0;
`
const InputSection = styled.div`
	margin-bottom: 20px;
	width: 100%;
`
const Label = styled.div`
	font-size: 16px;
	font-family: ${Fonts.primary};
	color: ${Colors.slateGray};
`
const FixTxListTypeItemWrapper = styled.div`
	margin: 0 -12px;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	margin-top: 8px;
`
const FixTxListTypeItem = styled.div`
	width: calc(50% - 24px);
	margin: 0 12px 16px 12px;
`
