import React, { ChangeEvent, FC, useEffect } from 'react'
import styled from 'styled-components'
import { PromotionConfig } from '../../@types/prefix'
import { Colors } from '../../helpers/color'
import CheckboxWithContent from '../Common/Checkbox/CheckboxWithContent'
import CheckboxItem from '../Common/Checkbox/CheckboxItem'
import DatePicker from '../Common/DatePicker'
import { MobileSize } from '../../helpers/responsive'
import { Fonts } from '../../helpers/font'

interface NoDepositBetweenProps {
	data: PromotionConfig

	onChange: (e: ChangeEvent<any>) => void
	setFieldValue: (field: string, value: any) => void
}
const NoDepositBetween: FC<NoDepositBetweenProps> = ({
	data,
	onChange,
	setFieldValue,
}) => {
	const rules = data.noDepositBetween.rules || {
		noBonus: false,
		noCashback: false,
		noLuckyWheel: false,
		noWithdraw: false
	}

	useEffect(() => {
		if(!data.noDepositBetween.rules) {
			setFieldValue('noDepositBetween.rules', rules)
		}
	}, [])

	return (
		<CheckboxWithContent
			label="ไม่มียอดฝาก"
			checked={data.noDepositBetween.enable}
			setChecked={(v: boolean) => setFieldValue('noDepositBetween.enable', v)}
		>
			<CheckboxWithContentBody>
				<TopCheckboxWithContentSection>
					<LeftCheckboxWithContentBody>
						<DateRangeItem>
							<DatePicker
								name="noDepositBetween.startDate"
								value={data.noDepositBetween.startDate}
								required
								onChange={onChange}
							/>
						</DateRangeItem>
						<DateRangeItem>
							<DatePicker
								name="noDepositBetween.endDate"
								value={data.noDepositBetween.endDate}
								required
								onChange={onChange}
							/>
						</DateRangeItem>
					</LeftCheckboxWithContentBody>
				</TopCheckboxWithContentSection>
				<CheckboxContainer>
					<CheckboxItem
						label={'ไม่มีรายการถอน'}
						checked={rules.noWithdraw}
						padding={'0 0 15px 0'}
						onChange={(v: boolean) => setFieldValue('noDepositBetween.rules.noWithdraw', v)}
					/>
					<CheckboxItem
						label={'ไม่มีรายการรับโบนัส'}
						checked={rules.noBonus}
						padding={'0 0 15px 0'}
						onChange={(v: boolean) => setFieldValue('noDepositBetween.rules.noBonus', v)}
					/>
					<CheckboxItem
						label={'ไม่มีรายการรับแคชแบ็ก'}
						checked={rules.noCashback}
						padding={'0 0 15px 0'}
						onChange={(v: boolean) => setFieldValue('noDepositBetween.rules.noCashback', v)}
					/>
					<CheckboxItem
						label={'ไม่มีรายการหมุนกงล้อเสี่ยงโชค'}
						checked={rules.noLuckyWheel}
						padding={'0 0 15px 0'}
						onChange={(v: boolean) => setFieldValue('noDepositBetween.rules.noLuckyWheel', v)}
					/>
				</CheckboxContainer>
			</CheckboxWithContentBody>
		</CheckboxWithContent>
	)
}
export default NoDepositBetween

const CheckboxWithContentBody = styled.div``

const TopCheckboxWithContentSection = styled.div`
	padding: 0 16px;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	margin: 0 0 15px;
`

const LeftCheckboxWithContentBody = styled.div`
	flex-direction: row;
	margin: 0 -6px;
	flex: 1;
	align-items: center;
`

const CheckboxContainer = styled.div`
	padding: 15px 15px 0;
	background-color: ${Colors.zircon}
`

const DateRangeItem = styled.div`
	flex: 0 0 auto;
	:nth-child(1),
	:nth-child(2),
	:nth-child(3) {
		width: 154px;
	}
	:not(:last-child) {
		margin-right: 24px;
	}

	input {
		background-color: #fff;
		border: 2px solid ${Colors.linkWater};
		border-radius: 8px;

		::-webkit-datetime-edit {
			font-family: ${Fonts.primary};
			font-weight: bold;
			padding-left: 16px;
			color: ${Colors.slateGray};
			font-size: 13px;
			display: flex;
			align-items: center;
		}
		::-webkit-calendar-picker-indicator {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: auto;
			height: auto;
			color: transparent;
			background: transparent;
		}
	}

	@media only screen and (max-width: ${MobileSize}px) {
		margin-bottom: 12px;
		:nth-child(1),
		:nth-child(2),
		:nth-child(3) {
			width: 100%;
		}
		:not(:last-child) {
			margin-right: 0;
		}
	}
`
