import moment from 'moment'
import React, { FC } from 'react'
import { MemberTransaction } from '../../../@types/member'
import { getBankName } from '../../../helpers/bank'
import { format } from '../../../helpers/number'
import MoneyWithCurrency from '../../Common/Money/MoneyWithCurrency'
import {
	BodyTR,
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Time,
	TimeDate,
	TimeWrapper,
} from '../../Common/Table'
import TxStatusBadge from '../../TxStatusBadge/TxStatusBadge'

interface WithdrawListTableProps {
	data: MemberTransaction[]
}
const WithdrawListTable: FC<WithdrawListTableProps> = ({ data }) => (
	<Table>
		<Thead>
			<tr>
				<Th style={{ textAlign: 'left' }}>เวลา</Th>
				<Th style={{ textAlign: 'left' }}>ไปยัง</Th>
				<Th style={{ textAlign: 'center' }}>จำนวนเงิน</Th>
				<Th style={{ textAlign: 'center' }}>สถานะ</Th>
			</tr>
		</Thead>
		<Tbody>
			{data.length <= 0 ? (
				<BodyTR>
					<Td colSpan={4} style={{ textAlign: 'center' }}>
						ไม่มีข้อมูล
					</Td>
				</BodyTR>
			) : (
				data.map((row) => (
					<BodyTR key={row.createdAt}>
						<Td>
							<TimeWrapper>
								<TimeDate>
									{moment(row.createdAt).format('DD/MM/YYYY')}
								</TimeDate>
								<Time>
									{moment(row.createdAt).format('HH:mm:ss')}
								</Time>
							</TimeWrapper>
						</Td>
						<Td>
							<b>{getBankName(row.transferTo)}</b>
						</Td>
						<Td>
							<MoneyWithCurrency
								value={format(row.amount, 2)}
								fontSize="16px"
								position="center"
							/>
						</Td>
						<Td>
							<TxStatusBadge status={row.status} />
						</Td>
					</BodyTR>
				))
			)}
		</Tbody>
	</Table>
)

export default WithdrawListTable
