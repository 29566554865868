import React, { FC } from 'react'
import styled from 'styled-components'
import activeRadioIcon from '../../../assets/images/active-radio-button-icon.svg'
import nonActiveRadioIcon from '../../../assets/images/non-active-radio-button-icon.svg'
import { Colors } from '../../../helpers/color'
import { Fonts } from '../../../helpers/font'

interface RadioProps {
	active: boolean
	className?: string
	onClick: () => void
}
const Radio: FC<RadioProps> = ({ children, active, onClick, className }) => {
	return (
		<RadioItemElement active={active} onClick={onClick} className={className}>
			{active ? (
				<RadioIcon src={activeRadioIcon} alt="active" />
			) : (
				<RadioIcon src={nonActiveRadioIcon} alt="non-active" />
			)}

			<RadioLabel active={active}>{children}</RadioLabel>
		</RadioItemElement>
	)
}

export default Radio

interface RadioItemElementStyledProps {
	active: boolean
}
const RadioItemElement = styled.div<RadioItemElementStyledProps>`
	flex: 1;
	border: 2px solid
		${({ active }) => (active ? Colors.anakiwa : Colors.linkWater)};
	padding: 10px 16px;
	flex-direction: row;
	${({ active }) => !active && `background:${Colors.zircon2};`};
	cursor: ${({ active }) => (active ? 'normal' : 'pointer')};
	border-radius: 8px;
	align-items: center;
`

const RadioIcon = styled.img``

interface RadioLabelStyledProps {
	active: boolean
}
const RadioLabel = styled.div<RadioLabelStyledProps>`
	margin-left: 8px;
	font-size: 12px;
	font-weight: bold;
	font-family: ${Fonts.primary};
	color: ${({ active }) => (active ? Colors.dodgerBlue : Colors.casper2)};
`
