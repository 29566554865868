import { useFormik } from 'formik'
import React from 'react'
import NumberFormat from 'react-number-format'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { AddSCBAccountData } from '../@types/scb'
import ConfirmSCBAccountOTPModal from '../components/Bank/SCBAccount/ConfirmSCBAccountOTPModal'
import PrimaryButton from '../components/Common/Button/PrimaryButton'
import ContentContainer from '../components/Common/ContentContainer'
import Input from '../components/Common/Input'
import PageLayout from '../components/PageLayout'
import { Colors } from '../helpers/color'
import { Fonts } from '../helpers/font'
import { MobileSize } from '../helpers/responsive'
import PrefixService from '../services/prefix'
import { requestAuthInit } from '../store/auth/auth.actions'

const AddSCBAccount = () => {
	const { values, isSubmitting, setSubmitting, setFieldValue, handleChange, handleSubmit } =
		useFormik<AddSCBAccountData>({
			initialValues: {
				identification: '',
				birthDate: '',
				phone: '',
				accountNumber: '',
				pin: '',

				otpCode: '',
				deviceID: '',
			},
			onSubmit: async (values, { setFieldValue }) => {
				try {
					const res = await PrefixService.requestSCBAccountOTP(values)

					toast.success(res.message)
					setFieldValue('deviceID', res.data.deviceID)
				} catch ({ message }) {
					toast.error(message as string)
				}
			},
		})

	const dispatch = useDispatch()
	const history = useHistory()
	const onConfirmOTP = async () => {
		if (!values.deviceID || !values.otpCode) {
			return false
		}

		setSubmitting(true)

		try {
			const res = await PrefixService.confirmSCBAccountOTP(values.deviceID, {
				accountNumber: values.accountNumber,
				pin: values.pin,
				otpCode: values.otpCode,
			})

			dispatch(requestAuthInit(false))
			toast.success(res.message)
			history.push('/bank')
		} catch ({ message }) {
			toast.error(message as string)
			setSubmitting(false)
		}
	}

	return (
		<PageLayout title="เพิ่ม SCB API" goBack="/bank">
			<Body>
				<ContentContainer>
					<InnerBody>
						<AddSCBAccountForm autoComplete="off" onSubmit={handleSubmit}>
							<MainTitleWrapper>
								<FormTitle>รายละเอียดบัญชี</FormTitle>
							</MainTitleWrapper>
							<InputSection>
								<Input
									type="text"
									name="identification"
									label="เลขบัตรประชาชน"
									maxLength={13}
									required
									onChange={handleChange}
								/>
							</InputSection>
							<InputSection>
								<NumberFormat
									type="text"
									name="birthDate"
									label="วัน/เดือน/ปีเกิด"
									format="##/##/####"
									mask="-"
									customInput={Input}
									required
									onChange={handleChange}
								/>
							</InputSection>
							<InputSection>
								<Input
									type="text"
									name="accountNumber"
									label="เลขที่บัญชี"
									required
									onChange={handleChange}
								/>
							</InputSection>
							<InputSection>
								<Input
									type="text"
									name="phone"
									label="เบอร์โทรศัพท์"
									required
									onChange={handleChange}
								/>
							</InputSection>
							<InputSection>
								<Input
									type="text"
									name="pin"
									label="PIN"
									maxLength={6}
									required
									onChange={handleChange}
								/>
							</InputSection>
							<InputSection>
								<PrimaryButton type="submit" loading={isSubmitting}>
									ส่งรหัส OTP
								</PrimaryButton>
							</InputSection>
						</AddSCBAccountForm>
					</InnerBody>
				</ContentContainer>
			</Body>

			{values.deviceID && (
				<ConfirmSCBAccountOTPModal
					loading={isSubmitting}
					onChange={handleChange}
					onSubmit={onConfirmOTP}
					onClose={() => setFieldValue('deviceID', '')}
				/>
			)}
		</PageLayout>
	)
}

export default AddSCBAccount

const Body = styled.div`
	background: ${Colors.white};
	width: 100%;
	height: 100%;
	margin-top: 18px;
`
const AddSCBAccountForm = styled.form`
	width: 100%;
`
const InnerBody = styled.div`
	max-width: 43%;
	width: 100%;
	margin: auto;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	@media only screen and (max-width: ${MobileSize}px) {
		max-width: 100%;
	}
`
const MainTitleWrapper = styled.div`
	flex-direction: row;
	width: 100%;
	justify-content: flex-end;
	align-items: center;
`
const FormTitle = styled.div`
	flex: 1;
	font-size: 24px;
	color: ${Colors.black2};
	font-weight: bold;
	font-family: ${Fonts.primary};
	margin: 20px 0 20px 0;
	padding: 0;
`
const InputSection = styled.div`
	margin-bottom: 20px;
	width: 100%;
`
