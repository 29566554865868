import moment from 'moment'
import React, { useState } from 'react'
import useSWR from 'swr'
import { WithdrawTransaction } from '../../../@types/transactions'
import PrefixService from '../../../services/prefix'
import ContentContainer from '../../Common/ContentContainer'
import DateRange from '../../Common/DateRange'
import WithdrawListTableContainer from './WithdrawListTableContainer'

const WithdrawTab = () => {
	const [dateRange, setDateRange] = useState({
		start: moment().format('YYYY-MM-DD'),
		end: moment().format('YYYY-MM-DD'),
	})

	const { data, mutate } = useSWR(
		`/withdraw?start=${dateRange.start}&end=${dateRange.end}`,
		() =>
			PrefixService.getTransactionList<WithdrawTransaction>({
				type: 'withdraw',
				dateRange: dateRange,
			}),
		{
			refreshInterval: 10000,
		}
	)

	return (
		<ContentContainer>
			<DateRange onChange={setDateRange} />

			<WithdrawListTableContainer
				loading={!data}
				count={data?.count || 0}
				data={data?.transactions || []}
				update={mutate}
			/>
		</ContentContainer>
	)
}

export default WithdrawTab
