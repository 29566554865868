import { ButtonHTMLAttributes, FC } from 'react'
import styled from 'styled-components'
import { Colors } from '../../../helpers/color'
import { Fonts } from '../../../helpers/font'

interface SecondButtonProps {
	height?: string
	width?: string
	mobileWidth?: string
	color?: string
	textColor?: string

	loading?: boolean
}
const SecondButton: FC<
	SecondButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
> = ({
	children,
	height,
	width,
	mobileWidth,
	color,
	textColor,
	loading,
	...rest
}) => (
	<Button
		{...rest}
		height={height}
		width={width}
		mobileWidth={mobileWidth}
		color={color}
		textColor={textColor}
		disabled={loading}
	>
		{loading ? <i className="fad fa-spinner fa-spin" /> : children}
	</Button>
)

export default SecondButton

interface ButtonStyledProps {
	height?: string
	width?: string
	mobileWidth?: string
	color?: string
	textColor?: string
}
const Button = styled.button<ButtonStyledProps>`
	cursor: pointer;
	width: 100%;
	max-width: ${({ width }) => width && width};
	height: ${({ height }) => (height ? height : '39px')};
	border-radius: 8px;
	background-color: ${({ color }) => color};
	color: ${({ textColor }) => textColor || Colors.dodgerBlue};
	border: none;
	outline: none;
	align-items: center;
	justify-content: center;
	font-family: ${Fonts.primary};
	font-weight: bold;

	&:disabled {
		background-color: ${Colors.casper};
	}
`
