import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { PromotionConfig } from '../../../@types/prefix'
import { Colors } from '../../../helpers/color'
import { format } from '../../../helpers/number'
import { MobileSize } from '../../../helpers/responsive'
import ContentContainer from '../../Common/ContentContainer'
import BonusCardItem from './BonusListItem'
import EditBonusModal from './EditBonusModal'
import AddBonusModal from './AddBonusModal'
import ConfirmDelete from './ConfirmDelete'
import 'antd/dist/antd.css'
import { Button } from 'antd'
import PrefixService from '../../../services/prefix'
import { toast } from 'react-toastify'
import { requestAuthInit } from '../../../store/auth/auth.actions'

interface BonusSectionProps {
	data: Record<string, PromotionConfig>
}
const BonusSection: FC<BonusSectionProps> = ({ data }) => {
	// Edit Member
	const dispatch = useDispatch()
	const history = useHistory()
	const [editBonus, setEditBonus] = useState(false)
	const [addBonus, setAddBonus] = useState(false)
	const [keyBonus, setKeyBonus] = useState('')
	const toggleAddBonus = () => setAddBonus(!addBonus)
	const toggleEditBonus = () => setEditBonus(!editBonus)
	
	const [deleteBonus, setDeleteBonus] = useState(false);
    const toggleDelete = () => setDeleteBonus(!deleteBonus);
	const paramKeyBonus = (key: string) => {
		setKeyBonus(key)
		toggleEditBonus()
	}

	

	const [paramKeyBonusDel, setParamKeyBonusDel] = useState<string>('')
	const currentParamKeyBonusDel = (number: string) => setParamKeyBonusDel(number);

	const submitDeleteBonus = async () => {

		try {
			const res = await PrefixService.deletePromotionConfig(paramKeyBonusDel)

			// toggle()
			// resetForm()
			dispatch(requestAuthInit(false))
			toast.success(res.message)
			history.push('/promotions')
			toggleDelete()
		} catch ({ message }) {
			toast.error(message as string)
		}
	}

	return (
		<>
			<ContentContainer>
				<TopController>
					<PromotionTitle>
						<h3>จัดการโบนัส</h3>
						<span>
							โบนัสทั้งหมด&nbsp;
							<b>{format(Object.keys(data).length, 0)}</b>
							&nbsp;รายการ
						</span>
					</PromotionTitle>
					<Button type="primary" onClick={() => toggleAddBonus()}>เพิ่มโบนัส</Button>
				</TopController>
			</ContentContainer>
			<CardWrapperOuside>
				<ContentContainer>
					<CardWrapper>
						{Object.keys(data).map((key) => (
							<CardItemWrapper key={key}>
								<BonusCardItem
									k={key}
									data={data[key]}
									setKey={paramKeyBonus}
									setKeyDel={currentParamKeyBonusDel}
									toggleDelete={() => toggleDelete()}
								/>
							</CardItemWrapper>
						))}
					</CardWrapper>
				</ContentContainer>
			</CardWrapperOuside>
			<EditBonusModal
				dataKey={keyBonus}
				isOpenModal={editBonus}
				toggle={toggleEditBonus}
			/>
			<AddBonusModal
				// update={update}
				dataKey={keyBonus}
				isOpenModal={addBonus}
				toggle={toggleAddBonus}
			/>
			<ConfirmDelete
				onSubmit={() => submitDeleteBonus()}
				isOpenModal={deleteBonus}
				toggleDelete={() => toggleDelete()}
			/>
		</>
	)
}

export default BonusSection

const TopController = styled.div`
	flex-direction: row;
	justify-content: space-between;
	margin-top: 24px;
	@media only screen and (max-width: ${MobileSize}px) {
		flex-direction: column;
	}
`

const PromotionTitle = styled.div`
	flex-direction: row;
	align-items: flex-end;
	@media only screen and (max-width: ${MobileSize}px) {
		margin-bottom: 12px;
	}
	h3 {
		font-size: 24px;
		color: ${Colors.dodgerBlue};
	}
	span {
		font-size: 14px;
		color: ${Colors.casper};
		margin-left: 8px;
	}
`
const CardWrapperOuside = styled.div`
	margin-top: 12px;
	background: ${Colors.linkWater};
	width: 100%;
	padding: 24px 0;
	@media only screen and (max-width: ${MobileSize}px) {
		padding-top: 12px;
	}
`
const CardWrapper = styled.div`
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0 -10px;
	@media only screen and (max-width: ${MobileSize}px) {
		flex-direction: column;
	}
`

const CardItemWrapper = styled.div`
	width: calc(100% * 1 / 3 - 20px);
	margin: 10px;
	float: left;
	@media only screen and (max-width: ${MobileSize}px) {
		width: 100%;
		margin: 6px 0;
	}
`
