import React, { ChangeEvent, FC, useEffect } from 'react'
import styled from 'styled-components'
import { PromotionConfig } from '../../@types/prefix'
import trashIcon from '../../assets/images/trash-icon.svg'
import { Colors } from '../../helpers/color'
import { Fonts } from '../../helpers/font'
import { MobileSize } from '../../helpers/responsive'
import CheckboxWithContent from '../Common/Checkbox/CheckboxWithContent'
import Input from '../Common/Input'

interface StepSettingProps {
	data: PromotionConfig

	onChange: (e: ChangeEvent<any>) => void
	setFieldValue: (field: string, value: any) => void
}
const StepSetting: FC<StepSettingProps> = ({
	data,
	onChange,
	setFieldValue,
}) => {
	const steps = data.steps || [{ min: 0, max: 0, bonus: 0, deleted: false }]

	useEffect(() => {
		if(!data.steps) {
			setFieldValue('steps', steps)
		}
	}, [])
	
	return (
		<CheckboxWithContent
			label="ใช้งานโบนัสแบบเงื่อนไข"
			checked={data.step}
			setChecked={(v: boolean) => setFieldValue('step', v)}
		>
			<CheckboxWithContentBody>
				{steps.map((step, stepIndex) =>
					step.deleted ? null : (
						<TopCheckboxWithContentSection key={stepIndex}>
							<LeftCheckboxWithContentBody>
								<CheckboxWithContentInputItemWrapper>
									<Input
										type="number"
										name={`steps[${stepIndex}].min`}
										label="ยอดฝาก"
										defaultValue={step.min}
										required
										onChange={onChange}
									/>
								</CheckboxWithContentInputItemWrapper>
								<ToLabel>ถึง</ToLabel>
								<CheckboxWithContentInputItemWrapper>
									<Input
										type="number"
										name={`steps[${stepIndex}].max`}
										label="ยอดฝาก"
										defaultValue={step.max}
										required
										onChange={onChange}
									/>
								</CheckboxWithContentInputItemWrapper>
								<CheckboxWithContentInputItemWrapper>
									<Input
										type="number"
										name={`steps[${stepIndex}].bonus`}
										label="โบนัส"
										defaultValue={step.bonus}
										required
										onChange={onChange}
									/>
								</CheckboxWithContentInputItemWrapper>
							</LeftCheckboxWithContentBody>
							<RigtCheckboxWithContentBody>
								{
									stepIndex > 0 ? (
										<RemoveItemButton
											src={trashIcon}
											alt="remove"
											onClick={() => {
												data.steps[stepIndex].deleted = true

												setFieldValue('steps', steps)
											}}
										/>
									) : (
										<div style={{ width: '24px' }}></div>
									)
								}
							</RigtCheckboxWithContentBody>
						</TopCheckboxWithContentSection>
					)
				)}
				<BottomCheckboxWithContentSection
					onClick={() => {
						steps.push({
							min: 0,
							max: 0,
							bonus: 0,
							deleted: false,
						})
						setFieldValue('steps', steps)
					}}
				>
					<AddItemButton>เพิ่มเงื่อนไข</AddItemButton>
				</BottomCheckboxWithContentSection>
			</CheckboxWithContentBody>
		</CheckboxWithContent>
	)
}

export default StepSetting

const CheckboxWithContentBody = styled.div``

const TopCheckboxWithContentSection = styled.div`
	padding: 0 16px;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;

	&:not(:first-child) {
		margin-top: 15px;
	}
`
const BottomCheckboxWithContentSection = styled.div`
	margin-top: 20px;
`

const LeftCheckboxWithContentBody = styled.div`
	flex-direction: row;
	margin: 0 -6px;
	flex: 1;
	align-items: center;
`
const CheckboxWithContentInputItemWrapper = styled.div`
	margin: 0 6px;
	flex: 1;
	width: fit-content;
	margin: 0 12px 0 0;
`
const RigtCheckboxWithContentBody = styled.div`
	margin-left: 16px;
	align-items: center;
`
const RemoveItemButton = styled.img`
	margin-top: 24px;
	cursor: pointer;
`
const AddItemButton = styled.div`
	flex: 0 0 auto;
	width: 100%;
	height: 35px;
	align-items: center;
	justify-content: center;
	background: ${Colors.linkWater2};
	border-radius: 0px 0px 10px 10px;
	font-family: ${Fonts.primary};
	font-weight: bold;
	font-size: 14px;
	color: ${Colors.casper2};
	cursor: pointer;
`
const ToLabel = styled.div`
	margin: 0 12px 0 0;
	padding-top: 24px;
	color: ${Colors.casper};
	font-family: ${Fonts.primary};
	font-size: 14px;
	@media only screen and (max-width: ${MobileSize}px) {
		display: none;
	}
`
