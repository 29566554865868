import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { Member } from '../@types/member'
import ContentContainer from '../components/Common/ContentContainer'
import Loading from '../components/Common/Loading'
import EditMemberForm from '../components/EditMember/EditMemberForm'
import PageLayout from '../components/PageLayout'
import { Colors } from '../helpers/color'
import { getFullName } from '../helpers/member'
import { MobileSize } from '../helpers/responsive'
import PrefixService from '../services/prefix'

interface EditMemberParams {
	id: string
}
const EditMember = () => {
	const params = useParams<EditMemberParams>()
	const history = useHistory()

	const { isSubmitting, setValues, handleChange, handleSubmit, setFieldValue } = useFormik({
		initialValues: {
			phone: '',
			firstName: '',
			lastName: '',
			firstNameEN: '',
			lastNameEN: '',
			bankCode: '',
			bankNumber: '',

			totalTurn: 0,
			minWithdraw: 0,
			maxWithdraw: 0,
		},
		onSubmit: async (values) => {
			try {
				const res = await PrefixService.updateMemberInfo(values)

				toast.success(res.message)
			} catch ({ message }) {
				toast.error(message as string)
			}
		},
	})

	const [member, setMember] = useState<Member>()
	useEffect(() => {
		PrefixService.getMemberInfo(params.id)
			.then((res) => {
				setValues(res.member)
				setMember(res.member)
			})
			.catch(({ message }) => {
				toast.error(message as string)
				history.push('/member')
			})
	}, [params, history, setValues])

	if (!member) {
		return <Loading />
	}
	return (
		<PageLayout title={getFullName(member)} subTitle="แก้ไขข้อมูลสมาชิก" goBack="/member">
			<Body>
				<ContentContainer>
					<InnerBody>
						<EditMemberForm
							member={member}
							loading={isSubmitting}
							setFieldValue={setFieldValue}
							onChange={handleChange}
							onSubmit={handleSubmit}
						/>
					</InnerBody>
				</ContentContainer>
			</Body>
		</PageLayout>
	)
}

export default EditMember

const Body = styled.div`
	background: ${Colors.white};
	width: 100%;
	padding-bottom: 32px;
	margin-top: 18px;
`
const InnerBody = styled.div`
	max-width: 43%;
	width: 100%;
	margin: auto;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	@media only screen and (max-width: ${MobileSize}px) {
		max-width: 100%;
	}
`
