import { useFormik } from 'formik'
import moment from 'moment'
import qs from 'querystring'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import AddFixTxForm from '../components/AddFixTx/AddFixTxForm'
import ContentContainer from '../components/Common/ContentContainer'
import PageLayout from '../components/PageLayout'
import { Colors } from '../helpers/color'
import { MobileSize } from '../helpers/responsive'
import PrefixService from '../services/prefix'
import { requestAuthInit } from '../store/auth/auth.actions'

const AddFixTx = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	const query = qs.parse(history.location.search.substr(1))

	const {
		values,
		isSubmitting,
		setFieldValue,
		handleChange,
		handleSubmit,
	} = useFormik({
		initialValues: {
			fixType: 'depositMemberBank',
			phone: query.phone?.toString() || '',
			amount: 0,
			turn: 0,
			maxWithdraw: 0,
			transferTime: moment().format('DD/MM/YYYY 00:00'),
			remark: '',
		},
		onSubmit: async (values) => {
			try {
				values.amount = parseFloat(values.amount.toString())
				values.turn = parseFloat(values.turn.toString())
				values.maxWithdraw = parseFloat(values.maxWithdraw.toString())
				const res = await PrefixService.fixTx(values)

				dispatch(requestAuthInit(false))
				toast.success(res.message)
				history.push('/fix-tx')
			} catch ({ message }) {
				toast.error(message as string)
			}
		},
	})

	return (
		<PageLayout
			title="แก้ไขรายการผิดพลาด"
			subTitle="จัดการเว็บไซต์"
			goBack="/fix-tx"
		>
			<Body>
				<ContentContainer>
					<InnerBody>
						<AddFixTxForm
							values={values}
							loading={isSubmitting}
							setFieldValue={setFieldValue}
							handleChange={handleChange}
							handleSubmit={handleSubmit}
						/>
					</InnerBody>
				</ContentContainer>
			</Body>
		</PageLayout>
	)
}

export default AddFixTx

const Body = styled.div`
	background: ${Colors.white};
	width: 100%;
	margin-top: 18px;
`
const InnerBody = styled.div`
	max-width: 43%;
	width: 100%;
	margin: auto;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	@media only screen and (max-width: ${MobileSize}px) {
		max-width: 100%;
	}
`
