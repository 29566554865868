import { useFormik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory, useParams } from 'react-router'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import Toggle from '../components/Common/Toggle'
import EditBonusForm from '../components/EditBonus/EditBonusForm'
import PageLayout from '../components/PageLayout'
import { Colors } from '../helpers/color'
import { Fonts } from '../helpers/font'
import { MobileSize } from '../helpers/responsive'
import PrefixService from '../services/prefix'
import { ApplicationState } from '../store'
import { requestAuthInit } from '../store/auth/auth.actions'

interface EditBonusParams {
	key: string
}
const EditBonus = () => {
	const params = useParams<EditBonusParams>()
	const dipatch = useDispatch()
	const history = useHistory()

	const prefixState = useSelector((state: ApplicationState) => state.prefix)
	const bonus = prefixState.prefix.promotions[params.key]

	const { values, isSubmitting, setValues, setFieldValue, handleChange, handleSubmit } =
		useFormik({
			initialValues: bonus,
			onSubmit: async (values) => {
				if (!values.limit) {
					toast.error('กรุณาระบุประเภทโบนัส')
					return false
				}
				if (!values.type) {
					toast.error('กรุณาระบุประเภทจำนวน')
					return false
				}
				if (!values.gameType) {
					toast.error('กรุณาระบุประเภทเกม')
					return false
				}

				values.amount = parseFloat(values.amount.toString())
				values.minimum = parseFloat(values.minimum.toString())
				values.turn = parseFloat(values.turn.toString())
				values.maxWithdraw = parseFloat(values.maxWithdraw.toString())
				values.minBonus = parseFloat(values.minBonus.toString())
				values.maxBonus = parseFloat(values.maxBonus.toString())
				values.steps = values.steps.filter((x) => !x.deleted)
				values.periods = values.periods.filter((x) => !x.deleted)

				try {
					const res = await PrefixService.savePromotionConfig(params.key, values)

					dipatch(requestAuthInit(false))
					toast.success(res.message)
					history.push('/promotions')
				} catch ({ message }) {
					toast.error(message as string)
				}
			},
		})

	if (!bonus) {
		return <Redirect to="/promotions" />
	}
	return (
		<PageLayout title="แก้ไขโบนัส" subTitle="จัดการเว็บไซต์" goBack="/promotions">
			<Body>
				<InnerBody>
					<MainTitleWrapper>
						<FormTitle style={{ marginTop: '20px' }}>รายละเอียดโบนัส</FormTitle>
						<PromotionStatusToggleWrapper>
							<Toggle
								activeLabel="เปิดใช้งาน"
								disableLabel="ปิดใช้งาน"
								active={values.enable}
								setActive={(v: boolean) => setFieldValue('enable', v)}
							/>
						</PromotionStatusToggleWrapper>
					</MainTitleWrapper>

					<EditBonusForm
						data={values}
						loading={isSubmitting}
						setValues={setValues}
						setFieldValue={setFieldValue}
						onChange={handleChange}
						onSubmit={handleSubmit}
					/>
				</InnerBody>
			</Body>
		</PageLayout>
	)
}

export default EditBonus

const Body = styled.div`
	background: ${Colors.white};
	width: 100%;
	margin-top: 18px;
`

const InnerBody = styled.div`
	max-width: 43%;
	width: 100%;
	margin: auto;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	@media only screen and (max-width: ${MobileSize}px) {
		max-width: 100%;
	}
`
const MainTitleWrapper = styled.div`
	flex-direction: row;
	width: 100%;
	justify-content: flex-end;
	align-items: center;
	@media only screen and (max-width: ${MobileSize}px) {
		justify-content: space-between;
	}
`

const FormTitle = styled.div`
	flex: 1;
	font-size: 24px;
	color: ${Colors.black2};
	font-weight: bold;
	font-family: ${Fonts.primary};
	margin: 0 0 20px 0;
	padding: 0;
`
const PromotionStatusToggleWrapper = styled.div`
	width: 162px;
	height: 36px;
	@media only screen and (max-width: ${MobileSize}px) {
		width: 90px;
	}
`
