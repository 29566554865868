import React, { FC } from 'react'
import { NewReportByMemberTransaction } from '../../../api/summary/types'
import { getFullName } from '../../../helpers/member'
import { format } from '../../../helpers/number'
import MoneyWithCurrency from '../../Common/Money/MoneyWithCurrency'
import PhoneNumberWithIcon from '../../Common/PhoneNumberWithIcon/PhoneNumberWithIcon'
import { BodyTR, Td, Th } from '../../Common/Table'
import EnhancedTable from '../../Common/Table/EnhancedTable'

interface ReportByMemberListTableProps {
	loading: boolean
	data: NewReportByMemberTransaction[]
}
const ReportByMemberListTable: FC<ReportByMemberListTableProps> = ({ loading, data }) => (
	<EnhancedTable
		loading={loading}
		data={data}
		theadSize={5}
		thead={
			<tr>
				<Th style={{ textAlign: 'left' }}>สมาชิก</Th>
				<Th>ยอดฝาก</Th>
				<Th>ยอดถอน</Th>
				<Th>กำไร/ขาดทุน</Th>
			</tr>
		}
	>
		{(row: NewReportByMemberTransaction) => (
			<BodyTR key={row?.member.id}>
				<Td>
					<b>{getFullName(row?.member)}</b>
					<br />
					<PhoneNumberWithIcon phoneNumber={row?.member.phone} />
				</Td>
				<Td>
					<MoneyWithCurrency
						value={format(row?.deposit, 2)}
						fontSize="16px"
						position="center"
						remark={`(${row?.depositCount} ครั้ง)`}
					/>
				</Td>
				<Td>
					<MoneyWithCurrency
						value={format(row?.withdraw, 2)}
						fontSize="16px"
						position="center"
						remark={`(${row?.withdrawCount} ครั้ง)`}
					/>
				</Td>
				<Td>
					<MoneyWithCurrency
						value={format((row?.deposit - row?.withdraw), 2)}
						fontSize="16px"
						position="center"
					/>
				</Td>
			</BodyTR>
		)}
	</EnhancedTable>
)

export default ReportByMemberListTable