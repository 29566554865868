import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import { BadgeType } from '../../@types/badge'
import { Member } from '../../api/member/types'
import activeToggleIcon from '../../assets/images/active-toggle-icon.svg'
import addCreditIcon from '../../assets/images/currency-button-icon.svg'
import historyIcon from '../../assets/images/history-button-icon.svg'
import noneActiveToggleIcon from '../../assets/images/non-active-toggle-icon.svg'
import settingIcon from '../../assets/images/setting-button-icon.svg'
import BadgeStatus from '../Common/BadgeStatus'
import BankType from '../Common/BankType/BankType'
import PhoneNumberWithIcon from '../Common/PhoneNumberWithIcon/PhoneNumberWithIcon'
import { BodyTR, Td, Th } from '../Common/Table'
import EnhancedTable from '../Common/Table/EnhancedTable'
import ToggleWithIcon from '../Common/Toggle/ToggleWithIcon'

interface MemberListTableProps {
	data: Member[]

	setMemberActive: (member: Member, v: boolean) => void
	fetchMemberInfo: (id: number) => void
	creditManagementModal: (phone: string) => void
}

const MemberListTable: FC<MemberListTableProps> = ({ data, setMemberActive, fetchMemberInfo, creditManagementModal }) => {
	const renderRow = (row: Member) => (
		<BodyTR key={`members.${row.id}`}>
			<Td>
				<b>
					{row.firstName} {row.lastName}
				</b>
				<br />
				<PhoneNumberWithIcon phoneNumber={row.phone} />
			</Td>
			<Td>
				<BankType bank={row.bankCode} bankNumber={row.bankNumber} />
			</Td>
			<Td>
				{row.status ? (
					<BadgeStatus type={BadgeType.info}>สมาชิกทั่วไป</BadgeStatus>
				) : (
					<BadgeStatus type={BadgeType.failed}>ระงับการใช้งาน</BadgeStatus>
				)}
			</Td>
			<Td>
				<MemberActionGroup>
					<ToggleWithIconWrapper data-tip data-for={`acc_status_${row.id}`}>
						<ToggleWithIcon
							leftIcon={noneActiveToggleIcon}
							rightIcon={activeToggleIcon}
							active={row.status}
							onChange={(v) => setMemberActive(row, v)}
						/>
					</ToggleWithIconWrapper>
					<ReactTooltip id={`acc_status_${row.id}`} effect="solid">
						<span>เปิด / ปิดการใช้งานบัญชี</span>
					</ReactTooltip>
					<ActionItem>
						<Link to={`/member/${row.id}/history`}>
							<HistoryButton src={historyIcon} alt="history" data-tip data-for={`history_${row.id}`} />
							<ReactTooltip id={`history_${row.id}`} effect="solid">
								<span>ประวัติการทำรายการ</span>
							</ReactTooltip>
						</Link>
					</ActionItem>
					<ActionItem onClick={() => creditManagementModal(row.phone)}>
						<AddCreditButton
							src={addCreditIcon}
							alt="add credit"
							data-tip
							data-for={`credit_${row.id}`}
						/>
						<ReactTooltip id={`credit_${row.id}`} effect="solid">
							<span>จัดการเครดิต</span>
						</ReactTooltip>
					</ActionItem>
					<ActionItem onClick={() => fetchMemberInfo(row.id)}>
						<SettingButton src={settingIcon} alt="setting" data-tip data-for={`edit_${row.id}`} />
						<ReactTooltip id={`edit_${row.id}`} effect="solid">
							<span>แก้ไข / ตั้งค่าสมาชิก</span>
						</ReactTooltip>
					</ActionItem>
				</MemberActionGroup>
			</Td>
		</BodyTR>
	)

	return (
		<EnhancedTable
			loading={!data}
			data={data}
			theadSize={4}
			thead={
				<tr>
					<Th style={{ textAlign: 'left' }}>สมาชิก</Th>
					<Th style={{ textAlign: 'left' }}>บัญชีธนาคาร</Th>
					<Th>สถานะสมาชิก</Th>
					<Th>จัดการ</Th>
				</tr>
			}
		>
			{(row: Member) => renderRow(row)}
		</EnhancedTable>
	)
}

export default MemberListTable

const MemberActionGroup = styled.div`
	flex-direction: row;
	justify-content: center;
`

const ToggleWithIconWrapper = styled.div`
	width: 70px;
	margin-right: 8px;
`
const HistoryButton = styled.img`
	cursor: pointer;
`
const AddCreditButton = styled.img`
	cursor: pointer;
`
const SettingButton = styled.img`
	cursor: pointer;
`

const ActionItem = styled.div`
	margin: 0 4px;
`
