import moment from 'moment'
import React, { FC } from 'react'
import styled from 'styled-components'
import { AffiliateSummaryItem } from '../../../api/summary/types'
import { Colors } from '../../../helpers/color'
import { format } from '../../../helpers/number'
import MoneyWithCurrency from '../../Common/Money/MoneyWithCurrency'
import PhoneNumberWithIcon from '../../Common/PhoneNumberWithIcon/PhoneNumberWithIcon'
import { BodyTR, Table, TableWrapper, Tbody, Td, Th, Thead, Time, TimeDate, TimeWrapper } from '../../Common/Table'

interface AffiliateTxListTableProps {
	data: AffiliateSummaryItem[]
}
const AffiliateTxListTable: FC<AffiliateTxListTableProps> = ({ data }) => {
	const renderContent = () =>
		data.map((row) => (
			<BodyTR key={row.createdAt}>
				<Td>
					<TimeWrapper>
						<TimeDate>{moment(row.createdAt).format('DD/MM/YYYY')}</TimeDate>
						<Time>{moment(row.createdAt).format('HH:mm:ss')}</Time>
					</TimeWrapper>
				</Td>
				<Td>
					<PhoneNumberWrapper>
						<span>
							{row.upline.firstName} {row.upline.lastName}
						</span>
						<PhoneNumberWithIcon phoneNumber={row.upline.phone} />
					</PhoneNumberWrapper>
				</Td>
				<Td>
					<PhoneNumberWrapper>
						<span>
							{row.downline.firstName} {row.downline.lastName}
						</span>
						<PhoneNumberWithIcon phoneNumber={row.downline.phone} />
					</PhoneNumberWrapper>
				</Td>
				<Td>
					<MoneyWithCurrency value={format(row.txAmount, 2)} fontSize="16px" />
				</Td>
				<Td>
					<MoneyWithCurrency value={format(row.amount, 2)} fontSize="16px" />
				</Td>
			</BodyTR>
		))

	return (
		<TableWrapper>
			<Table>
				<Thead>
					<tr>
						<Th style={{ textAlign: 'left' }}>เวลา</Th>
						<Th style={{ textAlign: 'left' }}>สมาชิกผู้แนะนำ</Th>
						<Th style={{ textAlign: 'left' }}>สมาชิกผู้เติมเงิน</Th>
						<Th style={{ textAlign: 'left' }}>ยอดฝาก</Th>
						<Th style={{ textAlign: 'left' }}>จำนวนเงิน</Th>
					</tr>
				</Thead>
				<Tbody>{renderContent()}</Tbody>
			</Table>
		</TableWrapper>
	)
}

export default AffiliateTxListTable

const PhoneNumberWrapper = styled.div`
	font-size: 16px;
	color: ${Colors.slateGray};
	font-weight: bold;
`
