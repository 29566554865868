import React, { ChangeEvent, FC } from 'react'
import Input from '../../Common/Input'
import ConfirmModal from '../../Common/Modal/ConfirmModal'

interface ConfirmSCBAccountOTPModalProps {
	loading: boolean

	onChange: (e: ChangeEvent<any>) => void
	onClose: () => void
	onSubmit: () => void
}
const ConfirmSCBAccountOTPModal: FC<ConfirmSCBAccountOTPModalProps> = ({
	loading,
	onChange,
	onClose,
	onSubmit,
}) => (
	<ConfirmModal
		title="ยืนยันบัญชี SCB API"
		loading={loading}
		onClose={onClose}
		onSubmit={onSubmit}
	>
		<Input type="text" name="otpCode" label="OTP" onChange={onChange} />
	</ConfirmModal>
)

export default ConfirmSCBAccountOTPModal
