import { FC } from 'react'
import styled from 'styled-components'
import { dateRanges, StaticDateRange } from '../../../@types/dashboard'
import { Colors } from '../../../helpers/color'
import { Fonts } from '../../../helpers/font'
import { MobileSize } from '../../../helpers/responsive'

interface StaticRangeSelectorProps {
	selected: StaticDateRange
	onSelect: (v: StaticDateRange) => void
}
const StaticRangeSelector: FC<StaticRangeSelectorProps> = ({
	selected,
	onSelect,
}) => {
	return (
		<StaticRangeWrapper>
			{dateRanges.map((range) => (
				<StaticRangeItem
					key={range.key}
					active={selected === range.key}
					onClick={() => onSelect(range.key)}>
					{range.text}
				</StaticRangeItem>
			))}
		</StaticRangeWrapper>
	)
}

export default StaticRangeSelector

const StaticRangeWrapper = styled.div`
	flex-direction: row;
	background: ${Colors.linkWater};
	border-radius: 1000px;
	flex: 1;
	padding: 6px 8px;
	@media only screen and (max-width: ${MobileSize}px) {
		border-radius: 0;
		width: 100%;
		overflow-y: scroll;
	}
`

interface StaticRangeItemProps {
	active: boolean
}
const StaticRangeItem = styled.div<StaticRangeItemProps>`
	height: 36px;
	border-radius: 1000px;
	padding: 0 18px;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	font-family: ${Fonts.primary};
	font-weight: 500;
	color: ${Colors.casper};
	${({ active }) =>
		active &&
		`box-shadow: 0px 10px 23px -10px rgba(59, 107, 231, 0.63); color: ${Colors.white}; background: ${Colors.dodgerBlue};`}

	:not(:last-child) {
		margin-right: 8px;
	}
`
