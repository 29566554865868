import React, { FC } from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import activeBankIcon from '../../../assets/images/active-bank.svg'
import activeDashboardIcon from '../../../assets/images/active-dashboard.svg'
import activeEditErrorListIcon from '../../../assets/images/active-edit-list.svg'
import activeMemberIcon from '../../../assets/images/active-member-list.svg'
import activePromotionIcon from '../../../assets/images/active-promotion.svg'
import activeWalletIcon from '../../../assets/images/active-wallet.svg'
import activeLogIcon from '../../../assets/images/active-log-icon.svg'
import bankIcon from '../../../assets/images/bank.svg'
import dashboardIcon from '../../../assets/images/dashboard.svg'
import editErrorListIcon from '../../../assets/images/edit-list.svg'
import logo from '../../../assets/images/logo-icon.svg'
import memberIcon from '../../../assets/images/member-list.svg'
import promotionIcon from '../../../assets/images/promotion.svg'
import settingIcon from '../../../assets/images/setting-icon.svg'
import logIcon from '../../../assets/images/log-icon.svg'
import walletIcon from '../../../assets/images/wallet.svg'
import { Colors } from '../../../helpers/color'
import { Fonts } from '../../../helpers/font'
import { MobileSize } from '../../../helpers/responsive'
import PrefixService from '../../../services/prefix'

interface SidebarItemProps {
	path: string
	icon: string
	activeIcon: string
	text: string
}
const SidebarItem: FC<SidebarItemProps> = ({
	path,
	icon,
	activeIcon,
	text,
}) => {
	const isActive = useLocation().pathname.indexOf(path) === 0

	return (
		<NavLink to={path}>
			<MenuLI isActive={isActive}>
				<MenuIcon src={isActive ? activeIcon : icon} alt={text} />
				<MenuText>{text}</MenuText>
			</MenuLI>
		</NavLink>
	)
}

interface SidebarProps {
	show: boolean
}
const Sidebar: FC<SidebarProps> = ({ show }) => {
	return (
		<Wrapper show={show}>
			<Logo>
				<LogoIcon src={logo} alt="MSN.bet" />
				<LogoText>{PrefixService.getPrefix()}</LogoText>
			</Logo>
			<MenuUL>
				<SidebarItem
					path="/main"
					icon={dashboardIcon}
					activeIcon={activeDashboardIcon}
					text="หน้าหลัก"
				/>

				{/* <MenuSeparator /> */}
				{/* <MenuHeader>จัดการเว็บไซต์</MenuHeader> */}
				<SidebarItem
					path="/member"
					icon={memberIcon}
					activeIcon={activeMemberIcon}
					text="รายการสมาชิก"
				/>
				<SidebarItem
					path="/transactions"
					icon={walletIcon}
					activeIcon={activeWalletIcon}
					text="รายการฝาก / ถอน"
				/>
				<SidebarItem
					path="/promotions"
					icon={promotionIcon}
					activeIcon={activePromotionIcon}
					text="โปรโมชั่น"
				/>
				<SidebarItem
					path="/fix-tx"
					icon={editErrorListIcon}
					activeIcon={activeEditErrorListIcon}
					text="แก้ไขรายการผิดพลาด"
				/>
				<SidebarItem
					path="/bank"
					icon={bankIcon}
					activeIcon={activeBankIcon}
					text="ธนาคาร"
				/>
				<SidebarItem
					path="/report"
					icon={logIcon}
					activeIcon={activeLogIcon}
					text="รายงาน"
				/>
				<SidebarItem
					path="/users"
					icon={memberIcon}
					activeIcon={activeMemberIcon}
					text="ผู้ใช้งาน"
				/>
				<SidebarItem
					path="/setting"
					icon={settingIcon}
					activeIcon={settingIcon}
					text="ตั้งค่า Prefix"
				/>
				<SidebarItem
					path="/log"
					icon={logIcon}
					activeIcon={activeLogIcon}
					text="บันทึกกิจกรรม"
				/>

				<MenuSeparator />

				<SidebarItem
					path="/logout"
					icon={settingIcon}
					activeIcon={settingIcon}
					text="ออกจากระบบ"
				/>
			</MenuUL>
		</Wrapper>
	)
}

export default Sidebar

interface WrapperStyledProps {
	show: boolean
}
const Wrapper = styled.div<WrapperStyledProps>`
	width: 18.88%;
	height: 100vh;
	overflow-y: auto;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
	::-webkit-scrollbar {
		width: 0px; /* Remove scrollbar space */
		background: transparent; /* Optional: just make scrollbar invisible */
	}
	background: ${Colors.mirage};
	@media only screen and (max-width: ${MobileSize}px) {
		position: fixed;
		width: 72%;
		height: calc(100vh - 52px);
		z-index: 99999;
		top: 52px;
		transition: all 0.3s linear;
		left: ${({ show }) => (show ? '0px' : '-1000px')};
	}
`

const Logo = styled.div`
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: 70px 0 40px 0;
	@media only screen and (max-width: ${MobileSize}px) {
		display: none;
	}
`

const LogoText = styled.h1`
	font-weight: 900;
	font-size: 24px;
	color: ${Colors.white};
	margin-left: 12px;
`

const LogoIcon = styled.img`
	width: 23px;
	height: 23px;
`

const MenuUL = styled.div`
	padding: 0;
	@media only screen and (max-width: ${MobileSize}px) {
		margin-top: 32px;
	}
`

interface MenuLIStyledProps {
	isActive: boolean
}
const MenuLI = styled.div<MenuLIStyledProps>`
	list-style: none;
	padding: 10px 0;
	flex-direction: row;
	background: ${({ isActive }) => (isActive ? Colors.ebonyClay : 'none')};
	align-items: center;
	cursor: pointer;
	a {
		color: ${({ isActive }) => isActive && Colors.white};
	}
	:hover a {
		color: ${Colors.white};
	}
	:hover {
		background: ${Colors.ebonyClay};
	}
	&:before {
		${({ isActive }) => isActive && "content:'';"}
		width: 4px;
		height: 100%;
		background: ${Colors.dodgerBlue};
		position: absolute;
		left: 0;
		top: 0;
	}
`
const MenuIcon = styled.img`
	width: 24px;
	height: 24px;
	margin-left: 30px;
`
const MenuText = styled.div`
	cursor: pointer;
	color: ${Colors.casper};
	font-size: 16px;
	font-weight: 600;
	margin-left: 20px;
	font-family: ${Fonts.primary};
	text-decoration: none;
`

const MenuSeparator = styled.div`
	height: 1px;
	width: 100%;
	flex: 0 0 auto;
	margin: 32px 0;
	background: ${Colors.ebonyClay};
`

const MenuHeader = styled.div`
	color: ${Colors.regentGray};
	font-size: 18px;
	font-family: ${Fonts.primary};
	font-weight: 700;
	margin-bottom: 16px;
	margin-left: 34px;
`
