import React, { FC } from 'react'
import styled from 'styled-components'
import { Log } from '../../api/log/types'
import { BodyTR, Td, Th, Time, TimeDate, TimeWrapper } from '../Common/Table'
import { Colors } from '../../helpers/color'
import { Fonts } from '../../helpers/font'
import EnhancedTable from '../Common/Table/EnhancedTable'
import moment from 'moment'

interface LogListTableProps {
	data: Log[]
}

const LogListTable: FC<LogListTableProps> = ({ data }) => {
	const renderRow = (row: Log) => (
		<BodyTR key={`members.${row.ID}`}>
			<Td>
				<TimeWrapper>
					<TimeDate>{moment(row?.UpdatedAt).format('DD/MM/YYYY')}</TimeDate>
					<Time>{moment(row?.UpdatedAt).format('HH:mm:ss')}</Time>
				</TimeWrapper>
			</Td>
			<Td><Text>{row?.Description}</Text></Td>
			<Td><Text>{row?.Operator}</Text></Td>
			<Td><Text>{row?.IPAddress}</Text></Td>
		</BodyTR>
	)

	return (
		<EnhancedTable
			loading={!data}
			data={data}
			theadSize={4}
			thead={
				<tr>
					<Th style={{ textAlign: 'left' }}>วันเวลา</Th>
					<Th style={{ textAlign: 'left' }}>รายละเอียด</Th>
					<Th style={{ textAlign: 'left' }}>ผู้ดำเนินการ</Th>
					<Th style={{ textAlign: 'left' }}>IP Address</Th>
				</tr>
			}
		>
			{(row: Log) => renderRow(row)}
		</EnhancedTable>
	)
}

export default LogListTable

const Text = styled.div`
	font-family: ${Fonts.primary};
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	color: ${Colors.slateGray};
`