import { useFormik } from 'formik'
import React, { FC } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import {
	BankConfig,
	SCBAccountConfig,
	WithdrawAccountConfig,
} from '../../@types/prefix'
import {
	createBankSelector,
	createSCBAccountSelector,
} from '../../helpers/bank'
import PrefixService from '../../services/prefix'
import PrimaryButton from '../Common/Button/PrimaryButton'
import Card, { CardTitle, CardTitleWrapper } from '../Common/Card'
import Input from '../Common/Input'
import Select from '../Common/Select'
import ContentContainer from '../Common/ContentContainer'

interface WithdrawSettingProps {
	config: WithdrawAccountConfig

	banks: BankConfig[]
	scbAccounts: SCBAccountConfig[]
}
const WithdrawSetting: FC<WithdrawSettingProps> = ({
	config,
	banks,
	scbAccounts,
}) => {
	const { values, isSubmitting, handleChange, handleSubmit } = useFormik({
		initialValues: config,
		onSubmit: async (values) => {
			try {
				const res = await PrefixService.saveWithdrawAccount(values)
				toast.success(res.message)
			} catch ({ message }) {
				toast.error(message as string)
			}
		},
	})

	return (
		<ContentContainer>
			<Card padding="24px 0" mobilePadding="12px 0">
				<CardTitleWrapper>
					<CardTitle>ตั้งค่าถอนเงินอัตโนมัติ</CardTitle>
				</CardTitleWrapper>

				<CardForm onSubmit={handleSubmit}>
					<InputSection>
						<Select
							items={[
								{
									label: 'SCB API',
									value: 'api',
								},
								{
									label: 'SCB Easy Net',
									value: 'easynet',
								},
							]}
							name="type"
							label="ประเภท"
							defaultValue={values.type}
							required
							onChange={handleChange}
						/>
					</InputSection>
					<InputSection>
						<Input
							type="number"
							label="ยอดถอนสูงสุด"
							defaultValue={values.maxAmount}
							required
							onChange={handleChange}
						/>
					</InputSection>
					<InputSection>
						<Select
							items={
								values.type === 'api'
									? createSCBAccountSelector(scbAccounts)
									: createBankSelector(banks)
							}
							name="accountNumber"
							label="บัญชี"
							defaultValue={values.accountNumber}
							required
							onChange={handleChange}
						/>
					</InputSection>
					{values.type === 'easynet' && (
						<InputSection>
							<Input
								type="text"
								label="เบอร์โทรศัพท์ 3 ตัวสุดท้าย"
								maxLength={3}
								defaultValue={values.mobileNo}
								required
								onChange={handleChange}
							/>
						</InputSection>
					)}

					<PrimaryButton
						type="submit"
						width="120px"
						loading={isSubmitting}
					>
						บันทึกข้อมูล
					</PrimaryButton>
				</CardForm>
			</Card>
		</ContentContainer>
	)
}

export default WithdrawSetting

const InputSection = styled.div`
	margin-bottom: 18px;
	flex: 1;
`
const CardForm = styled.form`
	padding: 32px 32px 0 32px;
`
