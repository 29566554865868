import React from 'react'
import styled from 'styled-components'
import { Colors } from '../../helpers/color'

const TabDataLoading = () => (
	<Wrapper>
		<i className="fad fa-spinner-third fa-spin fa-2x" />
	</Wrapper>
)

export default TabDataLoading

const Wrapper = styled.div`
	width: 100%;
	text-align: center;
	color: ${Colors.anakiwa};
	margin-top: 2.5rem;
`
